import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Typography,
  Drawer,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useStyles } from './PerformanceStyle';

import { AllResponses, IPerformanceCondition, IAdvancedCondition, questionType, ISurvey } from '../../types/survey';
import { cohortRules } from '../../static/questionRule';

import { updateCondition } from '../../slices/performanceSlice';
import { RootState, useAppDispatch } from '../../store';
import { filterConditionsHandler } from '../../utils/performanceUtil';
import AlertUtil from '../../utils/AlertUtil';
import { modifiedDate } from '../../utils/DateUtil';

import closeImg from '../../images/delete-icon.svg';
import deleteImg from '../../images/editor/delete-icon.svg';
import saveCohortImg from '../../images/surveyPerformance/save-cohort-icon.svg';
import whiteSaveCohortImg from '../../images/surveyPerformance/save-cohort-white.svg';
import intentTooltipIcon from '../../images/surveyPerformance/intent-tooltip-icon.svg';

import { StyledTableRow } from '../common/tableStyles';
import {
  createFollowupCohort,
  deleteCohort,
  editSurveyCohort,
  fetchFollowupCohortsBySurveyId,
} from '../../services/audienceService';
import { ISurveyFollowupCohort } from '../../types/audience';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

const conditionDefault = {
  question_id: '',
  question_type: '',
  rule_type: '',
  responses: [] as Array<string>,
  scale_value: '',
};

export default function PerformanceFilterDrawer(props: {
  toggleDrawer: (boolean) => void;
  rightState: boolean;
  fetchSurveyInfo: () => void;
  surveyInfo: ISurvey;
  allResponses: AllResponses[][];
  questionThemes: any;
  advancedConditions: IAdvancedCondition;
  handleAdvanceConditions: (only_high_intent: boolean) => void;
}) {
  const { toggleDrawer, rightState, surveyInfo, questionThemes, advancedConditions, handleAdvanceConditions } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const { survey_id, questions = [], created_at } = surveyInfo;

  const [drawerStatus, setDrawerStatus] = useState<string>('');
  const [followupCohorts, setFollowupCohorts] = useState<Array<ISurveyFollowupCohort>>([]);
  const [cohortName, setCohortName] = useState<string>('');
  const [activeCohortId, setActiveCohortId] = useState<string>('');

  const [tempConditions, setTempConditions] = useState<Array<IPerformanceCondition>>([]);
  const [tempAdvancedConditions, setTempAdvancedConditions] = useState<IAdvancedCondition>({
    only_high_intent: false,
  });

  const getFollowupCohortsBySurvey = async () => {
    const cohorts_result = await fetchFollowupCohortsBySurveyId(survey_id);
    if (cohorts_result) {
      setFollowupCohorts(cohorts_result);
    }
  };

  useEffect(() => {
    getFollowupCohortsBySurvey();
  }, []);

  // conditions for performance filter (coming from slice)
  const conditions = useSelector((state: RootState) => state.performanceConditions);

  const handleConditionsReset = () => {
    setTempConditions(JSON.parse(JSON.stringify(conditions)));
    setTempAdvancedConditions({ ...advancedConditions });
  };

  //deep clone
  useEffect(() => {
    handleConditionsReset();
  }, []);

  const handleApplyFilter = () => {
    const valid_conditions = filterConditionsHandler(tempConditions);
    dispatch(updateCondition(valid_conditions));
    handleAdvanceConditions(tempAdvancedConditions.only_high_intent);
    toggleDrawer(false);
  };

  const handleQuestionRules = (question_id: string) => {
    const questionItem = questions.find(q => q.question_id === question_id);
    if (questionItem) {
      const { question_type } = questionItem;
      if (question_type === 'single_select_feedback' || question_type === 'input_feedback') {
        return cohortRules.singleSelectRules;
      } else if (question_type === 'multi_select_feedback') {
        return cohortRules.multiSelectRules;
      } else if (
        question_type === 'nps_feedback' ||
        question_type === 'scale_feedback' ||
        question_type === 'star_feedback' ||
        question_type === 'emoji_feedback'
      ) {
        return cohortRules.scaleRules;
      } else if (question_type === 'intro_prompt') {
        return cohortRules.introPromptRules;
      } else if (question_type === 'data_collection') {
        return cohortRules.dataCollectionRules;
      }
    }
    return [];
  };

  const handleOptionsList = (question_id: string) => {
    const questionItem = questions.find(q => q.question_id === question_id);
    if (questionItem) {
      const { question_type, properties } = questionItem;
      if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
        return properties.options;
      } else if (question_type === 'input_feedback') {
        return questionThemes[question_id] || [];
      }
    }
    return [];
  };

  const handleQuestions = (question_id: string, idx: number) => {
    const questionItem = questions.find(q => q.question_id === question_id);
    if (questionItem) {
      const { question_type } = questionItem;
      tempConditions[idx] = {
        question_id,
        rule_type: '',
        responses: [],
        scale_value: '',
        question_type,
      };
      setTempConditions([...tempConditions]);
    }
  };

  const handleRules = (question_id: string, rule_type: string, idx: number) => {
    const questionItem = questions.find(q => q.question_id === question_id);
    if (questionItem) {
      const { question_type } = questionItem;
      tempConditions[idx] = { question_id, question_type, rule_type, responses: [], scale_value: '' };
      setTempConditions([...tempConditions]);
    }
  };

  const handleAnswers = (question_type: string, idx: number, fieldName: string, fieldValue: string) => {
    if (fieldName === 'responses') {
      if (tempConditions[idx].responses?.includes(fieldValue)) {
        tempConditions[idx].responses = tempConditions[idx].responses?.filter(o => o !== fieldValue);
      } else {
        tempConditions[idx].responses.push(fieldValue);
      }
    } else if (fieldName === 'scale_value') {
      fieldValue = fieldValue.trim();
      if (fieldValue === '') {
        tempConditions[idx].scale_value = '';
      } else {
        let number_value = +fieldValue;

        if (question_type === 'nps_feedback' && number_value < 0) {
          number_value = 0;
        }
        if (question_type === 'nps_feedback' && number_value > 10) {
          number_value = 10;
        }
        if (question_type === 'scale_feedback' && number_value <= 0) {
          number_value = 1;
        }
        if (question_type === 'scale_feedback' && number_value > 5) {
          number_value = 5;
        }
        if ((question_type === 'star_feedback' || question_type === 'emoji_feedback') && number_value <= 0) {
          number_value = 1;
        }
        if ((question_type === 'star_feedback' || question_type === 'emoji_feedback') && number_value > 5) {
          number_value = 5;
        }

        tempConditions[idx].scale_value = number_value.toString();
      }
    }

    setTempConditions([...tempConditions]);
  };

  const questionData = question_id => {
    const quest = questions.find(q => q.question_id === question_id);

    if (quest) {
      const { question_order, question_text } = quest;
      return (
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'subtitle1'} className={classes.questionOrder}>
            #{question_order}
          </Typography>
          <Typography pl={0.6} variant={'subtitle1'} noWrap>
            {question_text}
          </Typography>
        </Box>
      );
    }
    return <Typography variant={'subtitle1'} noWrap></Typography>;
  };

  //handle setting & empty followup cohort values
  const handleFollowupCohort = (
    cohort_id: string,
    cohort_name: string,
    conditions: Array<IPerformanceCondition>,
    advanced_conditions: IAdvancedCondition,
  ) => {
    if (conditions && conditions.length > 0) {
      setTempConditions(conditions);
    } else {
      setTempConditions(JSON.parse(JSON.stringify([conditionDefault])));
    }

    if (advanced_conditions && Object.keys(advanced_conditions).length > 0) {
      setTempAdvancedConditions(advanced_conditions);
    } else {
      setTempAdvancedConditions({ only_high_intent: false });
    }

    setDrawerStatus('edit');
    setCohortName(cohort_name);
    setActiveCohortId(cohort_id);
  };

  const handleFollowupCohortReset = () => {
    handleConditionsReset();
    setDrawerStatus('');
    setCohortName('');
    setActiveCohortId('');
  };

  const newFollowupCohort = async () => {
    const filtered_conditions = filterConditionsHandler(tempConditions);
    const new_cohort = await createFollowupCohort(
      { conditions: filtered_conditions, cohort_name: cohortName, advanced_conditions: tempAdvancedConditions },
      survey_id,
    );
    if (new_cohort) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Cohort created successfully',
      });

      if (tracking_info) {
        amplitudeEvent(tracking_info, 'create followup survey', { source: 'survey details page' });
      }
    }
  };

  const editFollowupCohort = async () => {
    const filtered_conditions = filterConditionsHandler(tempConditions);
    const cohort = await editSurveyCohort(
      {
        conditions: filtered_conditions,
        cohort_name: cohortName,
        advanced_conditions: tempAdvancedConditions,
        cohort_id: activeCohortId,
      },
      survey_id,
    );
    if (cohort) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Cohort edited successfully',
      });
    }
  };

  //handle both flows here
  const handleSave = async () => {
    if (activeCohortId) {
      await editFollowupCohort();
    } else {
      await newFollowupCohort();
    }

    handleFollowupCohortReset();
    await getFollowupCohortsBySurvey();
  };

  const deleteFollowupCohort = async (cohort_id: string) => {
    const cohort = await deleteCohort(cohort_id);
    if (cohort) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Cohort deleted successfully',
      });
    }

    handleFollowupCohortReset();
    await getFollowupCohortsBySurvey();
  };

  const addNewCondition = () => {
    const condition_count = tempConditions.length;

    if (condition_count > 10) {
      AlertUtil.fire({
        icon: 'error',
        title: 'More than 10 conditions cannot be applied',
      });
      return false;
    }

    tempConditions.push(conditionDefault);
    setTempConditions([...tempConditions]);
  };

  const deleteCondition = (idx: number) => {
    tempConditions.splice(idx, 1);
    setTempConditions([...tempConditions]);
  };

  const { only_high_intent } = tempAdvancedConditions;

  return (
    <Drawer
      anchor={'right'}
      open={rightState}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box py={5} sx={{ width: '48vw', minWidth: '600px' }} px={5}>
        <img src={closeImg} alt="" onClick={toggleDrawer} className={classes.closeIcon} />

        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={4}>
          {drawerStatus ? (
            <TextField
              className={classes.customCohortField}
              placeholder="Type cohort name here..."
              value={cohortName}
              onChange={e => setCohortName(e.target.value)}
            />
          ) : (
            <Typography variant="h4">Select filters</Typography>
          )}

          {drawerStatus ? (
            <div>
              <Button className={classes.transparentButton} onClick={() => handleFollowupCohortReset()}>
                <Typography variant="subtitle1" fontWeight={700} color={'primary.contrastText'} pl={0.5}>
                  Cancel
                </Typography>
              </Button>
              <Button
                variant={'contained'}
                onClick={() => {
                  handleSave();
                }}
              >
                <img src={whiteSaveCohortImg} alt="" />
                <Typography variant="subtitle1" fontWeight={700} pl={0.5}>
                  Save
                </Typography>
              </Button>
            </div>
          ) : (
            <div>
              <Button className={classes.transparentButton} onClick={() => setDrawerStatus('create')}>
                <Box display="flex" alignItems={'center'}>
                  <img src={saveCohortImg} alt="" />
                  <Typography variant="subtitle1" fontWeight={700} color={'primary.contrastText'} pl={0.5}>
                    Create cohort
                  </Typography>
                </Box>
              </Button>
              <Button variant={'contained'} onClick={() => handleApplyFilter()}>
                Apply filters ({filterConditionsHandler(tempConditions).length})
              </Button>
            </div>
          )}
        </Box>
        {created_at && moment(created_at).isAfter(moment('2023-06-12')) && (
          <Box display="flex" alignItems="center" mt={2}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem', color: 'black' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
              classes={{
                root: classes.check,
                checked: classes.checked,
              }}
              checked={only_high_intent}
              onChange={() => setTempAdvancedConditions({ only_high_intent: !only_high_intent })}
            />
            <Typography variant="subtitle1" fontWeight={700} mx={1} className={classes.intentTitle}>
              Include only high intent responses
            </Typography>
            <Tooltip
              classes={{
                tooltip: classes.customTooltip,
              }}
              title={
                <Box maxWidth={'150px'}>
                  <Typography variant="subtitle2" fontWeight={700}>
                    High intent
                  </Typography>
                  <Typography variant="subtitle2">{`Survey Responses with a response time >1.5s`}</Typography>
                </Box>
              }
              placement="top"
            >
              <img src={intentTooltipIcon} alt="" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>
        )}

        <Box display="flex" className={classes.conditionBox}>
          <Box px={2} py={3} className={classes.cohortType}>
            <Typography className={classes.conditionUserBox} px={1} variant={'subtitle1'} fontWeight={'700'}>
              The Users who
            </Typography>

            {tempConditions &&
              tempConditions.map((option, idx) => (
                <Box className={classes.conditionUserBox} key={idx}>
                  <Typography px={1} variant={'subtitle1'} fontWeight={'700'}>
                    AND also
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box width={1}>
            <Box>
              {tempConditions &&
                tempConditions.map(({ question_id, rule_type, responses, question_type, scale_value }, idx) => (
                  <Box px={2} pb={2} borderBottom={1} borderColor={'divider'} position={'relative'}>
                    <Box display="flex" alignItems="center" pt={2}>
                      <Typography
                        variant={'subtitle1'}
                        width={'90px'}
                        textAlign={'right'}
                        style={{ overflow: 'unset' }}
                        noWrap
                      >
                        ... responded to
                      </Typography>

                      <Select
                        variant="outlined"
                        displayEmpty
                        className={classes.selectField}
                        renderValue={() =>
                          question_id ? (
                            <Typography variant={'subtitle1'} maxWidth="30ch" style={{ whiteSpace: 'pre-wrap' }}>
                              {questionData(question_id)}
                            </Typography>
                          ) : (
                            <Typography variant={'subtitle1'}>Select Question</Typography>
                          )
                        }
                        value={question_id}
                        style={{ marginLeft: 21, width: '75%' }}
                        onChange={e => handleQuestions(e.target.value as string, idx)}
                      >
                        {questions.map(
                          ({ question_id, question_text, question_order }, id) =>
                            tempConditions.every(c => c.question_id !== question_id) && (
                              <MenuItem value={question_id} key={id}>
                                <Box display={'flex'} alignItems={'center'}>
                                  <Typography variant={'subtitle1'} className={classes.questionOrder}>
                                    #{question_order}
                                  </Typography>
                                  <Typography variant={'subtitle1'} ml={0.6} maxWidth="50ch" noWrap>
                                    {question_text}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ),
                        )}
                      </Select>
                      {idx > 0 && (
                        <img
                          src={closeImg}
                          alt=""
                          className={classes.deleteIcon}
                          onClick={() => deleteCondition(idx)}
                        />
                      )}
                    </Box>
                    {question_id && (
                      <Box display={'flex'} alignItems={'center'} mt={2}>
                        <Box width={'100px'} textAlign={'right'}>
                          <Typography variant={'subtitle1'} style={{ overflow: 'unset' }} noWrap>
                            {question_type === 'input_feedback' ? '... with theme' : '... with answer'}
                          </Typography>
                        </Box>

                        <Select
                          variant="outlined"
                          displayEmpty
                          className={classes.selectField}
                          renderValue={() => (
                            <Typography variant={'subtitle1'}>
                              {handleQuestionRules(question_id).find(q => q.value === rule_type)
                                ? `${handleQuestionRules(question_id).find(q => q.value === rule_type)?.label}`
                                : question_type === 'input_feedback'
                                ? 'Select theme'
                                : 'Select conditon type'}
                            </Typography>
                          )}
                          value={rule_type}
                          style={{ marginLeft: 12, width: '40%' }}
                          onChange={e => handleRules(question_id, e.target.value as string, idx)}
                        >
                          {handleQuestionRules(question_id).map((option, key) => (
                            <MenuItem key={key} value={option.value}>
                              <Typography noWrap>{option.label}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                        {(question_type === 'single_select_feedback' ||
                          question_type === 'multi_select_feedback' ||
                          question_type === 'input_feedback') && (
                          <Select
                            variant="outlined"
                            displayEmpty
                            className={classes.selectField}
                            renderValue={() => (
                              <Typography variant={'subtitle1'}>
                                {responses && responses.length !== 0 ? `${responses.length} selected` : 'Select answer'}
                              </Typography>
                            )}
                            value={responses}
                            style={{ marginLeft: 12, width: '33%' }}
                            onChange={e => handleAnswers(question_type, idx, 'responses', e.target.value as string)}
                          >
                            {handleOptionsList(question_id).map(({ option_text, _id = '' }, index) => (
                              <MenuItem value={_id} key={index}>
                                <Box display={'flex'} alignItems={'center'}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                                    checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                                    classes={{
                                      root: classes.check,
                                      checked: classes.checked,
                                    }}
                                    checked={responses && responses.includes(_id)}
                                  />
                                  <Typography variant="subtitle1" ml={0.5} maxWidth="40ch" noWrap>
                                    {option_text}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {(question_type === 'nps_feedback' ||
                          question_type === 'scale_feedback' ||
                          question_type === 'star_feedback' ||
                          question_type === 'emoji_feedback') && (
                          <TextField
                            variant="outlined"
                            type="number"
                            className={classes.numberField}
                            placeholder="Enter value"
                            value={scale_value}
                            onChange={e => handleAnswers(question_type, idx, 'scale_value', e.target.value as string)}
                            style={{ marginLeft: 12, width: '32%' }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
            </Box>

            <Box px={4} py={3}>
              <Typography variant="subtitle2">Select a condition to define your cohort</Typography>
              <Box display="flex" alignItems="center">
                <Button variant="contained" className={classes.cohortButton} onClick={() => addNewCondition()}>
                  <Typography variant={'subtitle1'}>Responded to question</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {followupCohorts.length > 0 && (
          <>
            <Typography fontWeight={700} mt={6.8} mb={1.5}>
              Saved cohorts
            </Typography>
            <TableContainer className={classes.cohortTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Cohort name</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Last modified</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {followupCohorts.map(
                    (
                      {
                        cohort_id,
                        cohort_name,
                        updated_at,
                        total_count,
                        follow_up_conditions = [],
                        follow_up_advanced_conditions = { only_high_intent: false },
                      },
                      idx,
                    ) => (
                      <StyledTableRow>
                        <TableCell component="th" scope="row" width={'5%'}>
                          {idx + 1}
                        </TableCell>
                        <TableCell width={'40%'}>{cohort_name}</TableCell>
                        <TableCell width={'10%'}>{total_count}</TableCell>
                        <TableCell width={'20%'}>{modifiedDate(updated_at, 'lll')}</TableCell>
                        <TableCell width={'15%'}>
                          <Button
                            className={classes.cohortDetailsButton}
                            onClick={() => {
                              handleFollowupCohort(
                                cohort_id,
                                cohort_name,
                                follow_up_conditions,
                                follow_up_advanced_conditions,
                              );
                            }}
                          >
                            <Typography variant={'subtitle2'} fontWeight={700} noWrap>
                              Edit
                            </Typography>
                          </Button>
                        </TableCell>
                        <TableCell width={'10%'}>
                          <img
                            src={deleteImg}
                            style={{ height: '40px', width: '40px', cursor: 'pointer' }}
                            alt=""
                            onClick={() => deleteFollowupCohort(cohort_id)}
                          />
                        </TableCell>
                      </StyledTableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Drawer>
  );
}
