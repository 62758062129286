import React from 'react';
import ForgetPassword from '../../components/login/ForgetPassword';

const ForgetPasswordPage = () => {
  return (
    <div>
      <ForgetPassword />
    </div>
  );
};

export default ForgetPasswordPage;
