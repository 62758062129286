import { styled, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const tableStyles = makeStyles(theme => ({
  tableMain: {
    '& .MuiTableCell-body': {
      fontSize: '0.87rem',
      padding: '11px 25px 12px 25px',
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
