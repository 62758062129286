export const allLanguages = {
  ar: 'Arabic',
  bn: 'Bengali',
  fr: 'French',
  de: 'German',
  hi: 'Hindi',
  id: 'Indonesian',
  kn: 'Kannada',
  ml: 'Malayalam',
  mr: 'Marathi',
  pt: 'Portuguese',
  es: 'Spanish',
  ta: 'Tamil',
  te: 'Telugu',
};

export const allLanguagesForSurvey = {
  en: 'English',
  ...allLanguages,
};
