import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Theme,
  Box,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import surveyStyles from '../common/SurveyStyles';
import { StyledTableRow } from '../common/tableStyles';
import { userRole } from '../../static/staticData';
import { IWorkspaceMember } from '../../types/member';
import { fetchWorkspaceMembers } from '../../services/workspaceMember';
import { editWorkspaceRole } from '../../services/workspaceMember';
import AlertUtil from '../../utils/AlertUtil';
import RemoveUserModal from '../organisation/modal/RemoveUserModal';
import { userImgFormat, userLastSeen } from '../../utils/stringUtil';
import { reInviteUser } from '../../services/adminService';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    roleBox: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 5,
      border: `1px solid ${theme.palette.divider}`,
    },

    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },
    userRoleField: {
      border: `1px solid ${theme.palette.divider}`,
      width: '7ch',
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-input': {
        padding: '0.5rem 0.9rem',
      },
    },
  }),
);

export default function ProjectUsersComponent(props: { openToggle: boolean; handleUserModal: () => void }) {
  const classes = useStyles();
  const common = surveyStyles();
  const [workspaceMembers, setWorkspaceMembers] = useState<Array<IWorkspaceMember>>();
  const [isLoading, setLoading] = useState(true);
  const [openUserAlertToggle, toggleUserAlertModal] = useState(false);
  const [memberId, setMemberId] = useState('');
  const { openToggle, handleUserModal } = props;

  const user = useSelector((state: RootState) => state.login);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'settings tab viewed', { tab: 'team' });
    }
  }, [tracking_info]);

  const fetchWorkspaceMember = async () => {
    const workspaceMember = await fetchWorkspaceMembers();
    if (workspaceMember) {
      setWorkspaceMembers(workspaceMember);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchWorkspaceMember();
  }, [openToggle, openUserAlertToggle]);

  const handleUserAlertModal = async (id: string) => {
    setMemberId(id);
    toggleUserAlertModal(true);
  };

  const reInviteNonVerifiedMember = async (id: string) => {
    const invite = await reInviteUser(id);
    if (invite) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Invite sent successfully',
      });
    }
  };

  const editWorkspace = async (member_id: string, value: string) => {
    const editWorkspaceItem = await editWorkspaceRole(
      {
        workspace_role: value,
      },
      member_id,
    );

    if (editWorkspaceItem && editWorkspaceItem.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Edited successfully',
      });
    }
    fetchWorkspaceMember();
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    return (
      <>
        {openUserAlertToggle && (
          <RemoveUserModal
            toggleModal={toggleUserAlertModal}
            open={openUserAlertToggle}
            memberId={memberId}
            isOrg={false}
          />
        )}
        <Grid container>
          <Box ml={'auto'} pt={1} pb={3}>
            <Button variant="contained" onClick={handleUserModal}>
              + Invite to project
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Last active</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workspaceMembers &&
                  workspaceMembers.map(
                    (
                      { member_id, member_name, member_img, member_email, last_active_at, workspace_role, is_verified },
                      idx,
                    ) => {
                      return (
                        <StyledTableRow key={idx}>
                          <TableCell component="th" scope="row" width={'25%'}>
                            <Box display="flex" alignItems="center">
                              {userImgFormat(member_name, member_img).isImg ? (
                                <img
                                  src={userImgFormat(member_name, member_img).userImg}
                                  alt=""
                                  className={classes.userImgBox}
                                />
                              ) : (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  className={classes.userImgBox}
                                  style={{ backgroundColor: `${userImgFormat(member_name, member_img).bgColor}` }}
                                >
                                  <Typography style={{ fontSize: '0.85rem', fontWeight: 700 }}>
                                    {userImgFormat(member_name, member_img).name}
                                  </Typography>
                                </Box>
                              )}

                              <Typography
                                style={{ paddingLeft: 10, maxWidth: '25ch', paddingRight: 5, marginTop: -3 }}
                                noWrap
                              >
                                {member_name ? member_name : 'New member'}
                              </Typography>
                              {user?.member_id === member_id ? `(You)` : ''}
                            </Box>
                          </TableCell>
                          <TableCell width={'25%'}>{member_email}</TableCell>
                          <TableCell width={'35%'}>
                            <Typography variant={is_verified ? 'body1' : 'body2'} style={{ fontSize: '0.9rem' }}>
                              {userLastSeen(last_active_at, is_verified)}
                            </Typography>
                          </TableCell>
                          <TableCell width={'15%'}>
                            {user?.w_role === 'viewer' || user?.member_id === member_id ? (
                              <Typography
                                pl={1.4}
                                py={1}
                                style={{
                                  fontSize: '0.95rem',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {workspace_role}
                              </Typography>
                            ) : (
                              <TextField
                                variant="outlined"
                                select
                                value={workspace_role}
                                className={classes.userRoleField}
                                onChange={e => editWorkspace(member_id, e.target.value)}
                                style={{ width: 140, textTransform: 'capitalize' }}
                              >
                                {userRole.map(({ label, value }, key) => (
                                  <MenuItem key={key} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                                <MenuItem onClick={() => handleUserAlertModal(member_id)}>Remove user</MenuItem>
                                {!is_verified && (
                                  <MenuItem onClick={() => reInviteNonVerifiedMember(member_id)} value="">
                                    Reinvite Member
                                  </MenuItem>
                                )}
                              </TextField>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      );
                    },
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  }
}
