import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, CircularProgress, Grid, MenuItem, Menu, Button, GridSize } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from '../spotlight/Spotlight.style';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

import { IPinnedChartType, IPinnedTakeaway } from '../spotlight/spotlight';
import DashboardHeader from './DashboardHeader';

import { DailyChartComponent } from '../spotlight/charts/DailyChartComponent';
import { ChartBaseComponent } from '../spotlight/charts/ChartBaseComponent';
import { SplitViewChartComponent } from '../spotlight/charts/SplitViewComponent';

import ConfirmationModal from '../spotlight/modal/ConfirmModal';

import { getDashboardDate } from '../spotlight/HelperUtil';
import AlertUtil from '../../utils/AlertUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

import { RootState, useAppDispatch } from '../../store';
import { retrieveChartDetails } from '../../slices/chartSlice';
import {
  deleteBoard,
  deleteTakeawayDetails,
  editChartView,
  fetchAllChartsInBoard,
  removeChartFromBoard,
  editChartOrder,
} from '../../services/spotlightService';
import { copyBoardLink } from '../../utils/stringUtil';

import { DashboardModal } from './DashboardModal';
import { retrieveDashboardDetails } from '../../slices/dashboardSlice';
import { getBoardImg } from '../../utils/BoardUtil';

import dashboardImg from '../../images/dashboard.svg';
import brokenLinkImg from '../../images/board/broken-link-img.png';
import copyImg from '../../images/spotlight/copy-icon.svg';

interface Iparams {
  board_id: string;
}

const EmptyChart = (props: { isPublicReport: boolean | string }) => {
  const classes = useStyles();
  const { isPublicReport } = props;
  return (
    <Box mt={10} className={classes.emptyStateContainer}>
      <img src={dashboardImg} alt="" style={{ width: 240 }} />
      <Typography mb={2} style={{ width: 280, marginTop: 21, textAlign: 'center' }}>
        Pin any chart here to track its performance over time
      </Typography>
      {!isPublicReport && (
        <Link to="/spotlight/new">
          <Button variant="contained">+ Create new chart</Button>
        </Link>
      )}
    </Box>
  );
};

const DashboardComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params: Iparams = useParams();
  const { board_id } = params;

  const [isLoading, setLoading] = useState(true);
  const [showConfirmModal, toggleConfirmModal] = useState(false);
  const [activeId, setActiveId] = useState('');

  const [openDashboardToggle, toggleDashboardModal] = useState(false);
  const [showBrokenLink, toggleBrokenLink] = useState(false);

  const [fullWidthChart, setFullWidthChart] = useState('');

  const [pinnedChartData, setPinnedChartData] = useState<Array<IPinnedChartType | IPinnedTakeaway>>([]);
  const [pinnedChartIds, setPinnedChartIds] = useState<Array<string>>([]);

  const IS_PUBLIC_REPORT = window.location && window.location.href && window.location.href.includes('report');

  const [chartAnchor, setChartAnchor] = React.useState<null | HTMLElement>(null);
  const [boardAnchor, setBoardAnchor] = React.useState<null | HTMLElement>(null);

  const [copyText, setCopyText] = useState('Copy link');

  if (!board_id) {
    history.push('/');
  }

  const dashboardDetails = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    dispatch(retrieveDashboardDetails(board_id));
  }, [board_id]);

  const { name, description, board_link, public_access } = dashboardDetails;

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setBoardAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setBoardAnchor(null);
  };

  // Get dashboard chart information
  const fetchBoardData = async () => {
    const boardResult = await fetchAllChartsInBoard(board_id);
    if (boardResult && boardResult.charts) {
      const { charts = [], takeaways = [], combined_order_ids = [] } = boardResult;
      let combined_results = [...charts, ...takeaways];

      //sort for maintaing order
      combined_results = combined_results.sort((a, b) => {
        const l_value = a.info_type === 'chart' ? a.chart_id : a.takeaway_id;
        const r_value = b.info_type === 'chart' ? b.chart_id : b.takeaway_id;
        return combined_order_ids.indexOf(l_value) - combined_order_ids.indexOf(r_value);
      });

      setPinnedChartData(combined_results);
      setPinnedChartIds(combined_order_ids);
    } else {
      if (IS_PUBLIC_REPORT) {
        toggleBrokenLink(true);
      } else {
        history.push('/');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBoardData();
  }, [board_id]);

  //delete chart && update activechart state
  const deletePinnedChart = async () => {
    if (!pinnedChartData) {
      return;
    }
    if (activeId) {
      const results = await removeChartFromBoard({ board_id }, activeId);
      if (results && results.message) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Chart unpinned successfully',
        });
      }

      if (tracking_info) {
        amplitudeEvent(tracking_info, 'chart unpinned', { tab: 'dashboard', 'chart id': activeId });
      }

      const activePinnedCharts = pinnedChartData.filter(c => c.info_type === 'takeaway' || c.chart_id !== activeId);
      setPinnedChartData(activePinnedCharts);

      const activeChartIds = pinnedChartIds.filter(c => c !== activeId);
      setPinnedChartIds(activeChartIds);
    }
    setChartAnchor(null);
  };

  const deletePinnedTakeaway = async () => {
    if (!pinnedChartData) {
      return;
    }
    if (activeId) {
      const results = await deleteTakeawayDetails({ board_id }, activeId);
      if (results && results.message) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Chart unpinned successfully',
        });
      }

      if (tracking_info) {
        amplitudeEvent(tracking_info, 'chart unpinned', { tab: 'dashboard', 'chart id': activeId });
      }

      const activePinnedCharts = pinnedChartData.filter(c => c.info_type === 'chart' || c.takeaway_id !== activeId);
      setPinnedChartData(activePinnedCharts);
    }
    setChartAnchor(null);
  };

  const canMoveInDirection = (curr_order: number, direction: string, curr_chart_width: number, chart_id: string) => {
    if (chart_id !== activeId) {
      return;
    }
    const chart_width_arr = pinnedChartData.map(p => p.chart_width);

    const previous_el_by_two = chart_width_arr[curr_order - 2] ? chart_width_arr[curr_order - 2] : null;
    const previous_el = chart_width_arr[curr_order - 1] ? chart_width_arr[curr_order - 1] : null;
    const curr_el = curr_chart_width;
    const next_el = chart_width_arr[curr_order + 1] ? chart_width_arr[curr_order + 1] : null;
    const next_el_by_two = chart_width_arr[curr_order + 2] ? chart_width_arr[curr_order + 2] : null;

    if (direction === 'u') {
      if (curr_order === 0) return null;
      if (curr_order === 1 && previous_el === 6 && curr_el === 6) {
        return null;
      }

      if (previous_el === 12 || previous_el_by_two === null) {
        return curr_order - 1;
      }

      if (previous_el === 6 && previous_el_by_two === 12) {
        return curr_order - 1;
      }

      return curr_order - 2;
    }

    if (direction === 'd') {
      if (curr_order === chart_width_arr.length - 1) return null;
      if (curr_order === chart_width_arr.length - 2 && next_el === 6 && curr_el === 6) {
        return null;
      }

      if (next_el === 12 || next_el_by_two === null) {
        return curr_order + 1;
      }

      if (next_el === 6 && next_el_by_two === 12) {
        return curr_order + 1;
      }

      return curr_order + 2;
    }

    if (direction === 'l' && curr_chart_width === 6) {
      if (curr_order === 0) return null;
      if (curr_el === 6 && previous_el === 12) {
        return null;
      }
      return curr_order - 1;
    }

    if (direction === 'r' && curr_chart_width === 6) {
      if (curr_order === chart_width_arr.length - 1) return null;
      if (curr_el === 6 && next_el === 12) {
        return null;
      }
      return curr_order + 1;
    }

    return null;
  };

  const moveInDirection = async (curr_order: number, direction: string, curr_chart_width: number, chart_id: string) => {
    const move_idx = canMoveInDirection(curr_order, direction, curr_chart_width, chart_id);

    if (move_idx !== null && move_idx !== undefined && move_idx >= 0 && pinnedChartIds.length > 0) {
      const modified_order_ids = pinnedChartIds;

      let min_idx = curr_order;
      let max_idx = move_idx;

      if (curr_order - move_idx < 0) {
        min_idx = move_idx;
        max_idx = curr_order;
      }

      const temp = modified_order_ids[min_idx];
      modified_order_ids[min_idx] = modified_order_ids[max_idx];
      modified_order_ids[max_idx] = temp;

      if (max_idx - min_idx == 2) {
        const temp = modified_order_ids[min_idx + 1];
        modified_order_ids[min_idx + 1] = modified_order_ids[max_idx];
        modified_order_ids[max_idx] = temp;
      }

      const update_results = await editChartOrder({ combined_order_ids: modified_order_ids }, board_id);
      if (update_results) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Edited successfully',
        });
        fetchBoardData();
      }
    }
  };

  const chartRedirectLink = (spotlight_type: string, chart_id: string, surveys) => {
    if (IS_PUBLIC_REPORT) {
      return;
    }

    if (spotlight_type === 'survey_details' && surveys?.data && surveys?.data.length > 0) {
      const activeId = surveys?.data[0];
      history.push(`/survey/${activeId}/`);
      return;
    }

    if (spotlight_type === 'spotlight') {
      history.push(`/spotlight/details/${chart_id}/`);
      return;
    }
  };

  // For delete the board
  const handleDeleteBoard = async () => {
    const deleteResult = await deleteBoard(board_id);
    if (deleteResult && deleteResult.message) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Deleted successfully',
      });

      dispatch(retrieveChartDetails());
      history.push(`/boards`);
    }
  };

  const handleCopyLink = () => {
    copyBoardLink(board_link);
    setCopyText('Copied!');
  };

  const handleChartView = async (chart_width: GridSize, chart_id: string, item_type: string) => {
    const view_result = await editChartView(
      {
        chart_width,
        item_type,
      },
      chart_id,
    );
    if (view_result) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Edited successfully',
      });
      fetchBoardData();
    }
  };

  if (isLoading || !pinnedChartData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    if (showBrokenLink) {
      return (
        <>
          <DashboardHeader />
          <Box mt={10} className={classes.emptyStateContainer}>
            <img src={brokenLinkImg} alt="" style={{ width: 200 }} />
            <Typography mb={2} style={{ width: 280, marginTop: 25, textAlign: 'center' }}>
              The link you are trying to access does not exist
            </Typography>
          </Box>
        </>
      );
    }
    return (
      <div>
        {openDashboardToggle && (
          <DashboardModal toggleModal={toggleDashboardModal} open={openDashboardToggle} boardId={board_id} />
        )}

        {showConfirmModal && (
          <ConfirmationModal
            toggleModal={toggleConfirmModal}
            open={showConfirmModal}
            handleDeleteBoard={handleDeleteBoard}
          />
        )}

        {IS_PUBLIC_REPORT && <DashboardHeader showOpenDashboard={true} />}

        <Box display={'flex'} alignItems={'flex-start'} mb={1} justifyContent={'space-between'}>
          <Box display={'flex'} width={'75%'}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.boardImgBox}
              style={{ backgroundColor: `${getBoardImg(board_id).color}` }}
              mr={2}
            >
              {getBoardImg(board_id).image}
            </Box>
            <Typography fontSize={'2.2rem'} fontWeight={700} mt={-0.5}>
              {name}
            </Typography>
          </Box>

          <Box display="flex" alignItems={'center'}>
            {public_access === 'public' && (
              <Button variant="contained" className={classes.optionButton} onClick={() => handleCopyLink()}>
                <Box display={'flex'} alignItems={'center'}>
                  <img src={copyImg} alt="" />
                  <Typography variant="subtitle1" fontWeight={700} pl={0.5}>
                    {copyText}
                  </Typography>
                </Box>
              </Button>
            )}
            {!IS_PUBLIC_REPORT && (
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                className={classes.optionButton}
                endIcon={<ExpandMoreIcon />}
                onClick={handleClick}
              >
                Settings
              </Button>
            )}

            <Menu
              className={classes.questionMenu}
              anchorEl={boardAnchor}
              keepMounted
              open={Boolean(boardAnchor)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  toggleDashboardModal(true);
                  handleClose();
                }}
              >
                <Typography variant="subtitle1">Edit details</Typography>
              </MenuItem>

              <MenuItem onClick={() => toggleConfirmModal(true)}>
                <Typography variant="subtitle1">Delete</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Typography mb={3}>{description}</Typography>
        {pinnedChartData && pinnedChartData.length > 0 ? (
          <Grid container spacing={1.75}>
            {pinnedChartData.map((item, idx) =>
              item.info_type === 'takeaway' ? (
                <Grid item lg={item.chart_width ? item.chart_width : 6} key={idx}>
                  <div className={classes.dashboardUpperBox} style={{ cursor: !IS_PUBLIC_REPORT ? 'pointer' : '' }}>
                    <div>
                      <Typography
                        variant="subtitle1"
                        className={IS_PUBLIC_REPORT ? classes.chartPublicTitle : classes.chartTitle}
                      >
                        {item.chart_name}
                      </Typography>
                      <Typography variant="subtitle2" lineHeight={0.7} pt={0.5}></Typography>
                    </div>

                    <div
                      style={{
                        marginLeft: 'auto',
                      }}
                    >
                      {!IS_PUBLIC_REPORT && (
                        <Box
                          p={0.5}
                          mr={-1.6}
                          onClick={e => {
                            e.stopPropagation();
                            setChartAnchor(e.currentTarget);
                            setActiveId(item.takeaway_id);
                          }}
                        >
                          <MoreVertIcon />
                        </Box>
                      )}

                      <Menu
                        id="long-menu"
                        anchorEl={chartAnchor}
                        open={item.takeaway_id === activeId}
                        onClose={e => {
                          setChartAnchor(null);
                          setActiveId('');
                        }}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            deletePinnedTakeaway();
                            setActiveId('');
                          }}
                        >
                          <Typography>Unpin</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            setFullWidthChart(fullWidthChart === item.takeaway_id ? '' : item.takeaway_id);
                            setActiveId('');
                          }}
                        >
                          <Typography
                            onClick={() =>
                              handleChartView(item.chart_width === 6 ? 12 : 6, item.takeaway_id, 'takeaway')
                            }
                          >
                            {item.chart_width === 6 ? 'Full Width' : 'Half Width'}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <div className={classes.dashboardLowerBox}>
                    {item.takeaways.map(({ title, desc }, idx) => (
                      <Box mt={1.5}>
                        <Box display="flex" alignItems="center" px={2.5}>
                          <div className={classes.takeawayOrderBox}>
                            <Typography variant="subtitle2" fontWeight="bold">
                              #{+idx + 1}
                            </Typography>
                          </div>

                          <Typography ml={2} mt={-0.2} lineHeight={1.5}>
                            {title}
                          </Typography>
                        </Box>

                        {desc && (
                          <Box mx={2.5} ml={7} mt={1.5} style={{ backgroundColor: '#DFD0F14D', borderRadius: 6 }}>
                            <Typography variant={'subtitle1'} p={1} lineHeight={1.5} color="#555">
                              💡 {desc}
                            </Typography>
                          </Box>
                        )}
                        {item.takeaways.length !== +idx + 1 && (
                          <Box style={{ backgroundColor: '#DFD0F14D', marginTop: 18, marginBottom: 0, height: 1.5 }} />
                        )}
                      </Box>
                    ))}
                  </div>
                </Grid>
              ) : (
                <Grid item lg={item.chart_width ? item.chart_width : 6} key={idx}>
                  <div
                    className={classes.dashboardUpperBox}
                    style={{ cursor: !IS_PUBLIC_REPORT ? 'pointer' : '' }}
                    onClick={() => chartRedirectLink(item.spotlight_type, item.chart_id, item.surveys)}
                  >
                    <div>
                      <Typography
                        variant="subtitle1"
                        className={IS_PUBLIC_REPORT ? classes.chartPublicTitle : classes.chartTitle}
                      >
                        {item.chart_name}
                      </Typography>
                      <Typography variant="subtitle2" lineHeight={0.7} pt={0.5}>
                        {getDashboardDate(item.date_range)}
                      </Typography>
                    </div>

                    <div
                      style={{
                        marginLeft: 'auto',
                      }}
                    >
                      {!IS_PUBLIC_REPORT && (
                        <Box
                          p={0.5}
                          mr={-1.6}
                          onClick={e => {
                            e.stopPropagation();
                            setChartAnchor(e.currentTarget);
                            setActiveId(item.chart_id);
                          }}
                        >
                          <MoreVertIcon />
                        </Box>
                      )}

                      <Menu
                        id="long-menu"
                        anchorEl={chartAnchor}
                        open={item.chart_id === activeId}
                        onClose={e => {
                          setChartAnchor(null);
                          setActiveId('');
                        }}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <Box display={'flex'} width={'250px'}>
                          <div>
                            <MenuItem
                              onClick={e => {
                                e.stopPropagation();
                                deletePinnedChart();
                                setActiveId('');
                              }}
                            >
                              <Typography>Unpin</Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={e => {
                                e.stopPropagation();
                                setFullWidthChart(fullWidthChart === item.chart_id ? '' : item.chart_id);
                                setActiveId('');
                              }}
                            >
                              <Typography
                                onClick={() => handleChartView(item.chart_width === 6 ? 12 : 6, item.chart_id, 'chart')}
                              >
                                {item.chart_width === 6 ? 'Full Width' : 'Half Width'}
                              </Typography>
                            </MenuItem>
                          </div>
                          <div style={{ cursor: 'auto', borderLeft: '1px #30303070 solid' }}>
                            <MenuItem
                              onClick={e => {
                                e.stopPropagation();
                                setActiveId('');
                              }}
                              style={{ cursor: 'auto' }}
                            >
                              <Box id="joystick">
                                <div></div>
                                <div
                                  role="button"
                                  onClick={() => moveInDirection(+idx, 'u', item.chart_width, item.chart_id)}
                                >
                                  <KeyboardArrowUpRoundedIcon
                                    style={
                                      canMoveInDirection(+idx, 'u', item.chart_width, item.chart_id) === null
                                        ? { fontSize: 30, color: '#30303070' }
                                        : { fontSize: 30, cursor: 'pointer' }
                                    }
                                  />
                                </div>
                                <div></div>
                                <div
                                  role="button"
                                  onClick={() => moveInDirection(+idx, 'l', item.chart_width, item.chart_id)}
                                >
                                  <KeyboardArrowLeftRoundedIcon
                                    style={
                                      canMoveInDirection(+idx, 'l', item.chart_width, item.chart_id) === null
                                        ? { fontSize: 30, color: '#30303070' }
                                        : { fontSize: 30, cursor: 'pointer' }
                                    }
                                  />
                                </div>
                                <div>
                                  <Typography fontSize={10} fontWeight={'bold'} mt={0.75} ml={0.3}>
                                    Move
                                  </Typography>
                                </div>
                                <div
                                  role="button"
                                  onClick={() => moveInDirection(+idx, 'r', item.chart_width, item.chart_id)}
                                >
                                  <KeyboardArrowRightRoundedIcon
                                    style={
                                      canMoveInDirection(+idx, 'r', item.chart_width, item.chart_id) === null
                                        ? { fontSize: 30, color: '#30303070' }
                                        : { fontSize: 30, cursor: 'pointer' }
                                    }
                                  />
                                </div>
                                <div></div>
                                <div
                                  role="button"
                                  onClick={() => moveInDirection(+idx, 'd', item.chart_width, item.chart_id)}
                                >
                                  <KeyboardArrowDownRoundedIcon
                                    style={
                                      canMoveInDirection(+idx, 'd', item.chart_width, item.chart_id) === null
                                        ? { fontSize: 30, color: '#30303070' }
                                        : { fontSize: 30, cursor: 'pointer' }
                                    }
                                  />
                                </div>
                                <div></div>
                              </Box>
                            </MenuItem>
                          </div>
                        </Box>
                      </Menu>
                    </div>
                  </div>
                  <div className={classes.dashboardLowerBox}>
                    {item.chart_type === 'daily_chart' && (
                      <DailyChartComponent
                        x_axis={item.chart_info?.x_axis}
                        data_groups={item.chart_info?.data_groups}
                        question_type={item.question_type}
                        chartHeight={item.chart_width === 6 ? 180 : 80}
                        is_dashboard={true}
                      />
                    )}

                    {item.chart_type === 'cumulated' && (
                      <ChartBaseComponent
                        question_type={item.question_type}
                        stats={item.chart_info?.stats ? item.chart_info?.stats : []}
                        nps_stats={item.chart_info?.nps_stats ? item.chart_info?.nps_stats : []}
                        is_dashboard={true}
                      />
                    )}

                    {item.chart_type === 'split_view' && (
                      <SplitViewChartComponent
                        question_stats={item.chart_info?.question_stats ? item.chart_info?.question_stats : []}
                        question_type={item.question_type}
                        is_dashboard={true}
                      />
                    )}
                  </div>
                </Grid>
              ),
            )}
          </Grid>
        ) : (
          <EmptyChart isPublicReport={IS_PUBLIC_REPORT} />
        )}
      </div>
    );
  }
};

export default DashboardComponent;
