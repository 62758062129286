import React, { FunctionComponent } from 'react';
import { BarChartComponent } from './BarChartComponent';
import { InputChartComponent } from './InputChartComponent';
import { NpsChartComponent } from './NpsChartComponent';

export const ChartBaseComponent: FunctionComponent<{
  question_type: string;
  stats: any;
  nps_stats: any;
  is_dashboard?: boolean;
}> = ({ question_type, stats = [], nps_stats = [], is_dashboard = false }) => {
  return (
    <div>
      {question_type === 'input_feedback' ? (
        <InputChartComponent stats={stats} />
      ) : question_type === 'nps_feedback' ? (
        <NpsChartComponent
          stats={stats}
          nps_stats={nps_stats}
          barWidth={is_dashboard ? 35 : 70}
          showNpsDesc={is_dashboard ? false : true}
        />
      ) : (
        <BarChartComponent stats={stats} />
      )}
    </div>
  );
};
