import React from 'react';
import SignUpComponent from '../../components/login/SignUpComponent';

const SignUpPage = () => {
  return (
    <div>
      <SignUpComponent />
    </div>
  );
};

export default SignUpPage;
