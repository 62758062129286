import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Typography, Box, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import defaultStyles from './loginStyles';
import { ErrorUtil, SuccessUtil } from '../../utils/MessageUtil';
import { forgetPassword } from '../../services/loginService';

const useStyles = makeStyles(theme => ({
  logoImg: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  inputColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function ForgetPasswordComponent() {
  const classes = useStyles();
  const common = defaultStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('' as string);
  const [message, setMessage] = useState('' as string);

  const handleClick = async () => {
    if (!email) {
      setError('Fields cannot be empty');
      setTimeout(() => setError(''), 3000);
      return;
    }
    const results = await forgetPassword({
      member_email: email,
    });
    if (results && results.data) {
      setMessage('Email sent successfully');
      localStorage.clear();
      setTimeout(() => history.push('/login'), 3000);
    } else {
      setError(results.error);
      setTimeout(() => setError(''), 3000);
      return;
    }
  };

  return (
    <Container maxWidth="lg" className={common.formBackground}>
      <Grid lg={5} container direction="column" justifyContent="center">
        <Grid lg={12} item>
          <Box className={classes.logoImg}>
            <img src="https://cdn.blitzllama.com/assets/logo/blitzllama-black.svg" alt="" />
          </Box>
        </Grid>
        <Grid lg={12} item className={common.formBox}>
          <Typography variant="h6" textAlign={'center'} mb={3}>
            Forget Password
          </Typography>

          <TextField
            variant="outlined"
            fullWidth
            autoComplete="off"
            placeholder="Registered email ID"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <Button
            variant="contained"
            className={common.button}
            endIcon={<ChevronRightIcon fontSize="large"></ChevronRightIcon>}
            onClick={() => handleClick()}
          >
            Submit
          </Button>
          <Grid item lg={12}>
            {error !== '' && <ErrorUtil message={error} />}
            {message !== '' && <SuccessUtil message={message} />}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ForgetPasswordComponent;
