import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const dashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '55px',
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(2.5),
      boxShadow: 'none',
    },

    loading: {
      color: theme.palette.primary.contrastText,
    },
    dashboardPaper: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.7),
      cursor: 'pointer',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2.5),
      boxShadow: 'none',
    },
    boardImgBox: {
      borderRadius: '50%',
      height: '20px',
      width: '20px',
      padding: theme.spacing(1.25),
    },
    dashboardTitle: {
      fontWeight: 700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    dashboardDescription: {
      color: theme.palette.text.secondary,
      lineHeight: 1.25,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    moreIcon: {
      color: theme.palette.text.secondary,
    },
    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },

    modalContainer: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
      alignItems: 'center',
    },
    nameField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.6rem 0.87rem',
        fontSize: '0.9rem',
      },
    },
    transparentButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    descField: {
      '& .MuiInputBase-root': { padding: '0.6rem 0.87rem', fontSize: '0.9rem' },
    },
  }),
);
