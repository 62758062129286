/* eslint-disable @typescript-eslint/ban-types */
import amplitude from 'amplitude-js';
import CONFIG from '../config';
import { IMemberTrackingInfo } from '../types/member';

export const amplitudeEvent = (trackingInfo: IMemberTrackingInfo, event_name: string, event_properties: Object) => {
  if (!trackingInfo || !event_name || CONFIG.amplitude_record_events !== 't') {
    return;
  }

  const { member_id, member_email, org_id, org_name, workspace_id, workspace_name } = trackingInfo;
  let common_properties = {};

  if (member_id) {
    common_properties = {
      email: member_email,
      'workspace id': workspace_id,
      'workspace name': workspace_name,
      'org name': org_name,
      'org id': org_id,
    };
  }

  const all_properties = { ...common_properties, ...event_properties };

  amplitude.getInstance().init(CONFIG.amplitude_api_key, member_id);

  const identify = new amplitude.Identify().setOnce('email', member_email).setOnce('org name', org_name);
  amplitude.getInstance().identify(identify);

  amplitude.getInstance().logEvent(event_name, all_properties);
};
