import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import Papaparse from 'papaparse';
import moment from 'moment';
import _ from 'lodash';
import {
  Grid,
  Button,
  TextField,
  Box,
  Theme,
  Typography,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  CircularProgress,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip from '@mui/material/Tooltip';

import { useAttributeState } from '../hooks/useAttribute';
import DeleteCohortDialog from './DeleteCohortModal';
import { audienceSelectColor } from '../../styles/colors';
import { previewDataType } from '../../static/staticData';
import { FileInfo, ICohortSurveyType } from '../../types/audience';
import {
  createCohort,
  fetchAllAttributes,
  fetchCohortById,
  filterCohortByName,
  editCohort,
} from '../../services/audienceService';
import { StyledTableRow } from '../common/tableStyles';
import AlertUtil from '../../utils/AlertUtil';
import { getOperatorLabel, getOperatorsForDatatype } from '../../utils/AttributeUtil';
import CONFIG from '../../config';

import { ReactComponent as UploadImg } from '../../images/audience/upload-icon.svg';
import { ReactComponent as DownloadImg } from '../../images/audience/download-icon.svg';
import { ReactComponent as CsvImg } from '../../images/audience/csv-icon.svg';
import deleteImg from '../../images/delete-icon.svg';

import { ErrorUtil } from '../../utils/MessageUtil';
import { modifiedDate } from '../../utils/DateUtil';
import { getAttributeLabel, getAttributeValue, getImageId } from '../../utils/stringUtil';
import UserDrawerComponent from './UserDrawerComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cohortNameInput: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.disabled,
      width: '50%',
      border: 0,
      '& .MuiOutlinedInput-input': {
        fontWeight: 700,
        padding: 0,
        fontSize: '2.19rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },
    response: {
      borderRadius: theme.spacing(2.3),
      backgroundColor: theme.palette.secondary.dark,
      padding: 18,
      paddingBlock: 8,
      marginRight: 10,
    },
    conditionBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 10,
      marginTop: 20,
    },
    userBox: {
      paddingBlock: theme.spacing(1.2),
      backgroundColor: theme.palette.secondary.dark,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      borderRadius: theme.spacing(0.7),
    },
    addProperty: {
      width: 'max-content',
      border: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      borderRadius: 40,
      paddingBlock: theme.spacing(1.2),
      paddingRight: theme.spacing(2.4),
      paddingLeft: theme.spacing(1.5),
      marginTop: theme.spacing(1.2),
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },

    csvDetailsTable: {
      marginBlock: theme.spacing(10),
      '& .MuiTableHead-root': {
        borderTop: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '& .MuiTable-root .MuiTableCell-root': {
        border: 0,
      },
    },

    selectDataField: {
      marginLeft: 5,
      borderRadius: 4,
      width: '88px',
      '& .MuiSelect-outlined': {
        backgroundColor: 'transparent',
        padding: '3px 10px',
        fontSize: '0.87rem',
        fontWeight: 700,
      },
      '& .MuiSelect-icon': {
        color: theme.palette.text.primary,
        marginTop: 3,
      },
    },
    userIdField: {
      marginLeft: 5,
      borderRadius: 4,
      fontSize: '0.8rem',
      fontWeight: 700,
      padding: '0.8px 13px',
    },
    avatar: {
      backgroundColor: '#FF979D',
      borderRadius: '50%',
      width: theme.spacing(3.1),
      height: theme.spacing(3.1),
    },
    downloadText: {
      color: theme.palette.primary.contrastText,
      fontSize: '0.85rem',
      marginLeft: 5,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    autoTextField: {
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.default,
        padding: '0.25rem 0.87rem',
        minHeight: '47px',
      },
      '& .MuiAutocomplete-tag': {
        border: `1px solid ${theme.palette.divider}`,
        marginBlock: 0,
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '1rem',
      },
    },
    table: { maxWidth: `calc(92.4vw - 220px)` },
    backButton: {
      padding: '0.57rem 2rem',
      border: `1px solid ${theme.palette.divider}`,
      textTransform: 'none',
      fontWeight: 700,
      borderRadius: '0.3rem',
    },
    csvButton: {
      textTransform: 'unset',
      padding: '0.62rem 2.6rem 0.62rem 1.68rem',
      fontWeight: 700,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.3rem',
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      marginRight: theme.spacing(1),
      '&$checked': {
        color: theme.palette.primary.contrastText,
        marginRight: 8,
      },
    },
    checked: {},
    cohortButton: {
      padding: '0.62rem 1.25rem',
      marginTop: theme.spacing(1.2),
    },
    customTextField: {
      '& .MuiOutlinedInput-input': { padding: '0.7rem 0.87rem' },
      minHeight: '37px',
      minWidth: '180px',
      marginLeft: '10px',
      paddingTop: 0,
    },
    newCsvContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(10),
    },
    newCsvBox: {
      textAlign: 'center',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(2),
    },
    uploadCsvButton: {
      marginTop: theme.spacing(0.5),
      paddingBlock: theme.spacing(1.7),
      paddingInline: theme.spacing(5.5),
    },
    userText: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    surveyName: {
      color: theme.palette.primary.contrastText,
      paddingLeft: theme.spacing(0.6),
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
);

type HeaderItem = {
  name: string;
  data_type: string;
};

const selectColorMapper = {
  string: audienceSelectColor.stringYellow,
  number: audienceSelectColor.numberPink,
  date: audienceSelectColor.dateSky,
  boolean: audienceSelectColor.booleanOrange,
};

const getColor = (type: string) => {
  if (selectColorMapper[type]) {
    return selectColorMapper[type];
  } else {
    return 'grey';
  }
};

function isNumeric(num) {
  num = '' + num; //coerce num to be a string
  return !isNaN(num) && !isNaN(parseFloat(num));
}

interface Iparams {
  cohort_id: string;
}

interface ILocation {
  pathname: string;
  state: {
    page: string;
  };
}

export default function CohortDetailsComponent() {
  const classes = useStyles();
  const history = useHistory();

  const location: ILocation = useLocation();

  const characterName = 'Name';
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [csvFile, setCSVFile] = useState<File | null>();
  const [cohortName, setCohortName] = useState(`Cohort ${characterName}`);
  const [cohortSurveyDetails, setCohortSurveyDetails] = useState<ICohortSurveyType>();
  const [errors, setErrors] = useState('');
  const [fileData, setFileData] = useState<FileInfo | null>();
  const [showUserDrawer, toggleUserDrawer] = React.useState(false);
  const [userId, setUserId] = useState('');

  const [cohortAnchor, setCohortAnchor] = useState<null | HTMLElement>(null);
  const openCohortMenu = Boolean(cohortAnchor);

  const [openDeleteToggle, toggleDeleteModal] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [uploadStatus, setUploadStatus] = useState('Loaded');

  const [edit, setEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const params: Iparams = useParams();
  const { cohort_id } = params;

  const cohort_type = location.state && location.state.page ? location.state.page : 'conditions';
  const mandatory_attributes = ['user_id', 'created_at', 'total_days_on_app'];

  const {
    conditions,
    attributeList,
    attributeValues,
    handleAttributeSelect,
    getValuesForAttribute,
    setAttributeValues,
    handleAttributeOperator,
    handleAttributeValue,
    handleCohortConditions,
    setConditions,
    addNewCondition,
    deleteCondition,
    setCount,
    preview,
    totalCount,
    headers,
    isLoading,
    setPreview,
    setHeaders,
    setLoading,
  } = useAttributeState({
    new_cohort: cohort_id === 'new',
    get_preview: cohort_type === 'conditions',
    initial_state: [
      {
        attribute: '',
        comparator: '',
        value: '',
        data_type: '',
        list: [],
      },
    ],
  });

  const fetchCohortItem = useCallback(async () => {
    const attributes = await fetchAllAttributes();

    const results = await fetchCohortById(cohort_id);
    if (results) {
      const { cohort_name, condition, csv, preview, attribute_values, total_count, survey } = results;
      setCohortName(cohort_name);
      setConditions(condition);
      setAttributeValues(attribute_values);
      setCount(total_count);
      if (survey) {
        setCohortSurveyDetails(survey);
      }

      handleCohortConditions(condition);

      if (!_.isEmpty(csv)) {
        setFileData(csv);
      }

      if (preview && preview.length > 0) {
        const uniqueKeys = Object.keys(
          preview.reduce(function(result, obj) {
            return Object.assign(result, obj);
          }, {}),
        );
        const headers = [{ name: 'user_id', data_type: 'string' }];

        for (const i in attributes) {
          const { name, data_type } = attributes[i];
          if (uniqueKeys.includes(name)) {
            headers.push({ name, data_type });
          }
        }
        setHeaders(headers);
        const previewData: Array<any> = [];
        for (const i in preview) {
          const previewItem = preview[i];
          const previewObj = {};

          for (const j in headers) {
            const { name } = headers[j];
            previewObj[name] = previewItem[name] ? previewItem[name] : '';
          }
          previewData.push(previewObj);
        }
        setPreview(previewData);
      }
    }

    setLoading(false);
  }, [cohort_id]);

  useEffect(() => {
    //edit state
    if (cohort_id !== 'new') {
      setEdit(true);
      fetchCohortItem();
    } else {
      handleCohortConditions([]);
    }
  }, [cohort_id, fetchCohortItem]);

  const handleCloseCohortMenu = () => {
    setCohortAnchor(null);
  };

  const handleDelete = () => {
    toggleDeleteModal(true);
    handleCloseCohortMenu();
  };

  const setSelectDataType = (data_type: string, header: string) => {
    for (const i in headers) {
      if (headers[i].name === header) {
        headers[i].data_type = data_type;
      }
    }
    setHeaders([...headers]);
  };

  const showOpenFileDialog = () => {
    if (csvFile) {
      AlertUtil.fire({
        icon: 'error',
        title: 'CSV file already uploaded',
      });
      return;
    }
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = event => {
    const csvfile = event.target.files[0];
    if (csvfile) {
      setCSVFile(csvfile);
      Papaparse.parse(csvfile, {
        preview: 20,
        worker: true,
        skipEmptyLines: true,
        header: true,

        complete: ({ data }) => {
          if (data && data.length > 0) {
            setFileData({
              filename: csvfile.name,
              completed_at: moment().format(),
              total_count: csvfile.size,
              upload_status: uploadStatus,
            });

            const previewItem: any = data[0];
            const headers: Array<HeaderItem> = [];
            for (const i in previewItem) {
              headers.push({ name: i, data_type: 'string' });
            }
            const user_id_obj = headers.find(o => o.name.trim() === 'user_id');
            if (user_id_obj) {
              setHeaders(headers);
              setPreview(data);
            } else {
              setCSVFile(null);
              setFileData(null);
              AlertUtil.fire({
                icon: 'error',
                title: 'user_id is not present',
              });
            }
          }
        },
      });
    }
  };

  const handleFileValidation = () => {
    return new Promise(resolve => {
      if (!csvFile) {
        resolve({ success: false });
        return;
      }

      const headerObj = {};

      //check if for same attributes datatype is changed
      for (const i in headers) {
        const { name, data_type } = headers[i];
        const attribute = attributeList.find(a => a.name === name);

        if (attribute && attribute.data_type !== data_type) {
          resolve({ success: false, error: `${name} should be of ${attribute.data_type} datatype` });
          return;
        }

        headerObj[name] = data_type;
      }

      Papaparse.parse(csvFile, {
        worker: true,
        skipEmptyLines: true,
        header: true,
        step: ({ data }: any, parser) => {
          for (const key of Object.keys(data)) {
            if (headerObj[key] === 'number' && data[key] !== '' && !isNumeric(data[key])) {
              setErrors(`${headerObj[key]} has non-number data`);
              resolve({ success: false, error: `${headerObj[key]} datatype has non-number data` });
              parser.abort();
            } else if (
              headerObj[key] === 'boolean' &&
              data[key] !== '' &&
              !data[key] &&
              (data[key].toString().toLowerCase() !== 'true' || data[key].toString().toLowerCase() !== 'false')
            ) {
              setErrors(`${headerObj[key]} has non-boolean data`);
              resolve({ success: false, error: `${headerObj[key]} datatype has non-boolean data` });
              parser.abort();
            } else if (headerObj[key] === 'date' && data[key] !== '' && !moment(new Date(data[key])).isValid()) {
              setErrors(`${headerObj[key]} has non-date data`);
              resolve({ success: false, error: `${headerObj[key]} datatype has non-date data` });
              parser.abort();
            }
          }
        },
        complete: () => {
          setErrors('');
          resolve({ success: true });
        },
      });
    });
  };

  const handleSearchTerm = async (attribute: string, txt: string) => {
    if (txt.length > 2) {
      const attribute_names = await filterCohortByName(cohort_id, attribute, txt, 0);
      if (attribute_names) {
        const uniq_attributes = _.union([...attributeValues[attribute], ...attribute_names]);
        attributeValues[attribute] = uniq_attributes;
        setAttributeValues({ ...attributeValues });
      }
    }
  };

  const handleCohortValidation = () => {
    if (conditions.length === 0) {
      return false;
    }

    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].attribute === '' || conditions[i].comparator === '') {
        return false;
      }

      if (conditions[i].data_type === 'string') {
        if (conditions[i].list.length === 0) return false;
      } else if (!conditions[i].value) {
        return false;
      }
    }

    return true;
  };

  const handleCohortCreate = async () => {
    if (cohortName === '') {
      AlertUtil.fire({
        icon: 'error',
        title: 'Cohort name cannot be empty',
      });
      return;
    }

    const valid_conditions = handleCohortValidation();

    if (cohort_type === 'csv') {
      if (csvFile) {
        setIsSubmit(false);

        const fileValidation: any = await handleFileValidation();

        if (!fileValidation || !fileValidation.success) {
          AlertUtil.fire({
            icon: 'error',
            title: fileValidation && fileValidation.error ? fileValidation.error : 'Unexpected error',
          });

          setIsSubmit(true);
          return;
        }

        const myHeaders = new Headers();
        myHeaders.append('auth-token', localStorage.getItem('auth_token') as string);

        const formdata = new FormData();
        formdata.append('file', csvFile, csvFile.name);
        formdata.append('cohort_name', cohortName);
        formdata.append('condition', JSON.stringify([]));
        formdata.append('headers', JSON.stringify(headers));

        setUploadStatus('Uploading');

        const requestOptions = await fetch(CONFIG.api_endpoint + '/common/upload', {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
        });

        if (requestOptions && requestOptions.status === 200) {
          AlertUtil.fire({
            icon: 'success',
            title: 'Cohort created successfully',
          });
          history.push('/cohorts');
        }
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: 'Please upload the csv file',
        });
        return;
      }
    }

    // for conditions cohort
    if (cohort_type === 'conditions') {
      if (!valid_conditions) {
        AlertUtil.fire({
          icon: 'error',
          title: 'conditions cannot be empty',
        });
        return;
      }
      const newCohort = await createCohort({
        cohort_name: cohortName,
        condition: conditions,
      });

      if (newCohort && newCohort.data) {
        setIsSubmit(false);
        AlertUtil.fire({
          icon: 'success',
          title: 'created successfully',
        });
        history.push('/cohorts');
      }
    }
  };

  const handleCohortEdit = async () => {
    if (cohortName === '') {
      AlertUtil.fire({
        icon: 'error',
        title: 'Cohort name cannot be empty',
      });
      return;
    }

    //validate conditions only if cohort_type is conditions
    if (cohort_type === 'conditions') {
      const valid_conditions = handleCohortValidation();

      if (!valid_conditions) {
        AlertUtil.fire({
          icon: 'error',
          title: 'conditions cannot be empty',
        });
        return;
      }
    }

    const editCohortItem = await editCohort(
      {
        cohort_name: cohortName,
        condition: conditions,
      },
      cohort_id,
    );

    if (editCohortItem && editCohortItem.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Updated successfully',
      });
    }
  };

  const activateUserDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    toggleUserDrawer(!showUserDrawer);
  };

  const handleUserDrawer = async (e: any, user_id: string, open: boolean) => {
    if (open) {
      setUserId(user_id);
      activateUserDrawer(e);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <>
        {openDeleteToggle && (
          <DeleteCohortDialog toggleModal={toggleDeleteModal} open={openDeleteToggle} cohortId={activeId} />
        )}
        {userId && showUserDrawer && (
          <UserDrawerComponent
            toggleDrawer={activateUserDrawer}
            anchor={'right'}
            showUserDrawer={showUserDrawer}
            userId={userId}
          />
        )}
        <Box display={'flex'} alignItems="center" justifyContent="space-between">
          <TextField
            variant="outlined"
            value={cohortName}
            autoComplete="off"
            placeholder="Enter cohort name"
            className={classes.cohortNameInput}
            onChange={e => setCohortName(e.target.value)}
          />
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              <Button className={classes.backButton} style={{ marginRight: 10 }} onClick={() => history.goBack()}>
                Back
              </Button>
              {edit ? (
                <Box display={'flex'} alignItems={'center'}>
                  <Button variant="contained" onClick={() => handleCohortEdit()}>
                    Save Cohort
                  </Button>
                  <Box
                    onClick={e => {
                      setCohortAnchor(e.currentTarget);
                      setActiveId(cohort_id);
                    }}
                    pl={1}
                    style={{ cursor: 'pointer' }}
                  >
                    <MoreVertIcon />
                  </Box>

                  <Menu
                    open={openCohortMenu}
                    anchorEl={cohortAnchor}
                    onClose={handleCloseCohortMenu}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    style={{ marginTop: 20 }}
                  >
                    <MenuItem onClick={handleDelete}>
                      <Box px={1} py={0.5} style={{ borderRadius: 5 }}>
                        <Typography>Delete</Typography>
                      </Box>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <>
                  <Button
                    disabled={!isSubmit}
                    variant="contained"
                    style={{ lineHeight: 2 }}
                    onClick={() => handleCohortCreate()}
                  >
                    Create Cohort
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>

        {cohortSurveyDetails && cohortSurveyDetails.survey_id && (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="subtitle1">Source</Typography>
            <Typography variant="subtitle1" px={1}>
              |
            </Typography>
            <img
              src={`https://cdn.blitzllama.com/survey/platform/${cohortSurveyDetails.platform}-sq.svg`}
              alt=""
              width="16px"
              height="16px"
            />
            <Typography
              variant={'subtitle1'}
              className={classes.surveyName}
              onClick={() => history.push(`/survey/${cohortSurveyDetails.survey_id}/`)}
            >
              {cohortSurveyDetails.survey_name}
            </Typography>
          </Box>
        )}

        <Box mb={7}>
          {cohort_type === 'conditions' && (
            <div>
              <Grid container lg={12} alignItems="center" justifyContent="space-between">
                <div>
                  <Typography variant={'h4'}>Select attributes that define your cohort</Typography>
                  <Typography variant={'subtitle1'} mt={0.2}>
                    Attributes are certain properties that help filter users that are to be surveyed
                  </Typography>
                </div>
                <div className={classes.response}>
                  <Typography variant="h6" display="inline">
                    {totalCount} Users
                  </Typography>
                </div>
              </Grid>
              <Box display="flex" className={classes.conditionBox}>
                <Box
                  py={3}
                  width={'200px'}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  style={{ flexDirection: 'column' }}
                >
                  <Typography className={classes.userBox} px={1} variant={'subtitle1'} fontWeight={'700'}>
                    The Users who
                  </Typography>

                  {conditions.map((option, idx) => (
                    <Box className={classes.userBox} key={idx}>
                      <Typography px={1} variant={'subtitle1'} fontWeight={'700'}>
                        AND also
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box border={1} borderColor={'divider'}></Box>
                <Box width={1}>
                  {conditions.map(({ attribute, comparator, value, data_type, list }, idx) => (
                    <>
                      <Box px={4} py={3}>
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" alignItems="center">
                            <Typography variant={'subtitle1'} fontWeight={'700'}>
                              ...have attribute
                            </Typography>

                            <Select
                              variant="outlined"
                              displayEmpty
                              className={classes.selectField}
                              renderValue={() => (
                                <div>{attribute ? getAttributeLabel(attribute) : 'Select Attribute'}</div>
                              )}
                              value={attribute}
                              onChange={e => handleAttributeSelect(e.target.value as string, idx)}
                              style={{ width: 300, marginLeft: 13 }}
                            >
                              <MenuItem disabled>
                                <Typography variant={'subtitle2'}>Default Attributes</Typography>
                              </MenuItem>
                              <MenuItem value={'user_id'}>
                                <Typography style={{ marginLeft: 10 }}>User Id</Typography>
                              </MenuItem>
                              <MenuItem value={'created_at'}>
                                <Typography style={{ marginLeft: 10 }}>Created at</Typography>
                              </MenuItem>
                              <MenuItem value={'total_days_on_app'}>
                                <Typography style={{ marginLeft: 10 }}>Days since creation</Typography>
                              </MenuItem>
                              <MenuItem disabled>
                                <Typography variant={'subtitle2'}>User Attributes</Typography>
                              </MenuItem>
                              {attributeList.map(({ name }, key) => (
                                <MenuItem key={key} value={name}>
                                  <Typography style={{ marginLeft: 10 }}>{name}</Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <img
                            src={deleteImg}
                            alt=""
                            style={{ cursor: 'pointer', height: 32 }}
                            onClick={() => deleteCondition(idx)}
                          />
                        </Box>

                        {attribute && (
                          <Box display="flex" alignItems="flex-start" mt={2}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography variant={'subtitle1'} fontWeight={'700'}>
                                ...with property
                              </Typography>
                              <Select
                                variant="outlined"
                                displayEmpty
                                className={classes.selectField}
                                renderValue={() => (
                                  <div>{comparator ? getOperatorLabel(comparator, data_type) : 'Select operator'}</div>
                                )}
                                value={comparator}
                                onChange={e => handleAttributeOperator(e.target.value as string, idx)}
                                style={{ width: 225, marginLeft: 13 }}
                              >
                                <MenuItem disabled>
                                  <Typography variant={'subtitle2'}>Comparison Operator</Typography>
                                </MenuItem>
                                {getOperatorsForDatatype(data_type).map(({ label, value }, i) => (
                                  <MenuItem value={value}>{label}</MenuItem>
                                ))}
                              </Select>
                            </Box>

                            {comparator && data_type === 'string' && (
                              <Autocomplete
                                id="tags-outlined"
                                multiple
                                options={getValuesForAttribute(attribute, data_type, idx)}
                                disableCloseOnSelect
                                freeSolo
                                className={classes.autoTextField}
                                style={{ width: '36%', marginLeft: 10 }}
                                onChange={(event: any, newValue) => {
                                  handleAttributeValue(newValue, data_type, idx);
                                }}
                                value={list}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                                      checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                                      classes={{
                                        root: classes.check,
                                        checked: classes.checked,
                                      }}
                                      checked={selected}
                                    />

                                    {option}
                                  </li>
                                )}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Enter attribute values"
                                    onChange={e => handleSearchTerm(attribute, e.target.value)}
                                  />
                                )}
                                limitTags={2}
                              />
                            )}
                            {comparator && data_type === 'boolean' && (
                              <Autocomplete
                                options={getValuesForAttribute(attribute, data_type, idx)}
                                renderOption={(props, option) => <li {...props}>{option}</li>}
                                value={value}
                                style={{ width: '36%', marginLeft: 10 }}
                                onChange={(event: any, newValue) => {
                                  handleAttributeValue(newValue, data_type, idx);
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select attribute value"
                                    onChange={e => handleSearchTerm(attribute, e.target.value)}
                                  />
                                )}
                              />
                            )}

                            {comparator && data_type === 'date' && (
                              <TextField
                                variant="outlined"
                                type="date"
                                className={classes.customTextField}
                                value={conditions[+idx] && conditions[+idx].value ? conditions[+idx].value : ''}
                                placeholder="Select date value"
                                autoComplete="off"
                                onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                              />
                            )}

                            {comparator && data_type === 'number' && (
                              <TextField
                                variant="outlined"
                                type="number"
                                className={classes.customTextField}
                                value={conditions[+idx] && conditions[+idx].value ? conditions[+idx].value : ''}
                                placeholder="Enter number value"
                                autoComplete="off"
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                      <Box borderBottom={1} borderColor={'divider'}></Box>
                    </>
                  ))}

                  <Box px={4} py={3}>
                    <Typography variant="subtitle2">Select a condition to define your cohort</Typography>
                    <Box display="flex" alignItems="center">
                      <Button variant="contained" className={classes.cohortButton} onClick={() => addNewCondition()}>
                        <Typography variant={'subtitle1'}>Has user property</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          )}
          {cohort_type === 'csv' &&
            (edit ? (
              <div>
                {fileData && fileData.filename && (
                  <TableContainer className={classes.csvDetailsTable}>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell>File name</TableCell>
                          <TableCell>Date & time</TableCell>
                          <TableCell>{edit ? 'Total users' : 'File Size'}</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" width={'30%'}>
                            <Box display="flex" alignItems="center">
                              <CsvImg />
                              <Typography style={{ marginLeft: 15 }}>{fileData.filename}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell width={'25%'}>{modifiedDate(fileData.completed_at, 'lll')}</TableCell>
                          <TableCell width={'25%'}>
                            {edit ? fileData.total_count : `${fileData.total_count / 1000} KB`}
                          </TableCell>
                          <TableCell width={'20%'}>
                            {fileData.upload_status === 'completed' ? (
                              <Typography>{fileData.upload_status}</Typography>
                            ) : (
                              <Typography style={uploadStatus === 'Uploading' ? { color: '#FF8541' } : {}}>
                                {uploadStatus}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            ) : (
              <div>
                <Grid container lg={12} className={classes.newCsvContainer}>
                  <Grid item lg={4} py={5.2} px={3.5} className={classes.newCsvBox}>
                    <Typography variant={'h2'} fontSize={'22px'}>
                      Select a CSV file containing details about your users
                    </Typography>

                    <Typography variant={'subtitle2'} mt={3.5}>
                      *max file size = 10MB
                    </Typography>
                    <div>
                      <input
                        type="file"
                        id="file"
                        ref={inputFileRef}
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        className={classes.uploadCsvButton}
                        onClick={() => showOpenFileDialog()}
                      >
                        <Box display={'flex'} alignItems={'center'}>
                          <UploadImg />
                          <Typography pl={0.6} variant={'subtitle1'} fontWeight={700}>
                            {!csvFile ? 'Upload CSV file' : 'CSV Uploaded'}
                          </Typography>
                        </Box>
                      </Button>
                    </div>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'center'}
                      mt={3}
                      style={{ cursor: 'pointer' }}
                    >
                      <DownloadImg />
                      <a href={'/sample.csv'} download>
                        <Typography className={classes.downloadText}>Download sample .csv</Typography>
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            ))}

          {errors !== '' && <ErrorUtil message={errors} />}

          {headers && headers.length > 0 && (
            <>
              <Box pb={5} pt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h4'}>Preview</Typography>
                {cohort_type !== 'conditions' && edit && (
                  <div className={classes.response}>
                    <Typography variant="h6" display="inline">
                      {totalCount} Users
                    </Typography>
                  </div>
                )}
              </Box>
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers.map((item, key) => (
                        <TableCell key={key}>
                          <Box display="flex" alignItems="center">
                            <Tooltip title={getAttributeLabel(item.name)} placement="top">
                              <Typography variant={'h6'} noWrap>
                                {getAttributeLabel(item.name)}
                              </Typography>
                            </Tooltip>
                            {item.name === 'user_id' || edit || !fileData ? (
                              <></>
                            ) : (
                              <TextField
                                variant="outlined"
                                select
                                className={classes.selectDataField}
                                value={item.data_type}
                                onChange={e => setSelectDataType(e.target.value, item.name)}
                                style={{ backgroundColor: getColor(item.data_type) }}
                              >
                                {previewDataType.map(({ value, label }, idx) => (
                                  <MenuItem key={idx} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preview.map((item, idx) => (
                      <>
                        <StyledTableRow key={idx}>
                          {item &&
                            Object.keys(item).map((val, i) => (
                              <TableCell scope="row">
                                {headers[i] &&
                                headers[i].name &&
                                (mandatory_attributes.includes(headers[i].name) || item[headers[i].name]) ? (
                                  i === 0 ? (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      className={
                                        !item[headers[i].name].toString().startsWith('anonymous')
                                          ? classes.userText
                                          : ''
                                      }
                                    >
                                      <img
                                        src={`https://cdn.blitzllama.com/default/avatars/avatar-${getImageId(
                                          item[headers[i].name].toString(),
                                          6,
                                        )}.png`}
                                        alt=""
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          marginTop: 6,
                                          marginLeft: 7,
                                        }}
                                      />
                                      <Typography
                                        style={{ paddingLeft: 10, width: '10ch' }}
                                        noWrap
                                        onClick={e =>
                                          !item[headers[i].name].toString().startsWith('anonymous') &&
                                          handleUserDrawer(e, item[headers[i].name].toString(), true)
                                        }
                                      >
                                        {item[headers[i].name].toString()}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <Typography style={{ paddingLeft: 10 }} noWrap>
                                      {getAttributeValue(headers[i].name, item[headers[i].name])}
                                    </Typography>
                                  )
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            ))}
                        </StyledTableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography style={{ marginTop: 15 }}>
                {preview && `Showing preview of ${preview.length} results`}
              </Typography>
            </>
          )}
        </Box>
      </>
    );
  }
}
