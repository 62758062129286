import React, { useState, useEffect, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { Typography, Box, AppBar, Tabs, Tab, CircularProgress } from '@mui/material';

import surveyStyles from '../common/SurveyStyles';
import { workspaceById, editSurveyTheme } from '../../services/workspaceService';

import AlertUtil from '../../utils/AlertUtil';
import AddUserModal from '../organisation/modal/AddUserModal';
import BrandingComponent from './BrandingComponent';
import ProjectUsersComponent from './ProjectUsersComponent';
import GuardrailsComponent from './GuardrailsComponent';
import OverviewComponent from './OverviewComponent';
import { IMemberDetails } from '../../types/member';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const workspaceInitialState = {
  workspace_name: '',
  workspace_logo: '',
  resurvey_waiting_days: 0,
  app_key: '',
  default_language: '',
  environment_type: 'staging',
};

const SurveySettingsComponent: FunctionComponent<{ member: IMemberDetails }> = ({ member }) => {
  const common = surveyStyles();
  const [tabValue, setTabValue] = useState(0);

  const [workspaces, setWorkspaces] = useState(workspaceInitialState);

  const [themeType, setThemeType] = useState('white');

  const [isLoading, setLoading] = useState(true);
  const [message, setmessage] = useState('');

  const [openToggle, toggleModal] = useState(false);

  const { w_role } = member;

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'settings tab viewed', {});
    }
  }, [tracking_info]);

  const handleUserModal = () => {
    toggleModal(true);
  };

  useEffect(() => {
    const fetchWorkspace = async () => {
      const workspace = await workspaceById();
      if (workspace) {
        const {
          theme_type,
          workspace_name,
          resurvey_waiting_days,
          workspace_logo,
          app_key,
          default_language,
          environment_type,
        } = workspace;

        setWorkspaces({
          ...workspaces,
          workspace_name,
          resurvey_waiting_days,
          workspace_logo,
          environment_type,
          app_key,
          default_language,
        });
        setThemeType(theme_type);
        setLoading(false);
      }
    };
    fetchWorkspace();
  }, []);

  const checkIsValidWorkspace = () => {
    if (!workspaces) {
      return false;
    }
    const { workspace_name, resurvey_waiting_days } = workspaces;
    if (resurvey_waiting_days === null || resurvey_waiting_days < 0 || resurvey_waiting_days > 90) {
      setmessage('Reservey waiting days are not valid');
      setTimeout(() => setmessage(''), 3000);
      return false;
    }
    if (!validator.isLength(workspace_name, { min: 1, max: 255 })) {
      setmessage('Workspace name is not valid');
      setTimeout(() => setmessage(''), 3000);
      return false;
    }
    return true;
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleThemeClick = async () => {
    const { resurvey_waiting_days, default_language } = workspaces;

    const validationResult = checkIsValidWorkspace();
    if (!validationResult) {
      return;
    }

    const results = await editSurveyTheme({ theme_type: themeType, resurvey_waiting_days, default_language });
    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Saved successfully',
      });
    }
  };

  const { workspace_name, workspace_logo, environment_type } = workspaces;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    return (
      <>
        {openToggle && <AddUserModal toggleModal={toggleModal} open={openToggle} organisation={false} />}

        <Typography variant="h2" pb={3}>
          Project Settings
        </Typography>

        <AppBar position="static" className={common.tab}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
              {w_role === 'editor' && (
                <Tab
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography color={tabValue === 0 ? 'primary.contrastText' : ''}>Branding</Typography>
                    </Box>
                  }
                  {...a11yProps(0)}
                />
              )}
              {w_role === 'editor' && (
                <Tab
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography color={tabValue === 1 ? 'primary.contrastText' : ''}>Guardrails</Typography>
                    </Box>
                  }
                  {...a11yProps(1)}
                />
              )}

              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <Typography color={tabValue === 2 || w_role === 'viewer' ? 'primary.contrastText' : ''}>
                      Team Members
                    </Typography>
                  </Box>
                }
                {...a11yProps(2)}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <Typography color={tabValue === 3 || w_role === 'viewer' ? 'primary.contrastText' : ''}>
                      Overview
                    </Typography>
                  </Box>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
        </AppBar>
        {w_role === 'editor' && (
          <TabPanel value={tabValue} index={0}>
            <BrandingComponent />
          </TabPanel>
        )}

        {w_role === 'editor' && (
          <TabPanel value={tabValue} index={1}>
            <div>
              <GuardrailsComponent
                workspaces={workspaces}
                setWorkspaces={setWorkspaces}
                handleThemeClick={handleThemeClick}
                message={message}
              />
            </div>
          </TabPanel>
        )}

        <TabPanel value={tabValue} index={w_role === 'viewer' ? 0 : 2}>
          <ProjectUsersComponent openToggle={openToggle} handleUserModal={handleUserModal} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <OverviewComponent
            workspace_name={workspace_name}
            workspace_logo={workspace_logo}
            environment_type={environment_type}
          />
        </TabPanel>
      </>
    );
  }
};

export default SurveySettingsComponent;
