import React from 'react';
import { Grid, Theme, Box, Typography, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import deleteImg from '../../images/delete-icon.svg';
import { allLanguagesForSurvey } from '../../static/languageData';
import { modifiedDate } from '../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },

    attributeText: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      marginTop: 20,
    },
    tableHeading: {
      fontSize: '0.8rem',
      color: theme.palette.text.secondary,
    },
  }),
);

function capitalizeFirstLetter(str: string, attribute: string) {
  if (attribute === 'email') return str + ' ID';
  else return str.charAt(0).toUpperCase() + str.slice(1);
}

const getComparatorValue = (comparator: string, attribute: string) => {
  if (attribute === 'email') {
    if (comparator === 'in_list') {
      return 'contains';
    } else if (comparator === 'not_in_list') {
      return 'does not contain';
    } else {
      return '';
    }
  } else {
    if (comparator === 'in_list') {
      return 'is equal to';
    } else if (comparator === 'not_in_list') {
      return 'is not equal to';
    } else {
      return '';
    }
  }
};

export default function PerformanceSettingsDialog(props: any) {
  const classes = useStyles();

  const handleClose = () => {
    props.toggleSettingsModal(false);
  };

  const {
    platform,
    survey_name,
    survey_end_date,
    survey_response_limit,
    survey_retake_days,
    triggers,
    condition,
    languages,
  } = props?.surveyInfo;

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <Grid container className={classes.root}>
          <Box width={1} mt={1} display="flex" justifyContent="space-between" alignItems="flex-start">
            <div>
              <Typography variant={'h4'}>{survey_name}</Typography>
              <Box display="flex" alignItems="center" mt={1}>
                <img
                  src={platform ? `https://cdn.blitzllama.com/survey/platform/${platform}-sq.svg` : ''}
                  alt=""
                  width={30}
                  height={40}
                />
                <Typography style={{ marginLeft: 5 }}>
                  {platform === 'hybrid' ? 'React Native' : capitalizeFirstLetter(platform, '')}
                </Typography>
              </Box>
            </div>
            <img
              src={deleteImg}
              alt=""
              style={{ marginLeft: 'auto', height: 32, cursor: 'pointer' }}
              onClick={handleClose}
            />
          </Box>

          <Box width={1} component="div" mt={5}>
            <Box display="flex">
              <Typography className={classes.tableHeading} style={{ width: '40%' }}>
                COHORTS
              </Typography>
              <Typography className={classes.tableHeading} style={{ width: '35%' }}>
                EVENTS
              </Typography>
              <Typography className={classes.tableHeading} style={{ width: '25%' }}>
                OTHER SETTINGS
              </Typography>
            </Box>
            <Box borderBottom={1} borderColor={'divider'} mb={1.2} mt={0.5}></Box>
            <Box display="flex">
              <Box component="div" style={{ width: '40%' }}>
                <ul style={{ paddingInlineStart: 20 }}>
                  <li>
                    <Typography>{props?.cohortName ? props?.cohortName : 'All users'}</Typography>
                  </li>
                </ul>
                {condition.length > 0 && (
                  <ul style={{ paddingInlineStart: 30 }}>
                    {condition.map(({ attribute, comparator, list }, idx) => (
                      <li className={classes.attributeText} key={idx}>
                        <Typography>
                          {attribute} {getComparatorValue(comparator, attribute)}
                          {list.map((option, idx) => (
                            <>
                              <span key={idx}>{`${option}${idx === list.length - 1 ? '.' : ','} `}</span>
                            </>
                          ))}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>

              <ul style={{ paddingInlineStart: 30, width: '35%' }}>
                {triggers.map((option, idx) => (
                  <li key={idx}>
                    <Typography variant="body1">{option}</Typography>
                  </li>
                ))}
              </ul>
              <ul style={{ paddingInlineStart: 10, width: '25%' }}>
                <li style={{ marginBottom: 20 }}>
                  <Typography>Resurvey users after {survey_retake_days} days</Typography>
                </li>

                <li style={{ marginBottom: 20 }}>
                  <Typography>Collect {survey_response_limit} responses</Typography>
                </li>

                <li style={{ marginBottom: 20 }}>
                  <Typography>Collect responses till {modifiedDate(survey_end_date, 'lll')}</Typography>
                </li>
                {languages.length > 0 && (
                  <li style={{ marginBottom: 20 }}>
                    <Typography>
                      Languages: {languages.map(item => ` ${allLanguagesForSurvey[item]}`).toString()}
                    </Typography>
                  </li>
                )}
              </ul>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </div>
  );
}
