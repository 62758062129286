import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBoard } from '../components/spotlight/spotlight';
import { fetchAllBoard } from '../services/spotlightService';

const initialState: Array<IBoard> = [];

export const retrieveChartDetails = createAsyncThunk('chart/get', async () => {
  const results = await fetchAllBoard();
  return results;
});

const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(retrieveChartDetails.fulfilled, (state, action) => {
      return [...action.payload];
    });
  },
});

const { reducer } = chartSlice;
export default reducer;
