import React from 'react';
import SideNavComponent from '../../components/layout/SideNavComponent';
// import SpotlightComponent from '../../components/spotlight/SpotlightComponent';
// import NewSpotlightComponent from '../../components/spotlight/NewSpotlightComponent';
import SpotlightBaseComponent from '../../components/spotlight/BaseComponent';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      top: '55px',
    },
  }),
);

const SettingsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SideNavComponent />
      <main className={classes.content}>
        <SpotlightBaseComponent />
      </main>
    </div>
  );
};

export default SettingsPage;
