import React, { FunctionComponent } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useStyles } from './Spotlight.style';

import { DailyChartComponent } from './charts/DailyChartComponent';
import { ChartBaseComponent } from './charts/ChartBaseComponent';
import { SplitViewChartComponent } from './charts/SplitViewComponent';
import { DateFilter } from './DateFilter';

import { chartTypesIcon, questionTypeConfig } from './constants';
import { ISpotlightQuery } from './spotlight';

import emptySpotlightImg from '../../images/spotlight/empty-spotlight.svg';

const StartSpotlightComponent = () => {
  const classes = useStyles();
  return (
    <Box my={3} className={classes.emptyStateContainer}>
      <img src={emptySpotlightImg} alt="" />

      <Typography variant="subtitle1" fontWeight={700} mt={1.5}>
        <span role="img" aria-label="">
          💡
        </span>
        Select conditions to see data here
      </Typography>
      <Typography textAlign={'center'} variant={'subtitle2'}>
        First select a question type and other
        <br /> conditions to see results populate here
      </Typography>
    </Box>
  );
};

export const ChartComponent: FunctionComponent<{
  spotlightChart: any;
  spotlightQuery: ISpotlightQuery;
  handleDateRangeQuery: (range_type: string, field_name: string, value: string | number) => void;
  fetchSpotlightChart: (chart_type: string) => void;
}> = ({ spotlightChart, spotlightQuery, handleDateRangeQuery, fetchSpotlightChart }) => {
  const classes = useStyles();

  const { date_range, question_type } = spotlightQuery;
  const chartOptions = questionTypeConfig[question_type]
    ? questionTypeConfig[question_type].charts
    : questionTypeConfig['default'].charts;

  const { chart_type, chart_data = {} } = spotlightChart;

  //null cases
  const {
    overall_count = 0,
    stats = [],
    user_count = 0,
    x_axis = [],
    data_groups = [],
    nps_stats = [],
    question_stats = [],
  } = chart_data;

  // return chart_type label
  const getChartLabel = (chart_type: string) => {
    const chart_data = chartOptions.find(c => c.value === chart_type);
    return chart_data ? chart_data.label : '';
  };

  return (
    <div className={classes.spotlightLowerContainer}>
      <Box className={classes.spotlightLowerBox}>
        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.filterBox}>
          <Box display="flex" alignItems="center">
            <Typography variant={'subtitle1'} className={classes.greyBox}>
              {user_count} unique users
            </Typography>
            <Typography variant={'subtitle1'} ml={1} className={classes.greyBox}>
              {overall_count} Responses
            </Typography>
          </Box>
          <Box>
            <Select
              variant="outlined"
              displayEmpty
              className={classes.chartSelectField}
              style={{ marginRight: 10, height: 37, width: 'fit-content' }}
              renderValue={() => (
                <Box display={'flex'} alignItems={'center'}>
                  <div style={{ width: 'fit-content' }}>{chartTypesIcon[chart_type]}</div>
                  <Typography variant="subtitle1" ml={0.5} width="12ch" noWrap>
                    {getChartLabel(chart_type)}
                  </Typography>
                </Box>
              )}
              value={chart_type}
              onChange={e => fetchSpotlightChart(e.target.value)}
            >
              {chartOptions.map(({ label, value }, idx) => (
                <MenuItem key={idx} value={value}>
                  {chartTypesIcon[value]}
                  <Typography variant={'subtitle1'} ml={1}>
                    {label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <DateFilter date_range={date_range} handleDateChanges={handleDateRangeQuery} />
          </Box>
        </Box>
        <Box borderBottom={1} borderColor={'divider'}></Box>

        <Box className={classes.chartBox}>
          {question_type ? (
            <div>
              {chart_type === 'daily_chart' && (
                <DailyChartComponent
                  x_axis={x_axis}
                  data_groups={data_groups}
                  question_type={spotlightQuery.question_type}
                />
              )}
              {chart_type === 'cumulated' && (
                <ChartBaseComponent question_type={question_type} stats={stats} nps_stats={nps_stats} />
              )}
              {chart_type === 'split_view' && (
                <SplitViewChartComponent question_stats={question_stats} question_type={question_type} />
              )}
            </div>
          ) : (
            <StartSpotlightComponent />
          )}
        </Box>
      </Box>
    </div>
  );
};
