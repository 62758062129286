import { postCall, putCall } from '../apiCall';
import {
  JOIN_WORKSPACE_ADMIN,
  LEAVE_WORKSPACE_ADMIN,
  MAKE_MEMBER_ADMIN,
  INVITE_USERS_FOR_ALL,
  MAKE_USER_ADMIN,
  DELETE_USER_ADMIN,
  REINVITE_USER,
} from './apiEndPoints';

export const joinWorkspaceAdmin = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(JOIN_WORKSPACE_ADMIN(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const leaveWorkspaceAdmin = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(LEAVE_WORKSPACE_ADMIN(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const makeMemberAdmin = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(MAKE_MEMBER_ADMIN(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const makeMemberUser = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(MAKE_USER_ADMIN(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteMember = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DELETE_USER_ADMIN(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const inviteUsers = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(INVITE_USERS_FOR_ALL(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const reInviteUser = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(REINVITE_USER(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
