import React from 'react';
import SideNavComponent from '../../components/layout/SideNavComponent';
import CohortHistoryComponent from '../../components/audience/CohortHistoryComponent';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      top: '55px',
      paddingTop: theme.spacing(8),
    },
  }),
);

const CohortHistoryPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SideNavComponent />
      <main className={classes.content}>
        <CohortHistoryComponent />
      </main>
    </div>
  );
};

export default CohortHistoryPage;
