import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Theme, Typography, CircularProgress, TextField, Button, Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ImageInterface } from '../../types/ImageInterface';
import { imageUpload } from '../../services/imageService';
import { fetchOrganisation } from '../../services/organisationService';
import { IOrganisation } from '../../types/organisation';
import validator from 'validator';
import { editWorkspace } from '../../services/workspaceService';
import AlertUtil from '../../utils/AlertUtil';
import accountImg from '../../images/settings/workspace-img.png';
import ENV from '../../config';
import { ErrorUtil } from '../../utils/MessageUtil';
import { modifiedDate } from '../../utils/DateUtil';
import { envData } from '../../static/staticData';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { retrieveWorkspaceDetails } from '../../slices/workspaceSlice';
import { RootState, useAppDispatch } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overviewContainer: {
      paddingBlock: '2.5rem',
    },
    overviewBox: {
      paddingBottom: theme.spacing(2.5),
      borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    },
    detailTitle: {
      fontWeight: 700,
      width: '200px',
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    imageLabel: {
      fontSize: '0.6rem',
      color: theme.palette.text.secondary,
      marginTop: 5,
    },
    uploadButton: {
      fontSize: '0.8rem',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    uploadImg: {
      height: 90,
      width: 90,
      borderRadius: '50%',
    },
    workspaceNameField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.75rem 0.87rem',
      },
    },
    platformSelect: {
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '0.75rem 0.87rem',
      },
    },
  }),
);

export default function OverviewComponent(props: {
  workspace_name: string;
  workspace_logo: string;
  environment_type: string;
}) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [organisation, setOrganisation] = useState<IOrganisation>();
  const [isLoading, setLoading] = useState(true);
  const inputFile = useRef<HTMLInputElement>(null);

  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceLogo, setWorkspaceLogo] = useState('');

  const [environment, setEnvironment] = useState('staging');

  const [message, setmessage] = useState('');

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'settings tab viewed', { tab: 'overview' });
    }
  }, [tracking_info]);

  useEffect(() => {
    const fetchOrganisationDetails = async () => {
      if (props.workspace_name) {
        setWorkspaceName(props.workspace_name);
      }
      if (props.workspace_logo) {
        setWorkspaceLogo(props.workspace_logo);
      }
      if (props.environment_type) {
        setEnvironment(props.environment_type);
      }
      const organisationResult = await fetchOrganisation();
      if (organisationResult) {
        setOrganisation(organisationResult);
      }
      setLoading(false);
    };
    fetchOrganisationDetails();
  }, []);

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleSave = async () => {
    if (!validator.isLength(workspaceName, { min: 1, max: 255 })) {
      setmessage('Workspace name is not valid');
      setTimeout(() => setmessage(''), 3000);
      return;
    }

    const results = await editWorkspace({
      workspace_name: workspaceName,
      environment_type: environment,
      workspace_logo: workspaceLogo,
    });
    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Saved successfully',
      });
      dispatch(retrieveWorkspaceDetails());
    }
  };

  const imageHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name) {
      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: '',
        base64: imgBase64,
      });
      if (imgResult) {
        setWorkspaceLogo(`${ENV.cdn_url}${imgResult.img_url}`);
        await handleSave();
      }
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div className={classes.overviewContainer}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} pb={3}>
          <Button variant="contained" onClick={handleSave}>
            Save changes
          </Button>
        </Box>
        <Box display="flex" className={classes.overviewBox}>
          <input
            type="file"
            accept="image/*"
            name="image-upload"
            ref={inputFile}
            onChange={e => imageHandler(e)}
          ></input>
          <img src={workspaceLogo ? workspaceLogo : accountImg} alt="" id="img" className={classes.uploadImg} />
          <Box ml={3} mt={3}>
            <Button variant="contained" className={classes.uploadButton} onClick={onButtonClick}>
              Change display picture
            </Button>
            <Typography className={classes.imageLabel}>Min. Dimension: 100 x 100</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" className={classes.overviewBox}>
          <Typography variant="h6" className={classes.detailTitle}>
            Name
          </Typography>
          <TextField
            variant={'outlined'}
            style={{ width: '20%' }}
            autoComplete="off"
            className={classes.workspaceNameField}
            value={workspaceName}
            onChange={e => setWorkspaceName(e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="center" className={classes.overviewBox}>
          <Typography variant="h6" className={classes.detailTitle}>
            Type
          </Typography>
          <Select
            variant="outlined"
            style={{ width: '20%' }}
            name="default_language"
            value={environment}
            className={classes.platformSelect}
            onChange={e => setEnvironment(e.target.value)}
          >
            {envData.map(({ value, label }, idx) => (
              <MenuItem value={value} key={idx}>
                <Typography>{label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" alignItems="center" className={classes.overviewBox}>
          <Typography variant="h6" className={classes.detailTitle}>
            Organisation
          </Typography>
          <Typography variant="body2">{organisation?.org_name}</Typography>
        </Box>
        <Box display="flex" alignItems="center" className={classes.overviewBox}>
          <Typography variant="h6" className={classes.detailTitle}>
            Created By
          </Typography>
          <Typography variant="body2">{organisation?.org_email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" className={classes.overviewBox}>
          <Typography variant="h6" className={classes.detailTitle}>
            Date created
          </Typography>
          <Typography variant="body2">
            {organisation?.created_at ? modifiedDate(organisation?.created_at, 'lll') : ''}
          </Typography>
        </Box>
        {message !== '' && <ErrorUtil message={message} />}
      </div>
    );
  }
}
