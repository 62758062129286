import React from 'react';
import ErrorComponent from '../../components/survey/ErrorComponent';

const ErrorPage = () => {
  return (
    <div>
      <ErrorComponent />
    </div>
  );
};

export default ErrorPage;
