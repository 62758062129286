import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import useClickOutside from './useClickOutside';

import { Box, Theme, Typography } from '@mui/material';
import { isCustomTheme } from '../../utils/workspaceUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputSubTitle: { fontSize: '0.7rem', color: theme.palette.text.secondary, marginTop: '-4px' },
    colorPickerBox: {
      marginTop: theme.spacing(1),
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    colorBox: {
      minWidth: '28px',
      height: '28px',
      borderRadius: '3px',
      border: `1px solid #c5c5cc`,
    },
    popover: {
      position: 'absolute',
      top: 'calc(100% + 2px)',
      left: 0,
      borderRadius: '9px',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
      zIndex: 1,
    },
    inputColor: {
      textAlign: 'center',
      backgroundColor: 'transparent',
      border: 0,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(1.8),
      fontWeight: 700,
      fontSize: '0.8rem',
      fontFamily: 'rustica,sans-serif;',
      '&:focus-visible': {
        outline: '-webkit-focus-ring-color auto 0px',
      },
    },
    hexCodeBox: {
      backgroundColor: '#fff',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      paddingInline: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.7),
    },
    hexColorField: {
      backgroundColor: 'transparent',
      '& .MuiOutlinedInput-input': {
        padding: '0.2rem',
      },
    },
  }),
);

type ColorProps = {
  property: string;
  value: string;
  customThemeHandler: (fieldName: string, fieldValue: string) => void;
  label: string;
  theme_id?: string;
  subtitle: string;
};

const ColorPicker: FunctionComponent<ColorProps> = ({
  property,
  value,
  customThemeHandler,
  label,
  subtitle,
  theme_id,
}) => {
  const classes = useStyles();
  const popover: any = useRef();
  const [activePalette, setActivePalette] = useState('');

  const close = useCallback(() => setActivePalette(''), []);
  useClickOutside(popover, close);

  const handleColorPalette = (property: string) => {
    if (isCustomTheme(theme_id ? theme_id : '')) setActivePalette(property);
  };

  return (
    <div>
      <Box className={classes.colorPickerBox}>
        <div
          className={classes.colorBox}
          style={{
            backgroundColor: value,
            cursor: isCustomTheme(theme_id ? theme_id : '') ? 'pointer' : 'not-allowed',
          }}
          onClick={() => handleColorPalette(property)}
        />
        <Box display={'flex'} flexDirection={'column'} ml={1}>
          <Typography variant={'subtitle1'} mt={-0.5}>
            {label}
          </Typography>
          <Typography className={classes.inputSubTitle}>{subtitle}</Typography>
        </Box>

        {activePalette === property && (
          <div className={classes.popover} ref={popover}>
            <HexColorPicker color={value} onChange={e => customThemeHandler(property, e)} />
            <Box className={classes.hexCodeBox}>
              <HexColorInput
                autoFocus
                color={value}
                onChange={e => customThemeHandler(property, e)}
                className={classes.inputColor}
                prefixed
              />
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
};

export default ColorPicker;
