import React, { useEffect } from 'react';
import { useQuery } from '../../utils/stringUtil';
import { authMemberRedirect } from '../../services/loginService';

export default function RedirectFromBoard() {
  const query = useQuery();
  const token = query.get('token');

  const handleSurveyRedirect = async () => {
    if (!token) {
      return;
    }

    const domain = window.location.host;

    // fetch token and workspace_id
    const result = await authMemberRedirect(token, domain);
    if (result && result.token && result.w_id) {
      localStorage.setItem('auth_token', result.token);
      localStorage.setItem('active_workspace', result.w_id);
      window.location.href = '/';
    }
  };

  useEffect(() => {
    handleSurveyRedirect();
  }, []);

  return <div></div>;
}
