import { postCall, putCall, getCall } from '../apiCall';
import {
  CREATE_COHORT,
  FETCH_COHORT_LIST,
  EDIT_COHORT,
  FETCH_COHORT_BY_ID,
  DELETE_COHORT,
  FETCH_WORKSPACE_ATTRIBUTES,
  FETCH_WORKSPACE_ATTRIBUTE_VALUES,
  FILTER_COHORT_BY_ID,
  CREATE_SURVEY_COHORT,
  EDIT_SURVEY_COHORT,
  FETCH_ONGOING_COHORTS,
  FETCH_SURVEY_CSV_RESPONSE,
  FETCH_SURVEY_FOLLOWUP_COUNT,
  CREATE_SURVEY_FOLLOWUP_NEW,
  FETCH_COHORT_PREVIEW_DATA,
  FETCH_USER_ACTIVITY_FEED,
  FETCH_FOLLOWUP_COHORTS_BY_SURVEY_ID,
} from './apiEndPoints';
import { IAttributeItem, ICohort, IOngoingCohort, ISurveyFollowupCohort, IUserActivity } from '../types/audience';

export const createCohort = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_COHORT(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const cohortPreviewData = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_COHORT_PREVIEW_DATA(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createSurveyCohort = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_SURVEY_COHORT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveyCohort = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_COHORT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editCohort = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_COHORT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllCohort = async () => {
  return new Promise<Array<ICohort>>((resolve, reject) => {
    try {
      getCall(FETCH_COHORT_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchOngoingCohort = async () => {
  return new Promise<Array<IOngoingCohort>>((resolve, reject) => {
    try {
      getCall(FETCH_ONGOING_COHORTS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchCohortById = async (cohort_id: string) => {
  return new Promise<ICohort>((resolve, reject) => {
    try {
      getCall(FETCH_COHORT_BY_ID(cohort_id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const filterCohortByName = async (cohort_id: string, attribute: string, search_str: string, offset: number) => {
  return new Promise<Array<string>>((resolve, reject) => {
    try {
      getCall(FILTER_COHORT_BY_ID(cohort_id, attribute, search_str, offset))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteCohort = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DELETE_COHORT(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllAttributes = async () => {
  return new Promise<Array<IAttributeItem>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_ATTRIBUTES())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllAttributeValues = async (attribute: string) => {
  return new Promise<Array<string>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_ATTRIBUTE_VALUES(attribute))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchResponseCsv = async (payload: any, survey_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_SURVEY_CSV_RESPONSE(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const surveyFollowupCount = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_SURVEY_FOLLOWUP_COUNT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createFollowupCohort = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_SURVEY_FOLLOWUP_NEW(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchFollowupCohortsBySurveyId = async (id: string) => {
  return new Promise<Array<ISurveyFollowupCohort>>((resolve, reject) => {
    try {
      getCall(FETCH_FOLLOWUP_COHORTS_BY_SURVEY_ID(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchUserActivityFeed = async (id: string) => {
  return new Promise<IUserActivity>((resolve, reject) => {
    try {
      getCall(FETCH_USER_ACTIVITY_FEED(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
