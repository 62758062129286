import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { StyledTableRow } from '../common/tableStyles';

import { fetchAllAttributes } from '../../services/audienceService';
import { IAttributeItem } from '../../types/audience';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventTable: {
      width: '60%',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& .MuiTableHead-root': {
        borderTop: 0,
      },
    },
    emptyAttributeText: {
      fontSize: '1.2rem',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
      fontWeight: 700,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
  }),
);

export default function AttributesTabComponent() {
  const classes = useStyles();
  const [attributeList, setAttributeList] = useState<Array<IAttributeItem>>();

  const [isLoading, setLoading] = useState(true);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'cohorts tab viewed', { tab: 'attribute' });
    }
  }, [tracking_info]);

  const fetchAttributes = async () => {
    const attributes = await fetchAllAttributes();

    if (attributes) {
      setAttributeList(attributes);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else if (!attributeList || attributeList.length === 0) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography className={classes.emptyAttributeText}>No attributes to display</Typography>
      </Box>
    );
  } else {
    return (
      <div>
        <TableContainer className={classes.eventTable}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Attribute name</TableCell>
                <TableCell>Data type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributeList &&
                attributeList.map(({ name, data_type }, idx) => (
                  <StyledTableRow>
                    <TableCell width={'30%'}>{name}</TableCell>

                    <TableCell width={'20%'}>{data_type}</TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
