import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './Billing.style';
import surveyStyles from '../common/SurveyStyles';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import PlanConfirmationModal from './modal/PlanConfirmationModal';
import greenTickImg from '../../images/billing/green-tick.svg';
import blueTickImg from '../../images/billing/blue-tick.svg';
import { fetchOrganisation } from '../../services/organisationService';
import { IOrganisation } from '../../types/organisation';
import { billingPlanDetails } from '../../static/staticData';
import { updateBillingPlan } from '../../services/billingService';
import AlertUtil from '../../utils/AlertUtil';
import { useHistory } from 'react-router-dom';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

export default function BillingPlanComponent() {
  const classes = useStyles();
  const common = surveyStyles();
  const history = useHistory();
  const [openConfirmationModal, toggleConfirmationModal] = useState(false);
  const [organisationInfo, setOrganisationInfo] = useState<IOrganisation>();
  const [planName, setPlanName] = useState('');
  const [isLoading, setLoading] = useState(true);

  const fetchOrganisationInfo = async () => {
    const organisation = await fetchOrganisation();
    if (organisation) {
      setOrganisationInfo(organisation);
    }
    setLoading(false);
  };

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    fetchOrganisationInfo();
  }, []);

  if (!organisationInfo || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    const { curr_plan, is_card_added, referrer } = organisationInfo;

    // handle billing plan updates
    const handleUpdatePlan = async (plan_name: string) => {
      const updateResult = await updateBillingPlan({ plan: plan_name });
      if (updateResult) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Plan Updated successfully',
        });
      }
      toggleConfirmationModal(false);
      if (tracking_info) {
        amplitudeEvent(tracking_info, 'subscription switched', { 'subs type': plan_name });
      }
      fetchOrganisationInfo();
    };

    // used for billing plan switch
    const handlePlanSwitch = async (plan_name: string) => {
      if (plan_name === 'company') {
        window.open('https://calendly.com/blitzllama/blitz', '_blank');
      }

      if (curr_plan === 'free') {
        if (!is_card_added) {
          toggleConfirmationModal(true);
        } else {
          setPlanName(plan_name);
          await handleUpdatePlan(plan_name);
        }
      }
    };

    return (
      <>
        {openConfirmationModal && (
          <PlanConfirmationModal toggleConfirmationModal={toggleConfirmationModal} planName={planName} />
        )}

        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems="center"
          justifyContent={'center'}
          style={{ position: 'relative' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4">Pick your usage based billing plan</Typography>

            <Typography variant="h6" fontWeight={400} color="#219C0C" style={{ textTransform: 'capitalize' }}>
              Currently on: {curr_plan} plan
            </Typography>

            <Button
              variant="outlined"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={() => history.goBack()}
            >
              <Typography variant="subtitle1" pl={0.6}>
                Go Back
              </Typography>
            </Button>
          </div>
          <Grid
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item lg={10} className={classes.planContainer}>
              <Grid container>
                {billingPlanDetails.map(
                  ({ plan_name, title, discount_price, price, discount, desc, button_text, features }, idx) => (
                    <Grid
                      item
                      lg={4}
                      key={idx}
                      className={classes.planColumn}
                      style={{
                        borderRight: plan_name === 'company' ? 0 : '',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                        color={curr_plan === plan_name ? 'primary.contrastText' : ''}
                      >
                        {title}
                      </Typography>

                      <Typography variant="subtitle1" lineHeight={'18.28px'} mt={1.5} style={{ height: '50px' }}>
                        {desc}
                      </Typography>

                      <div style={{ height: '20px' }}>
                        {plan_name === 'team' && (
                          <Typography variant="subtitle1" fontWeight={400}>
                            Starting at
                          </Typography>
                        )}
                      </div>

                      <div style={{ height: '120px' }}>
                        {plan_name === 'team' && referrer === 'yc' ? (
                          <div style={{ display: 'flex' }}>
                            <Typography variant="h2" mb={2}>
                              {discount_price}
                            </Typography>
                            <Typography
                              variant="h4"
                              ml={1}
                              mt={0.8}
                              style={{ textDecoration: 'line-through' }}
                              color="#989898"
                            >
                              {price}
                            </Typography>
                          </div>
                        ) : (
                          <Typography variant="h2" mb={2}>
                            {price}
                          </Typography>
                        )}

                        {referrer === 'yc' && discount && (
                          <Typography variant="subtitle1" className={classes.discountBox}>
                            {discount}
                          </Typography>
                        )}
                      </div>

                      <div style={{ height: '130px' }}>
                        {features &&
                          features.map((item, idx) => (
                            <Box key={idx} display={'flex'} alignItems="center">
                              <img src={blueTickImg} alt="" />
                              <Typography variant="subtitle1" ml={0.5} color="primary.contrastText">
                                {item}
                              </Typography>
                            </Box>
                          ))}
                      </div>
                      {curr_plan === plan_name ? (
                        <Button className={classes.currentPlanButton}>
                          <img src={greenTickImg} alt="" />
                          <Typography variant="subtitle1" pl={0.6}>
                            Current plan
                          </Typography>
                        </Button>
                      ) : (
                        button_text &&
                        !(curr_plan === 'company' && plan_name === 'team') && (
                          <Button
                            variant="contained"
                            style={{ paddingBlock: '0.7rem' }}
                            onClick={() => handlePlanSwitch(plan_name)}
                          >
                            {button_text}
                          </Button>
                        )
                      )}
                    </Grid>
                  ),
                )}
              </Grid>
            </Grid>
          </Grid>

          <a href="https://blitzllama.com/pricing" target="_blank" rel="noopener noreferrer">
            <Button className={classes.comparisonButton}>View detailed comparision &#8594;</Button>
          </a>
          <Typography variant="subtitle1" color="#989889" pt={2.5}>
            Want to change your current plans?{' '}
            <span style={{ color: '#4D30FF' }}>Tell us about it via the Suport Chat</span>
          </Typography>
        </Box>
      </>
    );
  }
}
