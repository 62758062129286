import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(4),
      '& $h6': { color: theme.palette.text.secondary },
    },

    questionBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
    },
    responseBox: {
      borderTop: '1px solid rgba(255, 255, 255, 0.05)',
    },
    circularProgress: {
      '& .MuiCircularProgress-colorPrimary': {
        color: theme.palette.secondary.dark,
      },
    },
    avatar: {
      height: '25px',
      width: '25px',
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    settingsBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(3),
      cursor: 'pointer',
      marginLeft: 'auto',
      marginRight: '60px',
      marginTop: theme.spacing(1),
      width: 'max-content',
    },
    pinBox: {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: '#FFF',
      borderRadius: theme.spacing(0.8),
      cursor: 'pointer',
    },
    takeawayBox: {
      border: `1px solid #DFD0F1`,
      backgroundColor: '#FFF',
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    takeawayOrderBox: {
      backgroundColor: '#5F516F',
      color: '#FFF',
      borderRadius: 5,
      padding: 6,
      paddingBlock: 2,
      marginLeft: theme.spacing(1.5),
      width: 'fit-content',
      height: 'fit-content',
    },
    conditionOrder: {
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: 5,
      padding: 5,
      paddingBlock: 0.5,
      marginRight: theme.spacing(1),
    },
    greyColor: { color: '#989898' },
    tableHeading: { color: '#989898', fontSize: '0.85rem', fontWeight: 400 },
    filterSelect: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.5),
      marginBlock: theme.spacing(1.5),
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
      },
      '& .MuiSelect-outlined': {
        backgroundColor: 'transparent',
      },
      '& .MuiInputBase-input': {
        padding: '0.35rem 1rem 0.35rem 1rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    check: {
      margin: 0,
      padding: 0,
      color: theme.palette.divider,
      '&$checked': {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    statusButton: {
      marginLeft: 10,
      textTransform: 'unset',
      padding: '0.5rem 1.37rem 0.55rem 1.37rem',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.3rem',
      whiteSpace: 'nowrap',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
      },
    },
    performaceFilterButton: {
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      fontSize: '0.9rem',
      textTransform: 'none',
      padding: '0.55rem 1rem 0.57rem 0.875rem',
      fontWeight: 700,
      borderRadius: '5px',
      marginLeft: '9px',
    },
    activeFilterButton: {
      border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      backgroundColor: `${theme.palette.primary.contrastText}0D`,
      textTransform: 'none',
      fontSize: '0.9rem',
      padding: '0.55rem 1rem 0.57rem 0.875rem',
      borderRadius: '5px',
      marginLeft: '9px',
    },
    cohortCreate: {
      height: theme.spacing(5),
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      borderRadius: '5px',
      marginRight: '9px',
      fontSize: '1rem',
      textTransform: 'none',
      padding: '0.55rem 1rem 0.57rem 0.875rem',
      fontWeight: 700,
    },
    dateRangeBox: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      padding: 20,
      paddingBlock: 10,
      paddingLeft: 20,
      cursor: 'pointer',
      border: `1px solid ${theme.palette.divider}`,
    },
    dateRangePopup: {
      backgroundColor: 'white',
      '& .MuiDateRangePickerDay-day.Mui-selected': {
        willChange: 'none',
        backgroundColor: `${theme.palette.primary.contrastText}30`,
      },
    },
    userBox: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    responseHeading: {
      backgroundColor: '#F5F6FC',
    },
    responseEventBox: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1.5),
      flexWrap: 'wrap',
      gap: theme.spacing(0.6),
    },
    responseEventItem: {
      backgroundColor: `${theme.palette.primary.contrastText}12`,
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(0.2),
      minWidth: 'fit-content',
      borderRadius: '3px',
    },

    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },
    closeIcon: {
      position: 'absolute',
      top: '26px',
      left: '20px',
      cursor: 'pointer',
    },
    cohortNameInput: {
      width: '80%',
      color: theme.palette.text.disabled,
      '& .MuiOutlinedInput-input': {
        fontWeight: 700,
        fontSize: '1.1rem',
        paddingBlock: theme.spacing(1.1),
      },
    },
    deleteImg: {
      paddingLeft: theme.spacing(4),
      height: '36px',
      cursor: 'pointer',
    },
    conditionBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 10,
      marginTop: 20,
    },
    conditionUserBox: {
      paddingBlock: theme.spacing(0.9),
      backgroundColor: theme.palette.secondary.dark,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      borderRadius: theme.spacing(0.7),
      whiteSpace: 'nowrap',
    },
    clearFilterBox: {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': { paddingBlock: theme.spacing(0.8) },
    },
    cohortButton: {
      padding: '0.62rem 1.25rem',
      marginTop: theme.spacing(1.2),
    },
    cohortType: {
      width: '166px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-around',
      flexDirection: 'column',
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    countBox: {
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.dark,
      padding: 8,
      paddingBlock: 8,
      marginLeft: 'auto',
    },
    deleteIcon: {
      height: 24,
      width: 24,
      cursor: 'pointer',
      marginLeft: 5,
    },
    questionOrder: {
      backgroundColor: '#F5F5F5',
      borderRadius: 5,
      padding: 7,
      paddingBlock: 0,
    },
    numberField: {
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '& .MuiFormControl-root-MuiTextField-root': {
        border: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '0.44rem 0.9rem',
      },
    },
    intentTitle: {
      borderBottom: '1.5px dashed #0000007D',
    },
    customTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      border: `1px solid ${theme.palette.divider}`,
    },
    createLinkBox: {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      cursor: 'pointer',
    },
    cohortTable: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      paddingTop: theme.spacing(2),
    },
    transparentButton: {
      marginInline: theme.spacing(1.2),
      textTransform: 'unset',
      padding: '0.5rem 1.37rem 0.55rem 1.37rem',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.3rem',
      whiteSpace: 'nowrap',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
      },
    },
    customCohortField: {
      '& .MuiOutlinedInput-input': { paddingBlock: theme.spacing(1), paddingInline: theme.spacing(1.5) },
      flexGrow: 1,
    },
    cohortDetailsButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      padding: '0.56rem 1rem 0.5rem 0.9rem',
      borderRadius: '0.3rem',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
  }),
);
