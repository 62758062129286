import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import AlertUtil from '../../../utils/AlertUtil';

const CheckoutForm = (props: { closePIModal }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { closePIModal } = props;

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    closePIModal();

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      AlertUtil.fire({
        icon: 'error',
        title: 'Payment failed',
      });
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      AlertUtil.fire({
        icon: 'success',
        title: 'Payment successful',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        variant={'contained'}
        disabled={!stripe}
        style={{ float: 'right', marginTop: 20 }}
        onClick={e => {
          handleSubmit(e);
        }}
      >
        Submit
      </Button>
    </form>
  );
};

export default CheckoutForm;
