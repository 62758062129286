import { postCall, putCall, getCall } from '../apiCall';
import {
  CREATE_THEME,
  DELETE_THEME_BY_ID,
  EDIT_THEME_BY_ID,
  FETCH_THEME_BY_ID,
  FETCH_THEME_LIST,
} from './apiEndPoints';
import { ICustomTheme } from '../types/theme';

export const createTheme = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_THEME(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.data) {
            resolve(err.data.data);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchThemeList = async () => {
  return new Promise<Array<ICustomTheme>>((resolve, reject) => {
    try {
      getCall(FETCH_THEME_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchThemeById = async (theme_id: string) => {
  return new Promise<ICustomTheme>((resolve, reject) => {
    try {
      getCall(FETCH_THEME_BY_ID(theme_id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editThemeById = async (theme_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_THEME_BY_ID(theme_id), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.data) {
            resolve(err.data.data);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteThemeById = async (theme_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DELETE_THEME_BY_ID(theme_id), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
