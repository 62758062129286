import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStyles } from '../Billing.style';
import { ReactComponent as DeleteImg } from '../../../images/delete-icon.svg';
import CheckoutForm from './CheckoutForm';
import ENV from '../../../config';

export default function AddBillingModal(props: {
  open_payment_intent: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  clientSecret: string;
}) {
  const classes = useStyles();
  const { open_payment_intent, closeModal, clientSecret } = props;

  const [stripePromise, setStripePromise] = useState<any>(null);

  useEffect(() => {
    return setStripePromise(loadStripe(ENV.stripe_publish_key));
  }, []);

  return (
    <div>
      <Dialog open={open_payment_intent} fullWidth maxWidth={'sm'}>
        <div className={classes.confirmationModalRoot}>
          <Box display="flex" alignItems={'right'}>
            <div>
              <DeleteImg
                style={{ height: 21, cursor: 'pointer' }}
                onClick={() => {
                  closeModal(false);
                }}
              />
            </div>
          </Box>
          <Box>
            <>
              <Typography fontSize={24} fontWeight={'bold'}>
                Setup Payment
              </Typography>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm closePIModal={closeModal} />
                </Elements>
              )}
            </>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}
