import makeStyles from '@mui/styles/makeStyles';

export const triggerStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: `calc(100vh - 54px)`,
  },

  inputLabel: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '0.8rem',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  modalButton: {
    width: '45%',
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
  },
  warningBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    textAlign: 'center',
    padding: theme.spacing(2),
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },

  copyButton: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    padding: '3px 10.3px 5px 17px',
    textTransform: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
  copiedButton: {
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.divider}`,
    padding: '3px 10.3px 5px 17px',
    textTransform: 'none',
    color: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.background.default,
    },
  },
  selectButton: {
    position: 'absolute',
    right: 20,
    top: 4,
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
    fontWeight: 700,
    margin: '4.2px 0 0 216px',
    padding: '5px 11px 7px 10px',
    borderRadius: '6.8px',
    boxShadow: '0 2.9px 13.6px 0 rgba(0, 0, 0, 0.25)',
    border: 'solid 0.8px rgba(255, 255, 255, 0.2)',
    textTransform: 'none',
  },
  unSelectButton: {
    position: 'absolute',
    right: 20,
    top: 4,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    margin: '4.2px 0 0 216px',
    padding: '5px 11px 7px 10px',
    borderRadius: '6.8px',
    boxShadow: '0 2.9px 13.6px 0 rgba(0, 0, 0, 0.25)',
    border: 'solid 0.8px rgba(255, 255, 255, 0.2)',
    textTransform: 'none',
  },
}));
