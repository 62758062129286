import { postCall, putCall } from '../apiCall';
import {
  ADD_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
  EDIT_SURVEY_QUESTION_TYPE,
  INSERT_SURVEY_QUESTIONS,
} from './apiEndPoints';

export const addSurveyQuestion = async (payload: any, id: string, question_type: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(ADD_SURVEY_QUESTION(id, question_type), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const addBulkSurveyQuestions = async (payload: any, survey_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(INSERT_SURVEY_QUESTIONS(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteSurveyQuestion = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DELETE_SURVEY_QUESTION(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveyQuestionType = async (payload: any, id: string, question_type: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_QUESTION_TYPE(id, question_type), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
