import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, TextField, Button, Theme, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import defaultStyles from './loginStyles';
import { memberJobRole } from '../../static/staticData';
import { IMemberDetails } from '../../types/member';
import { editMemberDetails } from '../../services/organisationMemberService';
import { ErrorUtil } from '../../utils/MessageUtil';

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    border: '1px solid rgba(255, 255, 255, 0.1)',
    paddingBlock: theme.spacing(6),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    borderRadius: 20,
    width: '400px',
  },
  forgetPwd: {
    marginTop: theme.spacing(2),
    fontWeight: 700,
    textAlign: 'center',
  },
  inputColor: {
    backgroundColor: theme.palette.primary.main,
  },

  textField: {
    marginTop: 6,
    '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '50%',
    padding: theme.spacing(0.8),
    paddingBottom: 0,
    paddingLeft: theme.spacing(1.1),
    paddingRight: theme.spacing(1.1),
  },
  activeEmail: {
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '6px',
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    paddingBlock: theme.spacing(2),
    marginTop: 6,
  },
}));

const AddDetailsComponent: FunctionComponent<{ member: IMemberDetails }> = ({ member }) => {
  const classes = useStyles();
  const common = defaultStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [workRole, setWorkRole] = useState('developer');
  const [message, setMessage] = useState('' as string);

  const { member_email, member_name, member_img = '' } = member;

  const editMemberData = async () => {
    if (!name) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    }
    const results = await editMemberDetails({
      member_job_type: workRole,
      member_name: name,
      member_img,
    });
    if (results && results.data) {
      window.location.href = '/';
    } else {
      setMessage('Failed');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  useEffect(() => {
    if (member_name) {
      setName(member_name);
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box className={classes.borderBox}>
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <Typography variant={'h4'}>
            <span role="img" aria-label="welcom">
              🎉
            </span>{' '}
            Welcome
          </Typography>
        </div>
        <Box style={{ marginTop: '1.5rem' }}>
          <Typography>Your full name *</Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter name"
            autoComplete="off"
            value={name}
            onChange={e => setName(e.target.value)}
            className={classes.textField}
          />
        </Box>

        <Box style={{ marginTop: '1.5rem' }}>
          <Typography>Email ID</Typography>
          <Box className={classes.activeEmail}>
            <Typography>{member_email}</Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: '1.5rem' }}>
          <Typography>Your role</Typography>
          <TextField
            variant="outlined"
            select
            fullWidth
            value={workRole}
            onChange={e => setWorkRole(e.target.value)}
            className={classes.textField}
          >
            {memberJobRole.map(({ value, label }, idx) => {
              return (
                <MenuItem key={idx} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        {message !== '' && <ErrorUtil message={message} />}
        <Button
          variant="contained"
          className={common.button}
          type="submit"
          style={{ marginTop: '2.5rem' }}
          onClick={editMemberData}
        >
          Get started!
        </Button>
      </Box>
    </div>
  );
};

export default AddDetailsComponent;
