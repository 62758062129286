import React from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { capitalize } from 'lodash';
import { strongEmotionObj } from '../../static/insightData';

export const insightStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(5.5),
      paddingLeft: theme.spacing(5),
      paddingTop: 0,
    },
    createButton: {
      marginLeft: 'auto',
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(3),
    },
    stickyLoading: {
      color: theme.palette.common.white,
    },
    startTrigger: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(10),
    },

    triggerImg: {
      '& $svg path': { fill: theme.palette.text.primary },
    },

    disableTriggerImg: {
      '& $svg path': { fill: theme.palette.text.secondary },
    },
    triggerTable: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      paddingTop: theme.spacing(2),
    },
    tableViewButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      padding: '0.56rem 1rem 0.5rem 0.9rem',
      borderRadius: '0.3rem',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    propertiesContainer: {
      maxHeight: '120px',
      overflowY: 'scroll',
    },
    propertiesItem: {
      color: '#7266A9',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    seeMoreText: {
      color: '#7266A9',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    propertiesDataType: {
      fontSize: 12,
    },
    insightsPaper: {
      border: `1px solid #D6DEF2`,
      borderRadius: theme.spacing(1.3),
      cursor: 'pointer',
      padding: '1rem',
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.contrastText,
    },
    filterButton: {
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      fontSize: '0.9rem',
      textTransform: 'none',
      padding: '0.3rem 0.75rem 0.3rem 0.75rem',
      fontWeight: 700,
      borderRadius: '5px',
      marginLeft: '9px',
      marginTop: '-5px',
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    deleteImg: {
      paddingLeft: theme.spacing(4),
      height: '36px',
      cursor: 'pointer',
    },
    border: {
      paddingTop: theme.spacing(5),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    greenBox: {
      background: '#3ABD8E1A',
      display: 'flex',
      alignItems: 'center',
      color: '#3ABD8E',
      padding: theme.spacing(2),
      paddingBlock: theme.spacing(1.2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
    },
    textFieldBox: {
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(2.5),
      paddingBlock: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      overflowY: 'scroll',
      whiteSpace: 'nowrap',
    },
    copyBox: {
      cursor: 'pointer',
      background: 'rgba(34, 238, 226, 0.05)',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      borderRadius: 5,
      padding: theme.spacing(1.5),
      paddingBlock: theme.spacing(0.6),
      textTransform: 'none',
    },
    downloadText: {
      color: theme.palette.primary.contrastText,
      fontSize: '0.85rem',
      marginLeft: 5,
      marginTop: 3,
    },
    copiedIcon: {
      color: theme.palette.primary.contrastText,
    },
    connectionTextField: {
      '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
      },
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      width: '60%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': { padding: '0.65rem 0.87rem' },
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      '&$checked': {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },
    negativePill: {
      background: '#e1193107',
      color: '#e11931',
      border: '1px solid #e1193124',
      width: 'fit-content',
      borderRadius: 3,
    },
    successPill: {
      background: '#177c4507',
      color: '#177c45',
      border: '1px solid #177c4524',
      width: 'fit-content',
      borderRadius: 3,
    },
    primaryPill: {
      background: '#42308b07',
      color: '#42308b',
      border: '1px solid #42308b24',
      width: 'fit-content',
      borderRadius: 3,
    },
    takeawayItem: {
      border: '1px solid #DFD0F1',
      borderRadius: 6,
      paddingTop: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    sourceItemBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.7),
      cursor: 'pointer',
      paddingBlock: theme.spacing(0.75),
      paddingInline: theme.spacing(1.5),
      boxShadow: 'none',
    },
    generateButton: {
      backgroundColor: theme.palette.info.contrastText,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: theme.palette.info.contrastText,
      },
    },
    newCsvContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(10),
    },
    newCsvBox: {
      textAlign: 'center',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(2),
    },
    uploadCsvButton: {
      marginTop: theme.spacing(0.5),
      paddingBlock: theme.spacing(1.7),
      paddingInline: theme.spacing(5.5),
    },
    previewTable: { maxWidth: `calc(92.4vw - 220px)`, marginTop: theme.spacing(4) },
  }),
);

export function ColoredPill(props: { sentiment: string; add_strong: boolean }) {
  const classes = insightStyles();
  const sentiment = props.sentiment ? props.sentiment : '';

  let styleType = 'primaryPill';
  let formattedText = capitalize(sentiment);

  if (sentiment.toLowerCase() === 'positive') {
    styleType = 'successPill';
    formattedText = '😃 Positive';
  }

  if (sentiment.toLowerCase() === 'negative') {
    styleType = 'negativePill';
    formattedText = '😞 Negative';
  }

  if (sentiment.toLowerCase() === 'neutral') {
    formattedText = '😐 Neutral';
  }

  if (!sentiment) {
    return <div></div>;
  }

  if (props.add_strong) {
    formattedText = `Strong ${sentiment}`;
  }

  return (
    <Box className={classes[styleType]} p={0.25} px={0.75} ml={0.5} mb={0.5}>
      <Typography variant={'subtitle2'} noWrap>
        {formattedText}
      </Typography>
    </Box>
  );
}
