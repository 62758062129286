import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Theme,
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Select,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import surveyStyles from '../common/SurveyStyles';
import accountImg from '../../images/settings/workspace-img.png';

import MemberListModal from './modal/MemberListModal';

import { IWorkspaceList } from '../../types/workspace';
import { IWorkspaceMember } from '../../types/member';

import { fetchProjectList, fetchWorkspaceMembersById } from '../../services/workspaceMember';
import { joinWorkspaceAdmin, leaveWorkspaceAdmin } from '../../services/adminService';

import AlertUtil from '../../utils/AlertUtil';
import { StyledTableRow } from '../common/tableStyles';
import { envStatus } from '../../static/colorData';
import { retrieveWorkspaceDetails } from '../../slices/workspaceSlice';
import { RootState, useAppDispatch } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },

    envText: {
      borderRadius: '2px',
      paddingBlock: 0.2,
      paddingLeft: 6,
      paddingRight: 6,
      width: 'fit-content',
      textTransform: 'capitalize',
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-input': {
        padding: '0.5rem 0.9rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },
  }),
);

const ProjectComponent = (props: { openToggle: boolean }) => {
  const classes = useStyles();
  const common = surveyStyles();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [openMemberListModal, setOpenMemberListModal] = useState(false);

  const active_project = localStorage.getItem('active_workspace');

  const [projects, setProjects] = useState<Array<IWorkspaceList>>();
  const [workspaceMembers, setWorkspaceMembers] = useState<Array<IWorkspaceMember>>();

  const user = useSelector((state: RootState) => state.login);

  const { openToggle } = props;

  const WorkspaceMembersById = async (id: string) => {
    const members = await fetchWorkspaceMembersById(id);
    if (members) {
      setWorkspaceMembers(members);
    }
  };

  const fetchProjects = async () => {
    const project = await fetchProjectList();
    if (project) {
      setProjects(project);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, [openToggle]);

  const editProjectRole = async (workspace_id: string, admin_belongs: boolean) => {
    let results;
    if (!admin_belongs) {
      results = await joinWorkspaceAdmin(workspace_id);
    } else {
      results = await leaveWorkspaceAdmin(workspace_id);
    }

    if (results && results.data) {
      fetchProjects();
      dispatch(retrieveWorkspaceDetails());

      if (admin_belongs) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Project Left successfully',
        });
      } else {
        AlertUtil.fire({
          icon: 'success',
          title: 'Project Joined successfully',
        });
      }
    }
  };

  const handleMemberListModal = async (id: string) => {
    await WorkspaceMembersById(id);
    setOpenMemberListModal(true);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        {openMemberListModal && (
          <MemberListModal
            toggleModal={setOpenMemberListModal}
            open={openMemberListModal}
            workspaceMembers={workspaceMembers ? workspaceMembers : []}
          />
        )}
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects &&
                  projects.map(
                    ({ workspace_id, workspace_name, workspace_logo, environment_type, count, admin_belongs }, idx) => (
                      <StyledTableRow key={idx}>
                        <TableCell width={'20%'}>
                          <Box display="flex" alignItems="center">
                            <img
                              src={workspace_logo ? workspace_logo : accountImg}
                              alt=""
                              style={{ width: 31, height: 30, borderRadius: '50%' }}
                            />
                            <Typography variant={'subtitle1'} pl={1.25} width={'30ch'} noWrap>
                              {workspace_name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell width={'15%'}>
                          <Typography
                            variant={'subtitle2'}
                            className={classes.envText}
                            style={
                              envStatus[environment_type] && {
                                backgroundColor: envStatus[environment_type].backgroundColor,
                                color: envStatus[environment_type].color,
                              }
                            }
                          >
                            {environment_type}
                          </Typography>
                        </TableCell>
                        <TableCell width={'49%'}>
                          <Typography
                            variant={'subtitle1'}
                            color={'primary.contrastText'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleMemberListModal(workspace_id)}
                          >
                            {count ? <u>{count} members</u> : '-'}
                          </Typography>
                        </TableCell>

                        <TableCell width={'16%'}>
                          {user?.member_role === 'admin' && (
                            <>
                              {active_project === null || active_project !== workspace_id ? (
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  fullWidth
                                  className={classes.selectField}
                                  renderValue={() => {
                                    return <Typography variant={'subtitle1'}>Actions</Typography>;
                                  }}
                                >
                                  <MenuItem onClick={() => editProjectRole(workspace_id, admin_belongs)}>
                                    <Typography>{!admin_belongs ? 'Join project' : 'Leave project'}</Typography>
                                  </MenuItem>
                                </Select>
                              ) : (
                                <Typography variant={'subtitle1'} textAlign="center" style={{ marginTop: 10 }}>
                                  You are in here
                                </Typography>
                              )}
                            </>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    );
  }
};

export default ProjectComponent;
