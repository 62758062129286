import React from 'react';
import { ReactComponent as BoardIcon } from '../images/navIcons/dashboard-icon.svg';
import { ReactComponent as SurveyIcon } from '../images/navIcons/survey.svg';
import { ReactComponent as TemplateIcon } from '../images/navIcons/template.svg';
import { ReactComponent as TriggerIcon } from '../images/navIcons/trigger.svg';
import { ReactComponent as SettingsIcon } from '../images/navIcons/settings.svg';
import { ReactComponent as AudienceIcon } from '../images/navIcons/audience.svg';
import { ReactComponent as IntegrationIcon } from '../images/navIcons/integration.svg';
import { ReactComponent as SpotlightIcon } from '../images/navIcons/spotlight.svg';

export const navItems = [
  {
    name: 'Research',
    pathname: '/',
    icon: <SurveyIcon />,
    heading: 'CREATE',
  },
  {
    name: 'Templates',
    pathname: '/templates',
    icon: <TemplateIcon />,
  },
  {
    name: 'Prioritise',
    pathname: '/prioritise',
    icon: <BoardIcon />,
    heading: 'DISCOVER',
    line: true,
  },
  {
    name: 'Search',
    pathname: '/respository',
    icon: <SpotlightIcon />,
  },
  {
    name: 'Spotlight',
    pathname: '/spotlight/new',
    icon: <SpotlightIcon />,
  },
  {
    name: 'Dashboards',
    pathname: '/boards',
    icon: <BoardIcon />,
  },
  {
    name: 'Users & Cohorts',
    pathname: '/cohorts',
    icon: <AudienceIcon />,
    heading: 'CONFIGURE',
    line: true,
  },
  {
    name: 'Events',
    pathname: '/trigger',
    icon: <TriggerIcon />,
  },

  {
    name: 'Connections',
    pathname: '/connections',
    icon: <IntegrationIcon />,
  },
  {
    name: 'Settings',
    pathname: '/settings',
    icon: <SettingsIcon />,
  },
];
