import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  List,
  ListItemIcon,
  ListItemButton,
  Checkbox,
  Button,
  InputAdornment,
} from '@mui/material';
import { useStyles } from './Spotlight.style';
import { AttributeComponent } from './AttributeComponent';
import { comparatorRules, questionTypeConfig } from './constants';
import { ISpotlightQuery, ISpotlightStore } from './spotlight';
import { getQuestionTypeLabel, getStoreData, getStoreLabel, handleScaleValue } from './HelperUtil';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { questionTypes } from '../../static/questionRule';

import crossImg from '../../images/editor/cross-icon.svg';
import addImg from '../../images/spotlight/add-icon.svg';
import { ReactComponent as SearchIcon } from '../../images/audience/search-icon.svg';
import { ICondition } from '../../types/audience';
import { surveyStatusFeature } from '../../static/staticData';
import { modifiedDate } from '../../utils/DateUtil';

type QueryProps = {
  spotlightQuery: ISpotlightQuery;
  spotlightStore: ISpotlightStore;
  fetchSpotlight: (request_type: string, question_type: string) => void;
  handleQueryUpdates: (
    query_key: string,
    id: string,
    secondary_label?: string,
    action_type?: string,
    search_str?: string,
  ) => void;
  handleAttributesQuery: (conditions: Array<ICondition>) => void;
  isNewChart: boolean;
  chartId: string;
};

export const QueryComponent: FunctionComponent<QueryProps> = ({
  spotlightQuery,
  spotlightStore,
  fetchSpotlight,
  handleQueryUpdates,
  handleAttributesQuery,
  isNewChart,
  chartId,
}) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [activeName, setActiveName] = useState('');

  //open chip option based on question_type
  const [openQueryBlock, setQueryBlock] = useState<Array<string>>([]);
  const { question_type, scale, attributes, triggers } = spotlightQuery;

  /**
   * handle dropdown options
   */
  const handleOpen = (name: string, question_type: string) => {
    setActiveName(name);
    fetchSpotlight(name, question_type);
  };

  const handleClose = (e: any, default_close: boolean) => {
    if (!default_close) {
      if (e && e.target && e.target.className) {
        if (!e.target.className.includes('MuiBackdrop-root')) {
          return;
        }
      }
    }

    setActiveName('');
    setSearchString('');
  };

  //returns query array from query Request
  const getQueryData = (query_key: string) => {
    const required_key = query_key === 'theme' ? 'options' : `${query_key}s`;
    return spotlightQuery[required_key] && spotlightQuery[required_key].data ? spotlightQuery[required_key].data : [];
  };

  let displayChips: Array<string> =
    questionTypeConfig[question_type] && questionTypeConfig[question_type].chips
      ? questionTypeConfig[question_type].chips
      : [];

  const handleChipQuery = () => {
    const { options, scale, categorys, sentiments, suggestions } = spotlightQuery;
    if (
      (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') &&
      options.data.length > 0
    ) {
      setQueryBlock([...openQueryBlock, 'option']);
    } else if (
      (question_type === 'scale_feedback' ||
        question_type === 'nps_feedback' ||
        question_type === 'star_feedback' ||
        question_type === 'emoji_feedback') &&
      scale.data > -1
    ) {
      setQueryBlock([...openQueryBlock, 'condition']);
    } else if (question_type === 'input_feedback') {
      if (categorys.data.length > 0) {
        setQueryBlock([...openQueryBlock, 'category']);
      }
      if (sentiments.data.length > 0) {
        setQueryBlock([...openQueryBlock, 'sentiment']);
      }
      if (suggestions.data.length > 0) {
        setQueryBlock([...openQueryBlock, 'suggestion']);
      }
      if (options.data.length > 0) {
        setQueryBlock([...openQueryBlock, 'theme']);
      }
    }
  };

  if (openQueryBlock && openQueryBlock.length > 0) {
    displayChips = displayChips.filter(c => !openQueryBlock.includes(c));
  }

  useEffect(() => {
    setQueryBlock([]);
  }, [question_type]);

  useEffect(() => {
    if (!isNewChart) {
      handleChipQuery();
    }
  }, []);

  // reset conditions
  const handleQueryReset = (name: string) => {
    if (name === 'condition') {
      handleQueryUpdates('scale', '-1', 'data');
      handleQueryUpdates('scale', 'eq', 'rule_type');
    } else {
      handleQueryUpdates(name, '', '', 'clear_all', '');
    }

    const modifiedOpenChips = openQueryBlock.filter(o => o !== name);
    setQueryBlock([...modifiedOpenChips]);
  };

  const formatSelectListValue = (name: string, allSelectedOptions: any) => {
    const newText: Array<string> = [];
    const MAX_CHARS = 60;
    for (let i = 0; i < allSelectedOptions.length; i++) {
      const label = getStoreLabel(name, allSelectedOptions[i], spotlightStore);
      if (label) {
        newText.push(label);
      }
      if (newText.toString().length > MAX_CHARS) {
        break;
      }
    }
    return newText.toString().substring(0, MAX_CHARS);
  };

  //List View Item for select options
  const ListItem = (
    allSelectedOptions: Array<string>,
    name: string,

    item: {
      label: string;
      value: string;
      platform?: string;
      status?: string;
      created_at?: string;
      question_order?: number;
      survey_name?: string;
    },
    idx: number,
  ) => {
    const { label, value, platform, status, created_at, question_order, survey_name } = item;
    return (
      <ListItemButton key={idx} className={classes.multiListItem} onClick={() => handleQueryUpdates(name, value)} dense>
        <ListItemIcon>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
            classes={{
              root: classes.check,
              checked: classes.checked,
            }}
            checked={allSelectedOptions.includes(value)}
          />
        </ListItemIcon>
        {name === 'survey' && platform && status && created_at ? (
          <>
            <Box display="flex" alignItems={'center'}>
              <img
                src={platform ? `https://cdn.blitzllama.com/survey/platform/${platform}-sq.svg` : ''}
                alt=""
                style={{ marginLeft: 'auto', height: '26px' }}
              />
              <Box ml={1}>
                <Typography variant="subtitle1" lineHeight={1.05}>
                  {label}
                </Typography>

                <div style={{ marginTop: '-2px' }}>
                  <Typography
                    display="inline"
                    color={surveyStatusFeature[status].color}
                    className={classes.listSubtitle}
                  >
                    {status}{' '}
                  </Typography>
                  <Typography display="inline" color="text.secondary" className={classes.listSubtitle}>
                    | {modifiedDate(created_at, 'DD MMM YY')}
                  </Typography>
                </div>
              </Box>
            </Box>
            <Box marginLeft={'auto'}>{status && surveyStatusFeature[status]?.icon}</Box>
          </>
        ) : name === 'question' && question_order && survey_name ? (
          <Box>
            <div>
              <Typography display="inline" color={'text.secondary'} className={classes.listSubtitle}>
                Q{question_order}{' '}
              </Typography>
              <Typography display="inline" color="text.secondary" className={classes.listSubtitle}>
                | {survey_name}
              </Typography>
            </div>
            <Typography variant="subtitle1" mt={-0.5} lineHeight={1.2} mb={0.8}>
              {label}
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle1">{label}</Typography>
        )}
      </ListItemButton>
    );
  };

  const selectListOptions = (name: string) => {
    const allSelectedOptions = getQueryData(name);
    let allOptions = getStoreData(name, spotlightStore);

    //filter based on search str
    if (searchString.length > 0) {
      allOptions = allOptions.filter(o => o.label && o.label.toLowerCase().includes(searchString.toLowerCase()));
    }

    return (
      <Select
        variant="outlined"
        className={classes.activeSelectField}
        style={{ maxWidth: '70%', position: 'relative' }}
        renderValue={() =>
          allSelectedOptions && allSelectedOptions.length > 0 ? (
            <Typography variant={'subtitle1'} width={'30ch'} noWrap>
              {formatSelectListValue(name, allSelectedOptions)}
            </Typography>
          ) : (
            <Typography variant="subtitle1" color={'primary.contrastText'}>
              {name === 'category' ? 'All categories' : `All ${name === 'survey' ? `active` : ''} ${name}s`}
            </Typography>
          )
        }
        value={allSelectedOptions.length > 0 ? allSelectedOptions : `All ${name}s`}
        name={name}
        open={activeName === name}
        onOpen={() => handleOpen(name, question_type)}
        onClose={e => handleClose(e, false)}
      >
        <TextField
          fullWidth
          placeholder="Search a value..."
          className={classes.searchTextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => {
            setSearchString(e.target.value);
          }}
        />

        <Box style={{ maxHeight: 450, overflowY: 'auto' }}>
          {allSelectedOptions.length > 0 && (
            <>
              <Box display={'flex'} justifyContent="space-between" mt={2}>
                <Typography fontSize={13} ml={2}>
                  Selected
                </Typography>
                <Typography
                  color={'text.secondary'}
                  fontSize={13}
                  mr={2}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleQueryUpdates(name, '', '', 'clear_all', searchString)}
                >
                  Clear All
                </Typography>
              </Box>
              <List className={classes.searchList}>
                {allOptions.map(
                  (item, idx) =>
                    item &&
                    item.label &&
                    allSelectedOptions.includes(item.value) &&
                    ListItem(allSelectedOptions, name, item, idx),
                )}
              </List>
              <hr className={classes.selectHrLine} />
            </>
          )}
          <Box display={'flex'} alignItems={'center'} justifyContent="space-between" mt={2} mb={1}>
            <Typography fontSize={13} ml={2}>
              {name === 'category' ? 'All categories' : `All ${name}s`}
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={700}
              className={classes.selectAllBox}
              onClick={() => handleQueryUpdates(name, '', '', 'select_all', searchString)}
            >
              Select All
            </Typography>
          </Box>
          <List className={classes.searchList} style={{ paddingBottom: 50 }}>
            {allOptions.map((item, idx) => item && item.label && ListItem(allSelectedOptions, name, item, idx))}
          </List>
        </Box>

        <Box className={classes.applySelectBox}>
          {/* <Box display="flex" alignItems="center" ml={2}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
              classes={{
                root: classes.check,
                checked: classes.checked,
              }}
              checked={true}
            />
            <Typography variant={'subtitle1'} color={'primary.contrastText'}>
              Include archived surveys
            </Typography>
          </Box> */}
          <Button variant="contained" className={classes.applyButton} onClick={e => handleClose(e, true)}>
            <Typography fontSize={13} fontWeight={700} textAlign="right">
              Apply
            </Typography>
          </Button>
        </Box>
      </Select>
    );
  };

  const selectComparatorOptions = () => {
    return (
      <Box display={'flex'} alignItems="center">
        <TextField
          select
          variant="outlined"
          className={classes.activeSelectField}
          value={scale.rule_type}
          onChange={e => handleQueryUpdates('scale', e.target.value, 'rule_type')}
        >
          {comparatorRules.map(({ label, value }, idx) => (
            <MenuItem key={idx} value={value}>
              <Typography variant="subtitle1">{label}</Typography>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type="number"
          variant="outlined"
          placeholder="Enter a value"
          value={handleScaleValue(scale.data)}
          onChange={e => handleQueryUpdates('scale', e.target.value, 'data')}
          className={classes.textField}
          style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
        />
      </Box>
    );
  };

  const addNewQuery = () => {
    return displayChips.map((item, idx) => (
      <Box key={idx} className={classes.addNewBox} onClick={() => setQueryBlock([...openQueryBlock, item])}>
        <img src={addImg} alt="" style={{ marginTop: 4 }} />
        <Typography variant="subtitle1" color="primary.contrastText" fontWeight={700} ml={0.6}>
          {item}
        </Typography>
      </Box>
    ));
  };

  return (
    <Box display="flex" className={classes.spotlightBox}>
      <Box width={'45%'}>
        <Box className={classes.spotlightHeading}>
          <Typography variant="subtitle2" color="text.secondary">
            Put a spotlight on...
          </Typography>
        </Box>
        <Box className={classes.leftBox}>
          <Box display="flex" alignItems={'center'} justifyContent={'space-between'} className={classes.hoverContent}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" mr={1} fontWeight={700}>
                responses from
              </Typography>
              <Select
                displayEmpty
                variant="outlined"
                className={question_type ? classes.activeSelectField : classes.selectField}
                style={{ width: 'fit-content', maxWidth: '60%' }}
                renderValue={() =>
                  question_type ? (
                    <Typography>{getQuestionTypeLabel(question_type)}</Typography>
                  ) : (
                    <Typography variant="subtitle1" className={classes.selectPlaceholder}>
                      Select question type
                    </Typography>
                  )
                }
                value={question_type}
              >
                {questionTypes.map(({ value, label }, idx) => (
                  <MenuItem key={idx} value={value} onClick={e => handleQueryUpdates('question_type', value)}>
                    <Typography variant="subtitle1">{label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          {question_type && (
            <div>
              <Box
                display="flex"
                alignItems={'center'}
                justifyContent={'space-between'}
                mt={0.5}
                className={classes.hoverContent}
                maxWidth={'100%'}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" mx={1}>
                    ...inside survey(s) =
                  </Typography>
                  {selectListOptions('survey')}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems={'center'}
                justifyContent={'space-between'}
                mt={0.5}
                className={classes.hoverContent}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" mx={1}>
                    ...where question(s) =
                  </Typography>
                  {selectListOptions('question')}
                </Box>
              </Box>
            </div>
          )}

          <div>
            {openQueryBlock.map(
              (item, idx) =>
                item !== 'trigger' && (
                  <Box
                    key={idx}
                    display="flex"
                    alignItems={'center'}
                    justifyContent="space-between"
                    mt={1.3}
                    className={classes.hoverContent}
                  >
                    <Box display="flex" alignItems={'center'}>
                      <Typography variant="subtitle1" mx={1}>
                        and where {item}(s)
                      </Typography>
                      {item === 'condition' ? selectComparatorOptions() : selectListOptions(item)}
                    </Box>
                    <img src={crossImg} alt="" style={{ cursor: 'pointer' }} onClick={() => handleQueryReset(item)} />
                  </Box>
                ),
            )}

            <Box display="flex" alignItems="center" mt={2.5} className={classes.newQueryBox}>
              {addNewQuery()}
            </Box>
          </div>
        </Box>
      </Box>
      <Box className={classes.rightBorder}></Box>
      <Box width={'55%'}>
        <Box className={classes.spotlightHeading}>
          <Typography variant="subtitle2" color="text.secondary">
            Filter by...
          </Typography>
        </Box>
        <Box className={classes.rightBox}>
          <Box display="flex" alignItems="center" className={classes.hoverContent}>
            <Typography variant="subtitle1" mr={1} fontWeight={700}>
              Answered by users in
            </Typography>
            {selectListOptions('cohort')}
          </Box>

          <AttributeComponent
            queryAttributes={attributes}
            triggers={triggers}
            selectListOptions={selectListOptions}
            handleAttributesQuery={handleAttributesQuery}
            isNewChart={isNewChart}
            chartId={chartId}
            handleQueryUpdates={handleQueryUpdates}
          />
        </Box>
      </Box>
    </Box>
  );
};
