import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { dark } from './styles/muiTheme';

import { RouterConfig } from './navigation/RouterConfig';
import { useAppDispatch } from './store';
import { retrieveUserDetails } from './slices/loginSlice';
import { retrieveTrackingDetails } from './slices/trackingSlice';

import blitz from 'blitzllama-js';

// Handle console logs
import './utils/dropConsole';
import './scss/home.scss';

const BLITZ_APP_KEY = 'key_BmzPIHoEXQl5ZER';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    //ensure memberId is tracked
    blitz.init(BLITZ_APP_KEY);

    const auth_token = localStorage.getItem('auth_token');
    if (auth_token) {
      dispatch(retrieveUserDetails());
      dispatch(retrieveTrackingDetails());
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dark()}>
        <BrowserRouter>
          <RouterConfig />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
