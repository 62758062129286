import React, { useState, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { Grid, Theme, TextField, Box, Button, Dialog, Typography, Checkbox, MenuItem, Select } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import copy from 'copy-to-clipboard';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import deleteImg from '../../images/delete-icon.svg';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

import { triggerStyles } from '../common/TriggerStyle';
import { ErrorUtil } from '../../utils/MessageUtil';
import AlertUtil from '../../utils/AlertUtil';
import { createTriggerType, editTriggerType } from '../../services/triggerService';
import { ITriggerType } from '../../types/trigger';
import { isValidRegex, isValidSelector } from '../../utils/stringUtil';

import { ReactComponent as AndroidImg } from '../../images/editor/android-icon.svg';
import { ReactComponent as IosImg } from '../../images/editor/ios-icon.svg';
import { ReactComponent as WebImg } from '../../images/editor/web-icon.svg';
import { ReactComponent as HybridImg } from '../../images/editor/hybrid-icon.svg';
import { ReactComponent as FlutterImg } from '../../images/editor/flutter-icon.svg';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const triggerEnabledPlatform = [
  {
    value: 'android',
    icon: <AndroidImg />,
    label: 'Android',
  },
  {
    value: 'ios',
    icon: <IosImg />,
    label: 'iOS',
  },
  {
    value: 'web',
    icon: <WebImg />,
    label: 'Web',
  },
  {
    value: 'hybrid',
    icon: <HybridImg />,
    label: 'React Native',
  },
  {
    value: 'flutter',
    icon: <FlutterImg style={{ width: '12px', height: '12px' }} />,
    label: 'Flutter',
  },
];

const platformCodeSnippet = {
  android: `BlitzLlamaSDK.getSdkManager(this).triggerEvent("trigger_name")`,
  ios: `BlitzLlamaSDKController.getSDKManager.fetchSurvey(viewController: self, triggerName: trigger_name)`,
  web: `blitz('triggerEvent', 'trigger_name')`,
  hybrid: `<Blitzllama showSurvey={true} trigger="trigger_name"/>`,
  flutter: `BlitzllamaFlutter.triggerEvent("trigger_name")`,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '2.3rem 2rem 2.9rem 3.9rem',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
      justifyContent: 'space-between',
    },

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    inputLabel: {
      fontSize: '0.7rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    snippetBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: 0,
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      padding: theme.spacing(2),
      textAlign: 'left',
    },

    triggerTypeBoxLeft: {
      borderTopLeftRadius: '0.3rem',
      borderBottomLeftRadius: '0.3rem',
      paddingBlock: '0.6rem',
      cursor: 'pointer',
    },
    triggerTypeBoxRight: {
      borderTopRightRadius: '0.3rem',
      borderBottomRightRadius: '0.3rem',
      paddingBlock: '0.6rem',
      cursor: 'pointer',
    },
    triggerText: { fontWeight: 700, paddingLeft: '0.76rem', lineHeight: '18.4px' },

    triggerTab: {
      '& $svg rect': { fill: theme.palette.text.primary },
      '& $svg path': { fill: theme.palette.text.primary },
      color: theme.palette.text.primary,
    },
    activeTriggerTab: {
      '& $svg rect': { fill: theme.palette.primary.contrastText },
      '& $svg path': { fill: theme.palette.primary.contrastText },
      color: theme.palette.primary.contrastText,
    },
    selectorBox: {
      padding: theme.spacing(2.3),
      paddingBlock: theme.spacing(1.6),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.3rem',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    check: {
      margin: 0,
      padding: 0,
      paddingTop: 2,
      color: theme.palette.divider,
      '&$checked': {
        paddingTop: 2,

        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    triggerButton: {
      float: 'right',
      marginTop: theme.spacing(3.5),
    },
    copyBox: {
      display: 'flex',
      alignItems: 'flex-end',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      border: `1px solid ${theme.palette.divider}`,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    triggerNameBox: {
      backgroundColor: theme.palette.primary.main,
      padding: '0.75rem 0.87rem',
      borderRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
    },
    patternSelect: {
      border: `1px solid ${theme.palette.divider}`,
      fontWeight: 700,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    textField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.75rem 0.87rem',
      },
    },
    platformTab: {
      '& $svg rect': { fill: theme.palette.text.primary },
      '& $svg path': { fill: theme.palette.text.primary },
    },
    activeTab: {
      '& $svg rect': { fill: theme.palette.primary.contrastText },
      '& $svg path': { fill: theme.palette.primary.contrastText },
      color: theme.palette.primary.contrastText,
    },
    platform: {
      backgroundColor: theme.palette.secondary.light,
      border: `1.5px solid ${theme.palette.divider}`,
      borderRadius: 5,
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(3),
    },
    activePlatform: {
      background: `${theme.palette.primary.contrastText}0D`,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      borderRadius: 5,
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(1.5),
    },
  }),
);

const urlPatternTypes = [
  { label: 'Contains', value: 'matches' },
  { label: 'Is exactly', value: 'exactly' },
  { label: 'Not exactly', value: 'not_exactly' },
  { label: 'Starts with', value: 'starts' },
  { label: 'Ends with', value: 'ends' },
  { label: 'Does not contains', value: 'not_matches' },
  { label: 'Matches regex', value: 'regex' },
];

interface TriggerProps {
  trigger: ITriggerType;
  toggleModal: (boolean) => void;
  open: boolean;
  edit: boolean;
  setActiveTrigger: (any) => void;
  handleTriggerFetch: () => void;
}

const TriggerModal: FunctionComponent<TriggerProps> = ({
  trigger,
  toggleModal,
  open,
  edit,
  setActiveTrigger,
  handleTriggerFetch,
}) => {
  const classes = useStyles();
  const common = triggerStyles();

  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [message, setMessage] = useState('' as string);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  // view ->page url open, click-> css selector
  // web-> NocodeTrigger, android-> androidTrigger

  const {
    trigger_name,
    trigger_meta,
    active_count,
    trigger_web,
    trigger_type = 'android',
    is_nocode = false,
    trigger_activity_name = '',
  } = trigger;

  const changeHandler = (fieldName: string, fieldValue: any) => {
    if (fieldName === 'trigger_type' && fieldValue === 'hybrid') {
      trigger['is_nocode'] = false;
    }
    setActiveTrigger({ ...trigger, [fieldName]: fieldValue });
  };

  const changeTriggerWebHandler = (fieldName: string, fieldValue: any) => {
    if (trigger_web) {
      trigger_web[fieldName] = fieldValue;
      setActiveTrigger({ ...trigger, trigger_web });
    }
  };

  function copyText(str: string) {
    copy(str);
    setCopyButtonText('Copied!');
  }

  const handleClick = async (trigger_type: string) => {
    if (!trigger_name) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (!validator.isLength(trigger_name, { min: 1, max: 50 })) {
      setMessage('Trigger name length should be in 1 to 50');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (!validator.isLength(trigger_meta, { min: 0, max: 255 })) {
      setMessage('Trigger meta length should be in 1 to 255');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (trigger_type === 'android' && is_nocode && trigger_activity_name.length <= 0) {
      setMessage('Trigger Activity Name cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (trigger_type === 'web' && is_nocode) {
      if (!trigger_web?.url_pattern) {
        setMessage('Url cannot be empty');
        setTimeout(() => setMessage(''), 3000);
        return;
      } else if (trigger_web?.url_pattern && trigger_web?.pattern_type) {
        if (trigger_web?.pattern_type === 'regex' && !isValidRegex(trigger_web?.url_pattern)) {
          setMessage('Invalid regex');
          setTimeout(() => setMessage(''), 3000);
          return;
        }
        if (trigger_web?.action === 'click') {
          if (trigger_web?.css_selector) {
            if (!isValidSelector(trigger_web?.css_selector)) {
              setMessage('Invalid CSS selector');
              setTimeout(() => setMessage(''), 3000);
              return;
            }
          } else {
            setMessage('CSS selector cannot be empty');
            setTimeout(() => setMessage(''), 3000);
            return;
          }
        }
      }
    }

    if (edit && trigger.trigger_id) {
      const saveTrigger = await editTriggerType(trigger, trigger.trigger_id);
      if (saveTrigger && saveTrigger.data) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Edited successfully',
        });
      }
    } else {
      const newTrigger = await createTriggerType(trigger);

      if (newTrigger && newTrigger.data) {
        AlertUtil.fire({
          icon: 'success',
          title: 'created successfully',
        });
        if (tracking_info) {
          amplitudeEvent(tracking_info, 'trigger created', { platform: trigger_type, 'trigger name': trigger_name });
        }
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: 'Trigger creation failed',
        });
      }
    }
    handleTriggerFetch();
    toggleModal(false);
    setActiveTrigger(undefined);
  };

  const handleToggle = () => {
    setActiveTrigger(undefined);
    toggleModal(false);
  };

  const codeSnippet = platformCodeSnippet[trigger_type]
    ? platformCodeSnippet[trigger_type].replace('trigger_name', trigger_name)
    : '';

  return (
    <div>
      <Dialog onClose={handleToggle} open={open} fullWidth maxWidth={'md'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Box display="flex" alignItems={'center'} justifyContent="space-between" mb={3.2}>
              {edit ? (
                <div>
                  <Typography variant="h4">Edit event</Typography>
                  {active_count > 0 && (
                    <Typography variant={'subtitle2'} color={'warning.dark'} pt={1}>
                      Currently used in {active_count} active survey(s)
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography variant="h4">Create new event</Typography>
              )}
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={() => handleToggle()} />
            </Box>
            <Typography className={classes.inputLabel}>Choose platform</Typography>
            <Box display="flex" mt={1} mb={1.5}>
              {triggerEnabledPlatform.map(
                ({ value, icon, label }, idx) =>
                  (!edit || trigger_type === value) && (
                    <Box
                      mr={1.5}
                      key={idx}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      className={trigger_type === value ? classes.activePlatform : classes.platform}
                      onClick={() => changeHandler('trigger_type', value)}
                    >
                      <div
                        className={trigger_type === value ? classes.activeTab : classes.platformTab}
                        style={{ paddingTop: 6 }}
                      >
                        {icon}
                      </div>
                      <Typography
                        variant={'subtitle1'}
                        fontWeight={700}
                        pl={0.6}
                        color={trigger_type === value ? 'primary.contrastText' : 'GrayText.secondary'}
                      >
                        {label}
                      </Typography>
                    </Box>
                  ),
              )}
            </Box>
            <div style={{ paddingRight: '5.6rem' }}>
              {trigger_type === 'web' && (
                <>
                  {!edit ? (
                    <Box display="flex" alignItems="center" mt={2.2}>
                      <Box className={classes.selectorBox} onClick={e => changeHandler('is_nocode', false)}>
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          classes={{
                            root: classes.check,
                            checked: classes.checked,
                          }}
                          checked={!is_nocode}
                        />
                        <Typography variant={'subtitle1'} fontWeight={700} pl={1}>
                          Code Trigger
                        </Typography>
                      </Box>

                      <Box className={classes.selectorBox} ml={2} onClick={e => changeHandler('is_nocode', true)}>
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          classes={{
                            root: classes.check,
                            checked: classes.checked,
                          }}
                          checked={is_nocode}
                        />
                        <Typography variant={'subtitle1'} fontWeight={700} pl={1}>
                          No Code Trigger
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box className={classes.selectorBox} maxWidth="fit-content">
                      <Typography variant={'subtitle1'} fontWeight={700}>
                        {is_nocode ? 'No Code Trigger' : 'Code Trigger'}
                      </Typography>
                    </Box>
                  )}
                </>
              )}

              <Box display="flex" alignItems="center">
                <Box width={1 / 2}>
                  <Typography className={classes.inputLabel}>*Event name</Typography>
                  {!edit ? (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type event name here..."
                        autoComplete="off"
                        value={trigger_name}
                        className={classes.textField}
                        onChange={e => changeHandler('trigger_name', e.target.value)}
                      />
                      <Typography variant="subtitle2" color="text.secondary">
                        Enter only alphanumeric characters
                      </Typography>
                    </>
                  ) : (
                    <Box className={classes.triggerNameBox}>
                      <Typography>{trigger_name}</Typography>
                    </Box>
                  )}
                </Box>
                <Box width={1 / 2} style={{ paddingLeft: '1rem', marginTop: '-18px' }}>
                  <Typography className={classes.inputLabel}>Description</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type a description..."
                    autoComplete="off"
                    value={trigger_meta}
                    className={classes.textField}
                    onChange={e => changeHandler('trigger_meta', e.target.value)}
                  />
                </Box>
              </Box>

              {trigger_type === 'web' && is_nocode ? (
                <>
                  <Typography className={classes.inputLabel}>Type</Typography>
                  <Box display="flex" alignItems="center">
                    <Box className={classes.selectorBox} onClick={e => changeTriggerWebHandler('action', 'view')}>
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        classes={{
                          root: classes.check,
                          checked: classes.checked,
                        }}
                        checked={trigger_web?.action === 'view'}
                      />
                      <Typography variant={'subtitle1'} fontWeight={700} pl={1}>
                        Page URL open
                      </Typography>
                    </Box>
                    <Box
                      className={classes.selectorBox}
                      ml={2}
                      onClick={e => changeTriggerWebHandler('action', 'click')}
                    >
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        classes={{
                          root: classes.check,
                          checked: classes.checked,
                        }}
                        checked={trigger_web?.action === 'click'}
                      />
                      <Typography variant={'subtitle1'} fontWeight={700} pl={1}>
                        CSS selector
                      </Typography>
                    </Box>
                  </Box>

                  <>
                    <Typography className={classes.inputLabel}>*URL</Typography>
                    <Box display="flex" alignItems="center">
                      <Select
                        variant="outlined"
                        placeholder="Type event name here..."
                        displayEmpty
                        value={trigger_web?.pattern_type}
                        className={classes.patternSelect}
                        onChange={e => changeTriggerWebHandler('pattern_type', e.target.value)}
                      >
                        {urlPatternTypes.map(({ label, value }, key) => (
                          <MenuItem key={key} value={value}>
                            <Typography>{label}</Typography>
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="e.g.: /*/, https://blitz..."
                        autoComplete="off"
                        value={trigger_web?.url_pattern}
                        className={classes.textField}
                        style={{ marginLeft: '0.25rem' }}
                        onChange={e => changeTriggerWebHandler('url_pattern', e.target.value)}
                      />
                    </Box>
                  </>

                  {trigger_web?.action === 'click' && (
                    <>
                      <Typography className={classes.inputLabel}>CSS Selector to target</Typography>
                      <TextField
                        variant="outlined"
                        placeholder="e.g.: .foo.bar,a[href....]"
                        autoComplete="off"
                        value={trigger_web?.css_selector}
                        fullWidth
                        className={classes.textField}
                        onChange={e => changeTriggerWebHandler('css_selector', e.target.value)}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {!is_nocode && (
                    <>
                      <Typography className={classes.inputLabel}>
                        Insert this code snippet in your code base.
                      </Typography>
                      <div>
                        <Box className={classes.snippetBox}>
                          <Typography>{codeSnippet}</Typography>
                        </Box>
                        <Box className={classes.copyBox}>
                          <Button
                            style={{ marginLeft: 'auto' }}
                            className={copyButtonText === 'Copy' ? common.copyButton : common.copiedButton}
                            onClick={() => copyText(codeSnippet)}
                          >
                            <FileCopyIcon />
                            {copyButtonText}
                          </Button>
                        </Box>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <Button variant="contained" className={classes.triggerButton} onClick={() => handleClick(trigger_type)}>
              {edit ? '+ Save event' : '+ Create event'}
            </Button>
            {message !== '' && <ErrorUtil message={message} />}{' '}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default TriggerModal;
