import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography, Button, Dialog, TextField } from '@mui/material';
import deleteImg from '../../images/delete-icon.svg';
import { sourceInsightImgs, sourceRepoConfigs } from '../../static/connectionData';
import { insightStyles } from './InsightStyle';

export const AddSourceModal: FunctionComponent<{
  open: boolean;
  toggleModal: (value: boolean) => void;
}> = ({ open, toggleModal }) => {
  const classes = insightStyles();
  const history = useHistory();
  return (
    <div>
      <Dialog open={open} fullWidth maxWidth={'sm'}>
        <Grid container p={4}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight={'bold'}>Add New Source</Typography>
          </Box>
          <img
            src={deleteImg}
            alt=""
            style={{ marginLeft: 'auto', height: 32, cursor: 'pointer' }}
            onClick={() => {
              toggleModal(false);
            }}
          />

          <Grid item lg={12}>
            <Typography fontWeight={'bold'} variant={'subtitle1'} mb={1.5}>
              Integrations
            </Typography>
            <Box display={'flex'}>
              {sourceRepoConfigs['Integrations'].map((item, idx) => (
                <Box textAlign={'center'} className={classes.sourceItemBox} width={'120px'} key={idx}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img src={sourceInsightImgs[item.source]} height={14} />
                    <Typography variant={'subtitle1'} ml={0.5}>
                      {item.title}
                    </Typography>
                  </Box>
                  {!item.is_live && (
                    <Typography variant={'subtitle2'} color="text.secondary" mt={-0.5}>
                      Coming soon
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>

            <Typography fontWeight={'bold'} variant={'subtitle1'} mb={1.5} mt={2}>
              Documents
            </Typography>
            <Box display={'flex'}>
              {sourceRepoConfigs['Documents'].map((item, idx) => (
                <Box
                  textAlign={'center'}
                  className={classes.sourceItemBox}
                  width={'120px'}
                  key={idx}
                  onClick={() => {
                    history.push(item.url);
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img src={sourceInsightImgs[item.source]} height={14} />
                    <Typography variant={'subtitle1'} ml={0.5}>
                      {item.title}
                    </Typography>
                  </Box>
                  {!item.is_live && (
                    <Typography variant={'subtitle2'} color="text.secondary" mt={-0.5}>
                      Coming soon
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
