import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Checkbox,
  Tooltip,
  InputAdornment,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { getAttributeLabel, getAttributeValue, getImageId } from '../../utils/stringUtil';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ReactComponent as SearchIcon } from '../../images/audience/search-icon.svg';

import UserDrawerComponent from './UserDrawerComponent';
import { filterCohortByName } from '../../services/audienceService';

import _ from 'lodash';
import { StyledTableRow } from '../common/tableStyles';
import { useAttributeState } from '../hooks/useAttribute';

import deleteImg from '../../images/delete-icon.svg';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { getOperatorLabel, getOperatorsForDatatype } from '../../utils/AttributeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      color: theme.palette.primary.contrastText,
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: '0.5rem ',
        paddingBottom: '0.7rem',
      },
    },
    autoTextField: {
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.default,
        paddingTop: '0.25rem ',
        paddingBottom: '0.25rem',
        paddingInline: '0.87rem',
        minHeight: '42px',
      },
      '& .MuiAutocomplete-tag': {
        border: `1px solid ${theme.palette.divider}`,
        marginBlock: 0,
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '1rem',
      },
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      marginRight: theme.spacing(1),
      '&$checked': {
        color: theme.palette.primary.contrastText,
        marginRight: 8,
      },
    },
    checked: {},
    customTextField: {
      '& .MuiOutlinedInput-input': { paddingTop: '0.5rem ', paddingBottom: '0.7rem', paddingInline: '0.87rem' },
    },
    avatar: {
      backgroundColor: '#FF979D',
      borderRadius: '50%',
      width: theme.spacing(3.1),
      height: theme.spacing(3.1),
    },
    response: {
      borderRadius: theme.spacing(2.3),
      backgroundColor: theme.palette.secondary.dark,
      padding: 18,
      paddingBlock: 8,
      marginRight: 10,
    },
    selectDataField: {
      marginLeft: 5,
      borderRadius: 4,
      width: '88px',
      '& .MuiSelect-outlined': {
        backgroundColor: 'transparent',
        padding: '3px 10px',
        fontSize: '0.87rem',
        fontWeight: 700,
      },
      '& .MuiSelect-icon': {
        color: theme.palette.text.primary,
        marginTop: 3,
      },
    },
    table: {
      maxWidth: `calc(92.4vw - 220px)`,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      paddingTop: theme.spacing(2),
    },
    userBox: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    userFinderBox: {
      border: `1px solid #E0E1EB`,

      backgroundColor: '#F6F6FA',
      borderRadius: '4px',
      marginTop: theme.spacing(2),
    },
    searchBox: {
      borderBottom: `1px solid #E0E1EB`,
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(1.2),
      paddingRight: theme.spacing(2),
    },
    attributeBox: {
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(1.2),
    },
    searchTextField: {
      width: '40%',
      border: `1px solid #E0E1EB`,
      borderRadius: '5px',
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-input': {
        paddingRight: '0.2rem',
        paddingTop: '0.5rem ',
        paddingBottom: '0.7rem',
      },
      '& .MuiInputBase-root': {
        paddingLeft: '0.5rem',
        paddingRight: '0.3rem',
      },
    },
    searchButton: {
      paddingBlock: theme.spacing(0.4),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    searchDeleteIcon: {
      height: '22px',
      width: '22px',
      cursor: 'pointer',
    },
    paginationBox: {
      border: `1px solid #EFEFFE`,
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(2.5),
    },
    prevText: {
      paddingBlock: theme.spacing(0.6),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1.5),
      borderRight: `1px solid #EFEFFE`,
      cursor: 'pointer',
    },
    pageCountText: {
      backgroundColor: '#F6F6FA',
      paddingBlock: theme.spacing(0.6),
      paddingInline: theme.spacing(2),
    },
    nextText: {
      paddingBlock: theme.spacing(0.6),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      borderLeft: `1px solid #EFEFFE`,
      cursor: 'pointer',
    },
  }),
);

const anchor = 'right';

export default function UsersTabComponent() {
  const classes = useStyles();

  const [showUserDrawer, toggleUserDrawer] = React.useState(false);
  const [userSearchValue, setUserSearchValue] = useState('');

  const [userId, setUserId] = useState('');

  const mandatory_attributes = ['user_id', 'created_at', 'total_days_on_app'];

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const {
    conditions,
    attributeList,
    attributeValues,
    handleAttributeSelect,
    handleCohortConditions,
    getValuesForAttribute,
    setAttributeValues,
    handleAttributeOperator,
    handleAttributeValue,
    handleSearchByUserId,
    preview,
    totalCount,
    headers,
    isLoading,
    pageCount,
    handlePageOffset,
  } = useAttributeState({
    new_cohort: true,
    initial_state: [
      {
        attribute: '',
        comparator: '',
        value: '',
        data_type: '',
        list: [],
      },
    ],
  });

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    toggleUserDrawer(!showUserDrawer);
  };

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'cohorts tab viewed', { tab: 'users' });
    }
  }, [tracking_info]);

  const handleSearchTerm = async (attribute: string, txt: string) => {
    if (txt.length > 2) {
      const attribute_names = await filterCohortByName('in-survey', attribute, txt, 0);
      if (attribute_names) {
        const uniq_attributes = _.union([...attributeValues[attribute], ...attribute_names]);
        attributeValues[attribute] = uniq_attributes;
        setAttributeValues({ ...attributeValues });
      }
    }
  };

  const handleDeleteSearchValue = () => {
    setUserSearchValue('');
    handleCohortConditions(conditions);
  };

  // handle the drawer opening and setting the user details
  const handleUserDrawer = async (e: any, user_id: string, open: boolean) => {
    if (open) {
      setUserId(user_id);
      toggleDrawer(e);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        {userId && showUserDrawer && (
          <UserDrawerComponent
            toggleDrawer={toggleDrawer}
            anchor={anchor}
            showUserDrawer={showUserDrawer}
            userId={userId}
          />
        )}
        <Box className={classes.userFinderBox}>
          <div className={classes.searchBox}>
            <TextField
              placeholder="Search user id..."
              className={classes.searchTextField}
              value={userSearchValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={deleteImg}
                      alt=""
                      className={classes.searchDeleteIcon}
                      onClick={() => {
                        handleDeleteSearchValue();
                      }}
                    />
                    <Button
                      variant="contained"
                      className={classes.searchButton}
                      onClick={() => handleSearchByUserId(userSearchValue)}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
              }}
              onChange={e => {
                setUserSearchValue(e.target.value);
              }}
            />
          </div>
          <Box display="flex" alignItems="center" className={classes.attributeBox}>
            <Typography variant="subtitle1" width={'5%'} noWrap>
              Filter by
            </Typography>
            {conditions.map(({ attribute, comparator, value, data_type, list }, idx) => (
              <>
                <Select
                  style={{ width: '20%', marginLeft: 8 }}
                  variant="outlined"
                  displayEmpty
                  className={classes.selectField}
                  renderValue={() => <div>{attribute ? getAttributeLabel(attribute) : 'Select Attribute'}</div>}
                  value={attribute}
                  onChange={e => handleAttributeSelect(e.target.value as string, idx)}
                >
                  <MenuItem disabled>
                    <Typography variant={'subtitle2'}>Default Attributes</Typography>
                  </MenuItem>
                  <MenuItem value={'user_id'}>
                    <Typography style={{ marginLeft: 10 }}>User Id</Typography>
                  </MenuItem>
                  <MenuItem value={'created_at'}>
                    <Typography style={{ marginLeft: 10 }}>Created at</Typography>
                  </MenuItem>
                  <MenuItem value={'total_days_on_app'}>
                    <Typography style={{ marginLeft: 10 }}>Days since creation</Typography>
                  </MenuItem>
                  <MenuItem disabled>
                    <Typography variant={'subtitle2'}>User Attributes</Typography>
                  </MenuItem>
                  {attributeList.map(({ name }, key) => (
                    <MenuItem key={key} value={name}>
                      <Typography style={{ marginLeft: 10 }}>{name}</Typography>
                    </MenuItem>
                  ))}
                </Select>

                {attribute && (
                  <>
                    <Select
                      style={{ width: '20%', marginLeft: 8 }}
                      variant="outlined"
                      displayEmpty
                      className={classes.selectField}
                      renderValue={() => (
                        <div>{comparator ? getOperatorLabel(comparator, data_type) : 'Select operator'}</div>
                      )}
                      value={comparator}
                      onChange={e => handleAttributeOperator(e.target.value as string, idx)}
                    >
                      <MenuItem disabled>
                        <Typography variant={'subtitle2'}>Comparison Operator</Typography>
                      </MenuItem>
                      {getOperatorsForDatatype(data_type).map(({ label, value }, i) => (
                        <MenuItem value={value}>{label}</MenuItem>
                      ))}
                    </Select>

                    {comparator && data_type === 'string' && (
                      <Autocomplete
                        id="tags-outlined"
                        multiple
                        options={getValuesForAttribute(attribute, data_type, idx)}
                        disableCloseOnSelect
                        freeSolo
                        className={classes.autoTextField}
                        style={{ width: '24%', marginLeft: 8 }}
                        onChange={(event: any, newValue) => {
                          handleAttributeValue(newValue, data_type, idx);
                        }}
                        value={list}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                              checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                              classes={{
                                root: classes.check,
                                checked: classes.checked,
                              }}
                              checked={selected}
                            />

                            {option}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Enter attribute values"
                            onChange={e => handleSearchTerm(attribute, e.target.value)}
                          />
                        )}
                        limitTags={1}
                      />
                    )}
                    {comparator && data_type === 'boolean' && (
                      <Autocomplete
                        options={getValuesForAttribute(attribute, data_type, idx)}
                        renderOption={(props, option) => <li {...props}>{option}</li>}
                        value={value}
                        freeSolo
                        style={{ width: '24%', marginLeft: 8 }}
                        onChange={(event: any, newValue) => {
                          handleAttributeValue(newValue, data_type, idx);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select attribute value"
                            onChange={e => handleSearchTerm(attribute, e.target.value)}
                          />
                        )}
                      />
                    )}

                    {comparator && data_type === 'date' && (
                      <TextField
                        style={{ width: '24%', marginLeft: 8, height: 43, paddingTop: 0 }}
                        variant="outlined"
                        type="date"
                        className={classes.customTextField}
                        value={conditions[+idx] && conditions[+idx].value ? conditions[+idx].value : ''}
                        placeholder="Select date value"
                        autoComplete="off"
                        onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                      />
                    )}

                    {comparator && data_type === 'number' && (
                      <TextField
                        style={{ width: '24%', marginLeft: 8, height: 43, paddingTop: 0 }}
                        variant="outlined"
                        type="number"
                        className={classes.customTextField}
                        value={conditions[+idx] && conditions[+idx].value ? conditions[+idx].value : ''}
                        placeholder="Enter number value"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                      />
                    )}
                  </>
                )}

                <Box borderBottom={1} borderColor={'divider'}></Box>
              </>
            ))}
          </Box>
        </Box>
        <>
          <Box pb={3} pt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>User Records</Typography>

            <div className={classes.response}>
              <Typography variant="h6" display="inline">
                {totalCount} Users
              </Typography>
            </div>
          </Box>
          {headers && headers.length > 0 ? (
            <div>
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers.map((item, key) => (
                        <TableCell key={key}>
                          <Box display="flex" alignItems="center">
                            <Tooltip title={getAttributeLabel(item.name)} placement="top">
                              <Typography variant={'h6'} noWrap>
                                {getAttributeLabel(item.name)}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preview.map((item, idx) => (
                      <>
                        <StyledTableRow key={idx}>
                          {item &&
                            Object.keys(item).map((val, i) => (
                              <TableCell scope="row">
                                {headers[i] &&
                                headers[i].name &&
                                (mandatory_attributes.includes(headers[i].name) ||
                                  item[headers[i].name] ||
                                  typeof item[headers[i].name] === 'boolean') ? (
                                  i === 0 ? (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      className={classes.userBox}
                                      onClick={e => handleUserDrawer(e, item[headers[i].name], true)}
                                    >
                                      <img
                                        src={`https://cdn.blitzllama.com/default/avatars/avatar-${getImageId(
                                          item[headers[i].name].toString(),
                                          6,
                                        )}.png`}
                                        alt=""
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          marginTop: 6,
                                          marginLeft: 7,
                                        }}
                                      />

                                      <Typography style={{ paddingLeft: 10, width: 'fit-content' }} noWrap>
                                        {item[headers[i].name].toString()}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <Typography style={{ paddingLeft: 10 }} noWrap>
                                      {getAttributeValue(headers[i].name, item[headers[i].name])}
                                    </Typography>
                                  )
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            ))}
                        </StyledTableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} className={classes.paginationBox}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.prevText}
                    onClick={() => handlePageOffset('decr')}
                  >
                    Prev
                  </Typography>
                  <Typography variant="subtitle1" className={classes.pageCountText}>
                    {pageCount + 1}
                  </Typography>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.nextText}
                    onClick={() => handlePageOffset('inc')}
                  >
                    Next
                  </Typography>
                </Box>
                <Typography style={{ marginTop: 15 }}>{preview && `Showing ${preview.length} results`}</Typography>
              </Box>
            </div>
          ) : (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography variant={'subtitle1'} color={'text.secondary'}>
                No records to show
              </Typography>
            </Box>
          )}
        </>
      </div>
    );
  }
}
