import React, { FunctionComponent, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from '../Spotlight.style';
import { getSentimentTheme } from '../../../static/colorData';
import { sentimentIcon, actionIcon, actionTheme } from '../../../static/staticData';
import { getModifiedText } from '../../../utils/questionUtil';

import rightArrowButton from '../../../images/surveyPerformance/arrow-button.svg';
import { IInputResponseForModal, IInputResponseType, IInputStatType } from '../spotlight';
import { InputResponseModal } from '../modal/InputResponseModal';
import UserDrawerComponent from '../../audience/UserDrawerComponent';

interface IChartType {
  stats: Array<IInputStatType>;
}

export const InputChartComponent: FunctionComponent<IChartType> = ({ stats }) => {
  const classes = useStyles();
  const [openResponseToggle, toggleResponsetModal] = useState(false);
  const [showUserDrawer, toggleUserDrawer] = React.useState(false);
  const [userId, setUserId] = useState('');

  //   initial state of inputResponseData
  const [inputResponseData, setInputResponseData] = useState<IInputResponseForModal>({
    input_responses: [],
    theme_name: '',
    theme_responses_count: 0,
  });

  const activateUserDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    toggleUserDrawer(!showUserDrawer);
  };

  const handleUserDrawer = async (e: any, user_id: string, open: boolean) => {
    if (open) {
      setUserId(user_id);
      activateUserDrawer(e);
    }
  };

  /**
   * update inputResponeData value to send in input response modal
   * @param input_responses
   * @param theme_name
   * @param theme_responses_count
   */
  const handleResponseModal = (
    input_responses: Array<IInputResponseType>,
    theme_name: string,
    theme_responses_count: number,
  ) => {
    setInputResponseData({ input_responses, theme_name, theme_responses_count });
    toggleResponsetModal(true);
  };

  return (
    <div>
      {openResponseToggle && (
        <InputResponseModal
          inputResponseData={inputResponseData}
          toggleModal={toggleResponsetModal}
          open={openResponseToggle}
          handleUserDrawer={handleUserDrawer}
        />
      )}

      {userId && showUserDrawer && (
        <UserDrawerComponent
          toggleDrawer={activateUserDrawer}
          anchor={'right'}
          showUserDrawer={showUserDrawer}
          userId={userId}
        />
      )}
      <TableContainer className={classes.table}>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  TOPICS
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  RESPONSES
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  SENTIMENT
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  RECOMMENDATION
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map(({ theme_name, theme_responses_count, sentiment, action, input_responses }, idx) => (
              <TableRow
                key={idx}
                style={{ cursor: 'pointer' }}
                hover
                onClick={e => {
                  e.stopPropagation();
                  handleResponseModal(input_responses, theme_name, theme_responses_count);
                }}
              >
                <TableCell width="40%">
                  <Typography variant={'subtitle1'} style={{ wordBreak: 'break-all' }}>
                    {theme_name}
                  </Typography>
                </TableCell>
                <TableCell width="17%">{theme_responses_count}</TableCell>
                <TableCell width="17%">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.sentimentBox}
                    style={getSentimentTheme(sentiment)}
                  >
                    {sentimentIcon[sentiment]}
                    <Typography variant={'subtitle2'} pl={0.5}>
                      {sentiment ? getModifiedText(sentiment) : ''}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell width="18%">
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.actionBox}
                    style={{ color: actionTheme[action] }}
                  >
                    {actionIcon[action]}
                    <Typography variant={'subtitle2'} pl={0.5} noWrap>
                      {action ? getModifiedText(action) : ''}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell width="8%" style={{ textAlign: 'right' }}>
                  <Box
                    ml={0.5}
                    onClick={e => {
                      e.stopPropagation();
                      handleResponseModal(input_responses, theme_name, theme_responses_count);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <img src={rightArrowButton} alt="" />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
