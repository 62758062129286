import { IDateRange, ISpotlightQuery, ISpotlightStore } from './spotlight';
import { modifiedDate } from '../../utils/DateUtil';
import { spotlightInitialData } from './constants';
import { questionTypes } from '../../static/questionRule';
import { ICondition } from '../../types/audience';

//return value for id
export const getStoreLabel = (store_key: string, id: string, spotlightStore: ISpotlightStore) => {
  const {
    survey_store,
    question_store,
    theme_store,
    category_store,
    sentiment_store,
    suggestion_store,
    option_store,
    trigger_store,
    cohort_store,
  } = spotlightStore;

  //survey_store
  if (store_key === 'survey') {
    const required_key = survey_store.find(s => s.survey_id === id);
    return required_key ? required_key.survey_name : id;
  }

  // question store
  else if (store_key === 'question') {
    const required_key = question_store.find(q => q.question_id === id);
    return required_key ? required_key.question_text : id;
  }

  // theme store
  else if (store_key === 'theme') {
    const required_key = theme_store.find(t => t && t.theme_id.toString() === id);
    return required_key ? required_key.theme_name : id;
  }

  // category store
  else if (store_key === 'category') {
    const required_key = category_store.find(c => c.value === id);
    return required_key ? required_key.label : id;
  }

  // sentiment store
  else if (store_key === 'sentiment') {
    const required_key = sentiment_store.find(s => s.value === id);
    return required_key ? required_key.label : id;
  }

  // suggestion store
  else if (store_key === 'suggestion') {
    const required_key = suggestion_store.find(s => s.value === id);
    return required_key ? required_key.label : id;
  }

  // option store
  else if (store_key === 'option') {
    const required_key = option_store.find(o => o.option_id === id);
    return required_key ? required_key.label : id;
  }

  // trigger store
  else if (store_key === 'trigger') {
    const required_key = trigger_store.find(t => t.trigger_name === id);
    return required_key ? required_key.trigger_name : id;
  }

  // trigger store
  else if (store_key === 'cohort') {
    const required_key = cohort_store.find(t => t.cohort_id === id);
    return required_key ? required_key.cohort_name : id;
  }
};

//return store Array
export const getStoreData = (
  store_key: string,
  spotlightStore: ISpotlightStore,
): Array<{ label: string; value: string; platform?: string; status?: string; created_at?: string }> => {
  const required_key = `${store_key}_store`;

  const results: Array<{
    label: string;
    value: string;
    platform?: string;
    status?: string;
    created_at?: string;
    question_order?: number;
    survey_name?: string;
  }> = [];

  // survey_store array
  if (required_key === 'survey_store') {
    const survey_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (survey_data.length > 0) {
      for (const i in survey_data) {
        const { survey_id, survey_name, platform, status, created_at } = survey_data[i];
        results.push({
          label: survey_name,
          value: survey_id,
          platform,
          status,
          created_at,
        });
      }
    }
  }

  // question_store array
  else if (required_key === 'question_store') {
    const question_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (question_data.length > 0) {
      for (const i in question_data) {
        const { question_order, question_id, question_text, survey_name } = question_data[i];
        results.push({ label: question_text, value: question_id, question_order, survey_name });
      }
    }
  }

  // theme_store array
  else if (required_key === 'theme_store') {
    const theme_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (theme_data.length > 0) {
      for (const i in theme_data) {
        const { theme_id, theme_name } = theme_data[i];
        results.push({ label: theme_name, value: theme_id.toString() });
      }
    }
  }

  // category_store array
  else if (required_key === 'category_store') {
    const category_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (category_data.length > 0) {
      for (const i in category_data) {
        const { label, value } = category_data[i];
        results.push({ label: label, value: value });
      }
    }
  }

  // sentiment_store array
  else if (required_key === 'sentiment_store') {
    const sentiment_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (sentiment_data.length > 0) {
      for (const i in sentiment_data) {
        const { label, value } = sentiment_data[i];
        results.push({ label: label, value: value });
      }
    }
  }

  // suggestion_store array
  else if (required_key === 'suggestion_store') {
    const suggestion_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (suggestion_data.length > 0) {
      for (const i in suggestion_data) {
        const { label, value } = suggestion_data[i];
        results.push({ label: label, value: value });
      }
    }
  }

  // option_store array
  else if (required_key === 'option_store') {
    const option_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (option_data.length > 0) {
      for (const i in option_data) {
        const { option_id, label } = option_data[i];
        results.push({ label: label, value: option_id });
      }
    }
  }

  // trigger_store array
  else if (required_key === 'trigger_store') {
    const trigger_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (trigger_data.length > 0) {
      for (const i in trigger_data) {
        const { trigger_name } = trigger_data[i];
        results.push({ label: trigger_name, value: trigger_name });
      }
    }
  }

  // cohort_store array
  else if (required_key === 'cohort_store') {
    const cohort_data = spotlightStore[required_key] ? spotlightStore[required_key] : [];
    if (cohort_data.length > 0) {
      for (const i in cohort_data) {
        const { cohort_name, cohort_id } = cohort_data[i];
        results.push({ label: cohort_name, value: cohort_id });
      }
    }
  }

  return results;
};

//return label for question type
export const getQuestionTypeLabel = (question_type: string) => {
  const questionItem = questionTypes.find(q => q.value === question_type);
  return questionItem ? questionItem.label : question_type;
};

export const getDashboardDate = (date_range: IDateRange) => {
  const { type, time_from, time_to, days_count } = date_range;
  if (type === 'custom') {
    const format_time_from = modifiedDate(time_from, 'DD MMM YYYY');
    const format_time_to = modifiedDate(time_to, 'DD MMM YYYY');
    return `${format_time_from} - ${format_time_to}`;
  } else if (type === 'days_since') {
    const format_time_from = modifiedDate(time_from, 'DD MMM YYYY');
    return `From ${format_time_from}`;
  }

  return `Last ${days_count} days`;
};

export const resetQueryProperties = (
  query_key: string,
  question_type: string,
  spotlightStore: ISpotlightStore,
  spotlightQuery: ISpotlightQuery,
  updated_arr: Array<string>,
) => {
  const resetProperties: any = {};
  if (['survey', 'question', 'category', 'sentiment', 'suggestion'].includes(query_key)) {
    const { questions, options } = spotlightQuery;
    const { theme_store, option_store, question_store } = spotlightStore;

    if (query_key === 'survey' && questions && questions.data.length > 0 && updated_arr.length > 0) {
      //filter required question objects (store)
      const required_question_data = question_store.filter(q => updated_arr.includes(q.survey_id));

      //create a array with just allowed store question ids
      const filtered_store_ids = required_question_data.map(r => r.question_id);

      //create a array with filtered ids based on question query data (query)
      const questions_ids = questions.data.filter(q => filtered_store_ids.includes(q));
      resetProperties.questions = {
        type: 'all',
        data: questions_ids,
        rule_type: 'in',
      };

      if (
        question_type === 'single_select_feedback' ||
        (question_type === 'multi_select_feedback' && options.data.length > 0 && questions_ids.length > 0)
      ) {
        //filter required question objects (store)
        const required_option_data = option_store.filter(q => questions_ids.includes(q.question_id));

        //create a array with just allowed store question ids
        const filtered_store_ids = required_option_data.map(r => r.option_id);

        //create a array with filtered ids based on question query data (query)
        const option_ids = options.data.filter(o => filtered_store_ids.includes(o));
        resetProperties.options = {
          type: 'all',
          data: option_ids,
          rule_type: 'in',
        };
      }
    }

    if (query_key === 'question' && options && questions.data.length > 0 && updated_arr.length > 0) {
      if (
        question_type === 'single_select_feedback' ||
        (question_type === 'multi_select_feedback' && options.data.length > 0 && updated_arr.length > 0)
      ) {
        //filter required question objects (store)
        const required_option_data = option_store.filter(q => updated_arr.includes(q.question_id));

        //create a array with just allowed store question ids
        const filtered_store_ids = required_option_data.map(r => r.option_id);

        //create a array with filtered ids based on question query data (query)
        const option_ids = options.data.filter(o => filtered_store_ids.includes(o));
        resetProperties.options = {
          type: 'all',
          data: option_ids,
          rule_type: 'in',
        };
      }
    }

    if (question_type === 'input_feedback') {
      if (
        (query_key === 'category' || query_key === 'sentiment' || query_key === 'suggestion') &&
        options &&
        options.data.length > 0 &&
        updated_arr.length > 0
      ) {
        //filter required question objects (store)
        const required_option_data = theme_store.filter(q => updated_arr.includes(q[query_key]));

        //create a array with just allowed store question ids
        const filtered_store_ids = required_option_data.map(r => r.theme_id);

        //create a array with filtered ids based on question query data (query)
        const option_ids = options.data.filter(o => filtered_store_ids.includes(+o));

        resetProperties.options = {
          type: 'all',
          data: option_ids,
          rule_type: 'in',
        };
      }
    }
  }

  return resetProperties;
};

export const handleScaleValue = (value: number) => {
  if (value === null || value === undefined || +value === -1) return '';
  return value;
};

//reset query values
export const resetQueryObj = (full_reset: boolean) => {
  const {
    questions,
    options,
    triggers,
    scale,
    categorys,
    sentiments,
    suggestions,
    surveys,
    date_range,
    cohorts,
  } = spotlightInitialData;

  questions.data = [];
  options.data = [];
  categorys.data = [];
  sentiments.data = [];
  suggestions.data = [];
  scale.data = -1;
  scale.rule_type = 'eq';

  const resetQueryKeys: any = {
    questions,
    options,
    triggers,
    scale,
    categorys,
    sentiments,
    suggestions,
    surveys,
  };

  //triggers, cohorts, attributes only reset on full reload (new chart/chart_id)
  if (full_reset) {
    date_range.type = 'days';
    date_range.days_count = 30;
    surveys.data = [];
    cohorts.data = [];
    triggers.data = [];
    resetQueryKeys.surveys = surveys;
    resetQueryKeys.cohorts = cohorts;
    resetQueryKeys.triggers = triggers;
    resetQueryKeys.date_range = date_range;
    resetQueryKeys.attributes = [];
  }

  return resetQueryKeys;
};

export const resetChangesArray = (
  query_key: string,
  spotlightStore: ISpotlightStore,
  action_type: string,
  data: Array<string>,
  id: string,
  search_str: string,
): Array<string> => {
  const store_data = getStoreData(query_key, spotlightStore);

  if (!action_type) {
    let results_arr = data;

    //if values exist remove otherwise add value
    if (data.includes(id)) {
      results_arr = data.filter(s => s !== id);
    } else {
      results_arr.push(id);
    }

    return results_arr;
  }

  //store_data copy
  let modified_store_data = store_data;

  if (action_type === 'select_all') {
    //remove values based search string

    if (search_str) {
      modified_store_data = store_data.filter(
        ({ label, value }) => label && (data.includes(value) || label.toLowerCase().includes(search_str)),
      );
    }

    const values_arr = modified_store_data.map(s => s.value);
    return values_arr;
  }

  if (action_type === 'clear_all') {
    //action clear_all reset values
    //if no search_str default [] return
    if (search_str) {
      modified_store_data = store_data.filter(
        ({ label, value }) => label && !label.toLowerCase().includes(search_str) && data.includes(value),
      );

      const values_arr = modified_store_data.map(s => s.value);
      return values_arr;
    }
  }

  return [];
};

export const validateAttributesQuery = (conditions: Array<ICondition>) => {
  if (conditions && conditions.length > 0) {
    for (let i = 0; i < conditions.length; i++) {
      const { attribute, comparator, data_type, list, value } = conditions[i];
      if (attribute.trim() === '') {
        return { success: false, message: 'Attribute cannot be empty' };
      }
      if (comparator.trim() === '') {
        return { success: false, message: 'Comparator cannot be empty' };
      }
      if (data_type === 'string' && list.length === 0) {
        return { success: false, message: 'Attribute values cannot be empty' };
      }
      if (data_type !== 'string' && (value === undefined || value === null || value === '')) {
        return { success: false, message: 'Attribute value cannot be empty' };
      }
    }
  }
  return { success: true, message: '' };
};
