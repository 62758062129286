import React from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { capitalizeFirstLetter } from '../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(4),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function StatusConfirmationDialog(props: {
  toggleModal: (boolean) => void;
  open: boolean;
  handleSurveyType: (type: string) => void;
  convertStatus: string;
  handleCloseDropDown: () => void;
  researchType: string;
}) {
  const classes = useStyles();

  const { toggleModal, open, handleSurveyType, convertStatus, handleCloseDropDown, researchType } = props;

  const handleCloseModal = () => {
    handleCloseDropDown();
    toggleModal(false);
  };

  const handleStatusChange = async () => {
    handleSurveyType(convertStatus);
    handleCloseModal();
  };

  const resurveyText = convertStatus.replace('Survey', capitalizeFirstLetter(researchType));

  return (
    <div>
      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Typography variant={'h4'} mb={2}>
              Are you sure you want to {resurveyText}?
            </Typography>
            <Box className={classes.buttonSection}>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>

              <Button variant="contained" style={{ marginLeft: 10 }} onClick={handleStatusChange}>
                {resurveyText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
