import React, { useState } from 'react';
import { Grid, Typography, Button, Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ProductDialog from '../survey/CreateProjectModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    startProject: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(15),
    },
  }),
);

export default function StartProjectComponent() {
  const classes = useStyles();

  const [openToggle, toggleModal] = useState(false);
  const [startProjectAnchor, setStartProjectAnchor] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setStartProjectAnchor(null);
  };

  return (
    <Grid container lg={12} className={classes.startProject}>
      {openToggle && <ProductDialog toggleModal={toggleModal} open={openToggle} handleClose={handleClose} />}

      <Box
        width="250px"
        p={5}
        textAlign="center"
        style={{ border: '1px solid rgba(255, 255, 255, 0.05)', borderRadius: 10 }}
      >
        <Typography variant={'h2'}>
          <span role="img" aria-label="emoji">
            ✨
          </span>
          You are not part of any project
        </Typography>

        <Typography variant={'body2'} style={{ marginTop: 15 }}>
          You can create your own project or ask admins and project editors to invite you to an existing one
        </Typography>
        <Button variant="contained" style={{ marginTop: 15 }} onClick={() => toggleModal(true)}>
          + Create a project
        </Button>
      </Box>
    </Grid>
  );
}
