import React from 'react';
import BillingPlanComponent from '../../components/billing/BillingPlanComponent';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      paddingBlock: '4.25rem',
      paddingLeft: '3.8%',
      paddingRight: '3.8%',
    },
  }),
);

const BillingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <BillingPlanComponent />
      </main>
    </div>
  );
};

export default BillingPage;
