import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, CircularProgress, Menu, MenuItem, TextField, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { QueryComponent } from './QueryComponent';
import { ChartComponent } from './ChartComponent';
import pinImg from '../../images/spotlight/pin-icon.svg';

import { spotlightFetchData, spotlightInitialData } from './constants';
import {
  fetchSpotlightDetails,
  fetchChartDetails,
  createChartDetails,
  fetchChartById,
  updateChartById,
  fetchAllBoard,
  addChartToBoard,
  fetchBoardListForId,
} from '../../services/spotlightService';
import { IBoard, IBoardForChart, ISpotlightQuery, ISpotlightStore } from './spotlight';
import { categoryQuery, sentimentQuery, suggestionQuery } from './constants';
import { useStyles } from './Spotlight.style';
import AlertUtil from '../../utils/AlertUtil';
import { useHistory, useParams } from 'react-router-dom';
import { PinChartModal } from './modal/CreateChartModal';
import { resetChangesArray, resetQueryObj, resetQueryProperties, validateAttributesQuery } from './HelperUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { ICondition } from '../../types/audience';

import bluePinIcon from '../../images/spotlight/blue-pin-icon.svg';
import { BoardListTooltip } from '../../styles/customStyles';
import { RootState } from '../../store';

const spotlightDefault = { ...spotlightInitialData };

interface Iparams {
  chart_id: string;
}

export default function BaseComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [spotlightQuery, setSpotlightQuery] = useState<ISpotlightQuery>(spotlightDefault);
  const [spotlightStore, setSpotlightStore] = useState<ISpotlightStore>(spotlightFetchData);
  const [spotlightChart, setSpotlightChart] = useState<{
    chart_type: string;
    chart_data: any;
  }>({
    chart_type: 'daily_chart',
    chart_data: {},
  });
  const [chartName, setChartName] = useState<string>('');
  const [isLoading, setLoading] = useState(true);
  const [openPinChartToggle, togglePinChartModal] = useState(false);

  const [boardList, setBoardList] = useState<Array<IBoard>>([]);

  // boards where the chart is already pinned
  const [activeBoards, setActiveBoards] = useState<Array<IBoardForChart>>([]);

  // for add to dashboard checklist
  const [boardIds, setBoardIds] = useState<Array<string>>([]);

  const [anchorE, setAnchorE] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorE(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorE(null);
  };

  const params: Iparams = useParams();
  const { chart_id } = params;
  const { question_type } = spotlightQuery;

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'spotlight tab viewed', {});
    }
  }, [tracking_info]);

  const fetchSpotlightChart = async (chart_type: string) => {
    let requested_chart_type = chart_type ? chart_type : spotlightChart.chart_type;

    //handle intro prompt exception
    if (question_type === 'intro_prompt') {
      requested_chart_type = 'daily_chart';
    }

    const chartData = await fetchChartDetails({
      chart_type: requested_chart_type,
      search_request: spotlightQuery,
    });
    if (chartData && chartData.data) {
      setSpotlightChart({
        chart_type: requested_chart_type,
        chart_data: chartData.data,
      });
    }
  };

  //spotlight store population
  const fetchSpotlight = async (request_type: string, question_type: string) => {
    // update spotlightStore for category request
    if (request_type === 'category') {
      setSpotlightStore({
        ...spotlightStore,
        [`${request_type}_store`]: categoryQuery,
      });
      return;
    }

    // update spotlightStore for sentiment request
    if (request_type === 'sentiment') {
      setSpotlightStore({
        ...spotlightStore,
        [`${request_type}_store`]: sentimentQuery,
      });
      return;
    }

    // update spotlightStore for suggestion request
    if (request_type === 'suggestion') {
      setSpotlightStore({
        ...spotlightStore,
        [`${request_type}_store`]: suggestionQuery,
      });
      return;
    }

    const spotlightResult = await fetchSpotlightDetails({
      request_type,
      search_request: { ...spotlightQuery, question_type },
    });

    if (spotlightResult && spotlightResult.data) {
      const modifiedSpotlightStore = spotlightStore;
      modifiedSpotlightStore[`${request_type}_store`] = spotlightResult.data;
      setSpotlightStore({ ...modifiedSpotlightStore });
    }
  };

  //reset query values
  const resetQueryValues = (full_reset = true) => {
    // for resetting the chart_type on every question_type change
    setSpotlightChart({ ...spotlightChart, chart_type: 'daily_chart' });
    return resetQueryObj(full_reset);
  };

  /**
   * handle query updates & update state
   * @param query_key
   * @param id
   * @param secondary_label used by scale value to update nested keys
   */
  const handleQueryUpdates = (
    query_key: string,
    id: string,
    secondary_label?: string,
    action_type = '',
    search_str = '',
  ) => {
    // for setting the question_type value in SpotlightQuery
    if (query_key === 'question_type') {
      const resetQueryKeys = resetQueryValues(false);
      setSpotlightQuery({
        ...spotlightQuery,
        ...resetQueryKeys,
        question_type: id,
      });
    }

    // for setting the condition for nps_feedback and scale_feedback
    else if (query_key === 'scale' && secondary_label) {
      const scale_query = spotlightQuery.scale;
      scale_query[secondary_label] = id;
      setSpotlightQuery({ ...spotlightQuery, scale: scale_query });
    }

    // for setting other values
    else {
      const required_key = query_key === 'theme' ? 'options' : `${query_key}s`;

      //fetch required query object
      const required_query = spotlightQuery[required_key];

      if (!required_query) {
        return;
      }

      const { data } = required_query;
      //fetch data from store

      required_query.data = resetChangesArray(
        query_key,
        spotlightStore,
        action_type,
        data,
        id,
        search_str.toLowerCase(),
      );

      const resetProperties: any = resetQueryProperties(
        query_key,
        question_type,
        spotlightStore,
        spotlightQuery,
        required_query.data,
      );

      setSpotlightQuery({
        ...spotlightQuery,
        [required_key]: required_query,
        ...resetProperties,
      });
    }
  };

  const addChartToBoards = async (board_ids: Array<string>, chart_id: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'chart pinned', {
        tab: 'spotlight',
        chart_type: spotlightChart.chart_type,
        'chart id': chart_id,
      });
    }
    //add chart to board_ids api
    const addResult = await addChartToBoard(
      {
        board_ids,
      },
      chart_id,
    );

    if (addResult && addResult.message) {
      AlertUtil.fire({
        icon: 'success',
        title: 'chart pinned successfully',
      });
    }
  };

  // for validate the attribute query
  const validateChart = () => {
    const { attributes } = spotlightQuery;
    const { success, message } = validateAttributesQuery(attributes);
    if (!success) {
      AlertUtil.fire({
        icon: 'error',
        title: message,
      });
    }
    return success;
  };

  const createNewChart = async (name: string, board_ids: Array<string>) => {
    if (!validateChart()) {
      return;
    }
    const results = await createChartDetails({
      search_request: spotlightQuery,
      name,
      chart_type: spotlightChart.chart_type,
    });

    if (results && results.chart_id) {
      await addChartToBoards(board_ids, results.chart_id);

      history.push(`/spotlight/${results.chart_id}`);
    }
  };

  const saveChartChanges = async () => {
    if (!validateChart()) {
      return;
    }
    if (chart_id === 'new') {
      return;
    }

    if (!chartName || chartName.trim().length === 0) {
      AlertUtil.fire({
        icon: 'error',
        title: 'Enter a valid chart name',
      });
      return;
    }

    const results = await updateChartById(
      {
        search_request: spotlightQuery,
        name: chartName,
        chart_type: spotlightChart.chart_type,
      },
      chart_id,
    );

    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'chart changes saved',
      });
    }
  };

  const fetchBoardsList = async () => {
    const boards = await fetchAllBoard();
    if (boards) {
      setBoardList(boards);
    }
  };

  const handlePinDashboard = () => {
    fetchBoardsList();
    togglePinChartModal(true);
  };

  const handleDateRangeQuery = (range_type: string, field_name: string, value: string | number) => {
    const date_query = spotlightQuery.date_range;
    date_query['type'] = range_type;
    date_query[field_name] = value;
    setSpotlightQuery({ ...spotlightQuery, date_range: date_query });
  };

  const handleAttributesQuery = (conditions: Array<ICondition>) => {
    setSpotlightQuery({ ...spotlightQuery, attributes: [...conditions] });
  };

  //update query & chart state to reflect chart_id values
  const handleInitialQueryState = async () => {
    await fetchSpotlight('survey', question_type);
    if (chart_id !== 'new') {
      const results = await fetchChartById(chart_id);

      if (results && results.question_type) {
        const {
          date_range,
          attributes,
          cohorts,
          name,
          options,
          question_type,
          questions,
          categorys,
          sentiments,
          suggestions,
          surveys,
          triggers,
          chart_type,
          scale,
        } = results;

        setSpotlightChart({ ...spotlightChart, chart_type });
        setChartName(name);

        setSpotlightQuery({
          ...spotlightQuery,
          date_range,
          attributes,
          cohorts,
          options,
          question_type,
          questions,
          categorys,
          sentiments,
          suggestions,
          surveys,
          triggers,
          scale,
        });

        // reset query properties in edit flow
        if (questions && questions.data.length > 0) {
          await fetchSpotlight('question', question_type);
        }

        // reset query properties in edit flow
        if (cohorts && cohorts.data.length > 0) {
          await fetchSpotlight('cohort', question_type);
        }

        if (
          (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') &&
          options.data.length > 0
        ) {
          await fetchSpotlight('option', question_type);
        }

        if (question_type === 'input_feedback' && options.data.length > 0) {
          await fetchSpotlight('theme', question_type);
        }
      } else {
        history.push('/spotlight/new');
      }
    }

    //reset values to avoid previous state values being assigned
    else {
      const resetQueryKeys = resetQueryValues(true);
      setSpotlightQuery({
        ...spotlightQuery,
        ...resetQueryKeys,
        question_type: '',
      });
    }

    setLoading(false);
  };

  const fetchBoardListForChart = async () => {
    const boardResult = await fetchBoardListForId(chart_id);
    if (boardResult) {
      setActiveBoards(boardResult);
    }
  };

  useEffect(() => {
    fetchBoardsList();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchBoardListForChart();
    handleInitialQueryState();
  }, [chart_id]);

  useEffect(() => {
    if (question_type) {
      fetchSpotlightChart('');
    }
  }, [spotlightQuery]);

  // handle selected dashboard ids to add chart
  const handleBoardValues = (board_id: string) => {
    if (boardIds.includes(board_id)) {
      const boardResult = boardIds.filter(o => o !== board_id);
      setBoardIds(boardResult);
    } else {
      setBoardIds([...boardIds, board_id]);
    }
  };

  const checkBoardAvailability = (board_id: string, allow_add: boolean) => {
    return boardIds.includes(board_id) || !allow_add || activeBoards.some(a => a.board_id === board_id);
  };

  const addToDashboard = async () => {
    if (boardIds.length > 0) {
      await addChartToBoards(boardIds, chart_id);
      fetchBoardListForChart();
    }
    handleClose();
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    const isNewChart = chart_id === 'new';
    return (
      <div>
        {openPinChartToggle && (
          <PinChartModal
            toggleModal={togglePinChartModal}
            open={openPinChartToggle}
            createNewChart={createNewChart}
            boards={boardList}
          />
        )}
        <div className={classes.spotlightUpperContainer}>
          <Box display={'flex'} justifyContent={'space-between'}>
            {isNewChart ? (
              <Typography variant="h2">Create New Spotlight</Typography>
            ) : (
              <Box width="75%">
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  autoComplete="off"
                  value={chartName}
                  name="chart_name"
                  className={classes.chartNameInput}
                  placeholder="Enter chart name"
                  onChange={e => setChartName(e.target.value)}
                />

                <BoardListTooltip
                  title={
                    <Box className={classes.tooltipBox}>
                      {activeBoards.map(({ name }, idx) => (
                        <Typography variant={'subtitle1'} key={idx}>
                          {name}
                        </Typography>
                      ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box display={'flex'} alignItems="center" maxWidth={'fit-content'} style={{ cursor: 'pointer' }}>
                    <img src={bluePinIcon} alt="" />
                    <Typography variant={'subtitle2'} color="primary.contrastText" pl={0.5}>
                      {activeBoards.length} Dashboards
                    </Typography>
                  </Box>
                </BoardListTooltip>
              </Box>
            )}

            <Box display="flex" alignItems="center">
              {isNewChart ? (
                <Button variant="contained" disabled={!question_type} onClick={() => handlePinDashboard()}>
                  <img src={pinImg} alt="" />
                  <Typography variant={'subtitle1'} pl={0.5} fontWeight={700}>
                    Save to dashboard
                  </Typography>
                </Button>
              ) : (
                <Box display="flex" alignItems="center">
                  <div>
                    <Button
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant={'outlined'}
                      className={classes.addToButton}
                      onClick={handleClick}
                    >
                      <Typography variant={'subtitle1'} fontWeight={700}>
                        + Add to
                      </Typography>
                    </Button>
                    <Menu
                      className={classes.addBoardMenu}
                      anchorEl={anchorE}
                      keepMounted
                      open={Boolean(anchorE)}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      style={{ marginTop: '-5px' }}
                      onClose={handleClose}
                    >
                      <Box className={classes.menuItemBox}>
                        {boardList.map(({ name, board_id, allow_add }, idx) => (
                          <MenuItem
                            disabled={!allow_add || activeBoards.some(a => a.board_id === board_id)}
                            key={idx}
                            onClick={() => handleBoardValues(board_id)}
                          >
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                              checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                              classes={{
                                root: classes.check,
                                checked: classes.checked,
                              }}
                              checked={checkBoardAvailability(board_id, allow_add)}
                            />
                            <Typography variant={'subtitle1'} width="20ch" noWrap>
                              {name}
                            </Typography>
                            <Typography variant={'subtitle2'} color={'text.secondary'} ml={1}>
                              {!allow_add
                                ? 'Max. limit reached'
                                : activeBoards.some(a => a.board_id === board_id)
                                ? 'Chart already added'
                                : ''}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Box>
                      <Box className={classes.applySelectBox}>
                        <Button variant="contained" className={classes.applyButton} onClick={() => addToDashboard()}>
                          <Typography fontSize={13} fontWeight={700} textAlign="right">
                            Save & pin chart
                          </Typography>
                        </Button>
                      </Box>
                    </Menu>
                  </div>
                  <Button variant="outlined" onClick={() => saveChartChanges()} style={{ marginRight: 10 }}>
                    <Typography variant={'subtitle1'} pl={0.5} fontWeight={700}>
                      Save changes
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <QueryComponent
            spotlightQuery={spotlightQuery}
            spotlightStore={spotlightStore}
            fetchSpotlight={fetchSpotlight}
            handleQueryUpdates={handleQueryUpdates}
            handleAttributesQuery={handleAttributesQuery}
            isNewChart={isNewChart}
            chartId={chart_id}
          />
        </div>

        <ChartComponent
          spotlightChart={spotlightChart}
          spotlightQuery={spotlightQuery}
          handleDateRangeQuery={handleDateRangeQuery}
          fetchSpotlightChart={fetchSpotlightChart}
        />
      </div>
    );
  }
}
