import React, { useEffect, useState } from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import deleteImg from '../../images/delete-icon.svg';
import AlertUtil from '../../utils/AlertUtil';

import { fetchSurveySequence, updateSurveySequence } from '../../services/surveyService';
import { useStyles } from './EditorStyle';
import { ISurvey, ISurveySequence } from '../../types/survey';
import { modifiedDate } from '../../utils/DateUtil';

const customStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.4rem', marginBottom: theme.spacing(4), paddingLeft: theme.spacing(0.9), fontWeight: 700 },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function SurveySeqModal(props: {
  open: boolean;
  toggleModal: () => void;
  trigger_name: string;
  survey: ISurvey;
}) {
  const classes = useStyles();
  const custom = customStyles();
  const [surveySequence, setSurveySequence] = useState<Array<ISurveySequence>>([]);

  const { open, toggleModal, trigger_name, survey } = props;
  const { survey_id, survey_name, created_at, platform } = survey;

  const handleClose = () => {
    toggleModal();
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(surveySequence);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!setSurveySequence) {
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(result.source.index, result.destination.index);
    setSurveySequence(items);
  };

  const saveSequence = async () => {
    const survey_ids = surveySequence.map(s => s.survey_id);
    const results = await updateSurveySequence({ survey_ids, platform, trigger_name }, survey_id);

    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Saved successfully',
      });
    }
  };

  const getSequence = async () => {
    const results = await fetchSurveySequence(survey_id, platform, trigger_name);
    if (results) {
      const check_if_exist = results.find(s => s.survey_id === survey_id);
      if (!check_if_exist) {
        results.push({ survey_id, survey_name, created_at: new Date(created_at) });
      }
      setSurveySequence(results);
    }
  };

  useEffect(() => {
    getSequence();
  }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={custom.root}>
          <Grid item lg={12}>
            <Box
              borderBottom="1px solid rgba(255, 255, 255, 0.05)"
              pb={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <Typography className={custom.title} display="inline">
                  Prioritize Survey
                </Typography>
              </div>

              <img src={deleteImg} style={{ height: 32, cursor: 'pointer' }} onClick={handleClose} />
            </Box>

            <Box display={'flex'} alignItems={'flex-start'}>
              <div style={{ paddingLeft: '0.87rem', width: '100%' }}>
                <Typography>Users will be surveyed based on below order</Typography>

                <DragDropContext onDragEnd={e => onDragEnd(e)}>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {surveySequence.map(({ survey_name, created_at }, index) => (
                          <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Box className={classes.surveyOrderBox}>
                                  <div style={{ position: 'absolute', marginLeft: -40, zIndex: 1 }}>
                                    <Typography variant={'subtitle2'} fontWeight="bold">
                                      #{index + 1}
                                    </Typography>
                                  </div>
                                  <Typography>{survey_name}</Typography>
                                  <Typography variant={'subtitle2'} color={'gray'}>
                                    {modifiedDate(created_at.toString(), 'll')}
                                  </Typography>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <Button variant="contained" className={custom.buttonSection} onClick={() => saveSequence()}>
                  <Typography variant={'subtitle1'} fontWeight={700}>
                    Save Order
                  </Typography>
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
