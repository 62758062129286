import React from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import languageImg from '../../images/editor/translate-img.svg';
import deleteImg from '../../images/delete-icon.svg';
import { syncOtherLanguageSurveys } from '../../services/surveyService';
import AlertUtil from '../../utils/AlertUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.4rem', marginBottom: theme.spacing(4), paddingLeft: theme.spacing(0.9), fontWeight: 700 },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function TranslateModal(props: {
  open: boolean;
  toggleModal: (Boolean) => void;
  activeLanguage: string;
  survey_id: string;
  fetchSurvey: (lang_code: string) => void;
}) {
  const classes = useStyles();

  const { open, toggleModal, activeLanguage, survey_id, fetchSurvey } = props;

  const handleClose = () => {
    toggleModal(false);
  };

  const handleTranslate = async () => {
    const translateResult = await syncOtherLanguageSurveys({}, survey_id, activeLanguage);

    if (translateResult && translateResult.message) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Translated successfully',
      });
    } else {
      AlertUtil.fire({
        icon: 'error',
        title: 'Some error occured',
      });
    }
    fetchSurvey(activeLanguage);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Box
              borderBottom="1px solid rgba(255, 255, 255, 0.05)"
              pb={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <img src={languageImg} alt="" />
                <Typography className={classes.title} display="inline">
                  Sync survey edits
                </Typography>
              </div>
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleClose} />
            </Box>
            <Typography pt={3.8} pb={3}>
              You have made changes in the English version of the survey which have not been copied to selected
              translation languages. <br />
            </Typography>
            <Typography>
              Warning:
              <br />
              Any previous edits made to non-english survey copies will be over written.
            </Typography>
            <Box className={classes.buttonSection}>
              <Button variant="contained" style={{ marginLeft: 10 }} onClick={handleTranslate}>
                Sync changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
