import { getCall, postCall, putCall } from '../apiCall';
import {
  CANCEL_SUBSCRIPTION,
  EDIT_BILLING_ADDRESS,
  FETCH_COUNTRY_LIST,
  FETCH_INVOICE_LIST,
  FETCH_INVOICE_ITEM,
  PAYMENT_LINK_INIT,
  UPDATE_BILLING_PLAN,
  FETCH_PORTAL_SESSION_URL,
} from './apiEndPoints';
import { IInvoice, IPaymentConfirmation } from '../types/billing';

export const editBillingAddress = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_BILLING_ADDRESS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchCountryList = async () => {
  return new Promise<Array<string>>((resolve, reject) => {
    try {
      getCall(FETCH_COUNTRY_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchInvoiceList = async () => {
  return new Promise<Array<IInvoice>>((resolve, reject) => {
    try {
      getCall(FETCH_INVOICE_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchInvoiceItem = async (invoice_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(FETCH_INVOICE_ITEM(invoice_id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const paymentLinkInit = async (amount: number, curr_plan: string, response_count) => {
  return new Promise<IPaymentConfirmation>((resolve, reject) => {
    try {
      getCall(PAYMENT_LINK_INIT(amount, curr_plan, response_count))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateBillingPlan = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(UPDATE_BILLING_PLAN(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const cancelSubscription = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(CANCEL_SUBSCRIPTION(), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getPortalSessionUrl = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_PORTAL_SESSION_URL(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
