import { toggleWorkspaceById } from '../services/workspaceService';
import { baseCustomCSS, previewTheme } from '../static/staticData';
import { ISurveyTheme } from '../types/workspace';

export const switchWorkspace = async (workspace_id: string) => {
  const results = await toggleWorkspaceById(workspace_id);
  if (results && results.auth_token) {
    localStorage.setItem('auth_token', results.auth_token);
    localStorage.setItem('active_workspace', workspace_id);
    window.location.href = '/';
  }
};

export const filterCSSData = (css: any) => {
  const css_data = baseCustomCSS;
  for (const i in baseCustomCSS) {
    if (css && css[i]) {
      css_data[i] = css[i];
    }
  }
  return css_data;
};

//convert colours based on theme variants for adaptive design
export const handleSurveyColor = (theme_variant: string, theme: ISurveyTheme) => {
  const bg_color = theme.bg_color;
  const text_color = theme.text_color;
  const secondary_text_color = theme.text_color;
  const highlight_color = theme.highlight_color;
  const cta_text_color = theme.cta_text_color;
  const close_icon_url = theme.close_icon_url;

  let option_bg_color = theme.option_bg_color;
  let border_color = theme.border_color;

  let selected_text_color = theme.highlight_color;
  let selected_border_color = theme.highlight_color;
  let selected_option_bg_color = theme.highlight_color;
  let selected_radio_button_color = theme.highlight_color;
  let radio_button_color = theme.border_color;
  let range_border_color = theme.border_color;
  let selected_range_higlight_color = theme.highlight_color;
  let input_border_color = theme.border_color;
  let input_background_color = theme.option_bg_color;
  let range_background_color = theme.option_bg_color;

  if (theme_variant === 'borderless') {
    border_color = theme.bg_color;
    option_bg_color = theme.bg_color;
    selected_text_color = theme.text_color;
    selected_border_color = theme.bg_color;
    selected_option_bg_color = theme.bg_color;
    selected_radio_button_color = theme.highlight_color;
    radio_button_color = theme.border_color;
    range_border_color = theme.border_color;
    range_background_color = theme.option_bg_color;
    selected_range_higlight_color = theme.highlight_color;
    input_border_color = theme.border_color;
    input_background_color = theme.option_bg_color;
  }

  return {
    intro: theme.intro,
    bg_color,
    text_color,
    border_color,
    highlight_color,
    option_bg_color,
    cta_text_color,
    close_icon_url,
    selected_text_color,
    selected_border_color,
    selected_option_bg_color,
    selected_radio_button_color,
    radio_button_color,
    range_border_color,
    range_background_color,
    selected_range_higlight_color,
    input_border_color,
    input_background_color,
    secondary_text_color,
  };
};

export const getThemeType = (brand: ISurveyTheme) => {
  const theme = previewTheme.find(
    p =>
      brand.bg_color.toUpperCase() === p.bg_color &&
      brand.border_color.toUpperCase() === p.border_color &&
      brand.text_color.toUpperCase() === p.text_color &&
      brand.highlight_color.toUpperCase() === p.highlight_color &&
      brand.option_bg_color.toUpperCase() === p.option_bg_color &&
      brand.cta_text_color.toUpperCase() === p.cta_text_color,
  );

  if (theme) {
    return theme.value;
  }

  return 'custom';
};

export const isCustomTheme = (theme_id: string) => {
  return !previewTheme.some(p => p.value === theme_id);
};
