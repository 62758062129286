import { attrFunctions } from '../static/staticData';

// handle operator label
export const getOperatorLabel = (comparator: string, data_type: string) => {
  const operator = attrFunctions.find(a => a.type === data_type);
  if (!operator) return '';
  const datetypeGroup = operator.options.find(o => o.value === comparator);
  return datetypeGroup ? datetypeGroup.label : '';
};

// handle operators for different data types of attribute
export const getOperatorsForDatatype = (comparator: string) => {
  if (!comparator) return [];
  const operator = attrFunctions.find(a => a.type === comparator);
  return operator ? operator.options : [];
};
