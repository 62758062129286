import React from 'react';
import SideNavComponent from '../../components/layout/SideNavComponent';
import ProfileComponent from '../../components/organisation/ProfileComponent';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      top: '55px',
      paddingBlock: '1.2rem',
      paddingLeft: '3.8%',
      paddingRight: '3.8%',
    },
  }),
);

const ProfilePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SideNavComponent />
      <main className={classes.content}>
        <ProfileComponent />
      </main>
    </div>
  );
};

export default ProfilePage;
