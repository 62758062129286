import { getCall, putCall } from '../apiCall';
import {
  FETCH_WORKSPACES_LIST,
  FETCH_WORKSPACE_MEMBERS,
  FETCH_WORKSPACES_FOR_MEMBER,
  FETCH_WORKSPACE_MEMBERS_BY_ID,
  EDIT_WORKSPACE_ROLE,
  LEAVE_WORKSPACE,
  REMOVE_WORKSPACE,
  REDIRECT_FEEDBACK_BOARD,
} from './apiEndPoints';
import { IWorkspaceForMember, IWorkspaceMember } from '../types/member';
import { IWorkspaceList } from '../types/workspace';

export const fetchProjectList = async () => {
  return new Promise<Array<IWorkspaceList>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACES_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWorkspaceMembers = async () => {
  return new Promise<Array<IWorkspaceMember>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_MEMBERS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWorkspacesForMember = async (id: string) => {
  return new Promise<Array<IWorkspaceForMember>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACES_FOR_MEMBER(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWorkspaceMembersById = async (id: string) => {
  return new Promise<Array<IWorkspaceMember>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_MEMBERS_BY_ID(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editWorkspaceRole = async (payload: any, member_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_WORKSPACE_ROLE(member_id), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const leaveWorkspace = async (member_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(LEAVE_WORKSPACE(member_id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const removeWorkspace = async (member_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(REMOVE_WORKSPACE(member_id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const redirectToBoard = async (workspace: string) => {
  return new Promise<string>((resolve, reject) => {
    try {
      getCall(REDIRECT_FEEDBACK_BOARD(workspace))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
