import React from 'react';
import { Theme, Box, Dialog, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import deleteImg from '../../../images/delete-icon.svg';
import { userImgFormat } from '../../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },
  }),
);

export default function ProjectModal(props: {
  open: boolean;
  toggleModal: (Boolean) => void;
  workspaceMembers: Array<any>;
}) {
  const classes = useStyles();

  const { toggleModal, workspaceMembers } = props;

  const handleToggle = () => {
    toggleModal(false);
  };

  return (
    <div>
      <Dialog onClose={handleToggle} open={props.open} fullWidth maxWidth={'sm'}>
        <div className={classes.root}>
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Project members</Typography>

            <div style={{ marginLeft: 'auto' }}>
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleToggle} />
            </div>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={1}
            mt={1.5}
            pb={1.25}
            borderColor={'divider'}
          >
            <Typography>Members</Typography>
            <Typography>Access</Typography>
          </Box>

          {workspaceMembers.map(({ member_name, workspace_role, member_img, member_email }, idx) => {
            return (
              <Box key={idx} display="flex" alignItems="center" justifyContent="space-between" mt={3.5}>
                <Box display="flex" alignItems="center">
                  {userImgFormat(member_name, member_img).isImg ? (
                    <img
                      src={userImgFormat(member_name, member_img).userImg}
                      alt=""
                      style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.userImgBox}
                      style={{ backgroundColor: `${userImgFormat(member_name, member_img).bgColor}` }}
                    >
                      <Typography style={{ fontSize: '0.85rem', fontWeight: 700 }}>
                        {userImgFormat(member_name, member_img).name}
                      </Typography>
                    </Box>
                  )}

                  <Typography variant={'subtitle1'} style={{ paddingLeft: 10, width: '30ch' }} noWrap>
                    {member_name ? member_name : member_email}
                  </Typography>
                </Box>
                <div>
                  <Typography variant={'subtitle1'}>{workspace_role}</Typography>
                </div>
              </Box>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
}
