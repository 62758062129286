import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDashboard } from '../types/dashboard';
import { fetchAllChartsInBoard } from '../services/spotlightService';

const initialState: IDashboard = {
  name: '',
  description: '',
  public_access: 'private',
  board_link: '',
  survey_id: '',
};

export const retrieveDashboardDetails = createAsyncThunk('boards/get', async (board_id: string) => {
  const results = await fetchAllChartsInBoard(board_id);
  let value = {};

  if (results) {
    const { charts, ...args } = results;
    value = args;
  }
  return value;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard() {
      return {
        ...initialState,
      };
    },
    updateValue: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(retrieveDashboardDetails.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

const { reducer } = dashboardSlice;
export const { updateValue } = dashboardSlice.actions;
export const { resetDashboard } = dashboardSlice.actions;

export default reducer;
