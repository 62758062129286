import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import blitz from 'blitzllama-js';
import { Drawer, Box, Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';
import { useStyles } from './LayoutStyle';

import HeaderComponent from './HeaderComponent';

import { navItems } from '../../static/navItems';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

const SideNavComponent = () => {
  const classes = useStyles();

  const [selected, setSelected] = useState<number | string>(0);
  const { pathname } = useLocation();

  const user = useSelector((state: RootState) => state.login);

  let role = '';

  if (user) {
    role = user.w_role;
  }

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  let showPrioritise = false;

  if (tracking_info && tracking_info.show_discovery) {
    showPrioritise = true;
  }

  useEffect(() => {
    for (let i = 0; i < navItems.length; i++) {
      if (navItems[i].pathname === pathname) {
        setSelected(i);
      } else if (pathname.includes('/survey')) {
        setSelected(0);
      } else if (pathname.includes('/templates')) {
        setSelected(1);
      } else if (pathname.includes('/prioritise')) {
        setSelected(2);
      } else if (pathname.includes('/respository')) {
        setSelected(3);
      } else if (pathname.includes('/spotlight/new')) {
        setSelected(4);
      } else if (pathname.includes('/board')) {
        setSelected(5);
      } else if (pathname.includes('/cohorts')) {
        setSelected(6);
      }
    }
  }, [pathname]);

  return (
    <>
      <HeaderComponent />

      <div className={classes.sideNavContainer}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {role &&
            navItems.map(({ line, heading, pathname, name, icon }, index) => (
              <>
                {role === 'editor' && (
                  <div>
                    {line && <Box className={classes.borderTop}></Box>}
                    {heading && <Typography className={classes.navHeading}>{heading}</Typography>}
                    <Link
                      to={pathname}
                      style={
                        !showPrioritise && pathname.includes('prioritise') && pathname.includes('respository')
                          ? { display: 'none' }
                          : {}
                      }
                    >
                      <Box
                        borderRadius="6px"
                        display="flex"
                        alignItems={'center'}
                        key={name}
                        className={selected === index ? classes.activeNavItem : classes.navItem}
                        onClick={() => setSelected(index)}
                      >
                        <Typography
                          variant="subtitle1"
                          className={selected === index ? classes.activeImg : classes.img}
                        >
                          {icon}
                        </Typography>
                        <Typography variant="subtitle1" ml={1} color={selected === index ? 'primary.contrastText' : ''}>
                          {name}
                        </Typography>
                        {/* {name === 'Spotlight' && <Typography className={classes.newTag}>New</Typography>} */}
                        {selected === index && <div className={classes.activeBorder}></div>}
                      </Box>
                    </Link>
                  </div>
                )}

                {role === 'viewer' && (index === 0 || index === 2 || index === 7) && (
                  <Link
                    to={pathname}
                    style={!showPrioritise && pathname.includes('prioritise') ? { display: 'none' } : {}}
                  >
                    <Box
                      borderRadius="6px"
                      display="flex"
                      alignItems={'center'}
                      key={name}
                      className={selected === index ? classes.activeNavItem : classes.navItem}
                      onClick={() => setSelected(index)}
                    >
                      <Typography variant="subtitle1" className={selected === index ? classes.activeImg : classes.img}>
                        {icon}
                      </Typography>
                      <Typography variant="subtitle1" ml={1} color={selected === index ? 'primary.contrastText' : ''}>
                        {name}
                      </Typography>
                    </Box>
                  </Link>
                )}
              </>
            ))}

          <Box
            className={classes.giveFeedbackBox}
            onClick={() => {
              blitz.triggerEvent('dashboard_feedback');
            }}
          >
            <Typography variant="subtitle2" px={3.25}>
              Give feedback
            </Typography>
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default SideNavComponent;
