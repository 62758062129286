import { createSlice } from '@reduxjs/toolkit';

type IConditionItem = {
  question_id: string;
  question_type: string;
  rule_type: string;
  responses: Array<string>;
  scale_value: string;
};

const initialState: Array<IConditionItem> = [
  {
    question_id: '',
    question_type: '',
    rule_type: '',
    responses: [] as Array<string>,
    scale_value: '',
  },
];

const performanceSlice = createSlice({
  name: 'conditions',
  initialState,
  reducers: {
    resetCondition() {
      return [...initialState];
    },
    updateCondition(state, action) {
      if (action.payload && action.payload.length > 0) {
        return [...action.payload];
      } else {
        return [...initialState];
      }
    },
  },
});

const { reducer } = performanceSlice;

export const { resetCondition, updateCondition } = performanceSlice.actions;

export default reducer;
