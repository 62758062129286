import { postCall, putCall, getCall } from '../apiCall';
import {
  CREATE_WORKSPACE,
  FETCH_WORKSPACE_LIST,
  FETCH_WORKSPACE_BY_ID,
  EDIT_SURVEY_THEME,
  EDIT_WORKSPACE,
  TOGGLE_WORKSPACE_BY_ID,
  FETCH_SURVEY_BRANDING,
  EDIT_BRANDING_THEME,
  EDIT_BRANDING_LABEL,
  EDIT_BRANDING_WEB_SETIINGS,
} from './apiEndPoints';
import { IBranding, IWorkspace } from '../types/workspace';

export const createWorkspace = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_WORKSPACE(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWorkspaceList = async () => {
  return new Promise<Array<IWorkspace>>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const workspaceById = async () => {
  return new Promise<IWorkspace>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_BY_ID())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveyTheme = async (payload: any) => {
  return new Promise<IWorkspace>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_THEME(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editWorkspace = async (payload: any) => {
  return new Promise<IWorkspace>((resolve, reject) => {
    try {
      putCall(EDIT_WORKSPACE(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const toggleWorkspaceById = async (workspace_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(TOGGLE_WORKSPACE_BY_ID(workspace_id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyBranding = async () => {
  return new Promise<IBranding>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_BRANDING())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveyBrandingTheme = async (payload: any) => {
  return new Promise<IWorkspace>((resolve, reject) => {
    try {
      putCall(EDIT_BRANDING_THEME(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editBrandingLabel = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_BRANDING_LABEL(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editBrandingWebSettings = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_BRANDING_WEB_SETIINGS(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
