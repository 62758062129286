import { IPerformanceCondition } from '../types/survey';

export const responseText = (response: string, raw_input: string, question_type: string, show_original: boolean) => {
  if (
    question_type === 'nps_feedback' ||
    question_type === 'scale_feedback' ||
    question_type === 'star_feedback' ||
    question_type === 'emoji_feedback' ||
    question_type === 'data_collection'
  ) {
    return response;
  }

  if (!response) {
    return '-';
  }

  return show_original ? raw_input : response;
};

export const linkLanguages = (lang_arr: Array<string>) => {
  lang_arr.unshift('en');
  return [...new Set(lang_arr)];
};

export const filterConditionsHandler = (conditions: Array<IPerformanceCondition>) => {
  const valid_conditions: Array<IPerformanceCondition> = [];

  for (const i in conditions) {
    let is_valid_condition = true;
    const { question_type, scale_value, rule_type, responses } = conditions[i];
    if (!question_type) {
      is_valid_condition = false;
    }

    if (
      question_type === 'single_select_feedback' ||
      question_type === 'multi_select_feedback' ||
      question_type === 'input_feedback'
    ) {
      if (responses.length === 0 || !rule_type) {
        is_valid_condition = false;
      }
    } else if (
      question_type === 'scale_feedback' ||
      question_type === 'star_feedback' ||
      question_type === 'emoji_feedback'
    ) {
      if (!rule_type || !scale_value || +scale_value <= 0 || +scale_value > 5) {
        is_valid_condition = false;
      }
    } else if (question_type === 'nps_feedback') {
      if (!rule_type || !scale_value || +scale_value < 0 || +scale_value > 10) {
        is_valid_condition = false;
      }
    } else if (question_type === 'intro_prompt' || question_type === 'data_collection') {
      if (!rule_type) {
        is_valid_condition = false;
      }
    }

    if (is_valid_condition) {
      valid_conditions.push(conditions[i]);
    }
  }
  return valid_conditions;
};
