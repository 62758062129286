import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Theme,
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Select,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import surveyStyles from '../common/SurveyStyles';

import ProjectListModal from './modal/ProjectListModal';
import RemoveUserModal from './modal/RemoveUserModal';

import { IMember, IWorkspaceForMember } from '../../types/member';

import { fetchAllMembers } from '../../services/organisationMemberService';
import { fetchWorkspacesForMember } from '../../services/workspaceMember';
import { makeMemberAdmin, makeMemberUser, reInviteUser } from '../../services/adminService';

import AlertUtil from '../../utils/AlertUtil';
import { userImgFormat, userLastSeen, userRole } from '../../utils/stringUtil';
import { StyledTableRow } from '../common/tableStyles';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },

    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-input': {
        padding: '0.5rem 0.9rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },
  }),
);

const TeamComponent = (props: { openToggle: boolean }) => {
  const classes = useStyles();
  const common = surveyStyles();
  const [isLoading, setLoading] = useState(true);
  const [openProjectListModal, setOpenProjectListModal] = useState(false);
  const [openUserAlertModal, setOpenUserAlertModal] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [teamMembers, setTeamMembers] = useState<Array<IMember>>();
  const [memberName, setMembername] = useState('');
  const [workspacesForMember, setworkspacesForMember] = useState<Array<IWorkspaceForMember>>();
  const { openToggle } = props;

  const user = useSelector((state: RootState) => state.login);

  const fetchTeamMembers = async () => {
    const members = await fetchAllMembers();
    if (members) {
      setTeamMembers(members);
    }
    setLoading(false);
  };

  const fetchWorkspaceMember = async (id: string) => {
    const workspace = await fetchWorkspacesForMember(id);
    if (workspace) {
      setworkspacesForMember(workspace);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, [openUserAlertModal, openToggle]);

  const handleProjectModal = async (id: string, member_name: string) => {
    await fetchWorkspaceMember(id);
    setMembername(member_name);
    setOpenProjectListModal(true);
  };

  const handleUserAlertModal = async (id: string) => {
    setMemberId(id);
    setOpenUserAlertModal(true);
  };

  const reInviteNonVerifiedMember = async (id: string) => {
    const invite = await reInviteUser(id);
    if (invite) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Invite sent successfully',
      });
    }
  };

  const editMemberRole = async (member_id: string, role: string) => {
    let results;
    if (role === 'make-admin') {
      results = await makeMemberAdmin({}, member_id);
    } else if (role === 'make-user') {
      results = await makeMemberUser({}, member_id);
    }

    if (results && results.data) {
      fetchTeamMembers();
      AlertUtil.fire({
        icon: 'success',
        title: 'Edited successfully',
      });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        {openProjectListModal && (
          <ProjectListModal
            toggleModal={setOpenProjectListModal}
            open={openProjectListModal}
            workspacesForMember={workspacesForMember ? workspacesForMember : []}
            memberName={memberName}
          />
        )}
        {openUserAlertModal && (
          <RemoveUserModal
            toggleModal={setOpenUserAlertModal}
            open={openUserAlertModal}
            memberId={memberId}
            isOrg={true}
          />
        )}
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Projects</TableCell>
                  <TableCell>Last active</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamMembers &&
                  teamMembers.map(
                    (
                      {
                        member_id,
                        member_name,
                        member_email,
                        member_img,
                        last_active_at,
                        member_role,
                        count,
                        is_verified,
                        is_primary,
                      },
                      idx,
                    ) => {
                      return (
                        <StyledTableRow key={idx}>
                          <TableCell component="th" scope="row" width={'20%'}>
                            <Box display="flex" alignItems="center">
                              {userImgFormat(member_name, member_img).isImg ? (
                                <img
                                  src={userImgFormat(member_name, member_img).userImg}
                                  alt=""
                                  style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                />
                              ) : (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  className={classes.userImgBox}
                                  style={{ backgroundColor: `${userImgFormat(member_name, member_img).bgColor}` }}
                                >
                                  <Typography style={{ fontSize: '0.85rem', fontWeight: 700 }}>
                                    {userImgFormat(member_name, member_img).name}
                                  </Typography>
                                </Box>
                              )}
                              <Typography
                                variant={'subtitle1'}
                                style={{ paddingLeft: 10, maxWidth: '25ch', paddingRight: 5 }}
                                noWrap
                              >
                                {member_name ? member_name : 'New member '}
                              </Typography>
                              {user?.member_id === member_id ? `(You)` : ''}
                            </Box>
                          </TableCell>
                          <TableCell width={'20%'}>{member_email}</TableCell>
                          <TableCell width={'15%'}>
                            <Typography
                              variant={'subtitle1'}
                              color={'primary.contrastText'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleProjectModal(member_id, member_name)}
                            >
                              {count ? <u>{count} projects</u> : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell width={'15%'}>
                            <Typography variant={is_verified ? 'body1' : 'body2'} style={{ fontSize: '0.9rem' }}>
                              {userLastSeen(last_active_at, is_verified)}
                            </Typography>
                          </TableCell>
                          <TableCell width={'15%'}>{userRole(member_role, is_primary)}</TableCell>
                          <TableCell
                            width={'15%'}
                            style={user?.member_id !== member_id ? { paddingBlock: '' } : { paddingBlock: '2.26rem' }}
                          >
                            {user?.member_role === 'admin' && (
                              <>
                                {user?.member_id !== member_id && (
                                  <Select
                                    displayEmpty
                                    variant="outlined"
                                    fullWidth
                                    className={classes.selectField}
                                    renderValue={() => {
                                      return <Typography variant={'subtitle1'}>Actions</Typography>;
                                    }}
                                  >
                                    {member_role === 'user' && (
                                      <MenuItem
                                        onClick={() => editMemberRole(member_id, 'make-admin')}
                                        value="make-admin"
                                      >
                                        Make Admin
                                      </MenuItem>
                                    )}
                                    {member_role === 'admin' && (
                                      <MenuItem
                                        onClick={() => editMemberRole(member_id, 'make-user')}
                                        value="make-user"
                                      >
                                        Make Member
                                      </MenuItem>
                                    )}
                                    {!is_verified && (
                                      <MenuItem onClick={() => reInviteNonVerifiedMember(member_id)} value="">
                                        Reinvite Member
                                      </MenuItem>
                                    )}
                                    <MenuItem onClick={() => handleUserAlertModal(member_id)} value="">
                                      Remove Member
                                    </MenuItem>
                                  </Select>
                                )}
                              </>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      );
                    },
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    );
  }
};

export default TeamComponent;
