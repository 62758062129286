import React from 'react';
import StartProjectComponent from '../../components/organisation/StartProjectComponent';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
    },
  }),
);

const SettingsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <StartProjectComponent />
      </main>
    </div>
  );
};

export default SettingsPage;
