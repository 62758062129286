import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Drawer,
  Theme,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import deleteImg from '../../images/delete-icon.svg';
import { ReactComponent as TimelineDotImg } from '../../images/audience/timeline-dot.svg';
import { fetchUserActivityFeed } from '../../services/audienceService';
import { IUserActivity } from '../../types/audience';
import { getAttributeValue } from '../../utils/stringUtil';
import { modifiedDate } from '../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      color: theme.palette.primary.contrastText,
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },

    deleteImg: {
      paddingLeft: theme.spacing(4),
      height: '36px',
      cursor: 'pointer',
    },
    userDetailsTable: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      marginTop: theme.spacing(2),
    },
    styledTableCell: {
      backgroundColor: `${theme.palette.secondary.contrastText}`,
    },
    questionBox: {
      border: `1px solid ${theme.palette.divider}`,
      padding: '0.7rem 1.2rem',
      borderRadius: '4px',
      marginTop: theme.spacing(1),
    },
    userDetailsBox: {
      marginTop: '-12px',
      marginBottom: '-8px',
      borderLeft: '1px solid #8B8BAA',
      paddingLeft: theme.spacing(3),
      marginLeft: '3px',
      paddingBottom: theme.spacing(2.5),
    },
  }),
);

const UserActivityTable = ({ userId, userActivity }) => {
  const classes = useStyles();
  const { user_details, user_activities } = userActivity;

  return (
    <Box ml={10} mr={9} pb={5}>
      <Typography variant={'h2'} mb={2}>
        {userId}
      </Typography>
      <Typography variant={'h6'}>User attributes</Typography>
      <TableContainer className={classes.userDetailsTable}>
        <Table>
          <TableBody>
            {Object.keys(user_details).map((item, idx) => (
              <TableRow key={idx}>
                <TableCell className={classes.styledTableCell} scope="row">
                  <Typography>{item}</Typography>
                </TableCell>
                <TableCell scope="column">
                  <Typography>{getAttributeValue(item, user_details[item])}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant={'h4'} pt={4} pb={2}>
        History
      </Typography>
      {user_activities && user_activities.length > 0 ? (
        user_activities.map(({ activity_type, created_at, survey_name, question_text, response }, idx) => (
          <VerticalTimeline>
            <VerticalTimelineElement icon={<TimelineDotImg />}>
              <Box className={classes.userDetailsBox}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2" color={'text.secondary'} style={{ marginTop: '-12px' }}>
                    {activity_type}
                  </Typography>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    {modifiedDate(created_at, 'lll')}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" mt={'-8px'}>
                  {survey_name}
                </Typography>

                {question_text && (
                  <Box className={classes.questionBox}>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                      Q: {question_text}
                    </Typography>
                    <Typography variant="subtitle1">{response ? response : '_'}</Typography>
                  </Box>
                )}
              </Box>
            </VerticalTimelineElement>
          </VerticalTimeline>
        ))
      ) : (
        <Box display="flex" alignItems={'center'} justifyContent="center">
          <Typography variant={'body2'} color={'text.secondary'}>
            No details to show
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default function UserDrawer(props: {
  anchor: 'right';
  toggleDrawer: (Boolean) => void;
  showUserDrawer: boolean;
  userId: string;
}) {
  const { anchor, toggleDrawer, showUserDrawer, userId } = props;
  const classes = useStyles();
  const [userActivity, setUserActivity] = useState<IUserActivity | null>();

  const fetchUserActivity = async () => {
    const userResult = await fetchUserActivityFeed(userId);
    if (userResult) {
      setUserActivity(userResult);
    }
  };

  useEffect(() => {
    fetchUserActivity();
  }, [userId]);

  const handleClose = () => {
    setUserActivity(null);
    toggleDrawer(false);
  };

  return (
    <Drawer
      anchor={anchor}
      open={showUserDrawer}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box pt={5} sx={{ width: 800 }}>
        <img src={deleteImg} alt="" onClick={() => handleClose()} className={classes.deleteImg} />
        {!userId || !userActivity || !userActivity.user_details ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
            <CircularProgress className={classes.loading} />
          </Box>
        ) : (
          <UserActivityTable userId={userId} userActivity={userActivity} />
        )}
      </Box>
    </Drawer>
  );
}
