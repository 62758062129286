import { Box, CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { RootState } from '../store';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      color: theme.palette.primary.contrastText,
    },
  }),
);

export function EditorRoute({ component: Component, ...restOfProps }) {
  const user = useSelector((state: RootState) => state.login);
  const tracking_info = useSelector((state: RootState) => state.tracking);
  const history = useHistory();
  const classes = useStyles();

  const auth_token = localStorage.getItem('auth_token');
  if (!auth_token) {
    history.push('/login');
  }

  const active_workspace = localStorage.getItem('active_workspace');
  if (!active_workspace) {
    history.push('/start-project');
  }

  if (!user.member_id || !tracking_info.member_id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <Route
        {...restOfProps}
        render={props =>
          user?.w_role === 'editor' ? <Component {...props} member={user} /> : <Redirect to="/survey" />
        }
      />
    );
  }
}

export function AdminRoute({ component: Component, ...restOfProps }) {
  const user = useSelector((state: RootState) => state.login);
  const tracking_info = useSelector((state: RootState) => state.tracking);
  const history = useHistory();
  const classes = useStyles();

  const auth_token = localStorage.getItem('auth_token');
  if (!auth_token) {
    history.push('/login');
  }

  if (!user.member_id || !tracking_info.member_id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <Route
        {...restOfProps}
        render={props =>
          user?.member_role === 'admin' ? <Component {...props} member={user} /> : <Redirect to="/survey" />
        }
      />
    );
  }
}

export function UserRoute({ component: Component, ...restOfProps }) {
  const user = useSelector((state: RootState) => state.login);
  const tracking_info = useSelector((state: RootState) => state.tracking);
  const history = useHistory();
  const classes = useStyles();

  const auth_token = localStorage.getItem('auth_token');
  if (!auth_token) {
    history.push('/login');
  }

  if (!user.member_id || !tracking_info.member_id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <Route
        {...restOfProps}
        render={props =>
          user?.w_role !== 'invalid' ? (
            <Component {...props} member={user ? user : {}} />
          ) : (
            <Redirect to="/start-project" />
          )
        }
      />
    );
  }
}
