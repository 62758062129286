import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  errorText: {
    color: '#F42B4F',
    marginTop: theme.spacing(2),
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successText: {
    color: '#00FFA3',
    marginTop: theme.spacing(2),
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function ErrorUtil(props: any) {
  const classes = useStyles();
  return (
    <Grid className={classes.errorText}>
      <Typography variant={'subtitle1'} textAlign="center">
        {props.message}
      </Typography>
    </Grid>
  );
}

export function SuccessUtil(props: any) {
  const classes = useStyles();
  return (
    <Grid className={classes.successText}>
      <Typography textAlign="center">{props.message}</Typography>
    </Grid>
  );
}
