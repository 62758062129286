import { getCall, putCall } from '../apiCall';
import { FETCH_ALL_NOTIFICATION, MARK_NOTIFICATION_ALL_AS_READ, MARK_NOTIFICATION_AS_READ } from './apiEndPoints';
import { INotification } from '../types/notification';

export const fetchAllNotifications = async () => {
  return new Promise<Array<INotification>>((resolve, reject) => {
    try {
      getCall(FETCH_ALL_NOTIFICATION())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const markAllRead = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(MARK_NOTIFICATION_ALL_AS_READ(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const markNotificationRead = async (notification_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(MARK_NOTIFICATION_AS_READ(notification_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
