import * as React from 'react';
import { MenuItem, Popover, TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import crossImg from '../../images/integration/cross-icon.svg';
import { IPropertyItem } from '../../types/trigger';
import { propertyDataTypes } from '../../static/staticData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyPopoverBox: {
      position: 'relative',
      padding: theme.spacing(2.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(1.8),
      minWidth: '150px',
    },
    deleteImg: {
      position: 'absolute',
      top: 8,
      right: 7,
      cursor: 'pointer',
    },
    dataTypeField: {
      marginTop: 6,
      '& .MuiOutlinedInput-input': { padding: '0.2rem 0.5rem' },
    },
    dataTypeText: {
      textTransform: 'capitalize',
    },
  }),
);

export interface IPropertyPopover {
  open: boolean;
  handleClose: () => void;
  triggerId: string;
  propertyItem: IPropertyItem;
  handlePropertyDataType: (value: string, trigger_id: string, property_name: string) => void;
  anchorEl: HTMLElement | null;
}

const PropertyPopover: React.FunctionComponent<IPropertyPopover> = ({
  handleClose,
  open,
  triggerId,
  propertyItem,
  handlePropertyDataType,
  anchorEl,
}) => {
  const classes = useStyles();
  const { property_name, data_type } = propertyItem;

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.propertyPopoverBox}>
        <img src={crossImg} alt="" className={classes.deleteImg} onClick={() => handleClose()} />
        <Typography variant="subtitle1" fontWeight={700}>
          Property name
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {property_name}
        </Typography>
        <Typography variant="subtitle1" fontWeight={700} mt={2.5}>
          Data type
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          select
          className={classes.dataTypeField}
          value={data_type}
          onChange={e => handlePropertyDataType(e.target.value, triggerId, property_name)}
        >
          {propertyDataTypes.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              <Typography variant="subtitle1" className={classes.dataTypeText}>
                {item}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Popover>
  );
};

export default PropertyPopover;
