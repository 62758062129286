import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import defaultStyles from './loginStyles';
import { useQuery } from '../../utils/stringUtil';
import { fetchMemberDetails, fetchMemberTrackingInfo } from '../../services/organisationMemberService';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

export default function LoginRedirectComponent() {
  const common = defaultStyles();
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email');
  const status = query.get('status');

  useEffect(() => {
    if (status === 'login') {
      if (token) {
        localStorage.setItem('auth_token', token);
      }
      setTimeout(async () => {
        const user = await fetchMemberDetails();
        const tracking_info = await fetchMemberTrackingInfo();

        if (tracking_info) {
          amplitudeEvent(tracking_info, 'dashboard login success', { method: 'google' });
        }

        if (!user?.member_job_type) {
          history.push('/add-details');
          return;
        }

        window.location.href = '/';
      }, 2000);
      return;
    }

    history.push(`/signup?email=${email}&status=${status}`);
  }, [status]);

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center" mt={'25%'}>
        <CircularProgress className={common.loading} />
      </Box>
    </div>
  );
}
