import React from 'react';
import { Box, Typography, Dialog, Button } from '@mui/material';
import { useStyles } from '../Billing.style';
import deleteImg from '../../../images/delete-icon.svg';
import stripeBlitzLogo from '../../../images/billing/stripe-blitz-logo.svg';
import buttonArrow from '../../../images/billing/button-arrow.svg';
import { getPortalSessionUrl } from '../../../services/billingService';

export default function PlanConfirmationModal(props: {
  toggleConfirmationModal: (value: boolean) => void;
  planName: string;
}) {
  const classes = useStyles();

  const { toggleConfirmationModal } = props;

  const handleToggle = () => {
    toggleConfirmationModal(false);
  };

  const handleRedirectStripe = async () => {
    const portal_session = await getPortalSessionUrl({ return_url: window.location.href });
    if (portal_session && portal_session.data) {
      window.open(portal_session.data);
    }
  };

  return (
    <div>
      <Dialog onClose={handleToggle} open={true} fullWidth maxWidth={'sm'}>
        <div className={classes.confirmationModalRoot}>
          <Box display="flex" alignItems={'center'} justifyContent="space-between">
            <div>
              <img src={stripeBlitzLogo} alt="" />
            </div>
            <div>
              <img src={deleteImg} alt="" style={{ height: 21, cursor: 'pointer' }} onClick={handleToggle} />
            </div>
          </Box>

          <Typography variant="h6" mt={1.5}>
            Manage payment settings on stripe
          </Typography>
          <Typography variant={'subtitle1'} pt={1.5}>
            Blitzllama uses Stripe’s premium security features to manage payment methods and billing settings.
          </Typography>
          <Typography variant="subtitle1" color="#D45151" pt={2}>
            NOTE: Your card will be charged only at the end of each month.
          </Typography>
          <Box display={'flex'} alignItems="flex-end" justifyContent={'flex-end'} pt={4}>
            <Button variant="contained" onClick={() => handleRedirectStripe()}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" pr={1.2}>
                  Manage payments on Stripe
                </Typography>
                <img src={buttonArrow} alt="" />
              </Box>
            </Button>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}
