import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Typography, Paper, Box, CircularProgress, Theme, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  fetchSurveyCollections,
  createSurvey,
  fetchContinuousTemplates,
  createSurveyTemplate,
} from '../../services/surveyService';
import { ICollection, IContinuousTemplate } from '../../types/template';
import templateCountImg from '../../images/survey/template-count-icon.svg';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { launchResearchTypes, templateChips } from '../../static/staticData';
import { RootState } from '../../store';

import aiStarImg from '../../images/editor/ai-star.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collectionPaper: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.3),
      cursor: 'pointer',
      padding: '2.3rem 1.8rem 3rem 2.5rem',
      boxShadow: 'none',
    },
    continuousPaper: {
      border: `1px solid #D6DEF2`,
      borderRadius: theme.spacing(1.3),
      cursor: 'pointer',
      padding: '1.3rem 1.5rem 1.3rem 1.5rem',
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.contrastText,
    },
    collectionTitle: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.25,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    collectionText: {
      lineHeight: 1.25,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    continuousDescription: {
      lineHeight: 1.25,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      paddingTop: '5px',
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    templateImg: {
      maxWidth: 'unset',
      height: '41px',
      width: '41px',
    },
    collectionMenu: {
      '& .MuiPaper-root': {
        width: '220px',
      },
    },
    collectionChip: {
      border: `1px solid #D4D7E7`,
      borderRadius: '100px',
      paddingBlock: theme.spacing(0.4),
      paddingInline: theme.spacing(2),
      marginLeft: theme.spacing(0.7),
      cursor: 'pointer',
    },
    activeCollectionChip: {
      backgroundColor: '#F5F6FC',
      color: theme.palette.primary.contrastText,
      border: `1px solid #D4D7E7`,
      borderRadius: '100px',
      paddingBlock: theme.spacing(0.4),
      paddingInline: theme.spacing(2),
      marginLeft: theme.spacing(0.7),
      cursor: 'pointer',
    },
    aiSurveyBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: `1px solid ${theme.palette.info.contrastText}`,
      backgroundColor: '#6951FF0D',
      paddingBlock: theme.spacing(0.7),
      paddingInline: theme.spacing(1.5),
      marginBottom: theme.spacing(1.8),
      borderRadius: '3px',
    },
    tryNewText: {
      color: theme.palette.info.contrastText,
      fontWeight: 700,
      cursor: 'pointer',
    },
  }),
);

export default function CollectionComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [collectionType, setCollectionType] = useState('all');
  const [collections, setCollection] = useState<Array<ICollection>>([]);
  const [continuousTemplates, setContinuousTemplates] = useState<IContinuousTemplate>();
  const [isLoading, setLoading] = useState(true);
  const [collectionAnchor, setCollectionAnchor] = React.useState<null | HTMLElement>(null);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'template tab viewed', {});
    }
  }, [tracking_info]);

  const handleAmplitudeClick = (category_name: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'template category clicked', { category: category_name });
    }
  };

  const handleAmplitudeTemplate = (template_name: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'template clicked', { template: template_name });
    }
  };

  useEffect(() => {
    const fetchCollections = async () => {
      const collection = await fetchSurveyCollections();
      const templatesList = await fetchContinuousTemplates();

      if (collection && collection.length > 0) {
        setCollection(collection);
      }
      if (templatesList) {
        setContinuousTemplates(templatesList);
      }
      setLoading(false);
    };
    fetchCollections();
  }, []);

  const createSurveyDraft = async (research_type: string) => {
    const newDraft = await createSurvey({ research_type });
    if (newDraft) {
      if (newDraft && newDraft.survey_id) history.push(`/${research_type}/${newDraft.survey_id}/editor`);
    }
  };

  const createSurveyFromTemplate = async (template_id: string) => {
    const newDraft = await createSurveyTemplate({}, template_id);
    if (newDraft && newDraft.survey_id) history.push(`/survey/${newDraft.survey_id}/editor`);
  };

  const handleCollectionAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setCollectionAnchor(event.currentTarget);
  };

  const handleCloseCollectionMenu = () => {
    setCollectionAnchor(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        <Grid container lg={12} alignItems={'center'} justifyContent={'space-between'} mb={2}>
          <Typography variant="h2">Templates</Typography>

          <div>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              endIcon={<ExpandMoreIcon />}
              onClick={handleCollectionAnchor}
            >
              + Create new research
            </Button>

            <Menu
              className={classes.collectionMenu}
              anchorEl={collectionAnchor}
              keepMounted
              open={Boolean(collectionAnchor)}
              onClose={handleCloseCollectionMenu}
            >
              {launchResearchTypes.map(
                ({ title, type_value, icon }, idx) =>
                  +idx < 3 && (
                    <MenuItem key={idx} onClick={() => createSurveyDraft(type_value)}>
                      <Box display={'flex'} alignItems={'center'}>
                        {icon}
                        <Typography variant="subtitle1" pl={1.5}>
                          {title}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ),
              )}
            </Menu>
          </div>
        </Grid>

        <div className={classes.aiSurveyBox}>
          <Box display="flex" alignItems={'center'}>
            <img src={aiStarImg} alt="" />
            <Typography variant="subtitle1" color="info.contrastText" pl={0.5} fontWeight={700}>
              Have a survey goal in mind? Try generating a survey with A.I.{' '}
            </Typography>
          </Box>
          <Typography variant="subtitle1" className={classes.tryNewText} onClick={() => createSurveyDraft('survey')}>
            Try new &rarr;
          </Typography>
        </div>

        <Box display={'flex'} alignItems="center" mb={5}>
          {templateChips.map(({ title, value }, idx) => (
            <Typography
              variant={'subtitle1'}
              key={idx}
              className={collectionType === value ? classes.activeCollectionChip : classes.collectionChip}
              onClick={() => setCollectionType(value)}
            >
              {title}
            </Typography>
          ))}
        </Box>

        {collectionType !== 'concept' && (
          <div>
            <Typography mb={2.5}>Most Popular</Typography>
            <Grid container spacing={2.3} alignItems="stretch">
              {continuousTemplates?.templates.map(({ template_img, template_name, description, template_id }, idx) => (
                <Grid
                  item
                  lg={4}
                  key={idx}
                  onClick={() => {
                    handleAmplitudeTemplate(template_name);
                    createSurveyFromTemplate(template_id);
                  }}
                >
                  <Paper className={classes.continuousPaper}>
                    <Box display={'flex'} alignItems={'center'}>
                      <img
                        src={`https://cdn.blitzllama.com/survey/theme-light/templates/${template_img}`}
                        alt=""
                        className={classes.templateImg}
                      />

                      <Box pl={2}>
                        <Typography className={classes.collectionTitle} style={{ fontSize: '1.1rem' }}>
                          {template_name}
                        </Typography>
                        <Typography variant={'subtitle2'} className={classes.continuousDescription}>
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <Typography mt={7.5} mb={2.5}>
              Survey templates
            </Typography>

            <Grid container spacing={2.3} alignItems="stretch">
              {collections
                .filter(c => c.order < 200)
                .map(({ category_img, category_name, category_tag, category_description, template_count }, idx) => (
                  <Grid
                    item
                    lg={4}
                    key={idx}
                    onClick={() => {
                      handleAmplitudeClick(category_name);
                      history.push(`/templates/` + category_tag);
                    }}
                  >
                    <Paper className={classes.collectionPaper}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <img
                          src={`https://cdn.blitzllama.com/survey/theme-light/${category_img}`}
                          alt=""
                          className={classes.templateImg}
                        />

                        <Box display={'flex'} alignItems={'center'}>
                          <img src={templateCountImg} alt="" />
                          <Typography variant={'subtitle1'} pl={0.5} color={'primary.contrastText'}>
                            {template_count} templates
                          </Typography>
                        </Box>
                      </Box>
                      <Typography className={classes.collectionTitle} pt={2.3}>
                        {category_name}
                      </Typography>
                      <Typography variant={'subtitle1'} pt={1.87} className={classes.collectionText}>
                        {category_description}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </div>
        )}

        {collectionType !== 'survey' && (
          <div>
            <Typography mt={7.5} mb={2.5}>
              Concept testing templates
            </Typography>

            <Grid container spacing={2.3} alignItems="stretch">
              {collections
                .filter(c => c.order > 200)
                .map(({ category_img, category_name, category_tag, category_description, template_count }, idx) => (
                  <Grid
                    item
                    lg={4}
                    key={idx}
                    onClick={() => {
                      handleAmplitudeClick(category_name);
                      history.push(`/templates/` + category_tag);
                    }}
                  >
                    <Paper className={classes.collectionPaper}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <img
                          src={`https://cdn.blitzllama.com/survey/theme-light/${category_img}`}
                          alt=""
                          className={classes.templateImg}
                        />

                        <Box display={'flex'} alignItems={'center'}>
                          <img src={templateCountImg} alt="" />
                          <Typography variant={'subtitle1'} pl={0.5} color={'primary.contrastText'}>
                            {template_count} templates
                          </Typography>
                        </Box>
                      </Box>
                      <Typography className={classes.collectionTitle} pt={2.3}>
                        {category_name}
                      </Typography>
                      <Typography variant={'subtitle1'} pt={1.87} className={classes.collectionText}>
                        {category_description}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
