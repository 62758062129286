import { postCall } from '../apiCall';
import { IMAGE_UPLOAD } from './apiEndPoints';

export const imageUpload = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(IMAGE_UPLOAD(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
