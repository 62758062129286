import React, { FunctionComponent, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { chartDataOptions, getChartDataSets } from '../../../utils/graphUtil';

interface IChartType {
  x_axis: any;
  data_groups: any;
  question_type: string;
  chartHeight?: number;
  is_dashboard?: boolean;
}

export const DailyChartComponent: FunctionComponent<IChartType> = ({
  x_axis,
  data_groups,
  question_type,
  chartHeight = 80,
  is_dashboard = false,
}) => {
  /**
   * chart Data tranformer
   * @param question_id question_id string
   * @returns formatted chart response
   */
  const getChartData = () => {
    const defaultChartData: any = { labels: [], datasets: [] };
    if (x_axis && data_groups) {
      if (x_axis && data_groups) {
        defaultChartData.labels = x_axis;
        const currDataSets: Array<any> = [];
        let hiddenCount = 0;
        let labelCount = 0;
        const counts = {};

        for (const i in data_groups) {
          // eslint-disable-next-line prefer-const
          let { label, values } = data_groups[i];
          const datasets = getChartDataSets(+i, question_type === 'nps_feedback');
          let hidden = hiddenCount >= 10 && question_type === 'input_feedback' ? true : false;
          if (!hidden) {
            hiddenCount++;
          }

          if (label === 'untagged' && data_groups.length < 4) {
            hidden = false;
          }

          if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
            if (label.includes('____')) {
              const option = label.split('____')[0];
              label = option;
            }

            counts[label] = counts[label] ? counts[label] + 1 : 1;
            if (counts[label] > 1) {
              label = `${label} (#${counts[label]})`;
            }
          }

          const chartItem = { ...datasets, label, data: values, hidden };

          if (!is_dashboard || (is_dashboard && labelCount < 10)) currDataSets.push(chartItem);
          labelCount += 1;
        }
        defaultChartData.datasets = currDataSets;
      }
    }
    return defaultChartData;
  };

  return (
    <Line
      height={chartHeight}
      data={getChartData()}
      options={{
        ...chartDataOptions(is_dashboard),
      }}
    />
  );
};
