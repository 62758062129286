import React from 'react';
import { ReactComponent as ChartImg } from '../../images/spotlight/chart-icon.svg';
import { ReactComponent as TimeChartImg } from '../../images/spotlight/time-chart-icon.svg';
import { ReactComponent as BarChartImg } from '../../images/spotlight/bar-chart-icon.svg';
import { ReactComponent as GroupByChartImg } from '../../images/spotlight/split-view-icon.svg';

export const categoryQuery = [
  {
    label: 'Potential issues & bugs',
    value: 'bug',
  },
  {
    label: 'User requests & suggestions',
    value: 'feature',
  },
];

export const sentimentQuery = [
  {
    label: 'Positive',
    value: 'positive',
  },
  {
    label: 'Negative',
    value: 'negative',
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
];

export const suggestionQuery = [
  {
    label: 'Take action',
    value: 'take_action',
  },
  {
    label: 'No action',
    value: 'no_action',
  },
  {
    label: 'Monitor',
    value: 'monitor',
  },
];

export const chartTypeData = [
  {
    icon: <ChartImg />,
    title: 'List',
    value: 'list',
  },
];

export const comparatorRules = [
  {
    label: '=',
    value: 'eq',
  },
  {
    label: '!=',
    value: 'neq',
  },
  {
    label: '<',
    value: 'lt',
  },
  {
    label: '<=',
    value: 'lte',
  },
  {
    label: '>',
    value: 'gt',
  },
  {
    label: '>=',
    value: 'gte',
  },
];

export const calenderData = [
  {
    title: '1D',
    value: '1',
    type: 'days',
  },
  {
    title: '7D',
    value: '7',
    type: 'days',
  },
  {
    title: '30D',
    value: '30',
    type: 'days',
  },
  {
    title: '60D',
    value: '60',
    type: 'days',
  },
  {
    title: '90D',
    value: '90',
    type: 'days',
  },
];

export const questionTypeConfig = {
  default: {
    chips: [],
    charts: [{ label: 'Time chart', value: 'daily_chart' }],
  },
  nps_feedback: {
    chips: ['condition'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'Bar chart', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
  scale_feedback: {
    chips: ['condition'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'Bar chart', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
  input_feedback: {
    chips: ['category', 'sentiment', 'suggestion', 'theme'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'List', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
  data_collection: {
    chips: [],
    charts: [{ label: 'Time chart', value: 'daily_chart' }],
  },
  star_feedback: {
    chips: ['condition'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'Bar chart', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
  emoji_feedback: {
    chips: ['condition'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'Bar chart', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
  intro_prompt: {
    chips: [],
    charts: [{ label: 'Time chart', value: 'daily_chart' }],
  },
  multi_select_feedback: {
    chips: ['option'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'Bar chart', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
  single_select_feedback: {
    chips: ['option'],
    charts: [
      { label: 'Time chart', value: 'daily_chart' },
      { label: 'Bar chart', value: 'cumulated' },
      { label: 'Group by question', value: 'split_view' },
    ],
  },
};

export const rightSideChips = ['trigger'];

export const spotlightInitialData = {
  surveys: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  question_type: '',
  questions: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  triggers: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  scale: {
    data: -1,
    rule_type: 'eq',
  },
  options: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  date_range: {
    type: 'days',
    days_count: 30,
    time_from: '',
    time_to: '',
  },
  categorys: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  sentiments: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  suggestions: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  cohorts: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  attributes: [],
};

export const spotlightFetchData = {
  survey_store: [],
  question_store: [],
  trigger_store: [],
  cohort_store: [],
  option_store: [],
  theme_store: [],
  category_store: [],
  sentiment_store: [],
  suggestion_store: [],
};

export const chartTypesIcon = {
  daily_chart: <TimeChartImg />,
  cumulated: <BarChartImg />,
  split_view: <GroupByChartImg />,
};
