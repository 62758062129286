export const envStatus = {
  production: {
    title: 'Production',
    backgroundColor: '#E2EAFF',
    color: '#201CE0',
  },
  staging: {
    title: 'Staging',
    backgroundColor: '#FFDEE2',
    color: '#AF4155',
  },
};

export const npsBackgroundColors = [
  '#F36A83',
  '#F36A83',
  '#F36A83',
  '#F36A83',
  '#F36A83',
  '#F36A83',
  '#F36A83',
  '#FFCC4A',
  '#FFCC4A',
  '#54DFAD',
  '#54DFAD',
];

export const chartColorPallete = [
  '#7856ff',
  '#ff7557',
  '#80e1d8',
  '#f9bb3b',
  '#b2596d',
  '#72bef4',
  '#ffb27a',
  '#0f7ea0',
  '#3baa73',
  '#ffbbb1',
  '#cb80dc',
  '#5cb7af',
];

export const npsChartColorPallete = ['#F36A83', '#FFCC4A', '#54DFAD'];

export const boardLabelColors = ['#F09745', '#4556F0', '#53B232', '#F2C349'];

export const sentimentTheme = [
  {
    sentiment_value: 'positive',
    backgroundColor: '#E3F9F2',
    color: '#14753B',
  },
  {
    sentiment_value: 'negative',
    backgroundColor: '#FFDEE2',
    color: '#AF4155',
  },
  {
    sentiment_value: 'neutral',
    backgroundColor: '#DAECFD',
    color: '#345F9E',
  },
];

export const getSentimentTheme = (sentiment: string) => {
  const found = sentimentTheme.find(element => element.sentiment_value === sentiment);
  if (!found) return {};
  const { backgroundColor, color } = found;
  return { backgroundColor, color };
};
