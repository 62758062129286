import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, Box, Typography, Dialog } from '@mui/material';

import deleteImg from '../../images/delete-icon.svg';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import { IMember } from '../../types/member';
import { fetchAllMembers } from '../../services/organisationMemberService';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
  }),
);

export const AdminListModal: FunctionComponent<{
  open: boolean;
  toggleModal: (value: boolean) => void;
}> = ({ open, toggleModal }) => {
  const classes = useStyles();
  const [teamMembers, setTeamMembers] = useState<Array<IMember>>([]);

  const handleClose = () => {
    toggleModal(false);
  };

  const fetchTeamMembers = async () => {
    const members = await fetchAllMembers();
    if (members) {
      setTeamMembers(members);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const admins = teamMembers.filter(t => t.member_role === 'admin');

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.modalContainer}>
          <Grid item lg={12}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant={'h6'} fontWeight={700}>
                Only admins can manage billing settings
              </Typography>
              <div>
                <img src={deleteImg} alt="" style={{ height: 28, cursor: 'pointer' }} onClick={handleClose} />
              </div>
            </Box>

            <Typography variant="subtitle1" py={2}>
              Please contact the admins listed below to manage your team’s billing
            </Typography>

            {admins.map(({ member_email }, idx) => (
              <Typography variant="subtitle1" key={idx}>
                {member_email} {admins.length > 1 && ','}
              </Typography>
            ))}

            <Typography variant="subtitle1" color="text.secondary" mt={3}>
              Chat with Support for additional queries
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
