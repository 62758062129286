import React, { useEffect, useState, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  Checkbox,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  SurveyAnonymousAccordion,
  SurveyBackgroundAccordion,
  SurveyDismissAccordion,
  SurveyResponsesAccordion,
  SurveyRetakeAccordion,
} from './AccordionItems';

import { ITriggerItem, ITriggerType } from '../../types/trigger';
import { IAttributeItem, ICohort, ICondition } from '../../types/audience';
import { ISurvey } from '../../types/survey';
import { fetchTriggerType } from '../../services/triggerService';
import {
  fetchAllCohort,
  fetchAllAttributes,
  fetchAllAttributeValues,
  filterCohortByName,
} from '../../services/audienceService';

import Autocomplete from '@mui/lab/Autocomplete';
import { useStyles } from './EditorStyle';

import { platforms, platformIcon, userCohortConditions } from '../../static/staticData';
import AlertUtil from '../../utils/AlertUtil';
import { getOperatorLabel, getOperatorsForDatatype } from '../../utils/AttributeUtil';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import deleteImg from '../../images/editor/delete-icon.svg';
import eventDeleteIcon from '../../images/editor/event-delete-icon.svg';
import conditionDeleteImg from '../../images/editor/condition-delete-icon.svg';
import cohortImg from '../../images/editor/cohort-icon.svg';
import triggerImg from '../../images/editor/trigger-icon.svg';
import warningImg from '../../images/warning-icon.svg';
import { ReactComponent as BackendTriggerImg } from '../../images/editor/backend-trigger.svg';
import { ReactComponent as SearchIcon } from '../../images/audience/search-icon.svg';

import { modifiedDate } from '../../utils/DateUtil';
import { getAttributeLabel, numberWithCommas } from '../../utils/stringUtil';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';
import { cohortChannels } from '../../static/connectionData';
import { comparatorRules } from '../spotlight/constants';
import SurveySeqModal from './SurveySeqModal';
import { getSurveyCohortCount } from '../../services/surveyService';

const triggerEnabledPlatform = ['android', 'ios', 'web', 'hybrid', 'flutter'];

type ConfigureProps = {
  survey: ISurvey;
  survey_id: string;
  updateChanges: (survey: ISurvey) => void;
  fetchSurvey: () => void;
  can_edit: boolean;
  can_save_changes: boolean;
  errors: string;
};

type IPrioritize = { show: boolean; trigger_name: string };

const ConfigureComponent: FunctionComponent<ConfigureProps> = ({ survey, updateChanges, can_save_changes, errors }) => {
  const classes = useStyles();

  const [attributeValues, setAttributeValues] = useState({});

  const [triggersList, setTriggersList] = useState<Array<ITriggerType>>([]);
  const [attributeList, setAttributes] = useState<Array<IAttributeItem>>([]);
  const [cohortList, setCohortList] = useState<Array<ICohort>>([]);
  const [searchString, setSearchString] = useState('');
  const [userSize, setUserSize] = useState(0);
  const [prioritizeModal, setPrioritizeModal] = React.useState<IPrioritize>({ show: false, trigger_name: '' });

  const [expanded, setExpanded] = React.useState<string | false>();

  const mandatory_attributes = ['user_id', 'created_at', 'total_days_on_app'];

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'editor config clicked', {
        accordion: panel,
        'survey id': survey.survey_id,
        'survey name': survey.survey_name,
      });
    }
  };

  const fetchTriggers = async () => {
    const triggerData = await fetchTriggerType();
    if (triggerData && triggerData.length > 0) {
      setTriggersList(triggerData);
    }
  };

  const fetchAttributeList = async () => {
    const results = await fetchAllAttributes();
    if (results) {
      setAttributes(results);
      const filtered_attributes = results.filter(h => {
        if (!mandatory_attributes.includes(h.name)) return h;
      });
      setAttributes(filtered_attributes);
    }
  };

  const fetchCohorts = async () => {
    const cohorts = await fetchAllCohort();
    if (cohorts) {
      setCohortList(cohorts);
    }
  };

  const getSurveySize = async () => {
    if (survey) {
      if (survey.user_cohort) {
        const cohort_item = cohortList.find(c => c.cohort_id === survey.user_cohort);
        if (cohort_item) {
          setUserSize(cohort_item.total_count);
        }
      } else {
        const count = await getSurveyCohortCount({ condition: survey?.condition, cohort_id: survey?.user_cohort });
        if (count && count.data) {
          setUserSize(count.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchTriggers();
    fetchAttributeList();
    fetchCohorts();
    setAttributeValues(survey?.attribute_values);
  }, []);

  useEffect(() => {
    getSurveySize();
  }, [survey?.condition, survey?.user_cohort]);

  if (!survey) {
    return <></>;
  }

  const {
    survey_name,
    trigger_with_events,
    platform,
    survey_repeat_frequency,
    condition,
    user_cohort,
    status,
    research_type,
    is_backend_trigger,
    backend_triggers,
    trigger_delay,
    user_cohort_condition,
  } = survey;

  const handleConfigurationSet = (accordion: string) => {
    if (accordion === 'trigger' && trigger_with_events.length === 0)
      return <ErrorOutlineTwoToneIcon color="error" style={{ width: 24, height: 24 }} />;
    if (accordion === 'resurvey' && survey_repeat_frequency === '')
      return <ErrorOutlineTwoToneIcon color="error" style={{ width: 24, height: 24 }} />;
    return <CheckCircleOutlineTwoToneIcon color="success" style={{ width: 24, height: 24 }} />;
  };

  const editSurveyDetails = async (fieldName: string, fieldValue: string | boolean) => {
    if (!survey) {
      return null;
    }

    const { status } = survey;

    if (status === 'archived' || status === 'completed') {
      return;
    }

    const stringTypeFields = [
      'survey_name',
      'survey_end_date',
      'platform',
      'survey_repeat_frequency',
      'user_cohort',
      'background_image',
      'all_question_logo',
      'user_cohort_condition',
    ];

    const numTypeFields = [
      'survey_response_limit',
      'trigger_delay',
      'survey_retake_days',
      'survey_repeat_days',
      'survey_daily_limit',
      'survey_views_limit',
      'survey_repeat_loop',
    ];

    const booleanTypeFields = [
      'is_continuous',
      'is_backend_trigger',
      'is_dismissible',
      'allow_anonymous',
      'enable_advanced_resurvey_options',
      'enable_advanced_survey_views',
    ];

    if (fieldName === 'platform') {
      if (fieldValue === 'link') {
        updateChanges({ ...survey, [fieldName]: fieldValue.toString(), trigger_with_events: [] });
      } else {
        updateChanges({
          ...survey,
          [fieldName]: fieldValue.toString(),
          trigger_with_events: [],
        });
      }
    } else if (fieldValue !== null && booleanTypeFields.includes(fieldName)) {
      updateChanges({ ...survey, [fieldName]: Boolean(fieldValue) });
    } else if (fieldValue !== null && stringTypeFields.includes(fieldName)) {
      updateChanges({ ...survey, [fieldName]: fieldValue });
    } else if (fieldValue !== null && numTypeFields.includes(fieldName)) {
      const int_val = fieldValue ? +fieldValue : 0;
      updateChanges({ ...survey, [fieldName]: int_val });
    }
  };

  let filteredCohortList: Array<ICohort> = cohortList;

  if (searchString.length > 0) {
    filteredCohortList = cohortList.filter(
      c => c.cohort_name && c.cohort_name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }

  // handle cohort conditions
  const editSurveyConditionDetails = async (fieldName: string, fieldValue: ICondition[]) => {
    if (!survey) {
      return null;
    }

    if (fieldValue !== null) {
      updateChanges({ ...survey, [fieldName]: [...fieldValue] });
    }
  };

  const handleAttributeSelect = async (attribute: string, idx: number) => {
    if (!survey) {
      return;
    }
    const { condition } = survey;

    condition[+idx].attribute = attribute;
    condition[+idx].comparator = '';
    condition[+idx].value = '';
    condition[+idx].list = [];
    const attributeItem = attributeList.find(a => a.name === attribute);
    condition[+idx].data_type = attributeItem ? attributeItem.data_type : '';

    if (attribute === 'created_at') {
      condition[+idx].data_type = 'date';
    }
    if (attribute === 'total_days_on_app') {
      condition[+idx].data_type = 'number';
    }
    if (attribute === 'user_id') {
      condition[+idx].data_type = 'string';
    }

    if (attribute) {
      if (attributeItem && attributeItem.data_type === 'boolean') {
        attributeValues[attribute] = ['true', 'false'];
        setAttributeValues(attributeValues);
      } else if (!attributeValues[attribute]) {
        const attributeValue = await fetchAllAttributeValues(attribute);
        if (attributeValue) {
          attributeValues[attribute] = attributeValue;
        } else {
          attributeValues[attribute] = [];
        }
        setAttributeValues(attributeValues);
      }
    }

    editSurveyConditionDetails('condition', condition);
  };

  const handleAttributeOperator = (comparator: string, idx: number) => {
    if (!survey) {
      return;
    }
    const { condition } = survey;

    condition[+idx].comparator = comparator;
    condition[+idx].value = '';
    editSurveyConditionDetails('condition', condition);
  };

  const handleAttributeValue = (attribute_values: any, data_type: string, idx: number) => {
    if (!survey) {
      return;
    }
    const { condition } = survey;

    if (data_type === 'string') {
      condition[+idx].list = attribute_values;
    } else {
      condition[+idx].value = attribute_values;
    }
    editSurveyConditionDetails('condition', condition);
  };

  const handleSearchTerm = async (attribute: string, txt: string) => {
    if (txt.length > 2) {
      const attribute_names = await filterCohortByName('in-survey', attribute, txt, 0);
      if (attribute_names) {
        const uniq_attributes = _.union([...attributeValues[attribute], ...attribute_names]);
        attributeValues[attribute] = uniq_attributes;
        setAttributeValues({ ...attributeValues });
      }
    }
  };

  const addNewCondition = () => {
    if (!survey) {
      return;
    }
    const { condition } = survey;
    const condition_count = condition.length;
    if (condition_count >= 0 && condition_count < 5) {
      condition[condition_count] = {
        attribute: '',
        comparator: '',
        value: '',
        data_type: '',
        list: [],
      };
      editSurveyConditionDetails('condition', condition);
    } else {
      AlertUtil.fire({
        icon: 'error',
        title: 'More than 5 filters cannot be added',
      });
      return;
    }
  };

  const deleteCondition = (idx: number) => {
    if (!survey) {
      return;
    }
    const { condition } = survey;

    condition.splice(idx, 1);
    editSurveyConditionDetails('condition', condition);
  };

  let change_platform = false;

  if (status === 'created' || status === 'draft') {
    change_platform = true;
  }

  const getCohortName = (cohort_id: string) => {
    if (!cohort_id) return '';
    const cohort = filteredCohortList.find(a => a.cohort_id === cohort_id);
    return cohort ? cohort.cohort_name : '';
  };

  const getValuesForAttribute = (attribute: string, data_type: string): Array<string> => {
    if (!attribute || !attributeValues[attribute]) return [];

    if (data_type === 'date') {
      const dateAttributeValues = attributeValues[attribute].map(d => {
        if (d.includes('00:00:00')) {
          return modifiedDate(d, 'YYYY-MM-DD');
        } else {
          return modifiedDate(d, 'YYYY-MM-DDThh:mm');
        }
      });
      return dateAttributeValues;
    }
    return attributeValues[attribute] ? attributeValues[attribute] : [];
  };

  // handle triggers and events
  const activeTriggerList = triggersList.filter(
    t =>
      (platform === 'android' && t.is_active && t.trigger_type === 'android') ||
      (platform === 'ios' && t.is_active && t.trigger_type === 'ios') ||
      (platform === 'web' && t.is_active && t.trigger_type === 'web') ||
      (platform === 'hybrid' && t.is_active && t.trigger_type === 'hybrid') ||
      (platform === 'flutter' && t.is_active && t.trigger_type === 'flutter'),
  );

  const backendTriggerList = triggersList.filter(t => t.is_active && t.trigger_type === 'backend_event');

  const editTriggerHandler = async (action: string, fieldValue: string, idx: number) => {
    if (!survey) {
      return;
    }

    const { trigger_with_events } = survey;

    if (action === 'add') {
      if (trigger_with_events.some(t => t.trigger_name === fieldValue)) {
        let trigger_list: Array<ITriggerItem> = [];
        trigger_list = trigger_with_events?.filter(o => o.trigger_name !== fieldValue);
        updateChanges({ ...survey, trigger_with_events: trigger_list });
      } else {
        if (trigger_with_events.length >= 0 && trigger_with_events.length < 10) {
          trigger_with_events.push({
            trigger_name: fieldValue,
            trigger_platform: platform,
            events: [],
            occurrences: {
              comparator: '',
              count: 0,
              days: 0,
            },
          });
        } else {
          AlertUtil.fire({
            icon: 'error',
            title: 'More than 10 triggers cannot be added',
          });
          return;
        }
        updateChanges({ ...survey, trigger_with_events });
      }
    } else if (action === 'delete') {
      trigger_with_events.splice(idx, 1);
      updateChanges({ ...survey, trigger_with_events });
    }
  };

  const handleBackendTriggers = async (fieldName: string, fieldValue: string | number, idx: number) => {
    if (!survey) {
      return;
    }
    const { backend_triggers } = survey;

    backend_triggers[+idx][fieldName] = fieldValue;
    updateChanges({ ...survey, backend_triggers });
  };

  const activateBackendTrigger = () => {
    backend_triggers[0] = {
      event_name: '',
      expires_at: 12,
    };
    updateChanges({ ...survey, backend_triggers, is_backend_trigger: !is_backend_trigger });
  };

  const isTriggerPresent = (trigger_name: string) => {
    return trigger_with_events.some(t => t.trigger_name === trigger_name);
  };

  const addNewEventFilter = (t_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }

    const { events } = trigger_with_events[t_idx];
    const events_count = events.length;
    if (events_count >= 0 && events_count < 5) {
      events[events_count] = {
        attribute: '',
        comparator: '',
        value: '',
        data_type: '',
        list: [],
      };
      trigger_with_events[t_idx].events = events;
      updateChanges({ ...survey, trigger_with_events });
    } else {
      AlertUtil.fire({
        icon: 'error',
        title: 'More than 5 event filters cannot be added',
      });
      return;
    }
  };

  const deleteEventFilter = (t_idx: number, e_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }

    const { events } = trigger_with_events[t_idx];

    events.splice(e_idx, 1);
    trigger_with_events[t_idx].events = events;
    updateChanges({ ...survey, trigger_with_events });
  };

  const getTriggerAttribute = (trigger_name: string) => {
    const trigger_item = activeTriggerList.find(a => a.trigger_name === trigger_name);
    return trigger_item && trigger_item.properties ? trigger_item.properties : [];
  };

  const handleEventAttributeSelect = async (attribute: string, t_idx: number, e_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }
    const { trigger_name, events } = trigger_with_events[t_idx];
    const attribute_item = getTriggerAttribute(trigger_name).find(t => t.property_name === attribute);

    if (!attribute_item) {
      return;
    }

    const { data_type } = attribute_item;

    events[+e_idx].attribute = attribute;
    events[+e_idx].data_type = data_type;
    events[+e_idx].comparator = '';
    events[+e_idx].value = '';
    events[+e_idx].list = [];

    trigger_with_events[t_idx].events = events;
    updateChanges({ ...survey, trigger_with_events });
  };

  const handleEventAttributeOperator = (comparator: string, t_idx: number, e_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }

    const { events } = trigger_with_events[t_idx];

    events[+e_idx].comparator = comparator;
    events[+e_idx].value = '';

    trigger_with_events[t_idx].events = events;
    updateChanges({ ...survey, trigger_with_events });
  };

  const handleEventAttributeValue = (attribute_values: any, data_type: string, t_idx: number, e_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }

    const { events } = trigger_with_events[t_idx];

    if (data_type === 'string') {
      events[+e_idx].list = _.isEmpty(attribute_values) ? [] : attribute_values.split(',');
    } else {
      events[+e_idx].value = attribute_values;
    }

    trigger_with_events[t_idx].events = events;
    updateChanges({ ...survey, trigger_with_events });
  };

  // trigger_with_event occurances
  const addEventOccurance = (t_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }

    const { occurrences } = trigger_with_events[+t_idx];

    trigger_with_events[+t_idx].occurrences = { ...occurrences, comparator: 'eq' };
    updateChanges({ ...survey, trigger_with_events });
  };

  const handleEventOccuranceValues = (fieldName: string, fieldValue: string | number, t_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }

    const { occurrences } = trigger_with_events[+t_idx];

    occurrences[fieldName] = fieldValue;
    trigger_with_events[+t_idx].occurrences = { ...occurrences };
    updateChanges({ ...survey, trigger_with_events });
  };

  const deleteEventOccurance = (t_idx: number) => {
    if (!trigger_with_events[t_idx]) {
      return;
    }
    trigger_with_events[+t_idx].occurrences = { comparator: '', count: 0, days: 0 };
    updateChanges({ ...survey, trigger_with_events });
  };

  const getActiveCount = (trigger_name: string) => {
    const item = activeTriggerList.find(t => t.trigger_name === trigger_name);
    return item ? item.active_count : 0;
  };

  return (
    <Grid item lg={6}>
      {prioritizeModal.show && prioritizeModal.trigger_name && (
        <SurveySeqModal
          open={prioritizeModal.show}
          toggleModal={() => {
            setPrioritizeModal({ show: false, trigger_name: '' });
          }}
          trigger_name={prioritizeModal.trigger_name}
          survey={survey}
        />
      )}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Enter survey name"
        autoComplete="off"
        value={survey_name}
        name="survey_name"
        className={classes.surveyNameInput}
        onChange={e => editSurveyDetails(e.target.name, e.target.value)}
      />
      {errors && (
        <Box className={classes.configureWarningBox} p={2} display="flex" alignItems="center">
          <img src={warningImg} alt="" />
          <Typography style={{ marginLeft: 5 }}>{errors}</Typography>
        </Box>
      )}

      <div>
        <Typography variant="subtitle2" pt={2} color="text.secondary">
          Choose platform
        </Typography>
        <Box display="flex" mt={1} mb={3.2} flexWrap={'wrap'}>
          {platforms.map(
            ({ value, name, sublabel, quiz_enabled }, idx) =>
              (change_platform || platform === value) &&
              (research_type === 'survey' || quiz_enabled) && (
                <Box
                  mr={1}
                  my={0.5}
                  key={idx}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ flexDirection: 'column', height: 45, width: 121, cursor: 'pointer' }}
                  className={platform === value ? classes.activePlatform : classes.platform}
                  onClick={
                    (research_type === 'survey' || quiz_enabled) && can_save_changes
                      ? () => {
                          editSurveyDetails('platform', value);
                        }
                      : undefined
                  }
                >
                  <Box display="flex" alignItems={'center'}>
                    <div className={platform === value ? classes.activeTab : ''} style={{ paddingTop: 6 }}>
                      {platformIcon[value]}
                    </div>
                    <Typography variant={'h6'} pl={0.6}>
                      {name}
                    </Typography>
                  </Box>
                  {sublabel && (
                    <Typography align="center" className={classes.platformSubLabel} color={'text.secondary'}>
                      {sublabel}
                    </Typography>
                  )}
                </Box>
              ),
          )}
        </Box>
      </div>

      {triggerEnabledPlatform.includes(platform) && (
        <>
          <Accordion
            expanded={expanded === 'cohort'}
            onChange={handleChange('cohort')}
            className={classes.configureAccordion}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
              <Box className={classes.accordionSummary}>
                <Box display="flex">
                  <img src={cohortImg} alt="" />
                  <div style={{ paddingLeft: '0.87rem' }}>
                    <Typography variant={'h6'}>Select cohort</Typography>
                    {expanded !== 'cohort' && (
                      <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                        Users {user_cohort_condition === 'in_list' ? 'in ' : 'not in '}
                        {user_cohort ? `${getCohortName(user_cohort)}` : 'All users'}
                        {condition.length > 0 && `, ${condition.length} conditions added`}
                        {+userSize > 0 && `, Total users: ${numberWithCommas(userSize)}`}
                      </Typography>
                    )}
                    {expanded === 'cohort' && (
                      <Typography variant="subtitle2" color="text.secondary">
                        Choose which users should see this survey
                      </Typography>
                    )}
                  </div>
                </Box>
                {handleConfigurationSet('cohort')}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={'flex'} alignItems={'center'}>
                <Typography>Users</Typography>
                <Select
                  variant="outlined"
                  className={classes.configureSelectField}
                  style={{ marginInline: '0.5rem' }}
                  value={user_cohort_condition}
                  name="user_cohort_condition"
                  onChange={e => editSurveyDetails(e.target.name as string, e.target.value as string)}
                >
                  {userCohortConditions.map(({ label, value }, idx) => (
                    <MenuItem value={value} key={idx}>
                      <Typography variant={'subtitle1'}>{label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  variant="outlined"
                  displayEmpty
                  className={classes.configureSelectField}
                  style={{ flexGrow: 1 }}
                  MenuProps={{ autoFocus: false, classes: { paper: classes.cohortMenuPaper } }}
                  renderValue={() => (user_cohort ? getCohortName(user_cohort) : <Typography>All users</Typography>)}
                  value={user_cohort}
                  name="user_cohort"
                  onChange={e => editSurveyDetails(e.target.name as string, e.target.value as string)}
                >
                  <MenuItem disabled>
                    <Typography variant={'subtitle2'}>Audiences</Typography>
                  </MenuItem>

                  <div className={classes.searchBox}>
                    <TextField
                      autoFocus
                      fullWidth
                      placeholder="Search cohort name..."
                      value={searchString}
                      className={classes.searchTextField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => {
                        setSearchString(e.target.value);
                      }}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>

                  {!(searchString.length > 0) && <MenuItem value="">All users</MenuItem>}

                  {filteredCohortList.map(({ cohort_name, cohort_id, created_by, total_count }, key) => (
                    <MenuItem key={key} value={cohort_id}>
                      <Box className={classes.cohortItemBox}>
                        <Typography>{cohort_name}</Typography>
                        <div style={{ display: 'flex' }}>
                          {+total_count > 0 && (
                            <Typography style={{ fontSize: 12, color: 'gray', marginTop: 6 }}>
                              {numberWithCommas(total_count)} users
                            </Typography>
                          )}
                          {cohortChannels[created_by].img ? (
                            <img
                              src={cohortChannels[created_by].img}
                              alt=""
                              className={classes.cohortImg}
                              style={{ marginLeft: 10 }}
                            />
                          ) : (
                            <div style={{ width: '40px' }}></div>
                          )}
                        </div>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
                {user_cohort && (
                  <img
                    src={deleteImg}
                    alt=""
                    style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer' }}
                    onClick={e => editSurveyDetails('user_cohort', '')}
                  />
                )}
              </Box>

              {condition.map(({ attribute, comparator, data_type, value, list }, idx) => (
                <Box display="flex" alignItems="flex-start" mt={1.4}>
                  <Box display={'flex'} alignItems={'center'} width={'60%'}>
                    <Typography variant={'subtitle1'} pr={1}>
                      AND
                    </Typography>
                    <Select
                      variant="outlined"
                      displayEmpty
                      className={classes.configureSelectField}
                      renderValue={() => (
                        <Typography variant={'subtitle1'}>
                          {attribute ? getAttributeLabel(attribute) : 'Select Attribute'}
                        </Typography>
                      )}
                      value={attribute}
                      onChange={e => handleAttributeSelect(e.target.value as string, idx)}
                      style={{ width: '40%' }}
                    >
                      <MenuItem disabled>
                        <Typography variant={'subtitle2'}>Default Attributes</Typography>
                      </MenuItem>
                      <MenuItem value={'user_id'}>
                        <Typography style={{ marginLeft: 10 }}>User Id</Typography>
                      </MenuItem>
                      <MenuItem value={'created_at'}>
                        <Typography style={{ marginLeft: 10 }}>Created at</Typography>
                      </MenuItem>
                      <MenuItem value={'total_days_on_app'}>
                        <Typography style={{ marginLeft: 10 }}>Days since creation</Typography>
                      </MenuItem>
                      <MenuItem disabled>
                        <Typography variant={'subtitle2'}>User Attributes</Typography>
                      </MenuItem>
                      {attributeList.map(({ name }, key) => (
                        <MenuItem key={key} value={name}>
                          <Typography style={{ marginLeft: 10 }}>{name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant={'subtitle1'} px={1}>
                      is
                    </Typography>
                    <Select
                      variant="outlined"
                      displayEmpty
                      className={classes.configureSelectField}
                      renderValue={() => (
                        <Typography variant={'subtitle1'}>
                          {comparator ? getOperatorLabel(comparator, data_type) : 'Select operator'}
                        </Typography>
                      )}
                      value={comparator}
                      onChange={e => handleAttributeOperator(e.target.value as string, idx)}
                      style={{ width: '50%' }}
                    >
                      <MenuItem disabled>
                        <Typography variant={'subtitle1'}>Comparison Operator</Typography>
                      </MenuItem>
                      {getOperatorsForDatatype(data_type).map(({ label, value }, i) => (
                        <MenuItem value={value}>
                          <Typography>{label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box width={'40%'}>
                    {(data_type === '' || data_type === 'string') && (
                      <Autocomplete
                        freeSolo
                        multiple
                        options={getValuesForAttribute(attribute, data_type)}
                        disableCloseOnSelect
                        style={{ marginLeft: 10 }}
                        className={classes.autoTextField}
                        onChange={(event: any, newValue) => {
                          handleAttributeValue(newValue, data_type, idx);
                        }}
                        value={list}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                              checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                              classes={{
                                root: classes.check,
                                checked: classes.checked,
                              }}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            placeholder="Enter attribute values"
                            onChange={e => handleSearchTerm(attribute, e.target.value)}
                          />
                        )}
                        limitTags={2}
                      />
                    )}

                    {data_type === 'boolean' && (
                      <Autocomplete
                        freeSolo
                        options={getValuesForAttribute(attribute, data_type)}
                        renderOption={(props, option) => <li {...props}>{option}</li>}
                        value={value}
                        style={{ marginLeft: 10, width: '70%' }}
                        className={classes.autoTextField}
                        onChange={(event: any, newValue) => {
                          handleAttributeValue(newValue, data_type, idx);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select attribute value"
                            onChange={e => handleSearchTerm(attribute, e.target.value)}
                          />
                        )}
                      />
                    )}

                    {data_type === 'date' && (
                      <TextField
                        fullWidth
                        style={{ marginLeft: 5, marginRight: 5 }}
                        variant="outlined"
                        type="date"
                        className={classes.customTextField}
                        value={value}
                        placeholder="Select date value"
                        autoComplete="off"
                        onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                      />
                    )}

                    {data_type === 'number' && (
                      <TextField
                        fullWidth
                        style={{ marginLeft: 5, marginRight: 5 }}
                        variant="outlined"
                        type="number"
                        className={classes.customTextField}
                        value={value}
                        placeholder="Enter number value"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                      />
                    )}
                  </Box>
                  <img
                    src={deleteImg}
                    alt=""
                    style={{ marginLeft: 10, cursor: 'pointer' }}
                    onClick={e => deleteCondition(idx)}
                  />
                </Box>
              ))}

              <Button variant="outlined" style={{ marginTop: '2rem' }} onClick={() => addNewCondition()}>
                <Typography variant={'subtitle1'} fontWeight={700}>
                  + Add filter
                </Typography>
              </Button>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'event'}
            onChange={handleChange('event')}
            className={classes.configureAccordion}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
              <Box className={classes.accordionSummary}>
                <Box display="flex">
                  <img src={triggerImg} alt="" />
                  <div style={{ paddingLeft: '0.87rem' }}>
                    <Typography variant={'h6'}>Select event</Typography>
                    {expanded !== 'event' && (
                      <div>
                        {trigger_with_events.length > 0 && (
                          <Typography display="inline" variant={'subtitle2'} color={'primary.contrastText'}>
                            {`Events: ` +
                              trigger_with_events.map(
                                ({ trigger_name }, idx) =>
                                  `${trigger_name}${+idx < trigger_with_events.length - 2 ? ', ' : ''}`,
                              )}
                            {is_backend_trigger &&
                              backend_triggers &&
                              backend_triggers[0].event_name &&
                              backend_triggers[0].expires_at &&
                              `${trigger_with_events.length > 0 && ' . '} Backend event: ${
                                backend_triggers[0].event_name
                              } has been completed in the last 
                          ${backend_triggers[0].expires_at} hours ${trigger_delay > 0 ? ' . ' : ''}`}
                            {trigger_delay > 0 ? ` Delay: ${trigger_delay} seconds` : ''}
                          </Typography>
                        )}
                      </div>
                    )}

                    {(expanded === 'trigger' || trigger_with_events.length === 0) && (
                      <Typography variant="subtitle2" color="text.secondary">
                        Choose when your survey shows up for the end user.
                      </Typography>
                    )}
                  </div>
                </Box>
                {handleConfigurationSet('trigger')}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ marginBottom: '24px' }}>
                <Box display="flex" alignItems="center">
                  <Select
                    multiple
                    variant="outlined"
                    displayEmpty
                    fullWidth
                    value={trigger_with_events}
                    className={classes.configureSelectField}
                    renderValue={() => <Typography>Select events</Typography>}
                  >
                    <MenuItem disabled>
                      <Typography color={'text.secondary'} variant={'subtitle1'}>
                        Event Triggers
                      </Typography>
                    </MenuItem>
                    <Box style={{ maxHeight: 240 }}>
                      {activeTriggerList.map(({ trigger_name, active_count }, key) => (
                        <MenuItem
                          key={key}
                          value={trigger_name}
                          onClick={e => editTriggerHandler('add', trigger_name, -1)}
                        >
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                            classes={{
                              root: classes.check,
                              checked: classes.checked,
                            }}
                            checked={isTriggerPresent(trigger_name)}
                          />
                          <Box pl={1}>
                            <Typography variant={'subtitle1'}>{trigger_name}</Typography>
                            {active_count > 0 && (
                              <Typography variant={'subtitle2'} color={'warning.dark'} mt={-0.8}>
                                Currently used in {active_count} active survey(s)
                              </Typography>
                            )}
                          </Box>
                        </MenuItem>
                      ))}
                    </Box>
                  </Select>
                </Box>

                {trigger_with_events.map(({ trigger_name, events, occurrences }, idx) => (
                  <Box className={classes.eventContainer}>
                    <div className={classes.eventHeading}>
                      <Typography variant="subtitle1">{trigger_name}</Typography>
                      <img
                        src={eventDeleteIcon}
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onClick={e => editTriggerHandler('delete', trigger_name, idx)}
                      />
                    </div>
                    {events.length > 0 && (
                      <div className={classes.conditionSection}>
                        {events.map(({ attribute, comparator, data_type, value, list }, e_idx) => (
                          <Box className={classes.eventCoditionItem} key={e_idx}>
                            <Select
                              variant="outlined"
                              displayEmpty
                              className={classes.eventSelectField}
                              value={attribute}
                              renderValue={() => (
                                <Typography variant={'subtitle1'}>
                                  {attribute ? attribute : 'Select Property'}
                                </Typography>
                              )}
                              style={{ width: '30%' }}
                              onChange={e => handleEventAttributeSelect(e.target.value as string, idx, e_idx)}
                            >
                              {getTriggerAttribute(trigger_name).length > 0 ? (
                                getTriggerAttribute(trigger_name).map(({ property_name }) => (
                                  <MenuItem value={property_name}>
                                    <Typography style={{ marginLeft: 10 }}>{property_name}</Typography>
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <Typography variant="subtitle1">No property to show</Typography>
                                </MenuItem>
                              )}
                            </Select>
                            {attribute && (
                              <Select
                                variant="outlined"
                                displayEmpty
                                className={classes.eventSelectField}
                                renderValue={() => (
                                  <Typography variant={'subtitle1'}>
                                    {comparator ? getOperatorLabel(comparator, data_type) : 'Select operator'}
                                  </Typography>
                                )}
                                value={comparator}
                                onChange={e => handleEventAttributeOperator(e.target.value as string, idx, e_idx)}
                                style={{ width: '30%' }}
                              >
                                {getOperatorsForDatatype(data_type).map(({ label, value }, i) => (
                                  <MenuItem value={value}>
                                    <Typography>{label}</Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            {comparator && (
                              <Box width={'30%'}>
                                {(data_type === '' || data_type === 'string') && (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={list.toString()}
                                    autoComplete="off"
                                    placeholder="Event value1, value2, value 3, ..."
                                    className={classes.eventAttributeValueField}
                                    onChange={e => handleEventAttributeValue(e.target.value, data_type, idx, e_idx)}
                                  />
                                )}

                                {data_type === 'boolean' && (
                                  <Select
                                    fullWidth
                                    variant="outlined"
                                    displayEmpty
                                    className={classes.eventSelectField}
                                    value={value}
                                    renderValue={() => (
                                      <Typography variant={'subtitle1'}>
                                        {value ? value : 'Select Property value'}
                                      </Typography>
                                    )}
                                    onChange={e =>
                                      handleEventAttributeValue(e.target.value as string, data_type, idx, e_idx)
                                    }
                                  >
                                    {['true', 'false'].map(item => (
                                      <MenuItem value={item}>
                                        <Typography style={{ marginLeft: 10 }}>{item}</Typography>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}

                                {data_type === 'date' && (
                                  <TextField
                                    fullWidth
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                    variant="outlined"
                                    type="date"
                                    className={classes.eventAttributeValueField}
                                    value={value}
                                    placeholder="Select date value"
                                    autoComplete="off"
                                    onChange={e => handleEventAttributeValue(e.target.value, data_type, idx, e_idx)}
                                  />
                                )}

                                {data_type === 'number' && (
                                  <TextField
                                    fullWidth
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                    variant="outlined"
                                    type="number"
                                    className={classes.eventAttributeValueField}
                                    value={value}
                                    placeholder="Enter number value"
                                    autoComplete="off"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    onChange={e => handleEventAttributeValue(e.target.value, data_type, idx, e_idx)}
                                  />
                                )}
                              </Box>
                            )}

                            <img
                              src={conditionDeleteImg}
                              alt=""
                              className={classes.deleteConditionImg}
                              onClick={() => deleteEventFilter(idx, e_idx)}
                            />
                          </Box>
                        ))}
                      </div>
                    )}

                    {occurrences && occurrences.comparator && (
                      <div className={classes.conditionSection}>
                        <Box display="flex" alignItems={'center'} mb={1}>
                          <Typography variant="subtitle1">who performed &nbsp;</Typography>
                          <Typography variant="subtitle1" maxWidth={'20ch'} noWrap>
                            {trigger_name} &nbsp;
                          </Typography>
                          <Select
                            variant="outlined"
                            displayEmpty
                            className={classes.eventSelectField}
                            value={occurrences.comparator}
                            name="comparator"
                            onChange={e => handleEventOccuranceValues(e.target.name, e.target.value as string, idx)}
                            style={{ width: '10%' }}
                          >
                            {comparatorRules.map(({ label, value }, i) => (
                              <MenuItem value={value}>
                                <Typography>{label}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            style={{ marginLeft: 5, marginRight: 5, background: 'white' }}
                            variant="outlined"
                            type="number"
                            className={classes.daysField}
                            name="count"
                            value={occurrences.count}
                            placeholder="Enter number value"
                            autoComplete="off"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={e => handleEventOccuranceValues(e.target.name, e.target.value, idx)}
                          />
                          <img
                            src={conditionDeleteImg}
                            alt=""
                            className={classes.deleteConditionImg}
                            onClick={() => deleteEventOccurance(idx)}
                          />
                        </Box>
                      </div>
                    )}

                    <div className={classes.addEventCondition}>
                      <Typography
                        variant="subtitle2"
                        className={classes.addFilterText}
                        onClick={() => addNewEventFilter(idx)}
                      >
                        + Filter by
                      </Typography>

                      {occurrences && !occurrences.comparator && (
                        <Typography
                          ml={1}
                          variant="subtitle2"
                          className={classes.addFilterText}
                          onClick={() => addEventOccurance(idx)}
                        >
                          + Performed
                        </Typography>
                      )}
                      {getActiveCount(trigger_name) > 0 && (
                        <Typography
                          ml={'auto'}
                          fontWeight={'bold'}
                          variant="subtitle2"
                          color={'primary.contrastText'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setPrioritizeModal({ show: true, trigger_name })}
                        >
                          Prioritize survey
                        </Typography>
                      )}
                    </div>
                  </Box>
                ))}
              </div>

              <Box
                display={'flex'}
                alignItems="center"
                className={is_backend_trigger ? classes.activatedBackendBox : classes.showBackendBox}
                onClick={() => activateBackendTrigger()}
              >
                <BackendTriggerImg />
                <Typography color={is_backend_trigger ? 'primary.contrastText' : ''} variant={'subtitle1'} pl={0.5}>
                  Activate with Backend event
                </Typography>
              </Box>

              {is_backend_trigger && (
                <>
                  {backend_triggers.map(({ event_name, expires_at }, idx) => (
                    <Box display="flex" key={idx} className={classes.backendEventBox}>
                      <Box
                        py={3}
                        width={'200px'}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ flexDirection: 'column' }}
                      >
                        <Typography
                          className={classes.eventConditionBox}
                          px={1}
                          variant={'subtitle1'}
                          fontWeight={'700'}
                        >
                          Show only if
                        </Typography>
                      </Box>
                      <Box borderLeft={1} borderColor={'divider'}></Box>
                      <Box width={1}>
                        <Box px={4} py={3}>
                          <Box display="flex" alignItems="center">
                            <Typography variant={'subtitle1'}>...backend event</Typography>

                            <Select
                              variant="outlined"
                              displayEmpty
                              className={classes.backendTriggerSelect}
                              renderValue={() => (
                                <Typography variant="subtitle1">{event_name ? event_name : 'Select Event'} </Typography>
                              )}
                              name="event_name"
                              value={event_name}
                              onChange={e => handleBackendTriggers(e.target.name, e.target.value, idx)}
                              style={{ width: 300, marginLeft: 13 }}
                            >
                              {backendTriggerList.map(({ trigger_name, active_count }, idx) => (
                                <MenuItem key={idx} value={trigger_name}>
                                  <div>
                                    <Typography variant={'subtitle1'}>{trigger_name}</Typography>
                                    {active_count > 0 && (
                                      <Typography variant={'subtitle2'} color={'warning.dark'}>
                                        Currently used in {active_count} active survey(s)
                                      </Typography>
                                    )}
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <Box display={'flex'} alignItems={'center'} mt={1.5}>
                            <Typography variant={'subtitle1'}>...has been completed in the last</Typography>
                            <TextField
                              variant="outlined"
                              type="number"
                              value={expires_at}
                              className={classes.daysField}
                              name="expires_at"
                              InputProps={{ inputProps: { min: 1, max: 96 } }}
                              onChange={e => handleBackendTriggers(e.target.name, e.target.value, idx)}
                            />
                            <Typography variant={'subtitle1'}>hours</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </>
              )}

              <Box mt={2} display="flex" alignItems={'center'}>
                <Typography>Show this survey after</Typography>
                <TextField
                  variant="outlined"
                  type="number"
                  value={trigger_delay.toString()}
                  className={classes.daysField}
                  name="trigger_delay"
                  InputProps={{ inputProps: { min: 0, max: 600 } }}
                  onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                />
                <Typography>seconds</Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {platform !== 'api' && (
        <div>
          <Typography color={'text.secondary'} pt={4.4} pb={1.9}>
            Advanced options
          </Typography>

          <SurveyRetakeAccordion
            survey={survey}
            accordion={{
              expanded,
              handleChange,
              handleConfigurationSet,
            }}
            editSurveyDetails={editSurveyDetails}
          />

          <SurveyResponsesAccordion
            survey={survey}
            accordion={{
              expanded,
              handleChange,
              handleConfigurationSet,
            }}
            editSurveyDetails={editSurveyDetails}
          />

          {platform !== 'link' && research_type === 'survey' && (
            <SurveyDismissAccordion
              survey={survey}
              accordion={{
                expanded,
                handleChange,
                handleConfigurationSet,
              }}
              editSurveyDetails={editSurveyDetails}
            />
          )}

          {platform === 'link' && (
            <div>
              <SurveyAnonymousAccordion
                survey={survey}
                accordion={{
                  expanded,
                  handleChange,
                  handleConfigurationSet,
                }}
                editSurveyDetails={editSurveyDetails}
              />

              <SurveyBackgroundAccordion
                survey={survey}
                accordion={{
                  expanded,
                  handleChange,
                  handleConfigurationSet,
                }}
                editSurveyDetails={editSurveyDetails}
              />
            </div>
          )}
        </div>
      )}
    </Grid>
  );
};

export default ConfigureComponent;
