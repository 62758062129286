import React from 'react';
import RedirectFromBoard from '../../components/login/RedirectFromBoard';

export default function RedirectFromBoardPage() {
  return (
    <div>
      <RedirectFromBoard />
    </div>
  );
}
