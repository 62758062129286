import { getCall, postCall, putCall } from '../apiCall';
import {
  FETCH_CONNECTION_LIST,
  CREATE_NEW_CONNECTION,
  DISABLE_CONNECTION,
  CREATE_SLACK_CONNECTION,
  UPDATE_SLACK_CHANNEL,
  DISABLE_SLACK_CHANNEL,
  FETCH_SLACK_CHANNEL,
  CREATE_WEBHOOK,
  FETCH_WEBHOOK_LIST,
  EDIT_WEBHOOK,
  DELETE_WEBHOOK,
} from './apiEndPoints';
import { IConnection, IWebhook } from '../types/connection';

export const fetchConnectionList = async () => {
  return new Promise<Array<IConnection>>((resolve, reject) => {
    try {
      getCall(FETCH_CONNECTION_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createNewConnection = async (id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_NEW_CONNECTION(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const disableConnection = async (id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DISABLE_CONNECTION(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createSlackConnection = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_SLACK_CONNECTION(), {}, 'slack')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateSlackChannel = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(UPDATE_SLACK_CHANNEL(), payload, 'slack')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const disableSlackChannel = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DISABLE_SLACK_CHANNEL(), {}, 'slack')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSlackChannel = async () => {
  return new Promise<{ channel_names: Array<string>; alert_every_response: boolean }>((resolve, reject) => {
    try {
      getCall(FETCH_SLACK_CHANNEL(), 'slack')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createWebhookConnection = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_WEBHOOK(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWebhookList = async () => {
  return new Promise<Array<IWebhook>>((resolve, reject) => {
    try {
      getCall(FETCH_WEBHOOK_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editWebhook = async (id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_WEBHOOK(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteWebhook = async (id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DELETE_WEBHOOK(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
