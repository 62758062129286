import { Box, LinearProgress, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ProgressColorUtil } from '../../../utils/ProgressColorUtil';
import { ProgressStatItem } from '../spotlight';
import { useStyles } from '../Spotlight.style';

export interface ProgressItem {
  stats: Array<ProgressStatItem>;
}

export const BarChartComponent: FunctionComponent<ProgressItem> = ({ stats }) => {
  const classes = useStyles();

  return (
    <>
      {stats.map(({ label, count, percent }) => (
        <div>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant={'subtitle1'} display="inline">
                {`${Math.round(percent ? percent * 100 : 0)}%`} .{' '}
              </Typography>
              <Typography variant={'subtitle1'} display="inline">
                {label}
              </Typography>
            </Box>
            <Typography variant={'subtitle1'}>{count} responses</Typography>
          </Box>
          <Box width="100%" mt={0.5} pb={3.75}>
            <LinearProgress
              variant="determinate"
              value={percent ? percent * 100 : 0}
              className={classes.progress}
              sx={{
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: ProgressColorUtil(percent),
                },
              }}
            />
          </Box>
        </div>
      ))}
    </>
  );
};
