import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Typography, IconButton, InputAdornment, Box, Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SuccessUtil, ErrorUtil } from '../../utils/MessageUtil';
import defaultStyles from './loginStyles';
import { changePassword } from '../../services/loginService';

const useStyles = makeStyles(theme => ({
  logoImg: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  forgetPwd: {
    marginTop: theme.spacing(2),
    fontWeight: 700,
    textAlign: 'center',
  },
  inputColor: {
    backgroundColor: theme.palette.primary.main,
    '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
  },
  errorText: {
    color: 'red',
    marginTop: theme.spacing(2),
    fontWeight: 700,
    textAlign: 'center',
  },
}));

function LoginComponent() {
  const classes = useStyles();
  const common = defaultStyles();
  const history = useHistory();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('' as any);
  const [message, setMessage] = useState('' as any);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClick = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError('Fields cannot be empty');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (oldPassword.length < 3 || newPassword.length < 3) {
      setError('Password must be atleast 3 characters');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (newPassword !== confirmPassword) {
      setError('passwords are not same');
      setTimeout(() => setError(''), 3000);
      return;
    }
    const results = await changePassword({
      old_password: oldPassword,
      new_password: newPassword,
    });
    if (results && results.data) {
      setMessage('Password changed successfully');
      setTimeout(() => {
        localStorage.clear();
        history.push('/login');
      }, 2000);
    }
  };

  return (
    <Container maxWidth="lg" className={common.root}>
      <Grid lg={5} container direction="column" justifyContent="center">
        <Box className={classes.logoImg}>
          <img src="https://cdn.blitzllama.com/assets/logo/blitzllama-black.svg" alt="" />
        </Box>

        <Box className={common.borderBox}>
          <Typography variant="h6" textAlign={'center'} mb={3}>
            Change Password
          </Typography>
          <TextField
            fullWidth
            autoComplete="off"
            name="password"
            placeholder="Old Password"
            type={showOldPassword ? 'text' : 'password'}
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
            className={classes.inputColor}
            style={{ marginBottom: '0.5rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    size="large"
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            autoComplete="off"
            name="password"
            placeholder="New Password"
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            className={classes.inputColor}
            style={{ marginBottom: '0.5rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    size="large"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="password"
            autoComplete="off"
            placeholder="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            className={classes.inputColor}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    size="large"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            className={common.button}
            endIcon={<ChevronRightIcon fontSize="large"></ChevronRightIcon>}
            onClick={() => handleClick()}
          >
            Submit
          </Button>

          {error !== '' && <ErrorUtil message={error} />}
          {message !== '' && <SuccessUtil message={message} />}
        </Box>
      </Grid>
    </Container>
  );
}

export default LoginComponent;
