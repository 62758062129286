import React from 'react';
import LoginRedirectComponent from '../../components/login/LoginRedirectComponent';

const LoginRedirectPage = props => {
  return (
    <div>
      <LoginRedirectComponent />
    </div>
  );
};

export default LoginRedirectPage;
