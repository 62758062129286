import React from 'react';

import { boardLabelImgs } from '../static/staticData';

export const getBoardImg = (board_id: string) => {
  const num = board_id.replace(/[^0-9]/g, '');
  const sum = num.split('').reduce((partialSum, a) => partialSum + +a, 0);

  const image = boardLabelImgs[sum % 6];

  return { color: '#818DFF', image };
};
