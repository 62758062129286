import React from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(4),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function ConfirmModal(props: {
  toggleModal: (boolean) => void;
  open: boolean;
  handleMerge: () => Promise<void>;
}) {
  const classes = useStyles();

  const { toggleModal, open, handleMerge } = props;

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handleStatusChange = async () => {
    await handleMerge();
    handleCloseModal();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Typography variant={'h4'} mb={1}>
              Ready to Merge
            </Typography>
            <Typography variant={'subtitle1'} mb={2}>
              Merging is permanent. You can exit and re upload if needed.
            </Typography>
            <Box className={classes.buttonSection}>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="contained" style={{ marginLeft: 10 }} onClick={handleStatusChange}>
                Merge Data
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
