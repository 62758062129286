import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      '& .MuiCircularProgress-colorPrimary': {
        color: theme.palette.secondary.dark,
      },
    },
    switchPlanButton: {
      marginRight: theme.spacing(1),
      textTransform: 'unset',
      padding: '0.3rem 1rem 0.35rem 1rem',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.3rem',
      whiteSpace: 'nowrap',
    },
    downloadText: {
      color: theme.palette.primary.contrastText,
      paddingLeft: 5,
    },
    warningBox: {
      backgroundColor: '#FF001F0D',
      borderRadius: '3px',
      paddingBlock: theme.spacing(1.5),
      paddingInline: theme.spacing(2.5),
    },
    payButton: {
      paddingBlock: theme.spacing(0.5),
    },

    //modal

    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    confirmationModalRoot: {
      margin: 0,
      padding: '1.8rem 2rem 2.9rem 2.5rem',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    textField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.65rem 0.87rem',
      },
    },
    inputLabel: {
      color: theme.palette.text.secondary,
      fontSize: '0.75rem',
      marginBottom: theme.spacing(1.2),
    },
    statsBox: {
      borderRight: `1px solid #E8E8E8`,
    },
    saveMoreBox: {
      border: `1px solid #E2BB2E80`,
      backgroundColor: '#E2BB2E0D',
      paddingBlock: theme.spacing(2.2),
      paddingInline: theme.spacing(3),
      borderRadius: '5px',
    },

    // plan component

    planContainer: {
      border: `1px solid #E8E8E8`,
      borderRadius: '5px',
      marginTop: theme.spacing(3),
    },
    planColumn: {
      paddingBlock: theme.spacing(5),
      paddingInline: theme.spacing(6),
      borderRight: '1px solid #E8E8E8',
      display: 'flex',
      flexDirection: 'column',
    },
    currentPlanButton: {
      textTransform: 'unset',
      padding: '0.62rem 1rem 0.62rem 1rem',
      border: `1px solid #219C0C`,
      borderRadius: '0.3rem',
      whiteSpace: 'nowrap',
      color: '#219C0C',
    },
    comparisonButton: {
      marginTop: theme.spacing(2.5),
      textTransform: 'unset',
      padding: '0.3rem 1rem 0.35rem 1rem',
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '0.3rem',
      whiteSpace: 'nowrap',
    },
    discountBox: {
      backgroundColor: '#C0FBE9',
      maxWidth: 'fit-content',
      paddingBlock: theme.spacing(0.3),
      paddingInline: theme.spacing(1.2),
    },

    //stripe payment Intent
    rowPaymentInput: {
      border: '1px solid #cccccc',
      height: '35px',
      paddingTop: '8px',
      paddingLeft: '10px',
      borderRadius: '5px',
      marginBottom: '15px',
    },
  }),
);
