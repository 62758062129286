import React, { FunctionComponent } from 'react';
import { Typography, Theme, Box, Container, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import previewImg from '../../images/editor/preview-img.svg';
import buttonArrowImg from '../../images/editor/button-arrow.svg';
import { ISurvey } from '../../types/survey';
import { surveyLinkUrl } from '../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(9),
      flexGrow: 1,
      position: 'relative',
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    prototypeContainer: {
      position: 'sticky',
      top: 100,
    },
    previewButton: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate(-50%,-50%)`,
    },
  }),
);

type PreviewProps = {
  surveyInfo: ISurvey;
  source?: string;
  updateDraftChanges?: (show_alert?: boolean, check_publish?: boolean, check_settings?: boolean) => Promise<boolean>;
};

const PrototypePreview: FunctionComponent<PreviewProps> = ({ surveyInfo, source, updateDraftChanges }) => {
  const classes = useStyles();

  const { link_url, default_language } = surveyInfo;

  const protoype_preview_link = surveyLinkUrl(
    link_url ? link_url : '',
    default_language ? default_language : '',
    true,
    true,
  );

  const handleButtonClick = async () => {
    if (source === `survey editor` && updateDraftChanges) {
      const check_survey = await updateDraftChanges(false, false, false);

      if (!check_survey) {
        return;
      }
    }

    window.open(protoype_preview_link, '_blank');
  };

  return (
    <div className={classes.prototypeContainer}>
      <Container maxWidth="lg" className={classes.root}>
        <div style={{ position: 'relative' }}>
          <img src={previewImg} alt="" />
          <Button variant="contained" className={classes.previewButton} onClick={() => handleButtonClick()}>
            <Box display={'flex'} alignItems="center">
              <Typography variant={source === `survey editor` ? 'subtitle1' : 'subtitle2'} fontWeight={700} pr={0.5}>
                Launch preview
              </Typography>
              <img src={buttonArrowImg} alt="" style={{ marginTop: 5 }} />
            </Box>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PrototypePreview;
