import React, { FunctionComponent } from 'react';
import { Grid, Box, Typography, Dialog } from '@mui/material';
import { useStyles } from '../Spotlight.style';

import deleteImg from '../../../images/delete-icon.svg';
import blackDotImg from '../../../images/surveyPerformance/black-dot.svg';
import { modifiedDate } from '../../../utils/DateUtil';
import { IInputResponseForModal } from '../spotlight';
import { getImageId } from '../../../utils/stringUtil';

interface IResponseType {
  inputResponseData: IInputResponseForModal;
  toggleModal: (value: boolean) => void;
  open: boolean;
  handleUserDrawer: (e: any, user_id: string, open: boolean) => void;
}

export const InputResponseModal: FunctionComponent<IResponseType> = ({
  inputResponseData,
  toggleModal,
  open = false,
  handleUserDrawer,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    toggleModal(false);
  };

  const handleUserToggle = (e: any, user_id: string) => {
    toggleModal(false);
    handleUserDrawer(e, user_id, true);
  };

  //   destructuring the inputResponseData
  const { theme_name = '', theme_responses_count = '', input_responses = [] } = inputResponseData;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <Grid container className={classes.modalContainer}>
          <img
            src={deleteImg}
            alt=""
            style={{ marginLeft: 'auto', height: 32, cursor: 'pointer' }}
            onClick={handleClose}
          />
          <Box width={1} mt={1} display="flex" justifyContent="space-between">
            <Typography variant="h6" mt={1} mb={2}>
              TOPIC : {theme_name}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography>Input text</Typography>
              <img src={blackDotImg} alt="" style={{ paddingTop: '6px', paddingLeft: '5px', paddingRight: '5px' }} />
              <Typography>{theme_responses_count} responses</Typography>
            </Box>
          </Box>
          <Box width={1} component="div">
            <Box borderBottom={1} borderColor={'divider'} mb={1.25}></Box>
            {input_responses.map(({ survey_input, user_id, created_at }, idx) => (
              <Box key={idx} display="flex" alignItems="center" py={3}>
                <Box display="flex" alignItems="center" width="30%">
                  <Box className={classes.avatar}>
                    <img
                      src={`https://cdn.blitzllama.com/default/avatars/avatar-${getImageId(user_id.toString(), 6)}.png`}
                      alt=""
                    />
                  </Box>
                  <Typography
                    variant={'subtitle1'}
                    width={'18ch'}
                    pl={1}
                    noWrap
                    className={!user_id.startsWith('anonymous') ? classes.userBox : ''}
                    onClick={e => !user_id.startsWith('anonymous') && handleUserToggle(e, user_id)}
                  >
                    {user_id}
                  </Typography>
                </Box>
                <Box width={'50%'}>
                  <Typography variant={'subtitle1'} width={'40ch'} style={{ overflowWrap: 'break-word' }}>
                    {survey_input}
                  </Typography>
                </Box>
                <Typography variant={'subtitle1'} style={{ width: '20%' }}>
                  {modifiedDate(created_at, 'lll')}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Dialog>
    </div>
  );
};
