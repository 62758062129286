import React from 'react';
import OrgDetailsComponent from '../../components/login/OrgDetailsComponent';

const OrgDetailsPage = () => {
  return (
    <div>
      <OrgDetailsComponent />
    </div>
  );
};

export default OrgDetailsPage;
