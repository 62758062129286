import React from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function DeleteQuestionModal(props: any) {
  const classes = useStyles();

  const { open, toggleModal, deleteQuestionItem, setActiveId, surveyStatus, setQuestionEditAnchor } = props;

  const handleClose = () => {
    toggleModal(false);
    setActiveId('');
    setQuestionEditAnchor(null);
  };

  const handleDelete = () => {
    deleteQuestionItem();
    setActiveId('');
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Typography className={classes.title}>Do you want to delete this question?</Typography>
            <Typography>
              {surveyStatus === 'live' || surveyStatus === 'paused'
                ? 'Removing the question on a live active survey can seriously impact the insights/results.'
                : 'It will remove this question permanently'}
            </Typography>
            <Box className={classes.buttonSection}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => handleDelete()}>
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
