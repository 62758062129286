export const strongEmotions = ['anger', 'annoyance', 'confusion', 'disappointment', 'disgust', ,];
export const allEmotions = [
  'admiration',
  'amusement',
  'anger',
  'annoyance',
  'approval',
  'caring',
  'confusion',
  'curiosity',
  'desire',
  'disappointment',
  'disapproval',
  'disgust',
  'excitement',
  'fear',
  'gratitude',
  'joy',
  'love',
  'neutral',
  'optimism',
  'realization',
  'sadness',
  'surprise',
];
export const strongEmotionObj = { anger: '😠', confusion: '😕', disgust: '😣', annoyance: '😒', disappointment: '😩' };
