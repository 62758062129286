import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'all',
  platform: 'all',
  search_str: '',
};

const surveyFilterSlice = createSlice({
  name: 'surveyFilter',
  initialState,
  reducers: {
    resetFilter() {
      return {
        ...initialState,
      };
    },
    changeFilter: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

const { reducer } = surveyFilterSlice;

export const { resetFilter } = surveyFilterSlice.actions;
export const { changeFilter } = surveyFilterSlice.actions;
export default reducer;
