import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Drawer,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material';

import deleteImg from '../../images/delete-icon.svg';
import downloadImg from '../../images/organisation/download-icon.svg';

import { modifiedDate } from '../../utils/DateUtil';
import { StyledTableRow } from '../common/tableStyles';
import { ColoredPill, insightStyles } from './InsightStyle';
import { IAllInsights, IWorkspaceTheme, ITopicSummary } from '../../types/insight';
import { getInsightsByThemeId } from '../../services/insightService';
import { DailyChartComponent } from '../spotlight/charts/DailyChartComponent';
import { IDateRange } from '../spotlight/spotlight';
import { sourceInsightImgs } from '../../static/connectionData';
import { usePapaParse } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

export default function InsightTopicDrawer(props: {
  toggleDrawer: (Boolean) => void;
  rightState: boolean;
  themeItem: IWorkspaceTheme;
  dateRange: IDateRange;
}) {
  const { toggleDrawer, rightState, themeItem, dateRange } = props;
  const classes = insightStyles();
  const { jsonToCSV } = usePapaParse();
  const [insights, setInsights] = useState<Array<IAllInsights>>([]);
  const [summaryInfo, setSummary] = useState<null | ITopicSummary>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chart, setChart] = useState<{ x_axis: Array<string>; data_groups: Array<any> }>({
    x_axis: [],
    data_groups: [],
  });

  const { theme_name, theme_text, sentiment, theme_id, tags } = themeItem;

  const fetchInsightsData = async () => {
    const results = await getInsightsByThemeId(theme_id, dateRange);
    if (results && results.data && results.data.responses) {
      setInsights(results.data.responses);
      setChart(results.data.chart);
      if (results.data && results.data.summary && results.data.summary.change) {
        setSummary(results.data.summary);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInsightsData();
  }, [theme_id]);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'cohorts tab viewed', { tab: 'attribute' });
    }
  }, [tracking_info]);

  function save(filename: string, data: string) {
    const blob = new Blob([data], {
      type: 'text/csv',
    });

    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  const handleDownloadCSV = async () => {
    //flatten response array

    if (insights && insights.length > 0) {
      //convert jsonArray to stringified csv data
      const filteredResponses = insights.map(i => {
        return {
          user_id: i.user_id,
          response: i.response,
          created_at: i.created_at,
          emotion: i.emotion,
          sentiment: i.sentiment,
        };
      });
      const responses: any = jsonToCSV(filteredResponses);
      const fileName = theme_name.toLowerCase().replace(/ /g, '_');
      save(fileName, responses);
    }
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'download csv responses', { topic: theme_name });
    }
  };

  const { x_axis, data_groups } = chart;

  return (
    <Drawer
      anchor={'right'}
      open={rightState}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box pt={5} sx={{ width: 1150, maxWidth: '75vw' }}>
        <img src={deleteImg} alt="" onClick={toggleDrawer} className={classes.deleteImg} />
        <Box ml={5} mr={5} display={'flex'} justifyContent={'flex-end'}>
          <Box
            display="flex"
            mt={-4}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleDownloadCSV();
            }}
          >
            <img src={downloadImg} alt="" width={'20px'} />
            <Typography variant="subtitle1" className={classes.downloadText}>
              Download
            </Typography>
          </Box>
        </Box>

        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
            <CircularProgress className={classes.loading} />
          </Box>
        ) : (
          <>
            <Box ml={5} mr={5} display={'flex'}>
              <Box width={'35%'}>
                <Typography variant={'body1'} color={'primary.contrastText'}>
                  Topic
                </Typography>
                <Typography variant={'h4'}>{theme_name}</Typography>
                <Typography variant={'subtitle1'} mt={1} lineHeight={1.2}>
                  {theme_text}
                </Typography>
                <Box mt={1} display={'flex'}>
                  <ColoredPill sentiment={sentiment} add_strong={false} />
                  {tags.map(t => (
                    <ColoredPill sentiment={t} add_strong={false} />
                  ))}
                </Box>
              </Box>
              <Box width={'65%'} mt={2} mr={1}>
                <DailyChartComponent
                  x_axis={x_axis}
                  data_groups={data_groups}
                  question_type={'input_feedback'}
                  chartHeight={200}
                  is_dashboard={true}
                />
              </Box>
            </Box>
            {summaryInfo && (
              <Box ml={4} mr={4} mb={4}>
                <Box mt={1.5} style={{ flex: '0 0 auto' }} className={classes.takeawayItem}>
                  <Box style={{ borderRadius: 6 }}>
                    <Typography variant={'subtitle1'} p={1.5} lineHeight={1.5} color="#555" fontSize={'14px'}>
                      {summaryInfo.summary}
                    </Typography>

                    <Typography
                      mt={-0.2}
                      lineHeight={1.5}
                      p={1.5}
                      style={{ backgroundColor: '#DFD0F14D', color: '#64458C', fontSize: '14px' }}
                    >
                      💡 {summaryInfo.change} <br />
                      💡 {summaryInfo.emotion} <br />
                      💡 {summaryInfo.sentiment} <br />
                      💡 {summaryInfo.source}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <Box ml={6} mr={5} my={6}>
              <TableContainer>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Report</TableCell>
                      <TableCell>Sentiment</TableCell>
                      <TableCell>Emotion</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Timestamp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {insights.map(({ user_id, created_at, response, source, emotion, sentiment }, i) => (
                      <StyledTableRow key={i}>
                        <TableCell width={'5%'}>
                          <Typography variant={'subtitle1'} flexWrap={'wrap'}>
                            {i + 1}
                          </Typography>
                        </TableCell>
                        <TableCell width={'20%'}>
                          <Typography variant={'subtitle1'} flexWrap={'wrap'}>
                            {user_id}
                          </Typography>
                        </TableCell>
                        <TableCell width={'40%'}>
                          <Typography variant={'subtitle1'} flexWrap={'wrap'}>
                            {response}
                          </Typography>
                        </TableCell>
                        <TableCell width={'8%'}>
                          <Typography variant={'subtitle1'}>
                            <ColoredPill sentiment={sentiment} add_strong={false} />
                          </Typography>
                        </TableCell>
                        <TableCell width={'8%'}>
                          <Typography variant={'subtitle1'} flexWrap={'wrap'}>
                            <ColoredPill sentiment={emotion} add_strong={false} />
                          </Typography>
                        </TableCell>
                        <TableCell width={'7%'}>
                          <Typography variant={'subtitle1'}>
                            {sourceInsightImgs[source] ? (
                              <img src={sourceInsightImgs[source]} height={16} alt={source} />
                            ) : (
                              ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell width={'12%'}>
                          <Typography variant={'subtitle1'} flexWrap={'wrap'}>
                            {modifiedDate(created_at, 'll')}
                          </Typography>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
}
