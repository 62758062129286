import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { deleteCohort } from '../../services/audienceService';

import AlertUtil from '../../utils/AlertUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function DeleteDialog(props: any) {
  const classes = useStyles();
  const [cohortId, setCohortId] = React.useState('');
  const history = useHistory();

  useEffect(() => {
    const fetchCohorts = async () => {
      setCohortId(props?.cohortId);
    };
    fetchCohorts();
  }, [props]);

  const handleClose = () => {
    props.toggleModal(false);
  };

  const handleDelete = async () => {
    const result = await deleteCohort(cohortId);
    if (result && result.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Deleted successfully',
      });
    }
    history.push('/cohorts');
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Typography className={classes.title}>Do you want to delete this cohort?</Typography>
            <Typography>It will remove this cohort permanently.</Typography>
            <Box className={classes.buttonSection}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" style={{ marginLeft: 10 }} onClick={handleDelete}>
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
