import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWorkspaceList } from '../services/workspaceService';
import { IWorkspace } from '../types/workspace';

const initialState: Array<IWorkspace> = [];

export const retrieveWorkspaceDetails = createAsyncThunk('workspace/get', async () => {
  const results = await fetchWorkspaceList();
  return results;
});

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(retrieveWorkspaceDetails.fulfilled, (state, action) => {
      return [...action.payload];
    });
  },
});

const { reducer } = workspaceSlice;
export default reducer;
