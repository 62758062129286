import React, { FunctionComponent } from 'react';
import { useStyles } from './Billing.style';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledTableRow } from '../common/tableStyles';
import { IInvoice } from '../../types/billing';
import { modifiedDate } from '../../utils/DateUtil';

import downloadImg from '../../images/organisation/download-icon.svg';

interface IInvoiceHistory {
  invoiceList: Array<IInvoice>;
  handleStripePISecret: (invoice_id: string) => {};
}

const InvoiceHistoryComponent: FunctionComponent<IInvoiceHistory> = ({ invoiceList, handleStripePISecret }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h4" mt={7} mb={2.25}>
        History
      </Typography>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {invoiceList.length > 0 &&
              invoiceList.map(
                (
                  {
                    subscription_end_date,
                    bill_name,
                    total_charge,
                    invoice_pdf,
                    payment_status,
                    invoice_link,
                    invoice_id,
                  },
                  idx,
                ) => (
                  <StyledTableRow key={idx}>
                    <TableCell component="th" scope="row" width={'15%'}>
                      {subscription_end_date ? modifiedDate(subscription_end_date, 'll') : ''}
                    </TableCell>
                    <TableCell width={'25%'}>{bill_name}</TableCell>
                    <TableCell width={'15%'}>{`$${(+total_charge / 100).toFixed(2)}`}</TableCell>
                    <TableCell width={'15%'}>{payment_status}</TableCell>
                    <TableCell width={'15%'}>
                      {invoice_pdf && (
                        <a href={invoice_pdf} target="_blank" rel="noopener noreferrer">
                          <Box display="flex">
                            <img src={downloadImg} alt="" />
                            <Typography variant="subtitle1" className={classes.downloadText}>
                              Download
                            </Typography>
                          </Box>
                        </a>
                      )}
                    </TableCell>
                    <TableCell width={'15%'}>
                      {payment_status !== 'paid' && (
                        // <a href={invoice_link} target="_blank" rel="noopener noreferrer">
                        <Button
                          variant="contained"
                          className={classes.payButton}
                          onClick={() => {
                            handleStripePISecret(invoice_id);
                          }}
                        >
                          <Typography variant="subtitle1">Pay now</Typography>
                        </Button>
                        // </a>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {invoiceList.length === 0 && (
        <Typography variant={'body2'} color={'text.secondary'} textAlign="center">
          No invoices to display
        </Typography>
      )}
    </div>
  );
};

export default InvoiceHistoryComponent;
