import React from 'react';
import errorImg from '../../images/error.png';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
  }),
);

const ErrorComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={errorImg} alt="" style={{ width: 500 }} />
    </div>
  );
};

export default ErrorComponent;
