import React from 'react';
import ChangePassword from '../../components/login/ChangePassword';

const ChangePasswordPage = () => {
  return (
    <div>
      <ChangePassword />
    </div>
  );
};

export default ChangePasswordPage;
