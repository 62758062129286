import { getCall, postCall } from '../apiCall';
import {
  MEMBER_LOGIN,
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  TOKEN_EMAIL_INFO,
  MEMBER_SIGNUP,
  AUTH_MEMBER_REDIRECT,
} from './apiEndPoints';
/**
 *
 * @param {email,password} payload
 * @returns auth_token
 */

export const signupMember = async (payload: { email: string; referrer: string }) => {
  return new Promise<any>((resolve, reject) => {
    try {
      const { email, referrer } = payload;
      postCall(MEMBER_SIGNUP(), { email, referrer })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const loginMember = async (payload: { email: string; password: string }) => {
  return new Promise<any>((resolve, reject) => {
    try {
      const { email, password } = payload;
      postCall(MEMBER_LOGIN(), { email, password })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const changePassword = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CHANGE_PASSWORD(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const forgetPassword = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FORGET_PASSWORD(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const resetPassword = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(RESET_PASSWORD(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const tokenEmailInfo = async (token: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(TOKEN_EMAIL_INFO(token))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const authMemberRedirect = async (token: string, domain: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(AUTH_MEMBER_REDIRECT(token, domain))
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
