import React, { FunctionComponent, useState } from 'react';
import { Grid, Box, Typography, Button, Dialog, TextField, MenuItem, Select, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useStyles } from '../Spotlight.style';

import deleteImg from '../../../images/delete-icon.svg';
import { IBoard } from '../spotlight';

export const PinChartModal: FunctionComponent<{
  open: boolean;
  toggleModal: (value: boolean) => void;
  createNewChart: (name: string, board_ids: Array<string>) => void;
  boards: Array<IBoard>;
}> = ({ open, toggleModal, createNewChart, boards }) => {
  const classes = useStyles();
  const [chartName, setChartName] = useState('');
  const [boardIds, setBoardIds] = useState<Array<string>>([]);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setChartName('');
    toggleModal(false);
  };

  const clickHandler = () => {
    if (chartName && chartName.trim().length > 0) {
      createNewChart(chartName, boardIds);
      toggleModal(false);
    } else {
      setError(true);
      setTimeout(() => setError(false), 2000);
    }
  };

  const getBoardName = (value: string) => {
    const result = boards.find(b => b.board_id === value);
    return result && result.name ? result.name : '';
  };

  // handle selected dashboard ids to add chart
  const handleBoardValues = (board_id: string) => {
    if (boardIds.includes(board_id)) {
      const boardResult = boardIds.filter(o => o !== board_id);
      setBoardIds(boardResult);
    } else {
      setBoardIds([...boardIds, board_id]);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.modalContainer}>
          <Grid item lg={12}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant={'h6'} fontWeight={700}>
                Pin chart to dashboard
              </Typography>
              <div>
                <img src={deleteImg} alt="" style={{ height: 28, cursor: 'pointer' }} onClick={handleClose} />
              </div>
            </Box>
            <Box pt={3}>
              <Typography variant="subtitle1" pb={1}>
                Chart name
              </Typography>
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="Name your chart..."
                className={classes.nameField}
                value={chartName}
                onChange={e => setChartName(e.target.value)}
              />
            </Box>
            <Box pt={3}>
              <Typography variant="subtitle1" pb={1}>
                Choose dashboard
              </Typography>
              <Select
                fullWidth
                displayEmpty
                multiple
                variant="outlined"
                className={classes.dashboardSelectField}
                style={{ position: 'relative' }}
                renderValue={() =>
                  boardIds && boardIds.length > 0 ? (
                    boardIds.map((item, idx) => (
                      <Typography variant={'subtitle1'} noWrap maxWidth={'48%'}>
                        {getBoardName(item)},
                      </Typography>
                    ))
                  ) : (
                    <Typography variant={'subtitle1'}>Select dashboard</Typography>
                  )
                }
                value={boardIds}
              >
                {boards.map(({ board_id, name }, idx) => (
                  <MenuItem key={idx} onClick={() => handleBoardValues(board_id)}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                      checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                      classes={{
                        root: classes.check,
                        checked: classes.checked,
                      }}
                      checked={boardIds && boardIds.includes(board_id)}
                    />
                    <Typography variant={'subtitle1'}>{name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {error && (
              <Typography fontSize={12} fontWeight="bold" color={'red'} mt={0.5}>
                Please enter a valid chart name
              </Typography>
            )}
            <Box className={classes.buttonSection}>
              <Button
                disabled={boardIds.length === 0}
                variant="contained"
                className={classes.pinButton}
                onClick={() => clickHandler()}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  Save & pin chart
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
