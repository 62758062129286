import React from 'react';
import { Grid, Theme, Box, Typography, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import deleteImg from '../../images/delete-icon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import blackDotImg from '../../images/surveyPerformance/black-dot.svg';
import { modifiedDate } from '../../utils/DateUtil';
import { getImageId } from '../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    avatar: {
      height: 30,
      width: 30,
    },
    userBox: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);

export default function PerformanceDialog(props: any) {
  const classes = useStyles();
  const { handleUserDrawer } = props;

  const handleClose = () => {
    props.toggleModal(false);
  };

  const handlePerformanceDrawer = (e: any, user_id: string, open: boolean) => {
    handleUserDrawer(e, user_id, open);
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <Grid container className={classes.root}>
          <img
            src={deleteImg}
            alt=""
            style={{ marginLeft: 'auto', height: 32, cursor: 'pointer' }}
            onClick={handleClose}
          />
          <Box width={1} mt={1} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography>Input text</Typography>
              <img src={blackDotImg} alt="" style={{ paddingTop: '6px', paddingLeft: '5px', paddingRight: '5px' }} />
              <Typography>{props?.otherSelectTexts.length} responses</Typography>
            </Box>
          </Box>
          <Box width={1} component="div">
            <Box borderBottom={1} borderColor={'divider'} mb={1.25}></Box>
            {props?.otherSelectTexts?.map(({ user_id, survey_input, created_at }, idx) => (
              <Box key={idx} display="flex" alignItems="center" py={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="30%"
                  className={!user_id.startsWith('anonymous') ? classes.userBox : ''}
                >
                  <Box className={classes.avatar}>
                    <img
                      src={`https://cdn.blitzllama.com/default/avatars/avatar-${getImageId(user_id.toString(), 6)}.png`}
                      alt=""
                    />
                  </Box>
                  <Typography
                    variant={'subtitle1'}
                    width={'18ch'}
                    pl={1}
                    noWrap
                    onClick={e => !user_id.startsWith('anonymous') && handlePerformanceDrawer(e, user_id, true)}
                  >
                    {user_id}
                  </Typography>
                </Box>
                <Box width={'50%'}>
                  <Typography variant={'subtitle1'} width={'40ch'} style={{ overflowWrap: 'break-word' }}>
                    {survey_input}
                  </Typography>
                </Box>
                <Typography variant={'subtitle1'} style={{ width: '20%' }}>
                  {modifiedDate(created_at, 'lll')}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Dialog>
    </div>
  );
}
