import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Dialog, Button } from '@mui/material';
import { useStyles } from '../Billing.style';
import { IMemberDetails } from '../../../types/member';

export default function AccountSuspendedModal(props: {
  memberRole: string;
  inactiveDate: string;
  user: IMemberDetails | undefined;
}) {
  const { memberRole, user } = props;
  const history = useHistory();
  const classes = useStyles();

  if (!user) {
    return <></>;
  }

  return (
    <Dialog
      open={true}
      onClose={() => {}}
      fullWidth
      maxWidth={'sm'}
      BackdropProps={{ style: { backgroundColor: '#5A5A5A50' } }}
    >
      <Grid container className={classes.root}>
        {user.warning_cause === 'payment_failed' ? (
          <Grid item lg={12}>
            <Typography variant={'h4'} mb={2.3}>
              Please pay the pending bills to access your account
            </Typography>
            <Typography>
              In case you are facing any issues paying the bill, please reach out to us via the support chat. Please
              note that only admins can access the billing page.
            </Typography>
            {memberRole === 'admin' && (
              <Button
                variant="contained"
                onClick={() => history.push('/organisation/billing')}
                style={{ marginTop: '20px' }}
              >
                Go to Billing
              </Button>
            )}
          </Grid>
        ) : (
          <Grid item lg={12}>
            <Typography variant={'h4'} mb={2.3}>
              Upgrade to access your account
            </Typography>
            <Typography>
              You exceeded the Free Plan's limits last month. Please navigate to the Billing plans to upgrade your
              account or reach out to us via the support chat.
            </Typography>
            {memberRole === 'admin' && (
              <Button
                variant="contained"
                onClick={() => history.push('/organisation/billing')}
                style={{ marginTop: '20px' }}
              >
                Go to Billing
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
}
