import moment from 'moment';
import { IDateRange } from '../components/spotlight/spotlight';

/**
 *
 * @param date date string object
 * @param format moment date format
 * @param from_now if true, time gap from the current moment
 * @returns
 */
export const modifiedDate = (date: string, format: string, from_now = false) => {
  if (!date) return '';

  const store_offset = localStorage.getItem('_time_offset');
  let offset_value = 0;

  if (store_offset) {
    offset_value = +store_offset;
  }

  if (from_now) {
    return moment(date)
      .utcOffset(offset_value)
      .fromNow();
  } else {
    return moment(date)
      .utcOffset(offset_value)
      .format(format);
  }
};

export const modifiedDateNoOffset = (date: string, format: string) => {
  if (!date) return '';

  return moment(date).format(format);
};

export const dateDiff = (start_date: string, end_date: string) => {
  if (!start_date || !end_date) {
    return 0;
  }

  const store_offset = localStorage.getItem('_time_offset');
  let offset_value = 0;

  if (store_offset) {
    offset_value = +store_offset;
  }

  const start = moment(new Date(start_date)).utcOffset(offset_value);
  const end = moment(new Date(end_date)).utcOffset(offset_value);
  return end.diff(start, 'days');
};

export const getResponsePercent = (status: string, responses: number, survey_response_limit: number) => {
  if (status === 'completed' || status === 'archived') {
    return 100;
  } else if (status === 'draft') {
    return 0;
  } else {
    return ((responses / survey_response_limit) * 100).toFixed(1);
  }
};

export const dateRangeQuery = (date_range: IDateRange) => {
  const { type, time_to, time_from, days_count = 1 } = date_range;
  if (type === 'custom') {
    return {
      time_to: modifiedDateNoOffset(time_to, ''),
      time_from: modifiedDateNoOffset(time_from, ''),
    };
  }

  const start = moment()
    .subtract(days_count, 'days')
    .format('');
  const end = moment().format('');
  return { time_to: modifiedDateNoOffset(end, ''), time_from: modifiedDateNoOffset(start, '') };
};
