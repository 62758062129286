import { putCall, getCall } from '../apiCall';
import { FETCH_MEMBER_DETAILS, EDIT_MEMBER_DETAILS, FETCH_ALL_MEMBERS, MEMBER_TRACKING_INFO } from './apiEndPoints';
import { IMemberDetails, IMember, IMemberTrackingInfo } from '../types/member';

export const fetchMemberDetails = async () => {
  return new Promise<IMemberDetails>((resolve, reject) => {
    try {
      getCall(FETCH_MEMBER_DETAILS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editMemberDetails = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_MEMBER_DETAILS(), { ...payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllMembers = async () => {
  return new Promise<Array<IMember>>((resolve, reject) => {
    try {
      getCall(FETCH_ALL_MEMBERS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchMemberTrackingInfo = async () => {
  return new Promise<IMemberTrackingInfo>((resolve, reject) => {
    try {
      getCall(MEMBER_TRACKING_INFO())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
