import React from 'react';
import AddDetailsComponent from '../../components/login/AddDetailsComponent';

const AddDetailsPage = props => {
  return (
    <div>
      <AddDetailsComponent member={props.member} />
    </div>
  );
};

export default AddDetailsPage;
