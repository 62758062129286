import React, { FunctionComponent } from 'react';
import { IQuestionStatsType } from '../spotlight';
import { Typography } from '@mui/material';
import { ChartBaseComponent } from './ChartBaseComponent';
import { useStyles } from '../Spotlight.style';

export const SplitViewChartComponent: FunctionComponent<{
  question_stats: Array<IQuestionStatsType>;
  question_type: string;
  is_dashboard?: boolean;
}> = ({ question_stats = [], question_type, is_dashboard = false }) => {
  const classes = useStyles();
  const survey_font_size = is_dashboard ? '14px' : '16px';
  const question_font_size = is_dashboard ? '16px' : '20px';
  return (
    <div>
      {question_stats.map(({ survey_name, question_text, stats = [], nps_stats = [] }, idx) => (
        <div key={idx} className={idx < question_stats.length - 1 ? classes.splitViewBox : ''}>
          <Typography fontSize={survey_font_size}>
            <span style={{ fontWeight: 700 }}>Survey:</span> {survey_name}
          </Typography>
          <Typography variant="h4" fontSize={question_font_size} mt={1.2} mb={3}>
            {question_text}
          </Typography>
          <ChartBaseComponent question_type={question_type} stats={stats} nps_stats={nps_stats} />
        </div>
      ))}
    </div>
  );
};
