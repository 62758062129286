import { useState, useEffect } from 'react';
import moment from 'moment';
import { IAttributeItem, ICondition } from '../../types/audience';
import { cohortPreviewData, fetchAllAttributes, fetchAllAttributeValues } from '../../services/audienceService';
import { modifiedDate, dateDiff } from '../../utils/DateUtil';
import AlertUtil from '../../utils/AlertUtil';

type HeaderItem = {
  name: string;
  data_type: string;
};

const INITIAL_STATE = [
  {
    attribute: '',
    comparator: '',
    value: '',
    data_type: '',
    list: [],
  },
];

const MAX_RECORDS_IN_PAGE = 25;

export const useAttributeState = ({ new_cohort, get_preview = true, initial_state = INITIAL_STATE }) => {
  const [attributeValues, setAttributeValues] = useState({});
  const [conditions, setConditions] = useState<Array<ICondition>>(initial_state);
  const [attributeList, setAttributes] = useState<Array<IAttributeItem>>([]);
  const [preview, setPreview] = useState<Array<any>>([]);
  const [totalCount, setCount] = useState(0);
  const [headers, setHeaders] = useState<Array<HeaderItem>>([]);

  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const mandatory_attributes = ['user_id', 'created_at', 'total_days_on_app'];

  //handle pageoffset changes inc/decr
  const handlePageOffset = (type: string) => {
    if (type === 'inc' && totalCount > (pageCount + 1) * MAX_RECORDS_IN_PAGE) {
      setPageCount(pageCount + 1);
    }

    if (type === 'decr' && pageCount > 0) {
      setPageCount(pageCount - 1);
    }
  };

  // get attributes list
  const fetchAttributeList = async () => {
    const results = await fetchAllAttributes();
    if (results) {
      setAttributes(results);
      const filtered_attributes = results.filter(h => {
        if (!mandatory_attributes.includes(h.name)) return h;
      });
      setAttributes(filtered_attributes);
    }
  };

  // handle preview data for conhort
  const handleCohortConditions = async (conditions: Array<ICondition>, offset = 0) => {
    if (!get_preview) {
      setLoading(false);
      return;
    }

    const cohortValues = await cohortPreviewData({
      conditions,
      offset,
    });
    if (cohortValues && cohortValues.data) {
      const { cohort_size, preview, headers } = cohortValues.data;
      setCount(cohort_size);
      if (preview && preview.length > 0) {
        setPreview(preview);

        for (const i in preview) {
          if (preview[i].created_at) {
            const time_now = moment().format();
            preview[i].total_days_on_app = dateDiff(preview[i].created_at, time_now);
          } else {
            preview[i].total_days_on_app = '';
            preview[i].created_at = '';
          }
        }

        let formatHeaders = headers.filter(h => {
          if (!mandatory_attributes.includes(h.name)) return h;
        });

        formatHeaders.push({
          name: 'total_days_on_app',
          data_type: 'number',
        });
        formatHeaders.push({
          name: 'created_at',
          data_type: 'date',
        });
        formatHeaders.push({
          name: 'user_id',
          data_type: 'string',
        });

        formatHeaders = formatHeaders.reverse();
        setHeaders(formatHeaders);
      } else {
        setHeaders([]);
        setPreview([]);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setConditions(initial_state);
    if (new_cohort) {
      handleCohortConditions([]);
    }
    fetchAttributeList();
  }, []);

  //new_cohort to avoids preview call in cohort details (new_cohort -> false)
  //page count is needed only in user lookup (new_cohort -> true).
  useEffect(() => {
    if (new_cohort) handleCohortConditions(conditions, pageCount);
  }, [pageCount]);

  //create new condition
  const addNewCondition = () => {
    const condition_count = conditions.length;

    if (condition_count > 10) {
      AlertUtil.fire({
        icon: 'error',
        title: 'More than 10 conditions cannot be applied',
      });
      return false;
    }

    conditions[condition_count] = {
      attribute: '',
      comparator: '',
      value: '',
      data_type: '',
      list: [],
    };
    setConditions([...conditions]);
    handleCohortConditions([...conditions]);
  };

  // for delete a condition
  const deleteCondition = (idx: number) => {
    conditions.splice(idx, 1);
    setConditions([...conditions]);
    handleCohortConditions([...conditions]);
  };

  //set attribute in the array of conditions.
  //check if attribute data exists in attributeValues or fetch and update the state
  const handleAttributeSelect = async (attribute: string, idx: number) => {
    conditions[+idx].attribute = attribute;
    conditions[+idx].comparator = '';
    conditions[+idx].value = '';
    conditions[+idx].list = [];

    const attributeItem = attributeList.find(a => a.name === attribute);
    conditions[+idx].data_type = attributeItem ? attributeItem.data_type : '';
    if (attribute === 'created_at') {
      conditions[+idx].data_type = 'date';
    }
    if (attribute === 'total_days_on_app') {
      conditions[+idx].data_type = 'number';
    }
    if (attribute === 'user_id') {
      conditions[+idx].data_type = 'string';
    }
    setConditions([...conditions]);
    handleCohortConditions([...conditions]);

    if (attributeItem && attributeItem.data_type === 'boolean') {
      attributeValues[attribute] = ['true', 'false'];
      setAttributeValues(attributeValues);
    } else if (!attributeValues[attribute]) {
      const attributeValue = await fetchAllAttributeValues(attribute);
      if (attributeValue) {
        attributeValues[attribute] = attributeValue;
      } else {
        attributeValues[attribute] = [];
      }
      setAttributeValues(attributeValues);
    }
    return;
  };

  // handle attribute comparator
  const handleAttributeOperator = (comparator: string, idx: number) => {
    conditions[+idx].comparator = comparator;
    setConditions([...conditions]);
    handleCohortConditions([...conditions]);
  };

  // search by user
  const handleSearchByUserId = (userSearchValue: string) => {
    if (userSearchValue) {
      handleCohortConditions([
        {
          attribute: 'user_id',
          comparator: 'in_list',
          data_type: 'string',
          value: '',
          list: [userSearchValue],
        },
      ]);
    } else {
      handleCohortConditions(conditions);
    }
  };

  // handle attribute values for particular attribute and comparator
  const handleAttributeValue = (attribute_values: any, data_type: string, idx: number) => {
    if (data_type === 'string') {
      conditions[+idx].list = attribute_values;
    } else {
      conditions[+idx].value = attribute_values;
    }
    setConditions([...conditions]);
    handleCohortConditions([...conditions]);
  };

  // get formatted values for attribute
  const getValuesForAttribute = (attribute: string, data_type: string, idx): Array<string> => {
    const attribute_key = attribute;
    const attributeValueList = attributeValues;

    if (!attribute_key || !attributeValueList[attribute_key]) return [];

    if (data_type === 'date') {
      const dateAttributeValues = attributeValueList[attribute_key].map(d => {
        if (d.includes('00:00:00')) {
          return modifiedDate(d, 'YYYY-MM-DD');
        } else {
          return modifiedDate(d, 'YYYY-MM-DDThh:mm');
        }
      });
      return dateAttributeValues;
    }

    return attributeValueList[attribute_key] ? attributeValueList[attribute_key] : [];
  };

  return {
    conditions,
    attributeList,
    attributeValues,
    addNewCondition,
    deleteCondition,
    fetchAttributeList,
    handleAttributeSelect,
    getValuesForAttribute,
    setAttributeValues,
    setConditions,
    handleAttributeOperator,
    handleAttributeValue,
    handleSearchByUserId,
    handleCohortConditions,
    setHeaders,
    setCount,
    setPreview,
    setLoading,
    preview,
    totalCount,
    headers,
    isLoading,
    pageCount,
    handlePageOffset,
  };
};
