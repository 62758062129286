import React from 'react';
import { Typography, Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import SurveyDemoComponent from '../demo/SurveyDemoComponent';
import previewImg from '../../images/preview-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionContainer: {
      position: 'sticky',
      top: 100,
      marginTop: theme.spacing(6),
    },
  }),
);

export const SurveyDemo = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.questionContainer}>
      <SurveyDemoComponent {...props.surveyInfo} source={props.source} />
      <Box display="flex" textAlign={'right'} justifyContent="flex-end" marginRight={'110px'}>
        <img src={previewImg} alt="" style={{ marginRight: 5 }} />
        <Typography variant={'subtitle1'} color={'#696969'}>
          PREVIEW
        </Typography>
      </Box>
    </div>
  );
};
