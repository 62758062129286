import { getCall, postCall, putCall } from '../apiCall';
import { IBoard, IBoardForChart, IDashboardChart, ISpotlightResults } from '../components/spotlight/spotlight';
import {
  FETCH_CHART_DETAILS,
  FETCH_SPOTLIGHT_DETAILS,
  CREATE_DASHBOARD_CHART,
  FETCH_CHART_BY_ID,
  UPDATE_CHART_BY_ID,
  FETCH_ALL_BOARD,
  CREATE_BOARD,
  ADD_CHART_TO_BOARD,
  FETCH_ALL_CHARTS_IN_BOARD,
  DELETE_BOARD,
  REMOVE_CHART_FROM_BOARD,
  FETCH_BOARD_LIST_FOR_ID,
  FETCH_ATTRIBUTE_VALUES,
  EDIT_BOARD_ACCESS,
  EDIT_BOARD_DETAILS,
  EDIT_BOARD_ORDER_DETAILS,
  FETCH_ALL_CHARTS_IN_BOARD_REPORT,
  EDIT_CHART_VIEW,
  CREATE_TAKEAWAY_CHART,
  REMOVE_TAKEAWAY_FROM_BOARD,
} from './apiEndPoints';

export const fetchSpotlightDetails = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_SPOTLIGHT_DETAILS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchChartDetails = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_CHART_DETAILS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createChartDetails = async ({ search_request, name, chart_type, spotlight_type = 'spotlight' }) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_DASHBOARD_CHART(), { search_request, name, chart_type, spotlight_type })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchChartById = async (id: string) => {
  return new Promise<ISpotlightResults>((resolve, reject) => {
    try {
      getCall(FETCH_CHART_BY_ID(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateChartById = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(UPDATE_CHART_BY_ID(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editChartView = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_CHART_VIEW(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editChartOrder = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_BOARD_ORDER_DETAILS(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllBoard = async () => {
  return new Promise<Array<IBoard>>((resolve, reject) => {
    try {
      getCall(FETCH_ALL_BOARD())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createBoard = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_BOARD(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editBoardAccess = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_BOARD_ACCESS(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const addChartToBoard = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(ADD_CHART_TO_BOARD(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const removeChartFromBoard = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(REMOVE_CHART_FROM_BOARD(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllChartsInBoard = async (id: string) => {
  const IS_PUBLIC_REPORT = window.location && window.location.href && window.location.href.includes('report');

  const board_url = IS_PUBLIC_REPORT ? FETCH_ALL_CHARTS_IN_BOARD_REPORT(id) : FETCH_ALL_CHARTS_IN_BOARD(id);
  return new Promise<IDashboardChart | null>((resolve, reject) => {
    try {
      getCall(board_url)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          resolve(null);
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editBoardDetails = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_BOARD_DETAILS(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteBoard = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DELETE_BOARD(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchBoardListForId = async (id: string) => {
  return new Promise<Array<IBoardForChart>>((resolve, reject) => {
    try {
      getCall(FETCH_BOARD_LIST_FOR_ID(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAtributeValues = (id: string) => {
  return new Promise<Array<any>>((resolve, reject) => {
    try {
      getCall(FETCH_ATTRIBUTE_VALUES(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createTakeawayDetails = async ({ survey_id, takeaway_name, board_ids }) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_TAKEAWAY_CHART(), { survey_id, takeaway_name, board_ids })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteTakeawayDetails = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(REMOVE_TAKEAWAY_FROM_BOARD(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
