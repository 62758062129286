import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  Box,
  AppBar,
  Tabs,
  Tab,
  Theme,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import surveyStyles from '../common/SurveyStyles';

import { ICohort } from '../../types/audience';
import { fetchAllCohort, fetchOngoingCohort } from '../../services/audienceService';

import CohortTabComponent from './CohortTabComponent';
import AttributesTabComponent from './AttributesTabComponent';
import UsersTabComponent from './UsersTabComponent';

import { amplitudeEvent } from '../../utils/amplitudeUtil';

import { ReactComponent as SearchIcon } from '../../images/audience/search-icon.svg';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(4.8),
      paddingTop: 0,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    startCohort: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(8),
    },
    audienceMenu: {
      '& .MuiPaper-root': {
        width: '220px',
      },
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
    },
    searchTextField: {
      width: '250px',
      '& .MuiOutlinedInput-input': {
        paddingRight: '0.6rem',
        paddingBlock: '0.6rem',
      },
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AudienceHomeComponent() {
  const classes = useStyles();
  const common = surveyStyles();
  const history = useHistory();

  const [cohortList, setCohortList] = useState<Array<ICohort>>([]);

  const [isLoading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const [audienceAnchor, setAudienceAnchor] = React.useState<null | HTMLElement>(null);

  const [searchString, setSearchString] = useState('');

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'cohorts tab viewed', {});
    }
  }, [tracking_info]);

  const handleCohortAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAudienceAnchor(event.currentTarget);
  };

  const handleCloseAudienceMenu = () => {
    setAudienceAnchor(null);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const fetchCohorts = async () => {
    const cohorts = await fetchAllCohort();
    const ongoing = await fetchOngoingCohort();
    if (cohorts) {
      if (ongoing && ongoing.length > 0) {
        for (const i in cohorts) {
          const { upload_csv_id, created_by } = cohorts[i];
          if (upload_csv_id && created_by === 'csv') {
            const ongoing_item = ongoing.find(o => o.upload_id === upload_csv_id && o.upload_status !== 'completed');
            if (ongoing_item) {
              const { total_chunks } = ongoing_item;
              cohorts[i].upload_percent = total_chunks;
            }
          }
        }
      }
      setCohortList(cohorts);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCohorts();
  }, []);

  useEffect(() => {
    let check = true;
    const interval = setInterval(async () => {
      if (check) {
        const ongoing = await fetchOngoingCohort();
        if (ongoing && ongoing.length > 0) {
          await fetchCohorts();
        } else {
          await fetchCohorts();
          check = false;
          clearInterval(interval);
        }
      }
    }, 6 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  let filteredCohortList: Array<ICohort> = cohortList;

  if (searchString.length > 0) {
    filteredCohortList = cohortList.filter(
      c => c.cohort_name && c.cohort_name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <>
        <Grid container lg={12} justifyContent={'space-between'} pb={3}>
          <Typography variant="h2">Users & Cohorts</Typography>

          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            endIcon={<ExpandMoreIcon />}
            onClick={handleCohortAnchor}
          >
            + Create user cohort
          </Button>

          <Menu
            className={classes.audienceMenu}
            anchorEl={audienceAnchor}
            keepMounted
            open={Boolean(audienceAnchor)}
            onClose={handleCloseAudienceMenu}
          >
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: '/cohorts/new',
                  state: {
                    page: 'conditions',
                  },
                })
              }
            >
              Create with Conditions
            </MenuItem>
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: '/cohorts/new',
                  state: {
                    page: 'csv',
                  },
                })
              }
            >
              Upload CSV
            </MenuItem>
          </Menu>
        </Grid>

        <AppBar position="static" className={common.tab}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  <Typography color={tabValue === 0 ? 'primary.contrastText' : ''}>All cohorts</Typography>
                </Box>
              }
              {...a11yProps(0)}
            />

            <Tab
              label={
                <Box display="flex" alignItems="center">
                  <Typography color={tabValue === 1 ? 'primary.contrastText' : ''}>User Lookup</Typography>
                </Box>
              }
              {...a11yProps(2)}
            />

            <Tab
              label={
                <Box display="flex" alignItems="center">
                  <Typography color={tabValue === 2 ? 'primary.contrastText' : ''}>All Attributes</Typography>
                </Box>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>

        {cohortList.length > 0 && tabValue === 0 && (
          <div className={classes.searchBox}>
            <TextField
              placeholder="Search cohort name..."
              className={classes.searchTextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => {
                setSearchString(e.target.value);
              }}
            />
          </div>
        )}

        <div>
          <TabPanel value={tabValue} index={0}>
            <CohortTabComponent cohortList={cohortList} filteredCohortList={filteredCohortList} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <UsersTabComponent />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <AttributesTabComponent />
          </TabPanel>
        </div>
      </>
    );
  }
}
