import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import validator from 'validator';
import { Container, Grid, Typography, IconButton, InputAdornment, Box, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import defaultStyles from './loginStyles';
import { ErrorUtil } from '../../utils/MessageUtil';
import { loginMember } from '../../services/loginService';
import { fetchMemberDetails, fetchMemberTrackingInfo } from '../../services/organisationMemberService';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import LoginTestimonial from './LoginTestimonial';
import ENV from '../../config';

import googleImg from '../../images/login/google-login.svg';

const useStyles = makeStyles(theme => ({
  logoImg: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  inputColor: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.light}`,
    '& .MuiOutlinedInput-input': { padding: '0.65rem 0.87rem', fontSize: '14px' },
  },
}));

function LoginComponent() {
  const classes = useStyles();
  const common = defaultStyles();
  const history = useHistory();

  const auth_token = localStorage.getItem('auth_token');
  if (auth_token) {
    window.location.href = '/';
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('' as string);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault();
    if (!email || !password) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (!validator.isEmail(email)) {
      setMessage('Email is invalid');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (password.length < 3) {
      setMessage('Password must be atleast 3 characters');
      setTimeout(() => setMessage(''), 3000);
      return;
    }

    const results = await loginMember({ email, password });

    if (results && results.auth_token) {
      await localStorage.setItem('auth_token', results.auth_token);
      const user = await fetchMemberDetails();
      const tracking_info = await fetchMemberTrackingInfo();

      if (tracking_info) {
        amplitudeEvent(tracking_info, 'dashboard login success', {});
      }

      if (!user?.member_name) {
        window.location.href = '/add-details';
      } else {
        window.location.href = '/survey';
      }
    } else {
      setMessage(results?.error);
      setTimeout(() => setMessage(''), 3000);
    }
  };

  return (
    <Grid container>
      <Grid item lg={7} className={common.showTestimonial}>
        <LoginTestimonial />
      </Grid>
      <Grid item lg={5} direction="column" justifyContent="center" className={common.loginContainer}>
        <Container maxWidth="lg" className={common.formContainer}>
          <Grid lg={9} container direction="column" justifyContent="center">
            <Typography className={common.loginTitle} textAlign={'center'}>
              Login to your team workspace
            </Typography>
            <Box className={common.borderBox}>
              <Typography variant="subtitle1">Email ID</Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={common.textField}
                placeholder="Enter email id"
                name="email"
                value={email}
                autoComplete="off"
                onChange={e => setEmail(e.target.value)}
              />

              <Typography variant="subtitle1" mt={1.5}>
                Password
              </Typography>
              <TextField
                name="password"
                fullWidth
                placeholder="Enter password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                autoComplete="off"
                onChange={e => setPassword(e.target.value)}
                className={classes.inputColor}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button variant="contained" className={common.button} type="submit" onClick={e => handleSubmit(e)}>
                Sign in
              </Button>
              <Box display="flex" alignItems={'center'}>
                <div className={common.loginSeparator}></div>
                <Typography variant="subtitle1" color="text.secondary" fontWeight={700} mx={1}>
                  OR
                </Typography>
                <div className={common.loginSeparator}></div>
              </Box>
              <Button
                className={common.googleLoginButton}
                onClick={() =>
                  (window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${ENV.google_auth_url}&client_id=${ENV.google_client_id}&access_type=offline&response_type=code&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&state=${window.location.origin}`)
                }
              >
                <Box display="flex" alignItems="center">
                  <Box className={common.googleLoginBox}>
                    <img src={googleImg} />
                  </Box>
                  <Typography variant="subtitle1" fontWeight={700} pl={1}>
                    Sign in with Google
                  </Typography>
                </Box>
              </Button>

              {message !== '' && <ErrorUtil message={message} />}

              <Link to="/forget-password">
                <Typography variant={'subtitle1'} color="primary.contrastText" mt={2} textAlign={'center'}>
                  Forgot password?
                </Typography>
              </Link>
            </Box>
            <Box textAlign={'center'} mt={3.2}>
              <Typography display={'inline'} variant="subtitle1">
                New to Blitzllama?{' '}
              </Typography>
              <Typography
                variant="subtitle1"
                display={'inline'}
                color="primary.contrastText"
                onClick={() => history.push('/signup')}
                style={{ cursor: 'pointer' }}
              >
                Sign Up
              </Typography>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default LoginComponent;
