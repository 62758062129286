import React, { FunctionComponent, useState } from 'react';
import { Grid, Box, Typography, Button, Dialog, TextField } from '@mui/material';

import deleteImg from '../../images/delete-icon.svg';
// import themeDeleteImg from '../../images/settings/theme-delete-icon.svg';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import { ICustomTheme } from '../../types/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    nameField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.6rem 0.87rem',
        fontSize: '0.9rem',
      },
    },
    createButton: {
      paddingBlock: theme.spacing(0.7),
      paddingInline: theme.spacing(2.4),
    },
    deleteButton: {
      backgroundColor: theme.palette.common.white,
      color: '#C23333',
      border: `1px solid ${theme.palette.divider}`,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: '#C23333',
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
);

const ThemeModal: FunctionComponent<{
  modalType: string;
  handleThemeModalClose: () => void;
  createNewTheme: (theme_name: string) => void;
  editCustomTheme: () => void;
  customThemeItem: ICustomTheme;
  customThemeHandler: (fieldName: string, fieldValue: string | boolean) => void;
}> = ({ modalType, handleThemeModalClose, createNewTheme, editCustomTheme, customThemeItem, customThemeHandler }) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [newThemeName, setNewThemeName] = useState('');

  const { theme_name } = customThemeItem;

  const themeClickHandler = () => {
    if (modalType === 'create' && newThemeName.trim().length > 0) {
      createNewTheme(newThemeName);
    } else if (modalType === 'edit' && theme_name.trim().length > 0) {
      editCustomTheme();
      setTimeout(() => handleThemeModalClose(), 1000);
    } else {
      setError('Please enter a valid theme name');
      setTimeout(() => setError(''), 2000);
    }
  };

  return (
    <div>
      <Dialog open={modalType !== ''} onClose={handleThemeModalClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.modalContainer}>
          <Grid item lg={12}>
            <Box display="flex" mb={1} flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant={'h6'} fontWeight={700}>
                Custom theme
              </Typography>
              <div>
                <img src={deleteImg} alt="" style={{ height: 28, cursor: 'pointer' }} onClick={handleThemeModalClose} />
              </div>
            </Box>

            {modalType === 'create' ? (
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="Enter theme name..."
                className={classes.nameField}
                value={newThemeName}
                onChange={e => setNewThemeName(e.target.value)}
              />
            ) : (
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="Enter theme name..."
                className={classes.nameField}
                name="theme_name"
                value={theme_name}
                onChange={e => customThemeHandler(e.target.name, e.target.value)}
              />
            )}

            {error && (
              <Typography variant="subtitle2" color={'error.main'} mt={0.5}>
                {error}
              </Typography>
            )}
            <Box className={classes.buttonSection}>
              {/* {modalType === 'edit' && (
                <Button variant="contained" className={classes.deleteButton} onClick={() => deleteTheme(theme_id)}>
                  <Box display="flex" alignItems="center">
                    <img src={themeDeleteImg} alt="" />
                    <Typography variant="subtitle2" fontWeight={700} ml={0.7}>
                      Delete
                    </Typography>
                  </Box>
                </Button>
              )} */}

              <Box display="flex" alignItems={'flex-end'} justifyContent={'flex-end'} width="100%">
                <Button variant="contained" className={classes.createButton} onClick={() => themeClickHandler()}>
                  {modalType === 'create' ? '+ Create theme' : 'Save theme'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default ThemeModal;
