import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import LockIcon from '@mui/icons-material/Lock';
import { Button, Typography, Theme, Box, TextField, CircularProgress, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import accountImg from '../../images/settings/workspace-img.png';

import { ImageInterface } from '../../types/ImageInterface';
import surveyStyles from '../common/SurveyStyles';
import { fetchMemberDetails, editMemberDetails } from '../../services/organisationMemberService';
import { imageUpload } from '../../services/imageService';
import { memberJobRole } from '../../static/staticData';
import AlertUtil from '../../utils/AlertUtil';
import ENV from '../../config';
import { ErrorUtil } from '../../utils/MessageUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '2.2rem',
      fontWeight: 700,
      lineHeight: 1.1,
    },
    imageLabel: {
      fontSize: '0.6rem',
      color: theme.palette.text.secondary,
      marginTop: 5,
    },
    uploadButton: {
      fontSize: '0.8rem',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    uploadImg: {
      height: 90,
      width: 90,
      borderRadius: '50%',
    },
    circularProgress: {
      '& .MuiCircularProgress-colorPrimary': {
        color: theme.palette.secondary.dark,
      },
    },
    progressText: {
      color: theme.palette.secondary.dark,
    },
    nameField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.75rem 0.87rem',
      },
    },
  }),
);

interface IProfile {
  member_img: string;
  member_job_type: string;
  member_name: string;
}

function ProfileComponent() {
  const classes = useStyles();
  const history = useHistory();
  const common = surveyStyles();
  const inputFile = useRef<HTMLInputElement>(null);

  const [member, setMember] = useState<IProfile>();
  const [message, setMessage] = useState('' as string);

  const [isLoading, setLoading] = useState(false);

  const fetchMember = async () => {
    const memberDetails = await fetchMemberDetails();
    if (memberDetails) {
      setMember(memberDetails);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMember();
  }, []);

  const changeMemberDetailsHandler = (fieldName: string, fieldValue: any) => {
    if (!member) return;
    setMember({ ...member, [fieldName]: fieldValue });
  };

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const imageHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name) {
      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: '',
        base64: imgBase64,
      });
      if (imgResult) {
        changeMemberDetailsHandler('member_img', `${ENV.cdn_url}${imgResult.img_url}`);
      }
    }
  };

  const handleEditMemberClick = async () => {
    if (!member) return;

    const { member_name, member_img, member_job_type } = member;
    if (!member_name) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    }

    const saveMember = await editMemberDetails({
      member_job_type: member_job_type || '',
      member_img: member_img || '',
      member_name,
    });
    if (saveMember && saveMember.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Edited successfully',
      });
      fetchMember();
    }
  };

  if (isLoading || !member) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    const { member_img, member_name, member_job_type } = member;

    return (
      <div>
        <Box>
          <Typography variant="h2" mb={5.25}>
            My Profile
          </Typography>
        </Box>

        <div>
          <Box display="flex">
            <input
              type="file"
              accept="image/*"
              name="image-upload"
              ref={inputFile}
              onChange={e => imageHandler(e)}
            ></input>
            <img src={member_img ? member_img : accountImg} alt="" id="img" className={classes.uploadImg} />
            <Box ml={3} mt={3}>
              <Button variant="contained" className={classes.uploadButton} onClick={onButtonClick}>
                Change display picture
              </Button>
              <Typography className={classes.imageLabel}>Min. Dimension: 100 x 100</Typography>
            </Box>
          </Box>

          <Typography variant={'subtitle1'} mt={3} mb={1}>
            Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter name"
            value={member_name}
            className={classes.nameField}
            autoComplete="off"
            style={{ width: '280px' }}
            onChange={e => changeMemberDetailsHandler('member_name', e.target.value)}
          />

          <Typography variant={'subtitle1'} mt={3} mb={1}>
            Your role
          </Typography>
          <TextField
            variant="outlined"
            select
            value={member_job_type}
            style={{ width: '280px' }}
            onChange={e => changeMemberDetailsHandler('member_job_type', e.target.value)}
          >
            {memberJobRole.map(({ label, value }, key) => (
              <MenuItem value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <div>
            <Button variant="contained" style={{ marginTop: 30 }} onClick={handleEditMemberClick}>
              Save Changes
            </Button>
          </div>
          <Button
            variant="outlined"
            style={{ marginTop: 20 }}
            aria-controls="customized-menu"
            startIcon={<LockIcon />}
            onClick={() => history.push('/change-password')}
          >
            Change Password
          </Button>
          {message !== '' && <ErrorUtil message={message} />}
        </div>
      </div>
    );
  }
}

export default ProfileComponent;
