import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMemberTrackingInfo } from '../services/organisationMemberService';
import { IMemberTrackingInfo } from '../types/member';
import { amplitudeEvent } from '../utils/amplitudeUtil';
import blitz from 'blitzllama-js';

const initialState: IMemberTrackingInfo = {
  org_name: '',
  workspace_name: '',
  member_id: '',
  member_email: '',
  org_id: '',
  workspace_id: '',
  show_discovery: false,
};

export const retrieveTrackingDetails = createAsyncThunk('tracking/get', async () => {
  const results = await fetchMemberTrackingInfo();
  return results;
});

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(retrieveTrackingDetails.fulfilled, (state, action) => {
      amplitudeEvent(action.payload, 'dashboard init', {});
      if (action.payload && action.payload.member_id) {
        blitz.createUser(action.payload.member_id);
        blitz.setUserEmail(action.payload.member_email);
      }
      return { ...state, ...action.payload };
    });
  },
});

const { reducer } = trackingSlice;
export default reducer;
