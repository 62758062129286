import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { AppBar, Toolbar, Box, Typography, Button, Grid } from '@mui/material';

import BackImg from '../../images/layout/back-icon.svg';
import RightArrowImg from '../../images/layout/right-arrow.svg';
import { ISurvey } from '../../types/survey';
import { capitalizeFirstLetter } from '../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
    },

    nextIcon: {
      paddingLeft: '0.6rem',
      paddingRight: '0.6rem',
      paddingTop: theme.spacing(0.5),
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    headerButton: {
      padding: '2.25rem',
      paddingTop: '0.45rem',
      paddingBottom: '0.6rem',
    },
  }),
);

type EditorProps = {
  survey: ISurvey;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  handleSaveChanges: () => void;
  allowSaveClick: boolean;
  updateDraftChanges: (value: boolean) => void;
  publishDraft: () => void;
  researchType: string;
};

const HeaderComponent: FunctionComponent<EditorProps> = ({
  setActiveTab,
  activeTab,
  handleSaveChanges,
  allowSaveClick,
  updateDraftChanges,
  publishDraft,
  researchType,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const is_configure = activeTab !== 0 ? true : false;

  return (
    <AppBar className={classes.root}>
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item lg={10}>
          <Toolbar className={classes.toolbar}>
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer', flex: '1 1 0px' }}
              onClick={() => history.goBack()}
            >
              <img src={BackImg} alt="" style={{ paddingTop: '0.2rem' }} />
              <Typography variant="subtitle1" pl={1}>
                BACK
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" style={{ cursor: 'pointer', flex: '1 1 0px' }}>
              <Typography
                variant="subtitle1"
                fontWeight={'700'}
                color={'primary.contrastText'}
                onClick={() => setActiveTab(0)}
              >
                CREATE
              </Typography>
              <img src={RightArrowImg} alt="" className={classes.nextIcon} />
              <Typography
                variant="subtitle1"
                color={is_configure ? 'primary.contrastText' : 'text.secondary'}
                fontWeight={is_configure ? 700 : 400}
                onClick={() => handleSaveChanges()}
              >
                CONFIGURE & LAUNCH
              </Typography>
            </Box>

            {is_configure ? (
              <div>
                <Button
                  variant="outlined"
                  className={classes.headerButton}
                  style={{ marginLeft: 10 }}
                  onClick={() => updateDraftChanges(true)}
                >
                  <Typography variant={'subtitle1'} fontWeight={700}>
                    Save changes
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  className={classes.headerButton}
                  style={{ marginLeft: 10 }}
                  onClick={() => publishDraft()}
                >
                  <Typography variant={'subtitle1'} fontWeight={700}>
                    Launch {capitalizeFirstLetter(researchType)}
                  </Typography>
                </Button>
              </div>
            ) : (
              <Button
                variant="contained"
                disabled={allowSaveClick}
                className={classes.headerButton}
                onClick={() => handleSaveChanges()}
              >
                <Typography variant={'subtitle1'} fontWeight={700}>
                  Next
                </Typography>
              </Button>
            )}
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default HeaderComponent;
