import React, { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { useStyles } from '../Spotlight.style';
import { spotlightNpsData } from '../spotlight';
import { graphOptions } from '../../../utils/graphUtil';
import { npsBackgroundColors } from '../../../static/colorData';
import { npsGraphData } from '../../../types/survey';
import { Box, Typography } from '@mui/material';
import { getNps } from '../../../utils/questionUtil';

interface IChartType {
  stats: Array<spotlightNpsData>;
  nps_stats: Array<spotlightNpsData>;
  barWidth?: number;
  showNpsDesc?: boolean;
}

export const NpsChartComponent: FunctionComponent<IChartType> = ({
  stats,
  nps_stats,
  barWidth = 60,

  showNpsDesc = true,
}) => {
  const classes = useStyles();

  //   data formatting for nps chart
  const npsPlottingData = (options: Array<spotlightNpsData>) => {
    const npsNewData = [] as Array<npsGraphData>;

    for (let i = 0; i < options.length; i++) {
      npsNewData.push({
        x: options[i].label,
        y: options[i].count,
      });
    }
    return {
      datasets: [
        {
          label: 'Count',
          data: npsNewData,
          barThickness: barWidth,
          backgroundColor: npsBackgroundColors,
        },
      ],
    };
  };

  return (
    <div>
      <div style={{ position: 'relative', height: 320 }}>
        <Bar data={npsPlottingData(stats)} options={graphOptions} />
      </div>
      <Box display="flex" justifyContent="space-between" ml={4} mt={2}>
        <div>
          <Typography className={classes.npsStats} style={{ color: '#F36A83' }}>
            {getNps(nps_stats)}
          </Typography>
          <Typography variant={'subtitle1'}>NPS SCORE</Typography>
        </div>
        {nps_stats &&
          nps_stats.map(({ label, count, percent }, idx) => (
            <div key={idx}>
              <Typography className={classes.npsStats}>{(percent * 100).toFixed(1)}%</Typography>
              <Typography variant={'subtitle1'}>
                {label} ({count})
              </Typography>
            </div>
          ))}
        {showNpsDesc && (
          <div style={{ marginTop: 'auto', width: '35%', textAlign: 'end' }}>
            <Typography variant={'subtitle2'}>NPS SCORE = % Promoters - % Detractors</Typography>
          </div>
        )}
      </Box>
    </div>
  );
};
