import axios from 'axios';
import CONFIG from './config';

export const getCall = async (path_url: string, _type = 'default') => {
  let api_endpoint = CONFIG.api_endpoint;
  if (_type === 'slack') {
    api_endpoint = CONFIG.slack_api_url;
  }

  const auth_token: any = localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : '';

  return axios
    .get(api_endpoint + path_url, {
      headers: { 'auth-token': auth_token },
    })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }

      if (error && error.response && error.response.status === 403) {
        window.location.href = '/start-project';
      }
      return error.response;
    });
};

export const postCall = async (path_url: string, payload: any, _type = 'default') => {
  let api_endpoint = CONFIG.api_endpoint;
  if (_type === 'slack') {
    api_endpoint = CONFIG.slack_api_url;
  }

  const auth_token: any = localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : '';

  return axios
    .post(api_endpoint + path_url, payload, {
      headers: { 'auth-token': auth_token },
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      if (error.response && error.response.status && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
      return error.response;
    });
};

export const putCall = async (path_url: string, payload: any, _type = 'default') => {
  let api_endpoint = CONFIG.api_endpoint;
  if (_type === 'slack') {
    api_endpoint = CONFIG.slack_api_url;
  }

  const auth_token: any = localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : '';

  return axios
    .put(api_endpoint + path_url, payload, {
      headers: { 'auth-token': auth_token },
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      if (error.response && error.response.status && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
      return error.response;
    });
};
