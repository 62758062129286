import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMemberDetails } from '../services/organisationMemberService';
import { IMemberDetails } from '../types/member';

const initialState: IMemberDetails = {
  account_active: false,
  banner_warning: false,
  inactive_date: '',
  member_img: '',
  member_job_type: '',
  member_name: '',
  member_role: '',
  w_role: '',
  member_id: '',
  member_email: '',
  time_offset: 0,
  trial_end: '',
  warning_cause: '',
  org_email: '',
  show_upgrade: false,
  unread_notifications: 0,
};

export const retrieveUserDetails = createAsyncThunk('user/get', async () => {
  const results = await fetchMemberDetails();
  if (results && results.time_offset.toString()) {
    localStorage.setItem('_time_offset', `${results.time_offset}`);
  }
  return results;
});

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(retrieveUserDetails.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

const { reducer } = loginSlice;
export default reducer;
