import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    brandingContainer: {
      paddingBlock: theme.spacing(4),
    },
    brandingBox: {
      borderRadius: theme.spacing(2),
      paddingInline: theme.spacing(3.12),
      paddingBlock: theme.spacing(3.5),
      marginRight: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
      position: 'relative',
    },
    copyThemeIcon: {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
    brandingTopHeading: {
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
      letterSpacing: '0.1rem',
    },
    brandingHeading: {
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
      letterSpacing: '0.1rem',
      marginTop: theme.spacing(4),
    },
    createThemeBox: {
      cursor: 'pointer',
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.common.white,
      paddingBlock: theme.spacing(1.5),
      paddingInline: theme.spacing(3.2),
      position: 'sticky',
      bottom: 0,
      zIndex: 100,
    },
    themeNameText: {
      textTransform: 'capitalize',
    },
    themeColorBox: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: theme.spacing(0.6),
      padding: theme.spacing(1.1),
    },
    customThemeItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    saveThemeButton: {
      marginRight: theme.spacing(1),
      paddingBlock: theme.spacing(0.8),
    },
    brandingSaveButton: {
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.contrastText}33`,
      paddingBlock: theme.spacing(0.8),
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.primary.contrastText}33`,
      },
    },

    inputSubTitle: {
      fontSize: '0.7rem',
      color: theme.palette.text.secondary,
    },
    selectField: {
      width: '40%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(1.25),
        padding: '0.9rem 1.25rem',
        '&:focus': {
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.spacing(1.25),
        },
      },
      '& .MuiSelect-icon': {
        color: theme.palette.text.secondary,
        marginTop: 3,
      },
    },

    themeSelectField: {
      width: '70%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(0.6),
        padding: '0.3rem 1.25rem 0.4rem 1.25rem',
        '&:focus': {
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.spacing(0.6),
        },
      },
      '& .MuiSelect-icon': {
        color: theme.palette.text.secondary,
        marginTop: 3,
      },
      '& .MuiMenu-list': {
        paddingBottom: 0,
      },
    },
    themeMenuPaper: {
      maxHeight: '350px',
    },
    brandingRadio: {
      marginRight: theme.spacing(5),
      marginLeft: '-4px',
      '& .Mui-checked': {
        color: theme.palette.primary.contrastText,
      },
    },

    selectVariantField: {
      marginLeft: theme.spacing(2),
      width: '30%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(1.25),
        padding: '0.9rem 1.25rem',
        '&:focus': {
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.spacing(1.25),
        },
      },
      '& .MuiSelect-icon': {
        color: theme.palette.text.secondary,
        marginTop: 3,
      },
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      '&$checked': {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    uploadFontButton: {
      backgroundColor: `${theme.palette.primary.contrastText}0D`,
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      fontSize: '0.8rem',
      boxShadow: 'none',
      textTransform: 'none',
      paddingTop: theme.spacing(0.35),
      paddingBottom: theme.spacing(0.5),
      paddingInline: theme.spacing(2),
      marginRight: theme.spacing(1),
      '&:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}0D`,
        boxShadow: 'none',
      },
    },
    deleteFontImg: {
      width: '16px',
      height: '16px',
      marginTop: theme.spacing(0.6),
      cursor: 'pointer',
    },
  }),
);
