import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Paper, Box, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import SegmentDrawerComponent from './SegmentDrawerComponent';
import InsightConnectionDrawer from './InsightConnectionDrawer';
import { workspaceById } from '../../services/workspaceService';
import { IConnection, IConnectionData } from '../../types/connection';
import {
  connectionData,
  connectedSlidePlatforms,
  connectedMainPlatforms,
  teamPlanConnections,
  insightsSlidePlatforms,
} from '../../static/connectionData';
import { IWorkspace } from '../../types/workspace';
import { fetchConnectionList } from '../../services/connectionService';

import dotImg from '../../images/connection/connected-dot.svg';
import lockImg from '../../images/connection/lock-img.svg';
import { modifiedDate } from '../../utils/DateUtil';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { fetchOrganisation } from '../../services/organisationService';
import _ from 'lodash';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(4.8),
    },
    connectionPaper: {
      borderRadius: theme.spacing(2),
      paddingInline: theme.spacing(3.12),
      paddingBlock: theme.spacing(3.5),
      marginRight: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      border: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      boxShadow: 'none',
      position: 'relative',
    },
    subTitle: {
      height: theme.spacing(5.5),
      lineHeight: '1.14rem',
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    comingSoonText: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.7),
      padding: theme.spacing(1),
      paddingBlock: theme.spacing(0.5),
      position: 'absolute',
      right: 20,
      top: 10,
    },
    lockBox: {
      backgroundColor: '#EEEEF8',
      border: `1px solid #A9B0C000`,
      maxWidth: 'fit-content',
      paddingBlock: theme.spacing(0.2),
      paddingInline: theme.spacing(1),
      borderRadius: '2px',
      position: 'absolute',
      top: 8,
      right: 20,
    },
  }),
);

const anchor = 'right';

const removeUnderscore = (str: string) => {
  const newStr = str.replace(/_/g, ' ');
  return newStr;
};

export default function ConnectionComponent() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [connectionList, setConnectionList] = useState<Array<IConnection>>([]);
  const [connectionItem, setConnectionItem] = useState<IConnectionData>();
  const [rightState, setRightState] = React.useState(false);
  const [workspace, setWorkspace] = useState<IWorkspace>();
  const [copyButtonText, setCopyButtonText] = useState('Copy');

  const [blockExport, toggleBlockExport] = useState(true);

  const [insightsItem, setInsightsItem] = useState<IConnectionData>();

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'connections tab viewed', {});
    }
  }, [tracking_info]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setRightState(!rightState);
    setInsightsItem(undefined);
    setCopyButtonText('copy');
  };

  const toggleInsightsDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setRightState(!rightState);
    setConnectionItem(undefined);
    setCopyButtonText('copy');
  };

  const fetchConnections = async () => {
    const connections = await fetchConnectionList();
    const workspace = await workspaceById();

    if (connections && _.isArray(connections)) {
      setConnectionList(connections);
      setWorkspace(workspace);
      setLoading(false);
    }

    const organisation = await fetchOrganisation();
    if (organisation && (organisation.curr_plan === 'company' || organisation.curr_plan === 'team')) {
      toggleBlockExport(false);
    }
  };

  useEffect(() => {
    fetchConnections();
  }, []);

  if (isLoading || !workspace) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    // handle the drawer opening and setting the connection item
    const handleConnectionItem = async (e: any, item: IConnectionData, open: boolean) => {
      //block opening api && webhook if not company plan
      if (blockExport) {
        if (teamPlanConnections.includes(item.connection_value)) {
          return;
        }
      }
      if (connectedSlidePlatforms.includes(item.connection_value)) {
        if (open) {
          toggleDrawer(e);
        }
        setConnectionItem({ ...item });
      }
      if (insightsSlidePlatforms.includes(item.connection_value)) {
        if (open) {
          toggleInsightsDrawer(e);
        }
        setInsightsItem({ ...item });
      }
    };

    // handle the connection item is connected or not
    const getLatestActivity = (name: string) => {
      const conn_item = connectionList.find(c => c.connection_type === name);
      if (conn_item) {
        const { last_active_at } = conn_item;
        if (last_active_at) {
          return (
            <Box display="flex" alignItems="center" pl={1.5}>
              <img src={dotImg} alt="" />
              <Typography variant={'subtitle1'} color={'#3ABD8E'} pl={0.8}>
                Last activity {modifiedDate(new Date(last_active_at).toString(), '', true)}
              </Typography>
            </Box>
          );
        }
        return (
          <Box display="flex" alignItems="center" pl={1.5}>
            <img src={dotImg} alt="" />
            <Typography variant={'subtitle1'} color={'#3ABD8E'} pl={0.8}>
              Enabled
            </Typography>
          </Box>
        );
      }
      return <></>;
    };

    if (connectionItem) {
      connectionItem.connection_key = '';
      connectionItem.connection_secret = '';
      connectionItem.connection_url = '';
      connectionItem.last_active_at = '';

      const check_connection = connectionList.find(c => c.connection_type === connectionItem.connection_value);
      if (connectedMainPlatforms.includes(connectionItem.connection_value)) {
        connectionItem.connection_key = workspace.app_key;
        if (check_connection) {
          connectionItem.last_active_at = check_connection.last_active_at ? check_connection.last_active_at : '';
        }
      } else if (check_connection) {
        connectionItem.connection_key = check_connection.connection_key;
        connectionItem.connection_secret = check_connection.connection_secret;
        connectionItem.connection_url = check_connection.connection_url;
        connectionItem.last_active_at = check_connection.last_active_at ? check_connection.last_active_at : '';
        //dummy key for slack to show up if connection exists
        if (connectionItem.connection_value === 'slack') {
          connectionItem.connection_key = 'slack_key';
          connectionItem.channel = check_connection.channel;
        }

        if (connectionItem.connection_value === 'segment_stream') {
          connectionItem.connection_key = 'segment_stream';
        }

        if (connectionItem.connection_value === 'mixpanel_destination') {
          connectionItem.connection_key = 'mixpanel_destination';
        }

        if (connectionItem.connection_value === 'amplitude_destination') {
          connectionItem.connection_key = 'amplitude_destination';
        }

        if (connectionItem.connection_value === 'clevertap_destination') {
          connectionItem.connection_key = 'clevertap_destination';
        }

        if (connectionItem.connection_value === 'rudderstack_destination') {
          connectionItem.connection_key = 'rudderstack_destination';
        }
      }
    }

    return (
      <div>
        {connectionItem && (
          <SegmentDrawerComponent
            toggleDrawer={toggleDrawer}
            anchor={anchor}
            rightState={rightState}
            fetchConnections={fetchConnections}
            connectionItem={connectionItem}
            copyButtonText={copyButtonText}
            setCopyButtonText={setCopyButtonText}
          />
        )}
        {insightsItem && <InsightConnectionDrawer toggleDrawer={toggleDrawer} rightState={rightState} />}
        <Typography variant="h2">Connect to your favourite platforms</Typography>
        {Object.keys(connectionData).map((el, idx) =>
          el !== 'feedback_sources' || (el === 'feedback_sources' && tracking_info.show_discovery) ? (
            <div key={idx}>
              <Typography style={{ textTransform: 'capitalize', color: 'rgb(152, 152, 152)' }} pt={3} pb={2}>
                {removeUnderscore(el)}
              </Typography>
              <Grid container>
                {connectionData[el].map((item, idx) =>
                  item ? (
                    <Grid item lg={6} key={idx}>
                      <Paper className={classes.connectionPaper} onClick={e => handleConnectionItem(e, item, true)}>
                        {blockExport && teamPlanConnections.includes(item.connection_value) && (
                          <Box display={'flex'} alignItems="center" className={classes.lockBox}>
                            <img src={lockImg} alt="" />
                            <Typography variant="subtitle2" pl={0.5}>
                              Team plan
                            </Typography>
                          </Box>
                        )}

                        {item.launch && (
                          <Typography variant={'subtitle1'} className={classes.comingSoonText}>
                            {item.launch}
                          </Typography>
                        )}

                        <Box display="flex" alignItems={'center'}>
                          <div style={{ minHeight: '87px', minWidth: '87px' }}>
                            <img src={item.connection_img} alt="" width={'87px'} style={{ borderRadius: 8 }} />
                          </div>

                          <Box width="100%" ml={3} mr={1}>
                            <Box component="div" display="flex" alignItems="center">
                              <Typography variant={'h6'}>{item.connection_title}</Typography>
                              {!item.launch &&
                                connectionList.find(c => c.connection_type === item.connection_value) &&
                                getLatestActivity(item.connection_value)}
                            </Box>

                            <Typography variant={'subtitle1'} pb={1.5} className={classes.subTitle}>
                              {item.connection_tile}
                            </Typography>

                            <Box display="flex" alignItems="center">
                              {item.chips.map((item, idx) => (
                                <Typography variant={'subtitle2'} color={'primary.contrastText'} mr={2} key={idx}>
                                  {item}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  ) : (
                    <></>
                  ),
                )}
              </Grid>
            </div>
          ) : (
            <></>
          ),
        )}
      </div>
    );
  }
}
