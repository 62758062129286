import React from 'react';
import { Theme, Box, Dialog, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import deleteImg from '../../../images/delete-icon.svg';
import PlatformImg from '../../../images/navIcons/platform-img.svg';
import { envStatus } from '../../../static/colorData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },

    envText: {
      borderRadius: '2px',
      paddingBlock: 0.2,
      paddingLeft: 6,
      paddingRight: 6,
      width: 'fit-content',
      textTransform: 'capitalize',
    },
  }),
);

export default function ProjectModal(props: {
  open: boolean;
  toggleModal: (Boolean) => void;
  memberName: string;
  workspacesForMember: Array<any>;
}) {
  const classes = useStyles();

  const { toggleModal, memberName, workspacesForMember } = props;

  const handleToggle = () => {
    toggleModal(false);
  };

  return (
    <div>
      <Dialog onClose={handleToggle} open={props.open} fullWidth maxWidth={'sm'}>
        <div className={classes.root}>
          <Box display="flex" alignItems="center">
            <Typography variant="h4">{memberName ? `${memberName}'s projects` : 'New user'}</Typography>

            <div style={{ marginLeft: 'auto' }}>
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleToggle} />
            </div>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={1}
            mt={1.5}
            pb={1.25}
            borderColor={'divider'}
          >
            <Typography>Projects</Typography>
            <Typography>Access</Typography>
          </Box>

          {workspacesForMember.map(({ workspace_name, environment_type, workspace_role, workspace_logo }, idx) => (
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={3.5}>
              <div>
                <Box display="flex" alignItems="center">
                  <img
                    src={workspace_logo ? workspace_logo : PlatformImg}
                    alt=""
                    style={{ width: 25, height: 25, borderRadius: '50%' }}
                  />
                  <Typography variant="h6" px={1.5} noWrap>
                    {workspace_name}
                  </Typography>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.envText}
                    style={
                      envStatus[environment_type] && {
                        backgroundColor: envStatus[environment_type].backgroundColor,
                        color: envStatus[environment_type].color,
                      }
                    }
                  >
                    {environment_type}
                  </Typography>
                </Box>
              </div>
              <div>
                <Typography variant={'subtitle1'} style={{ textTransform: 'capitalize' }}>
                  {workspace_role}
                </Typography>
              </div>
            </Box>
          ))}
        </div>
      </Dialog>
    </div>
  );
}
