import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Theme,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import surveyStyles from '../common/SurveyStyles';
import { ICohort } from '../../types/audience';
import { fetchAllCohort } from '../../services/audienceService';
import { modifiedDate } from '../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(5),
      paddingTop: theme.spacing(1),
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    cohortTable: {
      '& .MuiTableHead-root': {
        borderTop: 0,
      },
    },
  }),
);

export default function CohortHistoryComponent() {
  const classes = useStyles();
  const common = surveyStyles();

  const [cohortList, setCohortList] = useState<Array<ICohort>>([]);

  const [isLoading, setLoading] = useState(true);

  const fetchCohorts = async () => {
    const cohorts = await fetchAllCohort();
    if (cohorts) {
      setCohortList(cohorts);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCohorts();
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <>
        <Grid
          container
          lg={12}
          className={common.surveyHeader}
          style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.05)' }}
        >
          <Typography variant="h2">Cohorts History</Typography>
        </Grid>

        <Grid>
          <Grid container className={classes.root}>
            <TableContainer className={classes.cohortTable}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Cohort name</TableCell>
                    <TableCell>CSV file name</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cohortList.map(({ cohort_id, cohort_name, total_count, updated_at }, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row" width={'5%'}>
                          {idx + 1}
                        </TableCell>
                        <TableCell width={'20%'}>{cohort_name}</TableCell>
                        <TableCell width={'15%'}>test.csv</TableCell>
                        <TableCell width={'20%'}>Sharmi</TableCell>
                        <TableCell width={'20%'}>{modifiedDate(updated_at, 'lll')}</TableCell>

                        <TableCell width={'20%'}>Hii,This is cohort history</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  }
}
