import React from 'react';
import WelcomeComponent from '../../components/login/WelcomeComponent';

const WelcomePage = () => {
  return (
    <div>
      <WelcomeComponent />
    </div>
  );
};

export default WelcomePage;
