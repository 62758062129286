import makeStyles from '@mui/styles/makeStyles';

const surveyStyles = makeStyles(theme => ({
  surveyHeader: {
    padding: theme.spacing(4.8),
    paddingBlock: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainContainer: {
    padding: theme.spacing(4.5),
  },
  tab: {
    padding: 0,
    border: 0,
    boxShadow: 'none',
    '& .MuiTab-root': {
      textTransform: 'none',
      padding: 0,
      marginRight: '3rem',
      fontWeight: '700',
      minWidth: 0,
      maxWidth: 'fit-content',
    },
  },

  inputLabel: {
    color: '#fff',
    fontSize: '0.9rem',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  loading: {
    color: theme.palette.primary.contrastText,
  },
  surveyBox: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    marginTop: 20,
    '& $h3': { color: theme.palette.text.secondary },
    '& .MuiOutlinedInput-input': { padding: 14 },
  },
}));

export default surveyStyles;
