import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Box, Theme } from '@mui/material';
import { SurveyQuestionHandler } from './SurveyDemoQuestions';
import { ISurveyTheme, IWorkspace } from '../../types/workspace';
import { ISurvey, optionResponseItem, RuleItem } from '../../types/survey';
import { workspaceById } from '../../services/workspaceService';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    flexGrow: 1,
    position: 'relative',
    textAlign: 'right',
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: '-6px',
  },
  imgContainer: {
    zIndex: -1,
    width: '330px',
    height: '440px',
    '@media screen and (max-width: 1344px)': {
      width: '288px',
      height: '384px',
    },
  },
  questionContainer: {
    width: '296px',
    position: 'absolute',
    right: '17px',
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: '29px',
    maxHeight: '400px',
    overflowY: 'scroll',
    '@media screen and (max-width: 1344px)': {
      right: '14px',
      width: '260px',
      bottom: '25px',
    },
  },
}));

const checkRange = (question_type: string, value: number) => {
  if (value === null) return false;

  if (question_type === 'nps_feedback' && value >= 0 && value <= 10) {
    return true;
  }
  if (
    (question_type === 'scale_feedback' || question_type === 'star_feedback' || question_type === 'emoji_feedback') &&
    value >= 1 &&
    value <= 5
  ) {
    return true;
  }

  return false;
};

type workpsaceProperites = {
  cta_full_width: boolean;
  theme_variant: string;
  show_progress_bar: boolean;
  show_blitz_logo: boolean;
};

type surveyDemoProps = ISurvey & {
  activeLanguage?: string;
  survey_theme?: ISurveyTheme;
  source?: string;
  workspace_properties?: workpsaceProperites;
};

const SurveyDemoComponent: FunctionComponent<surveyDemoProps> = (surveyData: surveyDemoProps) => {
  const classes = useStyles();

  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [showCard, setShowCard] = useState(false);
  const [workspace, setWorkspace] = useState<IWorkspace>();
  const [hideDemo, setHideDemo] = useState(false);
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    const fetchWorkspace = async () => {
      const workspace = await workspaceById();
      if (workspace) setWorkspace(workspace);
    };

    fetchWorkspace();
  }, []);

  const restartDemo = () => {
    setHideDemo(true);
    setTimeout(() => {
      setHideDemo(false);
      setShowCard(false);
      setActiveIdx(0);
      setProgressBarWidth(0);
    }, 1200);
  };

  const nextSurveyScreen = async (
    question_order: number,
    question_type: string,
    rule_sets: Array<RuleItem>,
    scale_value?: number,
    responses?: Array<optionResponseItem>,
    survey_input?: string,
  ) => {
    if (!surveyData) return null;
    const { questions, source } = surveyData;

    //tracking call
    if (tracking_info && source) {
      amplitudeEvent(tracking_info, 'preview interaction', { source });
    }

    const handleRules = async (skip_to: number) => {
      if (skip_to === -1 || questions.length <= skip_to - 1) {
        // progressbarwidth for last question
        setProgressBarWidth(100);

        // thankscard and new question delay
        setTimeout(() => {
          setShowCard(true);
          setActiveIdx(-1);
        }, 500);

        // delay for restarting the demo
        setTimeout(() => restartDemo(), 2000);
      } else if (questions.length > skip_to - 1) {
        // progressbarwidth for questions except last one
        setProgressBarWidth((activeIdx + 1) * (100 / questions.length));

        // don't show thankscard and new question delay
        setTimeout(() => {
          setActiveIdx(skip_to - 1);
          setShowCard(false);
        }, 500);
      } else {
        setHideDemo(true);
      }
    };

    // console.log('----------------------------------');
    // console.log('responses --', responses);
    // console.log('question_order --', question_order);
    // console.log('question_type --', question_type);
    // console.log('scale_value --', scale_value);
    // console.log('rule_sets --', rule_sets);
    // console.log('----------------------------------');

    if (rule_sets && rule_sets.length > 0) {
      for (let i = 0; i < rule_sets.length; i++) {
        const { rule_type, option, options, skip_to, data_array } = rule_sets[i];

        if (
          question_type === 'nps_feedback' ||
          question_type === 'scale_feedback' ||
          question_type === 'star_feedback' ||
          question_type === 'emoji_feedback'
        ) {
          if (
            scale_value !== undefined &&
            option !== undefined &&
            checkRange(question_type, scale_value) &&
            checkRange(question_type, option) &&
            ((rule_type === 'eq' && scale_value === option) ||
              (rule_type === 'neq' && scale_value !== option) ||
              (rule_type === 'lt' && scale_value < option) ||
              (rule_type === 'lte' && scale_value <= option) ||
              (rule_type === 'gt' && scale_value > option) ||
              (rule_type === 'gte' && scale_value >= option))
          ) {
            handleRules(skip_to);
            return;
          }
        }

        if (question_type === 'single_select_feedback') {
          if (options && options[0] && responses && rule_type === 'in_list_exact') {
            if (responses.find(o => o.option_text === options[0])) {
              handleRules(skip_to);
              return;
            }
          }
        }

        if (options && responses && question_type === 'multi_select_feedback') {
          const response_arr = responses.map(r => r.option_text);
          if (rule_type === 'in_list_exact') {
            const check_equal = _.isEqual(_.sortBy(options), _.sortBy(response_arr));

            if (check_equal) {
              handleRules(skip_to);
              return;
            }
          }

          if (rule_type === 'in_list_once') {
            for (let i = 0; i < response_arr.length; i++) {
              if (options.includes(response_arr[i])) {
                handleRules(skip_to);
                return;
              }
            }
          }
        }

        if (data_array && survey_input && question_type === 'data_collection' && rule_type === 'in_list_once') {
          if (data_array.includes(survey_input.trim())) {
            handleRules(skip_to);
            return;
          }
        }

        if (rule_type === 'submit') {
          handleRules(skip_to);
          return;
        }

        if (i + 1 === rule_sets.length) {
          handleRules(question_order + 1);
          return;
        }
      }
    } else {
      if (questions.length > activeIdx + 1) {
        // progressbarwidth for questions except last one
        setProgressBarWidth((activeIdx + 1) * (100 / questions.length));

        setTimeout(() => {
          setActiveIdx(activeIdx + 1);
          setShowCard(false);
        }, 500);
      } else if (questions.length === activeIdx + 1) {
        // progressbarwidth for last question
        setProgressBarWidth(100);

        // delay for showing the thanks card
        setTimeout(() => {
          setShowCard(true);
        }, 500);
        setTimeout(() => restartDemo(), 2000);
      } else {
        setHideDemo(true);
      }
    }
  };

  if (!surveyData || !workspace) return null;
  else {
    const {
      questions,
      show_thanks_card,
      thanks_card_desc,
      thanks_card_title,
      theme_type,
      activeLanguage,
      survey_theme,
      is_dismissible,
      platform,
      workspace_properties,
      source,
    } = surveyData;
    const thanksCard = {
      show_thanks_card,
      thanks_card_desc,
      thanks_card_title,
    };

    const question = questions[activeIdx];
    let activeTheme = theme_type;
    let surveyTheme = workspace.survey_theme;
    const { show_progress_bar, theme_variant, cta_full_width } = workspace;

    // handle if company logo will be shown or not in survey demo
    let showLogo = workspace.show_blitz_logo;
    if (!activeTheme) {
      activeTheme = workspace.theme_type;
    }
    if (survey_theme) {
      surveyTheme = survey_theme;
    }

    let custom_properties = { is_dismissible, show_progress_bar, theme_variant, cta_full_width };

    //handling real-time settings page
    if (source && workspace_properties && source === 'settings') {
      custom_properties = { ...custom_properties, ...workspace_properties };
      showLogo = workspace_properties.show_blitz_logo;
    }

    return (
      <Container maxWidth="lg" className={classes.root}>
        <img src="https://cdn.blitzllama.com/survey/assets/demo.svg" alt="" className={classes.imgContainer} />

        <Box className={classes.questionContainer}>
          {!hideDemo && question && (
            <SurveyQuestionHandler
              question={question}
              thanksCard={thanksCard}
              showCard={showCard}
              activeLanguage={activeLanguage ? activeLanguage : 'en'}
              nextScreen={nextSurveyScreen}
              restartDemo={restartDemo}
              surveyTheme={surveyTheme}
              platform={platform}
              showLogo={showLogo}
              progressBarWidth={progressBarWidth}
              custom_properties={custom_properties}
            />
          )}
          {!hideDemo && activeIdx === -1 && showCard && (
            <SurveyQuestionHandler
              question={question}
              thanksCard={thanksCard}
              showCard={showCard}
              activeLanguage={activeLanguage ? activeLanguage : 'en'}
              nextScreen={nextSurveyScreen}
              restartDemo={restartDemo}
              surveyTheme={surveyTheme}
              platform={platform}
              showLogo={showLogo}
              progressBarWidth={progressBarWidth}
              custom_properties={custom_properties}
            />
          )}
        </Box>
      </Container>
    );
  }
};

export default SurveyDemoComponent;
