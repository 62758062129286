const webConfigs = {
  api_endpoint: process.env.REACT_APP_API_URL,
  socket_url: process.env.REACT_APP_SOCKET_URL as string,
  cdn_url: process.env.REACT_APP_CDN_URL as string,
  survey_link: process.env.REACT_APP_LINK_URL,
  slack_api_url: process.env.REACT_APP_SLACK_URL,
  slack_redirect_url: process.env.REACT_APP_SLACK_REDIRECT,
  stripe_redirect_url: process.env.REACT_APP_BILLING_REDIRECT,
  amplitude_api_key: process.env.REACT_APP_AMPLITUDE_API_KEY as string,
  amplitude_record_events: process.env.REACT_APP_AMPLITUDE_RECORD_EVENTS as string,
  board_domain: process.env.REACT_APP_BOARD_DOMAIN as string,
  google_auth_url: process.env.REACT_APP_GOOGLE_AUTH_URL as string,
  google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
  stripe_publish_key: process.env.REACT_APP_STRIPE_PUBLISH_KEY as string,
};
export default webConfigs;
