import React, { useEffect } from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AlertUtil from '../../utils/AlertUtil';
import { disableTrigger } from '../../services/triggerService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(4),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function DeleteDialog(props: {
  triggerId: string;
  toggleModal: (boolean) => void;
  handleTriggerFetch: () => void;
  surveyCount: number;
  open: boolean;
}) {
  const classes = useStyles();
  const [triggerId, setTriggerId] = React.useState('');

  useEffect(() => {
    const fetchTriggers = async () => {
      setTriggerId(props?.triggerId);
    };
    fetchTriggers();
  }, [props]);

  const handleClose = () => {
    props.toggleModal(false);
  };

  const handleDelete = async () => {
    const result = await disableTrigger(triggerId);
    if (result && result.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Disabled successfully',
      });
    }
    props.handleTriggerFetch();
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Typography variant={'h4'} mb={2}>
              {props.surveyCount === 0 ? 'Do you want to disable this event?' : 'Event attached to a active survey.'}
            </Typography>
            <Typography>
              {props.surveyCount === 0
                ? ' It will disable this event from your workspace.'
                : 'Please remove event from active surveys to disable it.'}
            </Typography>
            <Box className={classes.buttonSection}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              {props.surveyCount === 0 && (
                <Button variant="contained" style={{ marginLeft: 10 }} onClick={handleDelete}>
                  Disable
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
