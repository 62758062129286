import React from 'react';
import LoginComponent from '../../components/login/LoginComponent';

const LogInPage = () => {
  return (
    <div>
      <LoginComponent />
    </div>
  );
};

export default LogInPage;
