import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, MenuItem, Select, TextField, Typography, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useStyles } from './Spotlight.style';

import { rightSideChips } from './constants';
import { useAttributeState } from '../hooks/useAttribute';
import { ICondition } from '../../types/audience';

import { filterCohortByName } from '../../services/audienceService';
import { fetchAtributeValues } from '../../services/spotlightService';

import { getAttributeLabel } from '../../utils/stringUtil';
import { getOperatorLabel, getOperatorsForDatatype } from '../../utils/AttributeUtil';

import crossImg from '../../images/editor/cross-icon.svg';
import addImg from '../../images/spotlight/add-icon.svg';

type AttrProps = {
  queryAttributes: Array<ICondition>;
  triggers: {
    type: string;
    data: string[];
    rule_type: string;
  };
  selectListOptions: (name: string) => JSX.Element;
  isNewChart: boolean;
  chartId: string;
  handleAttributesQuery: (conditions: Array<any>) => void;
  handleQueryUpdates: (
    query_key: string,
    id: string,
    secondary_label?: string,
    action_type?: string,
    search_str?: string,
  ) => void;
};

export const AttributeComponent: FunctionComponent<AttrProps> = ({
  queryAttributes,
  triggers,
  handleAttributesQuery,
  selectListOptions,
  isNewChart,
  chartId,
  handleQueryUpdates,
}) => {
  const classes = useStyles();

  const [openRightQueryBlock, setRightQueryBlock] = useState<Array<string>>([]);

  // custom attribute hook
  const {
    conditions,
    setConditions,
    attributeList,
    attributeValues,
    addNewCondition,
    deleteCondition,
    handleAttributeSelect,
    getValuesForAttribute,
    setAttributeValues,
    handleAttributeOperator,
    handleAttributeValue,
  } = useAttributeState({ new_cohort: true, get_preview: false, initial_state: [] });

  const fetchEditAttributeValues = async () => {
    const attributeValuesResult = await fetchAtributeValues(chartId);
    if (attributeValuesResult) {
      setAttributeValues(attributeValuesResult);
    }
  };

  useEffect(() => {
    if (!isNewChart) {
      setConditions(queryAttributes);
      fetchEditAttributeValues();
    }
  }, []);

  useEffect(() => {
    handleAttributesQuery(conditions);
  }, [conditions]);

  // handle search
  const handleSearchTerm = async (attribute: string, txt: string) => {
    if (txt.length > 2) {
      const attribute_names = await filterCohortByName('in-survey', attribute, txt, 0);
      if (attribute_names) {
        const uniq_attributes = _.union([...attributeValues[attribute], ...attribute_names]);
        attributeValues[attribute] = uniq_attributes;
        setAttributeValues({ ...attributeValues });
      }
    }
  };

  let rightHandSideChips: Array<string> = rightSideChips;

  const handleRightSideChipQuery = () => {
    if (triggers.data.length > 0) {
      setRightQueryBlock([...openRightQueryBlock, 'trigger']);
    }
  };

  if (openRightQueryBlock && openRightQueryBlock.length > 0) {
    rightHandSideChips = rightSideChips.filter(r => !openRightQueryBlock.includes(r));
  }

  const handleQueryReset = (name: string) => {
    handleQueryUpdates(name, '', '', 'clear_all', '');

    const modifiedOpenChips = openRightQueryBlock.filter(o => o !== name);
    setRightQueryBlock([...modifiedOpenChips]);
  };

  const righthandChips = () => {
    return rightHandSideChips.map((item, idx) => (
      <Box key={idx} className={classes.addNewBox} onClick={() => setRightQueryBlock([...openRightQueryBlock, item])}>
        <img src={addImg} alt="" style={{ marginTop: 4 }} />
        <Typography variant="subtitle1" color="primary.contrastText" fontWeight={700} ml={0.6}>
          {item}
        </Typography>
      </Box>
    ));
  };

  useEffect(() => {
    if (!isNewChart) {
      handleRightSideChipQuery();
    }
  }, []);

  return (
    <div>
      {openRightQueryBlock.map((item, idx) => (
        <Box
          key={idx}
          display="flex"
          alignItems={'center'}
          justifyContent={'space-between'}
          className={classes.hoverContent}
        >
          <Box display="flex" alignItems={'center'}>
            <Typography variant="subtitle1" mr={1.5} minWidth={'fit-content'}>
              and where {item}(s)
            </Typography>
            {selectListOptions(item)}
          </Box>
          <img src={crossImg} alt="" style={{ cursor: 'pointer' }} onClick={() => handleQueryReset(item)} />
        </Box>
      ))}
      <Box>
        {conditions.map(({ attribute, comparator, value, data_type, list }, idx) => (
          <>
            <Box display="flex" alignItems={'center'} className={classes.hoverContent}>
              <Typography variant="subtitle1" width={'6ch'}>
                ...where
              </Typography>
              <Select
                style={{ minWidth: '100px', width: 'fit-content', maxWidth: '40%', marginLeft: 2 }}
                variant="outlined"
                displayEmpty
                className={attribute ? classes.activeSelectField : classes.selectField}
                renderValue={() => (
                  <Typography variant="subtitle1">{attribute ? getAttributeLabel(attribute) : 'attribute'}</Typography>
                )}
                value={attribute}
                onChange={e => handleAttributeSelect(e.target.value as string, idx)}
              >
                <MenuItem disabled>
                  <Typography variant={'subtitle2'}>Default Attributes</Typography>
                </MenuItem>
                <MenuItem value={'user_id'}>
                  <Typography variant="subtitle1">User Id</Typography>
                </MenuItem>
                <MenuItem value={'created_at'}>
                  <Typography variant="subtitle1">Created at</Typography>
                </MenuItem>
                <MenuItem value={'total_days_on_app'}>
                  <Typography variant="subtitle1">Days since creation</Typography>
                </MenuItem>
                <MenuItem disabled>
                  <Typography variant={'subtitle2'}>User Attributes</Typography>
                </MenuItem>
                {attributeList.map(({ name }, key) => (
                  <MenuItem key={key} value={name}>
                    <Typography variant="subtitle1">{name}</Typography>
                  </MenuItem>
                ))}
              </Select>

              {attribute && (
                <Select
                  style={{ minWidth: '100px', width: 'fit-content', maxWidth: '40%', marginLeft: 8 }}
                  variant="outlined"
                  displayEmpty
                  className={comparator ? classes.activeSelectField : classes.selectField}
                  renderValue={() => (
                    <Typography variant="subtitle1">
                      {comparator ? getOperatorLabel(comparator, data_type) : 'condition'}
                    </Typography>
                  )}
                  value={comparator}
                  onChange={e => handleAttributeOperator(e.target.value as string, idx)}
                >
                  <MenuItem disabled>
                    <Typography variant={'subtitle2'}>Comparison Operator</Typography>
                  </MenuItem>
                  {getOperatorsForDatatype(data_type).map(({ label, value }, i) => (
                    <MenuItem value={value}>
                      <Typography variant="subtitle1">{label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
              {comparator && (
                <>
                  {data_type === 'string' && (
                    <Autocomplete
                      id="tags-outlined"
                      multiple
                      options={getValuesForAttribute(attribute, data_type, idx)}
                      disableCloseOnSelect
                      className={classes.autoTextField}
                      style={{ minWidth: '200px', maxWidth: '60%', marginLeft: 8, flexGrow: 1 }}
                      onChange={(event: any, newValue) => {
                        handleAttributeValue(newValue, data_type, idx);
                      }}
                      value={list}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                            classes={{
                              root: classes.check,
                              checked: classes.checked,
                            }}
                            checked={selected}
                          />

                          {option}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="property"
                          onChange={e => handleSearchTerm(attribute, e.target.value)}
                        />
                      )}
                      limitTags={2}
                    />
                  )}
                  {data_type === 'boolean' && (
                    <Autocomplete
                      options={getValuesForAttribute(attribute, data_type, idx)}
                      renderOption={(props, option) => <li {...props}>{option}</li>}
                      value={value}
                      style={{ minWidth: '50px', maxWidth: '60%', marginLeft: 8 }}
                      onChange={(event: any, newValue) => {
                        handleAttributeValue(newValue, data_type, idx);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="property"
                          onChange={e => handleSearchTerm(attribute, e.target.value)}
                        />
                      )}
                    />
                  )}

                  {data_type === 'date' && (
                    <TextField
                      style={{ minWidth: '100px', maxWidth: '60%', marginLeft: 8, paddingTop: 0 }}
                      variant="outlined"
                      type="date"
                      className={classes.customTextField}
                      value={conditions[+idx] && conditions[+idx].value ? conditions[+idx].value : ''}
                      placeholder="date"
                      autoComplete="off"
                      onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                    />
                  )}

                  {data_type === 'number' && (
                    <TextField
                      style={{ minWidth: '50px', maxWidth: '30%', marginLeft: 8, paddingTop: 0 }}
                      variant="outlined"
                      type="number"
                      className={classes.customTextField}
                      value={conditions[+idx] && conditions[+idx].value ? conditions[+idx].value : ''}
                      placeholder="number"
                      autoComplete="off"
                      InputProps={{ inputProps: { min: 1 } }}
                      onChange={e => handleAttributeValue(e.target.value, data_type, idx)}
                    />
                  )}
                </>
              )}

              <Box ml={'auto'}>
                <img
                  src={crossImg}
                  alt=""
                  className={classes.deleteAttributeImg}
                  onClick={() => deleteCondition(idx)}
                />
              </Box>
            </Box>
          </>
        ))}
      </Box>

      <Box display="flex" alignItems="center" mt={2.2} className={classes.newQueryBox}>
        {righthandChips()}
        <Box className={classes.addNewBox} onClick={() => addNewCondition()}>
          <img src={addImg} style={{ marginTop: 4 }} alt="" />
          <Typography variant="subtitle1" color="primary.contrastText" fontWeight={700} ml={0.6}>
            attribute
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
