const lightTheme = {
  background: '#FFFFFF',
  lightBackground: '#00000005',
  drawerBackground: '#F5F6FC',
  activeNavBackgroud: '#E9EBF5',
  tableBackground: '#FBFBFD',
  formBackground: '#FAFAFA',
  contrastText: '#201CE0',
  dark: '#313131',
  formText: '#989898',
  disabledText: '#B8B8B8',
  border: '#F0F0F8',
  formBorder: '#EEEEEE',
  successGreen: '#23BD7C',
  lightOrange: '#FF8541',
  darkOrange: '#DC763C',
  lightGreen: '#4FFFD5',
  dangerRed: '#FF244C',
  notificationBg: '#EBEBFF',
  lightGrey: '#F2F2F6',
  selectBackgrond: '#FAFAFF',
  highlightPurple: '#780EFF',
};

const surveyStatus = {
  lightOrange: '#DC763C',
  activeGreen: '#4C9E37',
  blueGreen: '#53A2B4',
  darkGrey: '#7B80A0',
  darkPurple: '#201CE0',
};

const audienceSelectColor = {
  stringYellow: '#FFDEBF',
  numberPink: '#E8CFF6',
  dateSky: '#DFF6FB',
  booleanOrange: '#FF9D97',
};

export { lightTheme, surveyStatus, audienceSelectColor };
