import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Container,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ErrorUtil, SuccessUtil } from '../../utils/MessageUtil';
import defaultStyles from './loginStyles';
import { resetPassword, tokenEmailInfo } from '../../services/loginService';

const useStyles = makeStyles(theme => ({
  logoImg: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  inputColor: {
    backgroundColor: theme.palette.primary.main,
    '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
  },

  tokenExpire: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(18),
  },
  loading: {
    color: theme.palette.primary.contrastText,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
  const classes = useStyles();
  const common = defaultStyles();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorToken, setErrorToken] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const query = useQuery();
  const token = query.get('token');

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const fetchEmailByToken = async (token: string | null) => {
    if (token === null || token === '') {
      setErrorToken(true);
      return;
    }

    const tokenEmailResult = await tokenEmailInfo(token);

    if (tokenEmailResult && tokenEmailResult.email) {
      setErrorToken(false);
    } else {
      setErrorToken(true);
    }
  };

  useEffect(() => {
    fetchEmailByToken(token);
    setLoading(false);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleResetClick = async () => {
    if (!password) {
      setError('Password cannot be empty');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (!token) {
      setError('Token is not there');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (password.length < 3) {
      setError('Password must be atleast 3 characters');
      setTimeout(() => setError(''), 3000);
      return;
    }
    const results = await resetPassword({ token, new_password: password });

    if (results && results.data) {
      setMessage('Password reset successfully');
      setTimeout(() => history.push('/login'), 2000);
    } else {
      setError(results.error);
      setTimeout(() => setError(''), 3000);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        {errorToken ? (
          <Grid container lg={12} className={classes.tokenExpire}>
            <Box width="350px" p={5} textAlign="center" border={1} borderColor={'divider'} borderRadius={2.5}>
              <Typography variant={'h4'}>
                <span role="img" aria-label="emoji">
                  ✨
                </span>
                Reset Link has expired.
              </Typography>

              <Typography variant={'subtitle1'} mt={2}>
                Please request for reset password again
              </Typography>

              <Button variant="contained" style={{ marginTop: 15 }} onClick={() => history.push('/forget-password')}>
                Reset Password
              </Button>
            </Box>
          </Grid>
        ) : (
          <Container maxWidth="lg" className={common.formBackground}>
            <Grid lg={5} container direction="column" justifyContent="center">
              <Box className={classes.logoImg}>
                <img src="https://cdn.blitzllama.com/assets/logo/blitzllama-black.svg" alt="" />
              </Box>

              <Box className={common.formBox}>
                <Typography variant={'h6'} textAlign={'center'} mb={3}>
                  Reset Password for the account
                </Typography>

                <TextField
                  fullWidth
                  name="password"
                  placeholder="Password"
                  autoComplete="off"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className={classes.inputColor}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  className={common.button}
                  type="submit"
                  endIcon={<ChevronRightIcon fontSize="large"></ChevronRightIcon>}
                  onClick={() => handleResetClick()}
                >
                  Reset
                </Button>
                <Grid item lg={12}>
                  {error !== '' && <ErrorUtil message={error} />}
                  {message !== '' && <SuccessUtil message={message} />}
                </Grid>
              </Box>
            </Grid>
          </Container>
        )}
      </div>
    );
  }
}

export default ResetPassword;
