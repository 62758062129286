import React from 'react';
import { Grid, Theme, Box, Typography, Button, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AlertUtil from '../../../utils/AlertUtil';
import { deleteMember } from '../../../services/adminService';
import { removeWorkspace } from '../../../services/workspaceMember';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
    },
  }),
);

export default function RemoveUserModal(props: {
  memberId: string;
  open: boolean;
  isOrg: boolean;
  toggleModal: (boolean) => void;
}) {
  const classes = useStyles();
  const { isOrg, memberId, open } = props;
  const handleClose = () => {
    props.toggleModal(false);
  };

  const removeUser = async () => {
    let result;
    if (isOrg) {
      result = await deleteMember({}, memberId);
    } else {
      result = await removeWorkspace(memberId);
    }
    if (result) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Removed successfully',
      });
    }
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.root}>
          <Grid item lg={12}>
            <Typography className={classes.title}>Do you want to remove this user?</Typography>
            <Typography>It will remove this user permanently.</Typography>
            <Box className={classes.buttonSection}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" style={{ marginLeft: 10 }} onClick={removeUser}>
                Remove
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
