import React from 'react';

import { useHistory } from 'react-router-dom';
import { AppBar, Button } from '@mui/material';
import { dashboardStyles } from './DashboardStyle';

import companyLogo from '../../images/layout/company-logo.svg';

const DashboardHeader = (props: { showOpenDashboard?: boolean }) => {
  const classes = dashboardStyles();
  const history = useHistory();
  const { showOpenDashboard } = props;

  return (
    <div>
      <AppBar>
        <div className={classes.headerContainer}>
          <img src={companyLogo} alt="" />
          {showOpenDashboard && (
            <Button variant="contained" className={classes.transparentButton} onClick={() => history.push('/boards')}>
              Open Dashboard
            </Button>
          )}
        </div>
      </AppBar>
    </div>
  );
};

export default DashboardHeader;
