export const checkWorkEmail = (email: string) => {
  if (!email) {
    return false;
  }
  const formatted_email = email.toLowerCase();
  if (
    formatted_email.includes('@gmail') ||
    formatted_email.includes('@yahoo') ||
    formatted_email.includes('@outlook') ||
    formatted_email.includes('@hotmail') ||
    formatted_email.includes('@rediff') ||
    formatted_email.includes('@msn')
  ) {
    return false;
  }
  return true;
};
