import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Theme,
  Box,
  AppBar,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  Grid,
  MenuItem,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import accountImg from '../../images/settings/workspace-img.png';

import TeamTabComponent from './TeamTabComponent';
import ProjectTabComponent from './ProjectTabComponent';

import surveyStyles from '../common/SurveyStyles';
import { fetchOrganisation, fetchVerifiedEmails, editOrganisationDetails } from '../../services/organisationService';
import { imageUpload } from '../../services/imageService';
import AlertUtil from '../../utils/AlertUtil';
import { ImageInterface } from '../../types/ImageInterface';
import ENV from '../../config';

import UserDialog from './modal/AddUserModal';
import { IMemberDetails } from '../../types/member';
import { IOrganisation } from '../../types/organisation';
import { RootState, useAppDispatch } from '../../store';
import { timeZones } from '../../static/staticData';
import { retrieveUserDetails } from '../../slices/loginSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      '& .MuiCircularProgress-colorPrimary': {
        color: theme.palette.secondary.dark,
      },
    },
    textField: {
      width: '300px',
      '& .MuiOutlinedInput-input': {
        padding: '0.75rem 0.87rem',
      },
    },
    uploadButton: {
      fontSize: '0.8rem',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    uploadImg: {
      height: 90,
      width: 90,
      borderRadius: '50%',
    },
  }),
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const organisationInitialState = {
  org_logo: '',
  org_name: '',
  org_email: '',
  org_timezone: '',
};

const OrganisationSettingsComponent: FunctionComponent<{ member: IMemberDetails }> = ({}) => {
  const classes = useStyles();
  const common = surveyStyles();
  const dispatch = useAppDispatch();
  const active_workspace = localStorage.getItem('active_workspace');

  const [tabValue, setTabValue] = useState(0);

  const query = useQuery();

  const [isLoading, setLoading] = useState(false);
  const [openUserToggle, toggleUserModal] = useState(false);

  const [organisationInfo, setOrganisationInfo] = useState<IOrganisation>();
  const [organisationDetails, setOrganisationDetails] = useState(organisationInitialState);
  const [verifiedEmails, setVerifiedEmails] = useState<Array<string>>([]);
  const inputFile = useRef<HTMLInputElement>(null);

  const user = useSelector((state: RootState) => state.login);

  const fetchOrganisationResult = async () => {
    const tabText = query.get('id');
    if (tabText === 'organisation') {
      setTabValue(2);
    }
    const emails = await fetchVerifiedEmails();
    if (emails) {
      setVerifiedEmails(emails);
    }
    const organisation = await fetchOrganisation();
    if (organisation) {
      const { org_name, org_email, org_timezone, org_logo } = organisation;
      setOrganisationInfo(organisation);
      setOrganisationDetails({ ...organisationDetails, org_name, org_email, org_timezone, org_logo });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrganisationResult();
  }, []);

  const handleUserModal = () => {
    toggleUserModal(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const changeOrganisationHandler = (fieldName: string, fieldValue: any) => {
    setOrganisationDetails({ ...organisationDetails, [fieldName]: fieldValue });
  };

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const imageHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name) {
      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: new Date().toJSON().toString(),
        base64: imgBase64,
      });
      if (imgResult) {
        changeOrganisationHandler('org_logo', `${ENV.cdn_url}${imgResult.img_url}`);
      }
    }
  };

  const handleClickSave = async () => {
    const { org_logo, org_email, org_timezone } = organisationDetails;
    const results = await editOrganisationDetails({ org_logo, org_email, org_timezone });
    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Saved successfully',
      });
      fetchOrganisationResult();
      dispatch(retrieveUserDetails());
    }
  };

  const { org_name, org_email, org_logo, org_timezone } = organisationDetails;

  if (!organisationInfo || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    const { survey_stats, total_surveys_launched } = organisationInfo;
    return (
      <>
        {openUserToggle && <UserDialog toggleModal={toggleUserModal} open={openUserToggle} organisation={true} />}

        <Typography variant="h5" pb={3} fontWeight={700}>
          My organization
        </Typography>

        <Grid container mt={3} mb={5}>
          <Grid item lg={2}>
            <div>
              <Typography variant={'h4'} style={{ textTransform: 'capitalize' }}>
                {total_surveys_launched}
              </Typography>
              <Typography variant={'subtitle1'}>Research studies</Typography>
            </div>
          </Grid>

          <Grid item lg={2}>
            <Box display="flex" alignItems="center">
              <div style={{ marginLeft: 5 }}>
                <Typography variant={'h4'} display="inline">
                  {survey_stats?.responses}
                </Typography>

                <Typography variant={'subtitle1'}>Responses</Typography>
              </div>
            </Box>
          </Grid>

          <Grid item lg={2}>
            <div style={{ paddingLeft: 10 }}>
              <Typography variant={'h4'}>{survey_stats?.response_rate}%</Typography>
              <Typography variant={'subtitle1'}>Response rate</Typography>
            </div>
          </Grid>
        </Grid>

        <AppBar position="static" className={common.tab} elevation={0} style={{ paddingBottom: '2.6rem' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
              <Tab
                label={
                  <Box display="flex" alignItems="center" color={tabValue === 0 ? 'primary.contrastText' : ''}>
                    <Typography>Team</Typography>
                  </Box>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center" color={tabValue === 1 ? 'primary.contrastText' : ''}>
                    <Typography>Projects</Typography>
                  </Box>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center" color={tabValue === 2 ? 'primary.contrastText' : ''}>
                    <Typography>Organization</Typography>
                  </Box>
                }
                {...a11yProps(2)}
              />
            </Tabs>
            {active_workspace && (
              <Button variant="contained" onClick={handleUserModal}>
                + Invite team members
              </Button>
            )}
          </Box>
        </AppBar>

        <TabPanel value={tabValue} index={0}>
          <TeamTabComponent openToggle={openUserToggle} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProjectTabComponent openToggle={openUserToggle} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Box display="flex">
            <input
              type="file"
              accept="image/*"
              name="image-upload"
              ref={inputFile}
              onChange={e => imageHandler(e)}
            ></input>
            <img src={org_logo ? org_logo : accountImg} alt="" id="img" className={classes.uploadImg} />
            <Box ml={3} mt={3}>
              <Button
                variant="contained"
                className={classes.uploadButton}
                disabled={user?.member_role !== 'admin'}
                onClick={onButtonClick}
              >
                Change logo
              </Button>
              <Typography fontSize={'0.6rem'} color={'text.secondary'} mb={0.6}>
                Min. Dimension: 100 x 100
              </Typography>
            </Box>
          </Box>
          <Typography variant={'subtitle1'} pt={3} pb={1}>
            Company name
          </Typography>
          <TextField
            disabled
            className={classes.textField}
            autoComplete="off"
            variant="outlined"
            placeholder="Enter company name"
            value={org_name}
            onChange={e => changeOrganisationHandler('org_name', e.target.value)}
          />
          <Typography variant={'subtitle1'} pt={3} pb={1}>
            Billing email
          </Typography>

          <TextField
            select
            className={classes.textField}
            disabled={user?.member_role !== 'admin'}
            autoComplete="off"
            variant="outlined"
            placeholder="Enter email"
            value={org_email}
            onChange={e => changeOrganisationHandler('org_email', e.target.value)}
          >
            {verifiedEmails.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>

          <Typography variant={'subtitle1'} pt={3} pb={1}>
            Time zone
          </Typography>

          <TextField
            select
            className={classes.textField}
            disabled={user?.member_role !== 'admin'}
            autoComplete="off"
            variant="outlined"
            placeholder="Enter email"
            value={org_timezone}
            onChange={e => changeOrganisationHandler('org_timezone', e.target.value)}
          >
            {timeZones.map(({ value, label }, idx) => (
              <MenuItem key={idx} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="subtitle2" fontSize={'10px'} color="text.secondary" pt={0.3}>
            Please reach out to us if your preferred timezone is not in the list.
          </Typography>
          {user?.member_role === 'admin' && (
            <Box mt={4}>
              <Button variant="contained" onClick={() => handleClickSave()}>
                Save Changes
              </Button>
            </Box>
          )}
        </TabPanel>
      </>
    );
  }
};

export default OrganisationSettingsComponent;
