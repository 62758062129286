import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Button, TextField, CircularProgress, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import defaultStyles from './loginStyles';
import LoginTestimonial from './LoginTestimonial';
import { editOrgDetails, fetchOrganisation } from '../../services/organisationService';
import { ErrorUtil } from '../../utils/MessageUtil';
import { retrieveTrackingDetails } from '../../slices/trackingSlice';
import { useAppDispatch } from '../../store';
import { timeZones } from '../../static/staticData';
import { retrieveUserDetails } from '../../slices/loginSlice';

const useStyles = makeStyles(theme => ({
  inputColor: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
  },

  loading: {
    color: theme.palette.primary.contrastText,
  },
  timeSelectField: {
    '& .MuiOutlinedInput-input': {
      padding: '0.63rem 1.12rem',
      fontSize: '14px',
    },
  },
}));

const organisationInitialState = {
  org_name: '',
  org_website: '',
  org_timezone: '',
};

function OrgDetailsComponent() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const common = defaultStyles();
  const [organisationDetails, setOrganisationDetails] = useState(organisationInitialState);
  const [error, setError] = useState('');

  const [isLoading, setLoading] = useState(true);

  const fetchOrganisationResult = async () => {
    const organisation = await fetchOrganisation();
    if (organisation) {
      const { org_name, org_website, org_timezone } = organisation;
      setOrganisationDetails({ ...organisationDetails, org_name, org_website, org_timezone });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrganisationResult();
    dispatch(retrieveTrackingDetails());
  }, []);

  const changeOrganisationHandler = (fieldName: string, fieldValue: any) => {
    setOrganisationDetails({ ...organisationDetails, [fieldName]: fieldValue });
  };

  //   handle editing of org details
  const handleOrgDetails = async () => {
    const { org_name, org_website, org_timezone } = organisationDetails;
    if (!org_name) {
      setError('Company name cannot be empty');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (!org_website) {
      setError('Website url cannot be empty');
      setTimeout(() => setError(''), 3000);
      return;
    }

    const editResult = await editOrgDetails({ org_name, org_website, org_timezone });
    if (editResult) {
      dispatch(retrieveUserDetails());
      window.location.href = '/';
    } else {
      setError('Error. Please try again');
      setTimeout(() => setError(''), 3000);
    }
  };

  const { org_name, org_website, org_timezone } = organisationDetails;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        <Grid container>
          <Grid item lg={7}>
            <LoginTestimonial />
          </Grid>
          <Grid item lg={5} direction="column" justifyContent="center" className={common.root}>
            <Container maxWidth="lg" className={common.formContainer}>
              <Grid lg={9} container direction="column" justifyContent="center">
                <Typography className={common.loginTitle} textAlign={'center'}>
                  Tell us a bit more about your company
                </Typography>
                <Box className={common.borderBox}>
                  <Typography variant="subtitle1">Company name</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={common.textField}
                    placeholder="Enter company name"
                    autoComplete="off"
                    value={org_name}
                    onChange={e => changeOrganisationHandler('org_name', e.target.value)}
                  />

                  <Typography variant="subtitle1" mt={2}>
                    Company website URL
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={common.textField}
                    placeholder="Enter website url"
                    autoComplete="off"
                    value={org_website}
                    onChange={e => changeOrganisationHandler('org_website', e.target.value)}
                  />

                  <Typography variant={'subtitle1'} mt={2}>
                    Time zone
                  </Typography>

                  <TextField
                    select
                    fullWidth
                    className={classes.timeSelectField}
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Enter email"
                    value={org_timezone}
                    onChange={e => changeOrganisationHandler('org_timezone', e.target.value)}
                  >
                    {timeZones.map(({ value, label }, idx) => (
                      <MenuItem key={idx} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {error !== '' && <ErrorUtil message={error} />}

                  <Button variant="contained" className={common.button} onClick={() => handleOrgDetails()}>
                    <Typography variant="subtitle1" fontWeight={700}>
                      Start with Survey
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default OrgDetailsComponent;
