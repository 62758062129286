import { postCall, putCall, getCall } from '../apiCall';
import {
  CREATE_TRIGGER,
  FETCH_TRIGGERS,
  EDIT_TRIGGER,
  CREATE_WHITELIST_USER,
  FETCH_WHITELIST_USERS,
  ENABLE_TRIGGER,
  DISABLE_TRIGGER,
} from './apiEndPoints';
import { ITriggerType } from '../types/trigger';

export const createTriggerType = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_TRIGGER(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchTriggerType = async () => {
  return new Promise<Array<ITriggerType>>((resolve, reject) => {
    try {
      getCall(FETCH_TRIGGERS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editTriggerType = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_TRIGGER(id), { ...payload, id })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createWhitelistUser = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_WHITELIST_USER(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWhitelistUsers = async () => {
  return new Promise<Array<ITriggerType>>((resolve, reject) => {
    try {
      getCall(FETCH_WHITELIST_USERS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const enableTrigger = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(ENABLE_TRIGGER(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const disableTrigger = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DISABLE_TRIGGER(id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
