import React from 'react';
import SurveyEditorComponent from '../../components/editor/SurveyEditorComponent';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
    },
  }),
);

const SurveyEditorPage = () => {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <SurveyEditorComponent />
    </main>
  );
};

export default SurveyEditorPage;
