import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Grid, Theme, Box, Typography, Button, Dialog, MenuItem, Select, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import deleteImg from '../../images/delete-icon.svg';
import linkImg from '../../images/surveyPerformance/link-modal.svg';
import copyImg from '../../images/connection/copy-icon.svg';
import { allLanguagesForSurvey } from '../../static/languageData';
import { surveyLinkUrl } from '../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(4),
      paddingBlock: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },

    title: { fontSize: '1.5rem', marginBottom: theme.spacing(0.5), marginTop: theme.spacing(1) },

    linkField: {
      '& .MuiOutlinedInput-input': { padding: '0.6rem 0.87rem', fontSize: '0.9rem' },
      marginRight: theme.spacing(1),
    },

    linkText: {
      color: theme.palette.primary.contrastText,
      fontSize: '0.9rem',
      whiteSpace: 'nowrap',
      overflowY: 'scroll',
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      width: '22%',
      marginRight: 10,

      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '0.5rem 0.87rem',
      },
    },
  }),
);

export default function SurveyLinkDialog(props: {
  open: boolean;
  toggleModal: (Boolean) => void;
  languages?: Array<string>;
  survey_link: string;
  survey_type: string;
  custom_domain: string;
}) {
  const { open, toggleModal, languages = [], survey_link, survey_type, custom_domain } = props;
  const classes = useStyles();
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [language, setLanguage] = useState(languages ? languages[0] : '');

  function copyText(str: string) {
    copy(str);
    setCopyButtonText('Copied');
  }

  const handleClose = () => {
    toggleModal(false);
  };

  const is_prototype_research = survey_type === 'prototype';

  return (
    <Grid container>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <div className={classes.root}>
          <Grid item lg={12} textAlign={'right'}>
            <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleClose} />
          </Grid>
          <Grid item lg={12} textAlign={'center'}>
            <img src={linkImg} alt="" width={100} />
            <Typography variant={'h4'} pt={2} pb={1}>
              Survey Link
            </Typography>
            <Typography variant={'subtitle1'}>Copy your survey link and share it to collect responses</Typography>

            <Box display="flex" alignItems="center" mt={5} mb={1}>
              {languages && languages.length > 0 && (
                <Select
                  variant="outlined"
                  className={classes.selectField}
                  value={language}
                  renderValue={() => <span style={{ textTransform: 'uppercase' }}>({language})</span>}
                >
                  {Object.values(languages)?.map((item, idx) => (
                    <MenuItem
                      value={allLanguagesForSurvey[item]}
                      key={idx}
                      onClick={e => {
                        setCopyButtonText('Copy');
                        setLanguage(item);
                      }}
                    >
                      <Typography>
                        {allLanguagesForSurvey[item]} <span style={{ textTransform: 'uppercase' }}>({item})</span>
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}

              <TextField
                fullWidth
                autoComplete="off"
                className={classes.linkField}
                value={surveyLinkUrl(survey_link, language, is_prototype_research, false, custom_domain)}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Box display="flex">
                <Button
                  variant={'contained'}
                  style={{ paddingBlock: '0.45rem' }}
                  onClick={() =>
                    copyText(surveyLinkUrl(survey_link, language, is_prototype_research, false, custom_domain))
                  }
                >
                  <img src={copyImg} alt="" />
                  <Typography pl={0.5}>{copyButtonText}</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </div>
      </Dialog>
    </Grid>
  );
}
