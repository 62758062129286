import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Button, Typography, Box, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { ITemplateWithCategory } from '../../types/template';
import {
  fetchSurveyTemplates,
  createSurvey,
  createSurveyTemplate,
  fetchCategoryTemplateById,
} from '../../services/surveyService';

import { ReactComponent as EyeIcon } from '../../images/survey/template-eye-icon.svg';
import { demoSurvey } from '../../static/demoData';
import { SurveyDemo } from '../editor/SurveyDemo';
import { questionType } from '../../types/survey';
import { workspaceById } from '../../services/workspaceService';
import { IWorkspace } from '../../types/workspace';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templatePaper: {
      borderRadius: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(3),
      cursor: 'pointer',
      position: 'relative',
      padding: '1.7rem 1.87rem',
    },
    root: {
      padding: theme.spacing(3),
      borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    collectionImg: { width: theme.spacing(12.5), marginRight: theme.spacing(1.5) },
    collectionTitle: { fontSize: '1.4rem', fontWeight: 700, marginBottom: theme.spacing(1) },
    collectionText: {
      fontSize: '0.9rem',
      lineHeight: 1.25,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    templateText: {
      lineHeight: 1.25,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    rightDemoContainer: {
      position: 'fixed',
      right: '3.8vw',
    },
    selectButton: { fontSize: '0.95rem', position: 'absolute', bottom: theme.spacing(4) },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    eyeBox: {
      border: `1px solid ${theme.palette.divider}`,
      padding: '0.4rem 0.4rem 0.3rem 0.4rem',
      borderRadius: theme.spacing(0.6),
    },
    activeEyeBox: {
      border: `1px solid ${theme.palette.primary.contrastText}`,
      padding: '0.35rem 0.4rem 0.25rem 0.4rem',
      borderRadius: theme.spacing(0.6),
      background: `${theme.palette.primary.contrastText}0D`,
      '& $svg path': { fill: theme.palette.primary.contrastText },
    },
  }),
);

interface Iparams {
  category_tag: string;
}

export default function TemplateComponent() {
  const classes = useStyles();
  const history = useHistory();

  const params: Iparams = useParams();
  const { category_tag } = params;

  const [activeTemplate, setActiveTemplate] = useState<string>();
  const [workspace, setWorkspace] = useState<IWorkspace>();
  const [templateQuestions, setTemplateQuestions] = useState<Array<questionType>>([]);

  const [templateData, setTemplateData] = useState<ITemplateWithCategory>();
  const [isLoading, setLoading] = useState(true);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const handleAmplitudeTemplate = (template_name: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'template clicked', { template: template_name });
    }
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      const surveyTemplate = await fetchSurveyTemplates(category_tag);
      const workspace = await workspaceById();
      if (surveyTemplate) {
        setTemplateData(surveyTemplate);
        setWorkspace(workspace);
      }
      setLoading(false);
    };

    fetchTemplates();
  }, [category_tag]);

  const createSurveyDraft = async () => {
    const newDraft = await createSurvey({});
    if (newDraft && newDraft.survey_id) history.push(`/survey/${newDraft.survey_id}/editor`);
  };

  const createSurveyFromTemplate = async (template_id: string, research_type: string) => {
    const newDraft = await createSurveyTemplate({}, template_id);
    if (newDraft && newDraft.survey_id) history.push(`/${research_type}/${newDraft.survey_id}/editor`);
  };

  const fetchTemplateById = async (category_tag: string, template_id: string) => {
    const result = await fetchCategoryTemplateById(category_tag, template_id);
    if (result && workspace) {
      const { questions } = result;
      for (const i in questions) {
        const { rule_sets, properties, question_text } = questions[i];
        questions[i].question_id = (+i + 1).toString();
        questions[i].cta_link = '';
        questions[i].cta_text = 'submit';
        questions[i].rule_sets = rule_sets || [];
        questions[i].question_text = question_text.replace('##PRODUCT##', workspace.workspace_name);
        const { options } = properties;
        for (const j in options) {
          options[j]._id = (+i + 1).toString();
        }
        questions[i].properties = properties;
      }
      setTemplateQuestions(questions);
      setActiveTemplate(template_id);
    }
  };

  const survey = {
    ...demoSurvey,
    questions: templateQuestions,
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        <Grid container lg={12} alignItems={'center'} justifyContent={'flex-end'}>
          <Button variant="contained" size="medium" onClick={() => createSurveyDraft()}>
            + Create your own
          </Button>
        </Grid>

        <Grid container>
          <Grid item lg={8}>
            <Box display="flex" mt={2.9} mb={2.6}>
              <div>
                <Typography variant={'h3'} className={classes.collectionTitle}>
                  {templateData?.category_name}
                </Typography>
                <Typography className={classes.collectionText}>{templateData?.category_description}</Typography>
              </div>
            </Box>
            {templateData &&
              templateData.templates &&
              templateData.templates.map(
                ({ category_tag, template_img, template_name, description, template_id, research_type }, idx) => (
                  <Box
                    className={classes.templatePaper}
                    onClick={() => {
                      handleAmplitudeTemplate(template_name);
                      createSurveyFromTemplate(template_id, research_type);
                    }}
                    key={idx}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <img
                        src={'https://cdn.blitzllama.com/survey/theme-light/templates/' + template_img}
                        alt=""
                        style={{ height: '120px', width: '120px' }}
                      />
                      <Box pl={3.75}>
                        <Typography variant={'h4'} mb={1.3}>
                          {template_name}
                        </Typography>
                        <Typography variant={'subtitle1'} className={classes.templateText}>
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className={activeTemplate === template_id ? classes.activeEyeBox : classes.eyeBox}
                      position={'absolute'}
                      bottom={18}
                      right={20}
                      onClick={e => {
                        e.stopPropagation();
                        fetchTemplateById(category_tag, template_id);
                      }}
                    >
                      <EyeIcon />
                    </Box>
                  </Box>
                ),
              )}
          </Grid>
          <Grid item lg={4}>
            {activeTemplate && <SurveyDemo surveyInfo={survey} source="template" />}
          </Grid>
        </Grid>
      </div>
    );
  }
}
