import React, { useState, useRef } from 'react';
import { Grid, Theme, TextField, Box, Button, Dialog, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import workspaceImg from '../../images/workspace-default.svg';
import deleteImg from '../../images/delete-icon.svg';
import checkedImg from '../../images/navIcons/blue-tick.svg';

import { envData } from '../../static/staticData';
import { imageUpload } from '../../services/imageService';
import { ImageInterface } from '../../types/ImageInterface';
import { ErrorUtil } from '../../utils/MessageUtil';
import AlertUtil from '../../utils/AlertUtil';
import { createWorkspace } from '../../services/workspaceService';
import { switchWorkspace } from '../../utils/workspaceUtil';
import ENV from '../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    modalButton: {
      marginTop: theme.spacing(4),
    },
    buttonSection: {
      display: 'flex',
      float: 'right',
    },
    imageLabel: {
      fontSize: '0.8rem',
      color: theme.palette.text.secondary,
      marginBottom: 5,
    },

    designedBox: {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
    },
    activeDesignedBox: {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.contrastText}0D`,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(1),
    },
    projectTextField: {
      '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
    },
  }),
);

const initialState = {
  workspace_logo: '',
  workspace_name: '',
  env_type: 'production',
};

export default function ProjectModal(props: {
  open: boolean;
  toggleModal: (Boolean) => void;
  handleClose: () => void;
}) {
  const classes = useStyles();
  const [workspace, setWorkspace] = useState(initialState);
  const [profileImg] = useState(workspaceImg);
  const inputFile = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('' as string);

  const handleToggle = () => {
    props.toggleModal(false);
  };

  const changeWorkspaceHandler = (fieldName: string, fieldValue: any) => {
    setWorkspace({ ...workspace, [fieldName]: fieldValue });
  };

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const imageHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name) {
      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: '',
        base64: imgBase64,
      });
      if (imgResult) {
        changeWorkspaceHandler('workspace_logo', `${ENV.cdn_url}${imgResult.img_url}`);
      }
    }
  };

  const { workspace_name, env_type, workspace_logo } = workspace;

  const handleClick = async () => {
    if (!workspace_name) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    }
    const newWorkspace = await createWorkspace({
      workspace_logo: workspace_logo || '',
      workspace_name: workspace_name,
      environment_type: env_type,
    });

    if (newWorkspace && newWorkspace.data) {
      props.handleClose();
      handleToggle();
      AlertUtil.fire({
        icon: 'success',
        title: 'created successfully',
      });
      await switchWorkspace(newWorkspace.data);
    }
  };

  return (
    <div>
      <Dialog onClose={handleToggle} open={props.open} fullWidth maxWidth={'md'}>
        <div className={classes.root}>
          <Grid container justifyContent="space-between">
            <Typography variant="h4" mb={5}>
              Create a project
            </Typography>
            <div>
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleToggle} />
            </div>
          </Grid>
          <Grid container>
            <Grid item lg={3}>
              <Typography className={classes.imageLabel}>Image: 500 x 500</Typography>
              <input
                type="file"
                accept="image/*"
                name="image-upload"
                ref={inputFile}
                onChange={e => imageHandler(e)}
              ></input>
              <img
                src={workspace_logo ? workspace_logo : profileImg}
                alt=""
                id="img"
                style={{ cursor: 'pointer', width: 200 }}
                onClick={onButtonClick}
              />
            </Grid>

            <Grid item lg={9} style={{ paddingLeft: 10 }}>
              <Box>
                <Typography variant={'subtitle2'} pt={1.5} pb={1.2}>
                  *Project Name
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter product name"
                  value={workspace_name}
                  name="workspace_name"
                  autoComplete="off"
                  className={classes.projectTextField}
                  onChange={e => changeWorkspaceHandler('workspace_name', e.target.value)}
                  style={{ width: '70%' }}
                />

                <Typography variant={'subtitle2'} pt={1.5} pb={1.2}>
                  *Project Type
                </Typography>
                <Box display="flex">
                  {envData.map(({ value, label }, idx) => (
                    <Box
                      mr={2}
                      px={2}
                      py={1.2}
                      key={idx}
                      display="flex"
                      alignItems="center"
                      className={env_type === value ? classes.activeDesignedBox : classes.designedBox}
                      onClick={() => changeWorkspaceHandler('env_type', value)}
                    >
                      {env_type === value ? (
                        <img src={checkedImg} alt="" style={{ marginRight: 10, height: 17 }} />
                      ) : (
                        ''
                      )}

                      <Typography variant={'subtitle1'} fontWeight={700}>
                        {label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {message !== '' && <ErrorUtil message={message} />}
              </Box>
              <Box className={classes.buttonSection}>
                <Button
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  size="medium"
                  endIcon={<ChevronRightIcon fontSize="large"></ChevronRightIcon>}
                  className={classes.modalButton}
                  onClick={handleClick}
                >
                  Create project
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
