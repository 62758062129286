import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { Theme, TextField, Box, Button, Dialog, Typography, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Autocomplete from '@mui/material/Autocomplete';

import deleteImg from '../../../images/delete-icon.svg';
import accountImg from '../../../images/settings/workspace-img.png';

import { IMember } from '../../../types/member';
import { IWorkspace } from '../../../types/workspace';

import { fetchAllMembers } from '../../../services/organisationMemberService';
import { fetchWorkspaceList } from '../../../services/workspaceService';
import { inviteUsers } from '../../../services/adminService';

import AlertUtil from '../../../utils/AlertUtil';
import { ErrorUtil } from '../../../utils/MessageUtil';
import { userImgFormat } from '../../../utils/stringUtil';
import { fetchWorkspacesForMember } from '../../../services/workspaceMember';
import { envStatus } from '../../../static/colorData';
import { RootState } from '../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },

    modalButton: {
      marginTop: theme.spacing(4.5),
      float: 'right',
    },

    envText: {
      borderRadius: '2px',
      paddingBlock: 0.2,
      paddingLeft: 6,
      paddingRight: 6,
      width: 'fit-content',
      textTransform: 'capitalize',
    },

    avatar: {
      backgroundColor: theme.palette.info.main,
      borderRadius: '50%',
      padding: theme.spacing(0.8),
      paddingBottom: 0,
      paddingLeft: theme.spacing(1.1),
      paddingRight: theme.spacing(1.1),
    },

    activeProject: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '6px',
      padding: '0.75rem 0.87rem',
    },
    autoTextField: {
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.default,
        padding: '0.75rem 0.87rem',
      },
      '& .MuiAutocomplete-tag': {
        border: `1px solid ${theme.palette.divider}`,
        marginBlock: 0,
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '1rem',
      },
    },
    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },
  }),
);

export default function InviteUser(props: { open: boolean; toggleModal: (Boolean) => void; organisation: boolean }) {
  const classes = useStyles();

  const [workRole, setWorkRole] = useState('viewer');
  const [projectId, setProjectId] = useState('');
  const [inviteEmail, setInviteEmail] = useState<
    Array<{ member_email: string; member_name: string; member_img: string }>
  >([]);
  const [memberList, setMemberList] = useState<Array<IMember>>();
  const [projectList, setProjectList] = useState<Array<IWorkspace>>();
  const [activeProject, setActiveProject] = useState<IWorkspace>();
  const [myWorkspaces, setMyWorkspaces] = useState({});
  const [newEmail, setNewEmail] = useState<string>('');
  const [message, setMessage] = useState('');

  const user = useSelector((state: RootState) => state.login);

  useEffect(() => {
    if (!user) return;

    //workspace obj for role restriction
    const fetchMyWorkspaces = async () => {
      const workspaces = await fetchWorkspacesForMember(user.member_id);
      if (workspaces) {
        const myWorkspaces = {};
        for (const i in workspaces) {
          myWorkspaces[workspaces[i].workspace_id] = workspaces[i].workspace_role;
        }
        setMyWorkspaces(myWorkspaces);
      }
    };
    fetchMyWorkspaces();
  }, [user]);

  const handleToggle = () => {
    props.toggleModal(false);
  };

  const handleInviteEmails = emails => {
    setInviteEmail(emails);
    setNewEmail('');
  };

  const handleNewEmails = (txt: string) => {
    txt = txt.trimLeft();
    setNewEmail(txt);

    let email = '';
    if (txt.includes(' ')) {
      email = txt.split(' ')[0];
    } else if (txt.includes(',')) {
      email = txt.split(',')[0];
    }
    if (email) {
      setInviteEmail([...inviteEmail, { member_email: email, member_name: '', member_img: '' }]);
      setNewEmail('');
    }
  };

  const handleKeyDown = e => {
    e.defaultMuiPrevented = true;
    if (e.key === 'Enter') {
      if (newEmail && newEmail.length > 0) {
        const member_email = newEmail.trim();
        inviteEmail.push({ member_email, member_name: '', member_img: '' });
        setInviteEmail([...inviteEmail]);
        setNewEmail('');
      }
    }
  };

  const fetchOrganisationMembers = async () => {
    const organisationMembers = await fetchAllMembers();
    if (organisationMembers) {
      const filteredMembers = organisationMembers.filter(e => e.member_email !== user?.member_email);
      setMemberList(filteredMembers);
    }
  };

  const fetchProjects = async () => {
    const active_project = localStorage.getItem('active_workspace');
    const projects = await fetchWorkspaceList();
    if (projects) {
      setProjectList(projects);
    }
    if (active_project) {
      setProjectId(active_project);
    }
    if (projects && active_project) {
      const item = projects.find(p => p.workspace_id === active_project);
      setActiveProject(item);
    }
  };

  useEffect(() => {
    fetchOrganisationMembers();
    fetchProjects();
  }, []);

  const inviteMembers = async () => {
    const newEmailId = newEmail.trim();
    if (!projectId || (inviteEmail.length === 0 && newEmailId.length === 0)) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    }

    const invite_emails = inviteEmail.map(e => e.member_email);
    if (newEmailId.length > 0) {
      inviteEmail.push({ member_email: newEmailId, member_name: '', member_img: '' });
      invite_emails.push(newEmailId);
      setInviteEmail([...inviteEmail]);
      setNewEmail('');
    }

    for (let i = 0; i < invite_emails.length; i++) {
      if (!validator.isEmail(invite_emails[i])) {
        setMessage('Please enter valid email Ids');
        setTimeout(() => setMessage(''), 3000);
        return;
      }
    }

    for (let i = 0; i < invite_emails.length; i++) {
      if (!validator.isEmail(invite_emails[i])) {
        setMessage('Invalid Email');
        setTimeout(() => setMessage(''), 3000);
        return;
      }
    }

    const inviteItem = await inviteUsers(
      {
        workspace_role: workRole,
        invite_emails,
      },
      projectId,
    );

    if (inviteItem && inviteItem.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Invitations sent successfully',
      });
      handleToggle();
    }
  };

  return (
    <div>
      <Dialog onClose={handleToggle} open={props.open} fullWidth maxWidth={'sm'}>
        <div className={classes.root}>
          <Box display="flex" alignItems="center" style={{ marginBottom: 23 }}>
            <Typography variant="h4">Invite users to join</Typography>

            <div style={{ marginLeft: 'auto' }}>
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleToggle} />
            </div>
          </Box>

          <Typography variant={'subtitle1'} mb={1}>
            Email ID
          </Typography>

          <Autocomplete
            multiple
            freeSolo
            className={classes.autoTextField}
            options={memberList ? memberList : []}
            disableClearable
            getOptionLabel={option => option.member_email}
            onChange={(event: any, newValue) => {
              handleInviteEmails(newValue);
            }}
            onKeyDown={handleKeyDown}
            value={inviteEmail}
            clearOnBlur={false}
            renderOption={(props, option, { selected }) => (
              <Box display="flex" alignItems="center" component="li" sx={{ '& > img': { flexShrink: 0 } }} {...props}>
                {userImgFormat(option.member_name, option.member_img).isImg ? (
                  <img
                    src={userImgFormat(option.member_name, option.member_img).userImg}
                    alt=""
                    className={classes.userImgBox}
                  />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.userImgBox}
                    style={{ backgroundColor: `${userImgFormat(option.member_name, option.member_img).bgColor}` }}
                  >
                    <Typography style={{ fontSize: '0.85rem', fontWeight: 700 }}>
                      {userImgFormat(option.member_name, option.member_img).name}
                    </Typography>
                  </Box>
                )}
                <Box pl={1}>
                  <Typography variant={'subtitle1'} fontWeight={700} noWrap>
                    {option.member_name ? option.member_name : 'New user'}
                  </Typography>
                  <Typography variant={'subtitle2'} mt={-1} noWrap>
                    {option.member_email}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Enter emails to invite"
                inputProps={{
                  ...params.inputProps,
                }}
                onChange={e => {
                  handleNewEmails(e.target.value);
                }}
              />
            )}
          />
          <Typography variant={'subtitle2'} color={'text.secondary'}>
            For new emails, add them as comma separated values.
          </Typography>

          <Typography variant={'subtitle1'} mt={3} mb={1}>
            Invite to project
          </Typography>
          {props.organisation ? (
            <TextField
              variant="outlined"
              fullWidth
              select
              value={projectId}
              onChange={e => setProjectId(e.target.value)}
            >
              {projectList?.map(({ workspace_name, workspace_logo, workspace_id, environment_type }, idx) => {
                return (
                  <MenuItem key={idx} value={workspace_id} style={{ paddingBlock: 10 }}>
                    <Box display="flex" alignItems="center">
                      <img
                        src={workspace_logo ? workspace_logo : accountImg}
                        alt=""
                        style={{ width: 22, height: 22, borderRadius: '50%' }}
                      />
                      <Typography variant={'h6'} pl={1} pr={2}>
                        {workspace_name}
                      </Typography>
                      <Typography
                        variant={'subtitle2'}
                        className={classes.envText}
                        style={
                          envStatus[environment_type] && {
                            backgroundColor: envStatus[environment_type].backgroundColor,
                            color: envStatus[environment_type].color,
                          }
                        }
                      >
                        {environment_type}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </TextField>
          ) : (
            <Box className={classes.activeProject}>
              <Box display="flex" alignItems="center">
                <img
                  src={activeProject?.workspace_logo ? activeProject?.workspace_logo : accountImg}
                  alt=""
                  style={{ width: 22, height: 22, borderRadius: '50%' }}
                />
                <Typography variant="h6" style={{ paddingLeft: 7, paddingRight: 21, maxWidth: '30ch' }} noWrap>
                  {activeProject?.workspace_name}
                </Typography>
                {activeProject?.environment_type && (
                  <Typography
                    variant={'subtitle2'}
                    className={classes.envText}
                    style={
                      envStatus[activeProject?.environment_type] && {
                        backgroundColor: envStatus[activeProject?.environment_type].backgroundColor,
                        color: envStatus[activeProject?.environment_type].color,
                      }
                    }
                  >
                    {activeProject?.environment_type}
                  </Typography>
                )}
              </Box>
            </Box>
          )}

          <Typography variant={'subtitle1'} mt={3} mb={1}>
            Assign a project role
          </Typography>
          <TextField variant="outlined" fullWidth select value={workRole} onChange={e => setWorkRole(e.target.value)}>
            <MenuItem value="viewer">Viewer</MenuItem>
            {user?.w_role === 'editor' && myWorkspaces[projectId] === 'editor' && (
              <MenuItem value="editor">Editor</MenuItem>
            )}
          </TextField>
          {message !== '' && <ErrorUtil message={message} />}

          <Button
            variant="contained"
            size="medium"
            endIcon={<ChevronRightIcon fontSize="large"></ChevronRightIcon>}
            className={classes.modalButton}
            onClick={() => inviteMembers()}
          >
            Send invite
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
