import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Checkbox, CheckboxProps } from '@mui/material';

export const GreenCheckbox = withStyles(theme => ({
  root: {
    padding: 0,
    color: theme.palette.text.secondary,
    '&$checked': {
      color: theme.palette.success.main,
    },
  },
  checked: {},
}))((props: CheckboxProps) => <Checkbox {...props} />);
