import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { StyledTableRow } from '../common/tableStyles';

import { ICohort } from '../../types/audience';

import { cohortChannels } from '../../static/connectionData';
import { modifiedDate } from '../../utils/DateUtil';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';
import { numberWithCommas } from '../../utils/stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(4.8),
      paddingTop: 0,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    startCohort: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(8),
    },
    cohortDetailsButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      padding: '0.56rem 1rem 0.5rem 0.9rem',
      borderRadius: '0.3rem',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    questionMenu: {
      '& .MuiPaper-root': {
        width: '220px',
      },
    },
    cohortTable: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      paddingTop: theme.spacing(2),
    },
  }),
);

export default function CohortstabComponent(props: { cohortList: Array<ICohort>; filteredCohortList: Array<ICohort> }) {
  const classes = useStyles();
  const history = useHistory();

  const { cohortList, filteredCohortList } = props;

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'cohorts tab viewed', { tab: 'my cohorts' });
    }
  }, [tracking_info]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.contrastText,
    },
  }));

  return (
    <div>
      {cohortList.length === 0 ? (
        <Grid container lg={12} className={classes.startCohort}>
          <Grid item lg={5} p={4} textAlign="center" border={1} borderColor={'divider'} borderRadius={2}>
            <Typography variant={'h2'}>
              <span role="img" aria-label="emoji">
                ✨
              </span>
              Create a cohort
            </Typography>

            <Typography style={{ marginTop: 15 }}>
              The cohorts initiate a survey inside your app. Create a audience cohort for every critical screen inside
              your app such as homepage, cart or transaction screens.
            </Typography>
            <Button variant="contained" style={{ marginTop: 15 }} onClick={() => history.push('/cohorts/new')}>
              + Create user cohort
            </Button>
          </Grid>
        </Grid>
      ) : filteredCohortList.length > 0 ? (
        <TableContainer className={classes.cohortTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Cohort name</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Last modified</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCohortList.map(
                ({ cohort_id, cohort_name, created_at, total_count, updated_at, created_by, upload_percent }, idx) => {
                  return (
                    <StyledTableRow key={idx}>
                      <TableCell component="th" scope="row" width={'5%'}>
                        {idx + 1}
                      </TableCell>
                      <TableCell width={'25%'}>{cohort_name}</TableCell>
                      <TableCell width={'20%'}>{modifiedDate(created_at, 'lll')}</TableCell>
                      <TableCell width={'10%'}>{+total_count > 0 ? numberWithCommas(total_count) : '0'}</TableCell>
                      <TableCell width={'20%'}>{modifiedDate(updated_at, 'lll')}</TableCell>
                      <TableCell width={'15%'}>
                        <Box display="flex" alignItems="center">
                          {cohortChannels[created_by].img && (
                            <img
                              src={cohortChannels[created_by].img}
                              alt=""
                              style={{ height: '23px', width: '23px', paddingRight: '10px' }}
                            />
                          )}
                          <Typography variant={'subtitle1'}>
                            {cohortChannels[created_by] ? cohortChannels[created_by].name : ''}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell width={'25%'}>
                        {created_by === 'csv' && upload_percent !== undefined ? (
                          <Box sx={{ width: '75%' }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={upload_percent < 100 ? upload_percent : 100}
                            />
                            <Typography textAlign={'center'} mt={0.5} fontWeight={700} variant={'subtitle2'}>
                              uploading
                            </Typography>
                          </Box>
                        ) : (
                          <Button
                            className={classes.cohortDetailsButton}
                            onClick={() =>
                              history.push({
                                pathname: `/cohorts/${cohort_id}`,
                                state: {
                                  page: `${created_by}`,
                                },
                              })
                            }
                          >
                            <Typography variant={'subtitle2'} fontWeight={700} noWrap>
                              View details
                            </Typography>
                          </Button>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant={'body2'} color={'text.secondary'} textAlign="center">
          No cohorts to display
        </Typography>
      )}
    </div>
  );
}
