import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import loginReducer from '../slices/loginSlice';
import workspaceReducer from '../slices/workspaceSlice';
import trackingReducer from '../slices/trackingSlice';
import chartReducer from '../slices/chartSlice';
import surveyFilterReducer from '../slices/surveySlice';
import performanceConditionsReducer from '../slices/performanceSlice';
import dashboardReducer from '../slices/dashboardSlice';

const reducer = {
  login: loginReducer,
  workspace: workspaceReducer,
  tracking: trackingReducer,
  chart: chartReducer,
  surveyFilter: surveyFilterReducer,
  performanceConditions: performanceConditionsReducer,
  dashboard: dashboardReducer,
};

const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export default store;
