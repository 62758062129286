import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography, Button, Dialog, TextField } from '@mui/material';

import deleteImg from '../../images/delete-icon.svg';

import { dashboardStyles } from './DashboardStyle';
import { GreenSwitch } from '../../styles/customStyles';

import { retrieveChartDetails } from '../../slices/chartSlice';
import { RootState, useAppDispatch } from '../../store';
import AlertUtil from '../../utils/AlertUtil';
import { createBoard, editBoardDetails } from '../../services/spotlightService';

import publicIcon from '../../images/board/public-icon.svg';
import { useSelector } from 'react-redux';
import { resetDashboard, retrieveDashboardDetails, updateValue } from '../../slices/dashboardSlice';

export const DashboardModal: FunctionComponent<{
  open: boolean;
  toggleModal: (value: boolean) => void;
  surveyId?: string;
  boardId?: string;
}> = ({ open, toggleModal, surveyId, boardId }) => {
  const classes = dashboardStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [error, setError] = useState('');

  const dashboardDetails = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    if (boardId) {
      dispatch(retrieveDashboardDetails(boardId));
    } else {
      dispatch(resetDashboard());
    }

    if (surveyId) {
      dispatch(updateValue({ survey_id: surveyId }));
    }
  }, []);

  const { name, public_access, description, survey_id = '' } = dashboardDetails;

  const handleClose = () => {
    toggleModal(false);
  };

  // handle dashboard name and description
  const handleDashboardValue = (filter_field: string, filter_value: string) => {
    dispatch(updateValue({ ...dashboardDetails, [filter_field]: filter_value }));
  };

  // handle public access of the dashboard
  const handlePublicAccess = () => {
    const public_access_value = public_access === 'public' ? 'private' : 'public';
    dispatch(updateValue({ ...dashboardDetails, public_access: public_access_value }));
  };

  // create new dashboard
  const createDashboard = async () => {
    const result = await createBoard({
      name,
      public_access,
      description,
      survey_id,
    });
    if (result && result.board_id) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Dashboard created successfully',
      });

      history.push(`/board/${result.board_id}`);
      dispatch(retrieveChartDetails());
    }
  };

  // Edit dashboard
  const editDashboard = async () => {
    if (!boardId) {
      return;
    }
    const result = await editBoardDetails(
      {
        name,
        public_access,
        description,
        survey_id,
      },
      boardId,
    );
    if (result) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Saved successfully',
      });
      dispatch(retrieveChartDetails());
      handleClose();
    }
  };

  const handleButtonClick = () => {
    if (name.trim() === '') {
      setError('Dashboard name cannot be empty');
      setTimeout(() => setError(''), 2000);
      return;
    }
    if (boardId) {
      editDashboard();
    } else {
      createDashboard();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <Grid container className={classes.modalContainer}>
          <Grid item lg={12}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant={'h6'} fontWeight={700}>
                {boardId ? 'Edit dashboard' : 'Create new dashboard'}
              </Typography>
              <div>
                <img src={deleteImg} alt="" style={{ height: 28, cursor: 'pointer' }} onClick={handleClose} />
              </div>
            </Box>
            <Box pt={2}>
              <Typography variant="subtitle1" pb={0.7}>
                Dashboard name
              </Typography>
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="Enter dashboard name"
                className={classes.nameField}
                value={name}
                name="name"
                onChange={e => handleDashboardValue(e.target.name, e.target.value)}
              />
            </Box>

            <Box pt={1}>
              <Typography variant="subtitle1" pb={0.7}>
                Description
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                placeholder="Enter description text"
                className={classes.descField}
                value={description}
                name="description"
                onChange={e => handleDashboardValue(e.target.name, e.target.value)}
              />
            </Box>

            <Box display="flex" alignItems="center" justifyContent={'space-between'} mt={2.5}>
              <div>
                <Box display="flex" alignItems="center">
                  <img src={publicIcon} alt="" style={{ marginTop: '2px' }} />
                  <Typography variant="subtitle1" fontWeight={700} pl={0.8}>
                    Public access
                  </Typography>
                </Box>
                <Typography variant="subtitle2" pl={2.8} mt={-0.6}>
                  Anyone that has the dashboard link can view this dashboard
                </Typography>
              </div>
              <GreenSwitch checked={public_access === 'public'} onChange={() => handlePublicAccess()} />
            </Box>
            {error && (
              <Typography fontSize={12} fontWeight="bold" color={'red'} mt={0.5}>
                {error}
              </Typography>
            )}
            <Box className={classes.buttonSection}>
              <Button variant="contained" onClick={() => handleButtonClick()}>
                <Typography variant="subtitle1">{boardId ? 'Save' : 'Create dashboard & link'}</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
