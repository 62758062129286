import moment from 'moment';
import {
  scaleQuestionsRules,
  singleSelectQuestionRules,
  multiSelectQuestionRules,
  inputQuestionRules,
  collectionQuestionRules,
  questionTypes,
} from '../static/questionRule';
import { ISurvey, questionType } from '../types/survey';
import { isValidUrl } from './stringUtil';

const selectQuestionFormats = ['multi_select_feedback', 'single_select_feedback'];

const getLabel = (questionType: string) => {
  const question = questionTypes.find(q => q.value === questionType);
  return question?.label;
};

export const questionDefault = (question_type: string, question_order: number) => {
  let props: any;
  if (question_type === 'nps_feedback' || question_type === 'scale_feedback') {
    props = {
      properties: {
        randomize: 'in_set',
        labels: {
          left: 'Not likely',
          right: 'Extremely likely',
        },
        options: [],
      },
    };
  } else if (question_type === 'star_feedback' || question_type === 'emoji_feedback') {
    props = {
      properties: {
        randomize: 'in_set',
        labels: {
          left: '',
          right: '',
        },
        options: [],
      },
    };
  } else if (question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') {
    props = {
      properties: {
        randomize: 'in_set',
        options: [
          {
            option_text: '',
            order: 1,
            has_text_input: false,
          },
        ],
        labels: {},
      },
    };
  } else if (question_type === 'intro_prompt' || question_type === 'input_feedback') {
    props = {
      properties: { randomize: 'in_set', labels: {}, options: [] },
      placeholder: 'Type something here..',
    };
  } else if (question_type === 'data_collection') {
    props = {
      properties: { randomize: 'in_set', labels: {}, options: [] },
      placeholder: 'Type something here..',
      validations: {
        field_type: 'string',
        min_length: 1,
        max_length: 255,
      },
    };
  }
  if (props)
    return {
      question_type,
      question_desc: 'This will help us improve your experience',
      cta_text: 'Next',
      cta_link: '',
      question_text: '',
      question_img: '',
      question_variant: '',
      editable: true,
      question_order,
      question_id: question_order,
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: '',
      max_selections: -1,
      ...props,
    };
  return null;
};

export const getQuestionChangeData = (question: questionType, change_question_type: string, question_order: number) => {
  let new_question = questionDefault(change_question_type, question_order);

  if (!question) {
    return new_question;
  }

  const {
    question_type,
    question_text,
    question_img = '',
    question_desc = '',
    properties,
    prototype_link = '',
    is_mandatory,
    placeholder = '',
    cta_text = '',
    cta_link = '',
  } = question;

  new_question = {
    ...new_question,
    question_text,
    question_img,
    question_desc,
    prototype_link,
    placeholder,
    cta_text,
    cta_link,
  };

  if (selectQuestionFormats.includes(question_type)) {
    if (selectQuestionFormats.includes(change_question_type)) {
      new_question = {
        ...new_question,
        properties,
        is_mandatory,
      };
    }
  }

  if (question_type == 'nps_feedback' || question_type == 'scale_feedback') {
    if (change_question_type == 'nps_feedback' || change_question_type == 'scale_feedback') {
      new_question = {
        ...new_question,
        properties,
      };
    }
  }

  return new_question;
};

export const questionRule = (question_type: string, question_order: number, question_count: number) => {
  let props: any;
  if (
    question_type === 'nps_feedback' ||
    question_type === 'scale_feedback' ||
    question_type === 'star_feedback' ||
    question_type === 'emoji_feedback'
  ) {
    props = {
      rule_type: scaleQuestionsRules[0].value,
      option: '',
    };
  } else if (question_type === 'single_select_feedback') {
    props = {
      rule_type: singleSelectQuestionRules[0].value,
      options: [],
    };
  } else if (question_type === 'multi_select_feedback') {
    props = {
      rule_type: multiSelectQuestionRules[0].value,
      options: [],
    };
  } else if (question_type === 'intro_prompt' || question_type === 'input_feedback') {
    props = {
      rule_type: inputQuestionRules[0].value,
    };
  } else if (question_type === 'data_collection') {
    props = {
      rule_type: collectionQuestionRules[0].value,
      data_array: '',
      options: [],
    };
  }
  if (props) return { ...props, skip_to: question_order < question_count ? question_order + 1 : -1 };
  else return null;
};

export const checkSurveyForPublish = (survey: ISurvey, triggerCheck: boolean, checkSettings = true) => {
  if (!survey) {
    return { success: false, message: `` };
  }

  const {
    condition,
    trigger_with_events,
    platform,
    questions,
    survey_response_limit,
    survey_retake_days,
    survey_end_date,
    show_intro,
    intro_card_title,
    intro_card_cta,
    trigger_delay,
    survey_daily_limit,
    published_at,
    is_backend_trigger,
    backend_triggers,
    research_type,
    survey_repeat_frequency,
    survey_repeat_days,
    survey_views_limit,
    survey_repeat_loop,
  } = survey;

  if (show_intro && intro_card_title.length > 60) {
    return { success: false, message: 'Intro card title can contain maximum 60 characters.' };
  }
  if (show_intro && intro_card_cta.length > 9) {
    return { success: false, message: 'Intro card cta can contain maximum 9 characters.' };
  }

  if (platform === 'api') {
    checkSettings = false;
  }

  if (checkSettings) {
    const dateDiffFromNow = moment(survey_end_date).diff(moment().format('Y-MM-DD'), 'days');
    if (survey_response_limit < 1 || survey_response_limit > 1000000 || !survey_response_limit) {
      return { success: false, message: 'Survey response limit should be in 1 to 1,000,000' };
    }

    if (published_at && platform !== 'link') {
      const publish_date = moment(new Date(published_at));
      const end_date = moment(new Date(survey_end_date));

      const overall_days = end_date.diff(publish_date, 'days');
      if (overall_days > 365)
        return {
          success: false,
          message: `Survey published on ${moment(published_at).format(
            'Y-MM-DD',
          )}. Survey maximum duration (365 days) is exceeded.`,
        };
    }

    if (survey_daily_limit < 1 || survey_daily_limit > 10000) {
      return { success: false, message: 'Survey daily limit should be within 1 to 10,000' };
    }

    if (platform !== 'link') {
      if (dateDiffFromNow < 0 || dateDiffFromNow > 365) {
        return { success: false, message: 'Survey end date should be within 365 days from today' };
      }
    }

    if (survey_retake_days < 0 || survey_retake_days > 90) {
      return { success: false, message: 'Survey First time display must be between 0 to 90 days' };
    }

    if (trigger_delay < 0 || trigger_delay > 600) {
      return { success: false, message: 'Trigger delay should be within 0 to 600 seconds.' };
    }

    if (triggerCheck && platform !== 'link' && trigger_with_events.length === 0) {
      if (trigger_delay < 0 || trigger_delay > 600) {
        return { success: false, message: 'Trigger delay should be within 0 to 600 seconds.' };
      }
      return { success: false, message: 'Attach triggers to publish the survey from Survey settings' };
    }

    if (is_backend_trigger) {
      for (let i = 0; i < backend_triggers.length; i++) {
        const { event_name, expires_at } = backend_triggers[i];
        if (event_name.trim() === '') {
          return { success: false, message: 'Backend event name cannot be empty' };
        }
        if (expires_at < 1 || expires_at > 96) {
          return { success: false, message: 'Expiring days should be in range 1 to 96 hours' };
        }
      }
    }

    if (survey_repeat_frequency.trim() === '') {
      return { success: false, message: 'Survey repeat frequency cannot be empty' };
    }

    if ((survey_repeat_frequency && survey_repeat_days < 0) || survey_repeat_days > 90) {
      return { success: false, message: 'Survey repeat days should be within 0 to 90 days.' };
    }

    if (survey_views_limit && (survey_views_limit < 1 || survey_views_limit > 100)) {
      return { success: false, message: 'Survey views limit should be within 1 to 100' };
    }

    if (survey_repeat_loop && (survey_repeat_loop < 1 || survey_repeat_loop > 180)) {
      return { success: false, message: 'Survey repeat sequence should be within 1 to 180' };
    }

    if (condition && condition.length > 0) {
      for (let i = 0; i < condition.length; i++) {
        const { attribute, comparator, data_type, list, value } = condition[i];
        if (attribute.trim() === '') {
          return { success: false, message: 'Attribute cannot be empty' };
        }
        if (comparator.trim() === '') {
          return { success: false, message: 'Comparator cannot be empty' };
        }
        if (data_type === 'string' && list.length === 0) {
          return { success: false, message: 'Cohort conditions values cannot be empty' };
        }
        if (data_type !== 'string' && (value === undefined || value === null || value === '')) {
          return { success: false, message: 'Cohort conditions value cannot be empty' };
        }
      }
    }

    if (trigger_with_events && trigger_with_events.length > 0) {
      for (let i = 0; i < trigger_with_events.length; i++) {
        const { events } = trigger_with_events[i];
        for (let j = 0; j < events.length; j++) {
          const { attribute, comparator, data_type, list, value } = events[j];

          if (attribute.trim() === '') {
            return { success: false, message: 'Event attribute cannot be empty' };
          }
          if (comparator.trim() === '') {
            return { success: false, message: 'Event comparator cannot be empty' };
          }
          if (data_type === 'string' && list.length === 0) {
            return { success: false, message: 'Event attribute values cannot be empty' };
          }
          if (data_type !== 'string' && (value === undefined || value === null || value === '')) {
            return { success: false, message: 'Event attribute value cannot be empty' };
          }
        }
      }
    }
  }

  if (!questions || questions.length === 0) {
    return { success: false, message: 'Add atleast one question to the survey' };
  }

  for (let i = 0; i < questions.length; i++) {
    const {
      question_text,
      question_type,
      properties,
      cta_text,
      rule_sets,
      prototype_link,
      validations = {},
    } = questions[i];

    if (research_type === 'prototype' && !isValidUrl(prototype_link)) {
      return { success: false, message: 'Enter a valid url' };
    }

    if (question_text.trim() === '') {
      return { success: false, message: 'Question Text cannot be empty' };
    }

    if (question_type === 'nps_feedback' || question_type === 'scale_feedback') {
      if (!properties || !properties.labels || !properties.labels.left || !properties.labels.right) {
        return { success: false, message: `${getLabel(question_type)} cannot have empty labels` };
      }
    } else if (question_type === 'intro_prompt') {
      if (!cta_text) {
        return { success: false, message: `${getLabel(question_type)} cannot have empty CTA text` };
      }
    } else if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
      if (!properties || !properties.options || properties.options.length === 0) {
        return { success: false, message: `${getLabel(question_type)} options should not be have empty` };
      }
      if (properties.options) {
        const select_options = [] as string[];
        for (const opt in properties.options) {
          select_options.push(properties.options[opt].option_text.trim());
        }
        const findDuplicate = select_options.filter((item, index) => select_options.indexOf(item) !== index);
        if (findDuplicate.length > 0) {
          return { success: false, message: `${getLabel(question_type)} options should not be same` };
        }
      }
      if (properties.options) {
        for (const opt in properties.options) {
          const { option_text } = properties.options[opt];
          if (!option_text.trim()) {
            return { success: false, message: `${getLabel(question_type)} options should not be have empty` };
          }
        }
      }

      if (properties.options && research_type === 'quiz') {
        let has_correct_answer = false;
        for (const opt in properties.options) {
          has_correct_answer = properties.options.some(opt => opt.is_correct_option === true);
        }

        if (!has_correct_answer) {
          return {
            success: false,
            message: `${getLabel(question_type)} options should have a correct option selected`,
          };
        }
      }
    } else if (question_type === 'data_collection') {
      if (
        !validations ||
        validations.min_length === null ||
        validations.min_length === undefined ||
        validations.max_length === null ||
        validations.max_length === undefined
      ) {
        return { success: false, message: `${getLabel(question_type)} validation cannot be empty` };
      }
      if (
        validations.min_length <= 0 ||
        validations.min_length > 255 ||
        validations.max_length <= 0 ||
        validations.max_length > 255
      ) {
        return {
          success: false,
          message: `${getLabel(question_type)} validation character should be in range 1 - 255`,
        };
      }
    }

    if (rule_sets.length > 0) {
      for (const i in rule_sets) {
        const { rule_type, option, options } = rule_sets[i];

        if (rule_type !== 'submit') {
          if (
            question_type === 'nps_feedback' ||
            question_type === 'scale_feedback' ||
            question_type === 'star_feedback' ||
            question_type === 'emoji_feedback'
          ) {
            if (option === null || option === undefined) {
              return { success: false, message: `${getLabel(question_type)} question conditions cannot be empty` };
            }

            if (question_type === 'nps_feedback' && (option < 0 || option > 10)) {
              return { success: false, message: `${getLabel(question_type)} conditions should be in range 0 - 10` };
            }

            if (
              (question_type === 'scale_feedback' ||
                question_type === 'star_feedback' ||
                question_type === 'emoji_feedback') &&
              (option < 1 || option > 5)
            ) {
              return { success: false, message: `${getLabel(question_type)} conditions should be in range 1 - 5` };
            }
          }

          if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
            if (options === null || options === undefined || options.length === 0) {
              return { success: false, message: `${getLabel(question_type)} question conditions cannot be empty` };
            }
          }
        }
        // if (skip_to === null || skip_to === undefined || skip_to === questions.length + 1) {
        //   return { success: false, message: `${getLabel(question_type)} question rule sets can't have empty jump to` };
        // }
      }
    }
  }
  return { success: true, message: '' };
};

export const getModifiedText = (str: string) => {
  str = str.replace('_', ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getNps = (options: any) => {
  const promoterItem = options.find((element: { label: string }) => element.label === 'Promoters');
  const decoratorItem = options.find((element: { label: string }) => element.label === 'Detractors');
  if (!promoterItem || !decoratorItem) return 0;
  return Math.ceil((promoterItem.percent - decoratorItem.percent) * 100);
};

export const getRuleType = (rule_type: string) => {
  if (rule_type === 'in_list_exact') {
    return 'Is exactly';
  } else if (rule_type === 'in_list_once') {
    return 'Is one of';
  } else if (rule_type === 'submit') {
    return 'On submit';
  } else if (rule_type === 'eq') {
    return 'Equals to';
  } else if (rule_type === 'neq') {
    return 'Not Equals to';
  } else if (rule_type === 'lt') {
    return 'Lesser than';
  } else if (rule_type === 'lte') {
    return 'Lesser than Equal to';
  } else if (rule_type === 'gt') {
    return 'Greater than';
  } else if (rule_type === 'gte') {
    return 'Greater than Equal to';
  }
};

//performance component
export const getValue = (status: string, responses: number, survey_response_limit: number) => {
  if (status === 'completed' || status === 'archived') {
    return 100;
  } else if (status === 'draft') {
    return 0;
  } else {
    return ((responses / survey_response_limit) * 100).toFixed(1);
  }
};

export const getResponseRate = (total_responses: number, sent_count: number) => {
  if (sent_count > 0) {
    return ((total_responses / sent_count) * 100).toFixed(1);
  } else {
    return 0;
  }
};

export const validateDataField = (str: string, validations: any, is_mandatory: boolean) => {
  //empty
  if (!str || str.trim().length === 0) {
    return {
      goto_next: !is_mandatory,
      save_answer: false,
      message: 'Enter valid input',
    };
  }
  const str_length = str.trim().length;
  if (validations && validations.field_type) {
    if (validations && validations.min_length > 0 && validations.min_length > str_length) {
      return {
        goto_next: false,
        save_answer: false,
        message: `Minimum characters should be above ${validations.min_length}.`,
      };
    }
    if (validations && validations.max_length && validations.max_length < str_length) {
      return {
        goto_next: false,
        save_answer: false,
        message: `Maximum characters limit of ${validations.max_length} exceeded`,
      };
    }
    if (validations.field_type === 'number') {
      //regrex for [0-9]
      if (!str.match('[0-9]+'))
        return {
          goto_next: false,
          save_answer: false,
          message: 'Please enter only valid number',
        };
    }
    if (validations.field_type === 'email') {
      //regrex for email
      const regexForEmail = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      if (!regexForEmail.test(str))
        return {
          goto_next: false,
          save_answer: false,
          message: 'Please enter a valid email',
        };
    }
  }
  return { goto_next: true, save_answer: true, message: '' };
};
